import { QueryConstraint, collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { Voyage } from "./voyages";
import { useState, useEffect } from "react";
import { sharedState } from "../shared-state";
import { ScheduledMaintenanceTask } from '../VesselMaintenance/maintenanceSchedule';
import { MaintenanceTaskCompleted } from '../VesselMaintenance/maintenanceTasksCompleted';
import { registerFiles } from "../FileSyncSystem/filesToCache";

const voyageTimeLeeway = 5 * 60 * 1000; // Allow 5 minutes either side of voyage to match completed checks and tasks

// This returns the ScheduledMaintenanceTask that were completed during a voyage
export const useMaintenanceTasksCompletedDuringVoyage = (selectedVoyage: Voyage | undefined, includeLeeway?: boolean) => {
    const scheduledMaintenanceTasks = sharedState.scheduledMaintenanceTasks.use();
    const [maintenanceTasksCompletedDuringVoyage, setMaintenanceTasksCompletedDuringVoyage] = useState<ScheduledMaintenanceTask[]>();

    useEffect(() => {
        if (selectedVoyage && scheduledMaintenanceTasks?.byId) {
            const queries: QueryConstraint[] = [where('vesselId', '==', selectedVoyage.vesselId), where('state', '==', 'completed'), where('type', '==', 'scheduled')];

            if (includeLeeway) {
                queries.push(where('whenCompleted', '>=', selectedVoyage.whenDeparted - voyageTimeLeeway));
                queries.push(where('whenCompleted', '<=', (selectedVoyage.whenArrived || 0) + voyageTimeLeeway));
            } else {
                queries.push(where('whenCompleted', '>=', selectedVoyage.whenDeparted));
            }

            return onSnapshot(
                query(collection(firestore, 'maintenanceTasksCompleted'), ...queries),
                (snap) => {
                    const items = [] as ScheduledMaintenanceTask[];
                    snap.docs.forEach((doc) => {
                        const item = {
                            id: doc.id,
                            ...doc.data(),
                        } as MaintenanceTaskCompleted;
                        registerFiles(item.files, 'maintenanceTasksCompleted', item);
                        if (item.maintenanceTaskId && scheduledMaintenanceTasks.byId[item.maintenanceTaskId]) {
                            items.push(scheduledMaintenanceTasks.byId[item.maintenanceTaskId]);
                        }
                    });
                    setMaintenanceTasksCompletedDuringVoyage(items);
                }, (error) => {
                    console.log(`Failed to access maintenanceTasksCompleted for voyageId=${selectedVoyage?.id} (tab)`, error.message, error, {
                        selectedVoyage,
                    });
                }
            );
        }
    }, [includeLeeway, scheduledMaintenanceTasks?.byId, selectedVoyage]);

    return maintenanceTasksCompletedDuringVoyage;
};

import React from 'react';
import { loadingSystem, profileSharedState, sharedState } from '../../../shared-state/shared-state';
import SeaScrollableArea from '../../../components/SeaScrollableArea/SeaScrollableArea';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaTabsGroup from '../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../../components/SeaTab/SeaTab';
import SharedStateTab from './SharedStateTab/SharedStateTab';
import SharedStateProfilerTab from './SharedStateProfilerTab/SharedStateProfilerTab';
import DebugDataSyncTab from './DebugDataSyncTab/DebugDataSyncTab';
import DebugFilesTab from './DebugFilesTab/DebugFilesTab';
import AppActivityViewer from '../../../components/AppActivityViewer/AppActivityViewer';
import DebuggingTab from './DebuggingTab/DebuggingTab';
import './DebugView.css';

export const debugTabs = ['State', 'Debugging', 'Data Cache', 'Files', 'Loading System', 'App Activity'];
if (profileSharedState) {
    debugTabs.splice(1, 0, 'State Profiler');
}

const DebugView: React.FC = () => {
    const debugView = sharedState.debugView.use()!;

    return (
        <div
            className="debug-view"
            style={{
                position: 'fixed',
                width: '100%',
                height: '100%',
                //backgroundColor: '#e9e5e9',
                backgroundColor: '#33333f',
                color: 'white',
                left: '0px',
                right: '0px',
                top: '0px',
                bottom: '0px',
                zIndex: 1,
                paddingTop: 'var(--safe-inset-top)'
            }}
        >
            <div className="columns" style={{ height: '64px', padding: '4px 8px 0px 8px', justifyContent: 'space-between', overflowX: 'auto' }}>
                <div className="top-head">
                    <SeaTabsGroup
                        selectedTab={debugView.tab}
                        setTab={(tab: string) => sharedState.debugView.set((current) => {
                            return {
                                ...current!,
                                tab: tab
                            };
                        })}
                        mode="forms"
                    >
                        {debugTabs.map((tab) => {
                            return <SeaTab key={tab} tab={tab} mode="forms">{tab}</SeaTab>
                        })}
                    </SeaTabsGroup>
                </div>
                <div style={{ textAlign: 'right' }}>
                    <SeaButton
                        onClick={(e) => sharedState.debugView.set((current) => {
                            return { ...current!, show: false };
                        })}
                    >X</SeaButton>
                </div>
            </div>
            <SeaScrollableArea scrollX className="debug-view-scroll">
                {debugView.tab === 'State' && <SharedStateTab />}
                {debugView.tab === 'State Profiler' && <SharedStateProfilerTab />}
                {debugView.tab === 'Debugging' && <DebuggingTab />}
                {debugView.tab === 'Data Cache' && <DebugDataSyncTab/>}
                {debugView.tab === 'Files' && <DebugFilesTab/> }
                {debugView.tab === 'Loading System' &&
                    <div style={{ padding: '4px 8px 0px 8px' }}>
                        <div style={{ padding: '8px', whiteSpace: 'pre-wrap', cursor: 'text' }}>
                            {JSON.stringify(loadingSystem, undefined, '    ')}
                        </div>
                    </div>
                }
                {debugView.tab === 'App Activity' &&
                    <div style={{ padding: '4px 8px 0px 8px' }}>
                        <div style={{ padding: '8px', whiteSpace: 'pre-wrap', cursor: 'text' }}>
                            <p>
                                __appIsOkToRestart: {window.localStorage.getItem('__appIsOkToRestart')}
                                <br/>
                                __appLastState: {window.localStorage.getItem('__appLastState')}
                            </p>
                            <AppActivityViewer/>
                            {/* {JSON.stringify(appActivity, undefined, '    ')} */}
                        </div>
                    </div>
                }
            </SeaScrollableArea>
        </div>
    );
};

export default DebugView;

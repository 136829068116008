import React, { useState, useMemo, useEffect } from 'react';
import { formatTextAreaText, formatDate, formatValue, formatInterval, warnDays, formatSparePartsList, renderCamelCase } from '../../../../lib/util';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { permissionLevels, canView, canComplete, canEdit } from '../../../../shared-state/Core/userPermissions';
import { WriteBatch } from "firebase/firestore";
import { renderCategoryName } from '../../../../lib/categories';
import { SplittableBatch } from '../../../../lib/firebase';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { sharedState } from '../../../../shared-state/shared-state';
import { MaintenanceTaskCompleted } from '../../../../shared-state/VesselMaintenance/maintenanceTasksCompleted';
import { SparePart } from '../../../../shared-state/VesselMaintenance/spareParts';
import { ScheduledMaintenanceTask } from '../../../../shared-state/VesselMaintenance/maintenanceSchedule';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import EditMaintenanceSchedule from '../EditMaintenanceSchedule/EditMaintenanceSchedule';
import CompleteMaintenanceSchedule from '../CompleteMaintenanceSchedule/CompleteMaintenanceSchedule';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaStatusDueHours from '../../../../components/SeaStatusDueHours/SeaStatusDueHours';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaTabsGroup from '../../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../../../components/SeaTab/SeaTab';
import SeaTabContent from '../../../../components/SeaTabContent/SeaTabContent';
import ViewSparePart from '../../SparePartsList/ViewSparePart/ViewSparePart';
import './ViewMaintenanceSchedule.css';

interface ViewMaintenanceScheduleProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem?: ScheduledMaintenanceTask,
    level: number
}

const ViewMaintenanceSchedule: React.FC<ViewMaintenanceScheduleProps> = ({
    showModal,
    setShowModal,
    selectedItem,
    level
}) => {
    const vessel = sharedState.vessel.use(showModal);
    const engines = sharedState.engines.use(showModal);
    const contacts = sharedState.contacts.use(showModal);
    const equipment = sharedState.equipment.use(showModal);
    const equipmentManualDocuments = sharedState.equipmentManualDocuments.use(showModal);
    const maintenanceTasksCompleted = sharedState.maintenanceTasksCompleted.use(showModal);
    const spareParts = sharedState.spareParts.use(showModal);
    const vesselSystems = sharedState.vesselSystems.use(showModal);
    const vesselLocations = sharedState.vesselLocations.use(showModal);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [tab, setTab] = useState("history");
    const [editScheduledTaskCompletedModal, setEditScheduledTaskCompletedModal] = useState<{
        show: boolean,
        selectedItem: ScheduledMaintenanceTask | undefined,
        historyItemToUpdate: MaintenanceTaskCompleted | undefined
    }>({
        show: false,
        selectedItem: undefined ,
        historyItemToUpdate: undefined
    });
    const [viewSparePartModal, setViewSparePartModal] = useState<{
        show: boolean,
        selectedItem: SparePart | undefined,
        level: number
    }>({
        show: false, selectedItem: undefined, level: 1
    });

    // Keep viewSparePartModal.selectedItem fresh
    useEffect(() => {
        if (viewSparePartModal?.selectedItem?.id && spareParts?.byId[viewSparePartModal.selectedItem.id]) { 
            setViewSparePartModal((current) => {
                return {
                    ...current,
                    selectedItem: viewSparePartModal.selectedItem?.id ? spareParts.byId[viewSparePartModal.selectedItem.id] : undefined
                }
            });
        }
    }, [spareParts, viewSparePartModal?.selectedItem?.id]);

    const sortedMaintenanceTasksCompleted = useMemo(() => {
        if (selectedItem?.id && maintenanceTasksCompleted?.byMaintenanceTaskId[selectedItem.id]) {
            const tasksSorted = [...maintenanceTasksCompleted.byMaintenanceTaskId[selectedItem.id]].sort((a, b) => b.whenCompleted - a.whenCompleted);
            
            // Update whenLastService if the highest whenCompleted changes, or in case the wrong value has been set previously
            if (tasksSorted.length > 0) {
                const highestWhenCompleted = tasksSorted[0].whenCompleted;
                if (selectedItem.whenLastService !== highestWhenCompleted) {
                    selectedItem.whenLastService = highestWhenCompleted;
                }
            }
    
            return tasksSorted;
        }
        
        return [];
    }, [selectedItem, maintenanceTasksCompleted?.byMaintenanceTaskId]);

    const equipmentTasksCompleted = useMemo(() => {
        if (showModal && selectedItem && maintenanceTasksCompleted) {
            const tasksCompleted: MaintenanceTaskCompleted[] = [];
            maintenanceTasksCompleted.all.forEach((taskCompleted) => {
                if (taskCompleted.equipmentId === selectedItem.equipmentId) {
                    tasksCompleted.push(taskCompleted);
                }
            });
            tasksCompleted.sort((a, b) => {
                return b.whenCompleted - a.whenCompleted;
            });
            return tasksCompleted;
        }
        return [];
    }, [showModal, selectedItem, maintenanceTasksCompleted]);

    const equipmentSpareParts = useMemo(() => {
        if (showModal && selectedItem && spareParts) {
            const _spareParts: SparePart[] = [];
            spareParts.all.forEach((sparePart) => {
                if (sparePart.equipmentIds) {
                    for (let i = 0; i < sparePart.equipmentIds.length; i++) {
                        if (sparePart.equipmentIds[i] === selectedItem.equipmentId) {
                            _spareParts.push(sparePart);
                            break;
                        }
                    }
                }
            });
            return _spareParts;
        }
        return undefined;
    }, [showModal, selectedItem, spareParts]);

    const onEditItem = () => {
        setShowEditItemModal(true);
    };
    const onCompleteItem = (item?: MaintenanceTaskCompleted) => {
        console.log(`View completed maintenance task ${item?.id}`);
        setEditScheduledTaskCompletedModal({
            show: true,
            selectedItem: selectedItem,
            historyItemToUpdate: item
        });
    };
    const canEditItem = useMemo(() => {
        return canEdit('maintenanceHistory')
    }, []);

    const onViewSparePart = (item: SparePart) => {
        setViewSparePartModal({
            show: true,
            selectedItem: item,
            level: level+1
        });
    };

    const selectedEquipment = useMemo(() => {
        if (selectedItem?.equipmentId && equipment?.byId[selectedItem.equipmentId]) {
            return equipment.byId[selectedItem.equipmentId];
        }
        return undefined;
    }, [equipment, selectedItem]);

    if (!selectedItem) {
        return <></>;
    }

    return (
        <SeaModal
            title={
                <>
                    <span style={{marginRight: '12px'}}>
                        {selectedItem?.equipment?.equipment}
                        {selectedItem?.equipment?.state === 'deleted' && ' (deleted)'}
                    </span>
                    {selectedItem && (
                        selectedItem.useHours ?
                        <SeaStatusDueHours
                            engineHoursLeft={selectedItem.engineHoursLeft}
                            inline={true}
                        />
                        :
                        <SeaStatusDueDate
                            whenDue={selectedItem.whenDue}
                            warnDays={warnDays.maintenanceSchedule[0]}
                            inline={true}
                        />
                    )}
                </>
            }
            showModal={showModal}
            setShowModal={setShowModal}
            size='wide'
            level={level}
        >

            {selectedItem?.engineId && engines?.byId[selectedItem.engineId].state !== 'active' &&
                <div style={{color: 'var(--ion-color-danger)', fontWeight: 'bold', paddingBottom: '20px'}}>
                    WARNING: This task is connected to an engine that has been {
                        engines?.byId[selectedItem.engineId].state === 'archived' ? 'archived' : 'deleted'
                    }!
                </div>
            }
            <div className="info-image">
                <SeaFileImage
                    files={selectedItem?.equipment?.files}
                    size="medium"
                    showOthers
                />
            </div>
            {selectedItem &&
                <SeaGrid>
                    <SeaGridCell label="Task Title" w="50">
                        {formatValue(formatTextAreaText(selectedItem?.task))}
                    </SeaGridCell>
                    <SeaGridCell label="Interval" w="50">
                        {(selectedItem.intervalType === 'weekMonth' || selectedItem.intervalType === 'weekMonthAndHours') && formatInterval(selectedItem.intervalWeekMonth)}
                        {selectedItem.intervalType === 'weekMonthAndHours' && <br/>}
                        {(selectedItem.intervalType === 'engineHours' || selectedItem.intervalType === 'weekMonthAndHours') && `${selectedItem.intervalEngineHours} Hours`}
                    </SeaGridCell>
                    <SeaGridCell label="Task Description" w="100">
                        {formatValue(formatTextAreaText(selectedItem?.description))}
                    </SeaGridCell>
                    <SeaGridCell label="Maintenance Tags" w="100">
                        {formatValue(selectedItem?.maintenanceTags?.join(', '))}
                    </SeaGridCell>  
                    <SeaGridCell label="System" w="50">
                        {renderCategoryName(selectedEquipment?.systemId, vesselSystems)}
                    </SeaGridCell>
                    <SeaGridCell label="Location" w="50">
                        {formatValue(renderCategoryName(selectedItem?.locationId, vesselLocations))}
                    </SeaGridCell>
                    {selectedEquipment && (selectedEquipment.make || selectedEquipment.model || selectedEquipment.serial) &&
                        <>
                            <SeaGridCell label="Critical Equipment" w="50" help={{text: 'Equipment are marked as critical if its failure or loss of function could pose a risk to the vessel / crew. You can change whether an equipment item is critical or not through the Equipment List page.'}}>
                                {formatValue(selectedEquipment.isCritical ? 'Yes' : 'No')}
                            </SeaGridCell>
                            <SeaGridCell label="Make" w="50">
                                {formatValue(selectedEquipment.make)}
                            </SeaGridCell>
                            <SeaGridCell label="Model" w="50">
                                {formatValue(selectedEquipment.model)}
                            </SeaGridCell>
                            <SeaGridCell label="Serial" w="50">
                                {formatValue(selectedEquipment.serial)}
                            </SeaGridCell>
                        </>
                    }
                    {selectedEquipment?.notes &&
                        <SeaGridCell label="Equipment Notes" w="100">
                            {formatValue(formatTextAreaText(selectedEquipment.notes))}
                        </SeaGridCell>
                    }
                    {selectedEquipment?.contactIds && selectedEquipment.contactIds.length > 0 &&
                        <SeaGridCell label="Contacts / Suppliers" w="100">
                            {(contacts?.byId) ? (
                                selectedEquipment.contactIds.map((contactId: string) => {
                                    const contact = contacts.byId[contactId];
                                    return `${contact.name}${contact.company ? ` (${contact.company})` : ''}${contact.number ? `, ${contact.number}` : ''}${contact.email ? `, ${contact.email}` : ''}`
                                }).join('\n \n')
                            ) : (
                                '-'
                            )}
                        </SeaGridCell>
                    }
                    {!vessel?.isShoreFacility && selectedEquipment?.equipmentDocumentIds && selectedEquipment.equipmentDocumentIds.length > 0 &&
                        <SeaGridCell label="Manuals" w="50">
                            <div style={{whiteSpace: 'pre-line'}}>
                                {equipmentManualDocuments?.byId && selectedEquipment?.equipmentDocumentIds?.map((manualId: string) => {
                                    const manual = equipmentManualDocuments.byId[manualId];
                                    if (manual) {
                                        return (
                                            <div key={manualId} className="columns" style={{ marginBottom: '6px' }}>
                                                <div>
                                                    <SeaFileImage files={manual.files} size="tiny" showOthers/>
                                                </div>
                                                <div style={{ paddingLeft: '8px', alignSelf: 'center' }}>
                                                    {manual.title}
                                                </div>
                                            </div>
                                        );
                                    }
                                    return undefined;
                                })}
                                {(!(selectedEquipment?.equipmentDocumentIds) || selectedEquipment.equipmentDocumentIds.length === 0) &&
                                    '-'
                                }
                            </div>
                        </SeaGridCell>
                    }
                </SeaGrid>
            }

            <div className="view-modal-buttons">
                <RequirePermissions
                    role="maintenanceSchedule"
                    level={permissionLevels.EDIT}
                >
                    <SeaButton zone="white" type="submit" onClick={(e) => onEditItem()}><SeaIcon icon="edit" slot="start" />Edit Item</SeaButton>
                    <RequirePermissions
                        role="maintenanceSchedule"
                        level={permissionLevels.FULL}
                    >
                        <div className="spacer-wide"></div>
                        <SeaLinkButton
                            mode="standard-link"
                            onClick={(e) => {
                                deleteIfConfirmed(
                                    'scheduledMaintenanceTasks',
                                    selectedItem.id,
                                    (batch: WriteBatch | SplittableBatch) => {
                                        onCollectionUpdated(batch, 'scheduledMaintenanceTasks');
                                        setShowModal(false);
                                    },
                                    'task',
                                    selectedItem?.equipment?.equipment,
                                    [selectedItem.vesselId]
                                );
                            }}
                        >
                            Delete Item
                        </SeaLinkButton>
                    </RequirePermissions>
                </RequirePermissions>
            </div>


            <div className="end-info-line"></div>

            <div className={`columns maintenance-schedule tab-${tab}`} style={{ justifyContent: 'space-between' }}>
                <div>
                    <SeaTabsGroup key="viewMaintenance" selectedTab={tab} setTab={setTab} mode="forms" mini>
                        <SeaTab tab="history" mode="forms" requireRole="maintenanceHistory">Task History</SeaTab>
                        <SeaTab tab="equipmentHistory" mode="forms" requireRole="maintenanceHistory">Equipment History</SeaTab>
                        <SeaTab tab="spareParts" mode="forms" requireRole="sparePartsList">Spare Parts</SeaTab>
                    </SeaTabsGroup>
                </div>
                <RequirePermissions
                    role="maintenanceSchedule"
                    level={permissionLevels.COMPLETE}
                >
                    <div className="complete-button">
                        <SeaButton zone="white" onClick={(e) => onCompleteItem()}><SeaIcon icon="tick" slot="start" />Complete Task</SeaButton>
                    </div>
                </RequirePermissions>
            </div>

            {canView('maintenanceHistory') && (
                <SeaTabContent tab="history" selectedTab={tab}>
                    <SeaNoData
                        dataName="maintenance task"
                        isHistory={true}
                        isLoading={!maintenanceTasksCompleted}
                        hasNoData={
                            selectedItem?.id &&
                            maintenanceTasksCompleted &&
                            !sortedMaintenanceTasksCompleted.length ? true : false
                        }
                    />
                    <div className={`maintenance-schedule-history ${selectedItem?.id && maintenanceTasksCompleted && maintenanceTasksCompleted.byMaintenanceTaskId[selectedItem.id] ? 'reveal' : 'conceal'} ${selectedItem?.engineId ? 'has-engine' : ''}`}>
                        <div className="sea-row-history headings-history">
                            <div>Date</div>
                            <div>{selectedItem?.engineId && `Engine Hours`}</div>
                            <div>By</div>
                            <div>Parts Used</div>
                            <div>Notes</div>
                            <div></div>
                        </div>
                        {sortedMaintenanceTasksCompleted.map((item) => {
                            return (
                                <div key={item.id} className={`sea-card sea-row-history ${canEditItem && 'clickable'}`} onClick={(e) => canEditItem && onCompleteItem(item)}>
                                    <div>{formatDate(item.whenCompleted)}</div>
                                    <div>{item.engineHours}</div>
                                    <div className="truncate">{renderFullNameForUserId(item.completedBy)}</div>
                                    <div className="truncate-3">{item.spareParts ? formatValue(formatTextAreaText(formatSparePartsList(item.spareParts, equipmentSpareParts))) : '-'}</div>
                                    <div className="truncate-3">{formatValue(formatTextAreaText(item.notes))}</div>
                                    <div><SeaFileImage files={item.files} size="tiny"/></div>
                                </div>
                            )
                        })}
                    </div>
                </SeaTabContent>
            )}

            {canView('maintenanceHistory') && (
                <SeaTabContent tab="equipmentHistory" selectedTab={tab}>
                    <SeaNoData
                        dataName="maintenance task"
                        isHistory={true}
                        isLoading={!equipmentTasksCompleted}
                        hasNoData={equipmentTasksCompleted && equipmentTasksCompleted.length === 0}
                    />
                    <div className={`equipment-tasks-history has-status ${(equipmentTasksCompleted?.length) ? 'reveal' : 'conceal'}`}>
                        <div className="sea-row-history headings-history">
                            <div>Date</div>
                            <div>Task</div>
                            <div>Notes</div>
                            <div>Type</div>
                            <div>Completed By</div>
                            <div></div>
                        </div>
                        {equipmentTasksCompleted?.map((item) => {
                            return (
                                <div
                                    key={item.id}
                                    className={`sea-card sea-row-history ${canEditItem ? 'clickable' : ''}`}
                                    onClick={(e) => canEditItem && onCompleteItem(item)}
                                >
                                    <div>{formatDate(item.whenCompleted)}</div>
                                    <div className="truncate-3">{formatValue(item.task)}</div>
                                    <div className="truncate-3">{formatValue(formatTextAreaText(item.notes))}</div>
                                    <div className="truncate">{formatValue(item.type === 'unscheduled' ? 'Job' : renderCamelCase(item.type))}</div>
                                    <div className="truncate">{renderFullNameForUserId(item.completedBy)}</div>
                                    <div><SeaFileImage files={item.files} size="tiny"/></div>
                                </div>
                            );
                        })}
                    </div>

                </SeaTabContent>
            )}

            {canView('sparePartsList') && (
                <SeaTabContent tab="spareParts" selectedTab={tab}>
                    <SeaNoData
                        dataName="spare parts"
                        //isHistory={true}
                        isUsingFilter={true}
                        isLoading={!equipmentSpareParts}
                        hasNoData={equipmentSpareParts && equipmentSpareParts.length === 0}
                    />
                    <div className={`spare-parts-history has-thumbs ${(equipmentSpareParts?.length) ? 'reveal' : 'conceal'}`}>
                        <div className="sea-row-history headings-history">
                            <div></div>
                            <div>Item</div>
                            <div>Quantity</div>
                            <div>Location</div>
                            <div>Part #</div>
                        </div>
                        {equipmentSpareParts?.map((item) => {
                            return (
                                <div key={item.id} className={`sea-card sea-row-history clickable`} onClick={(e) => onViewSparePart(item)}>
                                    <div><SeaFileImage files={item.files} size="tiny"/></div>
                                    <div className="truncate">{formatValue(item.item)}</div>
                                    <div className="truncate">{formatValue(item.quantity)}</div>
                                    <div className="truncate">{formatValue(renderCategoryName(item.locationId, vesselLocations))}</div>
                                    <div className="truncate">{formatValue(item.partNum)}</div>
                                </div>
                            );
                        })}
                    </div>
                </SeaTabContent>
            )}

            {showModal &&
                <>
                    {editScheduledTaskCompletedModal.selectedItem && (
                        (editScheduledTaskCompletedModal.historyItemToUpdate && canEdit('maintenanceHistory')) ||
                        (!editScheduledTaskCompletedModal.historyItemToUpdate && canComplete('maintenanceSchedule')) 
                    ) && 
                        <CompleteMaintenanceSchedule
                            showModal={editScheduledTaskCompletedModal.show}
                            setShowModal={(showModal: boolean) => {
                                setEditScheduledTaskCompletedModal({
                                    ...editScheduledTaskCompletedModal,
                                    show: showModal
                                });
                            }}
                            setShowParentModal={setShowModal}
                            selectedItem={editScheduledTaskCompletedModal.selectedItem}
                            historyItemToUpdate={editScheduledTaskCompletedModal.historyItemToUpdate}
                            completedScheduledMaintenanceTasks={editScheduledTaskCompletedModal.selectedItem?.id ? maintenanceTasksCompleted?.byMaintenanceTaskId[editScheduledTaskCompletedModal.selectedItem.id] : []}
                            level={level+1}
                        />
                    }
                    {canEdit('maintenanceSchedule') && <EditMaintenanceSchedule
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        itemToUpdate={selectedItem}
                        level={level+1}
                    />}
                    {canView('sparePartsList') && viewSparePartModal.selectedItem && <ViewSparePart
                        showModal={viewSparePartModal.show}
                        setShowModal={(showModal: boolean) => setViewSparePartModal({
                            ...viewSparePartModal,
                            show: showModal
                        })}
                        selectedItem={viewSparePartModal.selectedItem}
                        level={viewSparePartModal.level}
                    />}
                </>
            }
        </SeaModal>
    );
};

export default ViewMaintenanceSchedule;

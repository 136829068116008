import React, { useState, useEffect, useMemo, Profiler, useCallback } from "react";
import { logPageView } from "../../../lib/firebase";
import { formatValue, extractSearchTerms } from "../../../lib/util";
import { onProfilerRender } from "../../../lib/performance";
import { usePageLimiter } from "../../../hooks/usePageLimiter";
import { permissionLevels } from "../../../shared-state/Core/userPermissions";
import { IonSelectOption } from "@ionic/react";
import { renderCategoryName } from "../../../lib/categories";
import { sharedState } from '../../../shared-state/shared-state';
import { SparePart } from '../../../shared-state/VesselMaintenance/spareParts';
import SeaStatusIcon from "../../../components/SeaStatusIcon/SeaStatusIcon";
import EditSparePartsListSettings from '../../../modals/VesselMaintenance/SparePartsList/EditSparePartsListSettings/EditSparePartsListSettings';
import SeaIcon from "../../../components/SeaIcon/SeaIcon";
import SeaButton from "../../../components/SeaButton/SeaButton";
import SeaNoData from "../../../components/SeaNoData/SeaNoData";
import SeaFileImage from "../../../components/SeaFileImage/SeaFileImage";
import RequirePermissions from "../../../components/RequirePermissions/RequirePermissions";
import SeaSearchbar from "../../../components/SeaSearchbar/SeaSearchbar";
import EditSparePart from "../../../modals/VesselMaintenance/SparePartsList/EditSparePart/EditSparePart";
import ViewSparePart from "../../../modals/VesselMaintenance/SparePartsList/ViewSparePart/ViewSparePart";
import SeaSelect from "../../../components/SeaSelect/SeaSelect";
import SparePartsListPdf from "../../../pdfs/VesselMaintenance/SparePartsListPdf";
import "./SparePartsList.css";

interface SparePartsListProps {
    visible: boolean;
}

const SparePartsList: React.FC<SparePartsListProps> = ({ visible }) => {
    const vessel = sharedState.vessel.use(visible ? 1 : 101);
    const spareParts = sharedState.spareParts.use(visible ? 1 : 101);
    const equipment = sharedState.equipment.use(visible ? 1 : 101);
    const vesselSystems = sharedState.vesselSystems.use(visible ? 1 : 101);
    const vesselLocations = sharedState.vesselLocations.use(visible ? 1 : 101);
    sharedState.contacts.use(visible ? 51 : 0); // Prepare for modals to access
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [sparePartsFiltered, setSparePartsFiltered] = useState<SparePart[]>([]);
    const [searchText, setSearchText] = useState("");
    const [systemFilter, setSystemFilter] = useState("");
    const [equipmentFilter, setEquipmentFilter] = useState("");
    const [locationFilter, setLocationFilter] = useState("");
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const [listType, setListType] = useState('all');
    const [showSparePartsListSettings, setShowSparePartsListSettings] = useState(false);
    const [viewSparePartModal, setViewSparePartModal] = useState<{
        show: boolean;
        selectedItem: SparePart | undefined;
        level: number;
    }>({
        show: false,
        selectedItem: undefined,
        level: 1,
    });
    const [showEditItemModal, setShowEditItemModal] = useState(false);

    useEffect(() => {
        if (visible) {
            logPageView("VesselMaintenance/SparePartsList");
        }
        resetPageLimit();
    }, [resetPageLimit, visible]);

    useEffect(() => {
        resetPageLimit();
    }, [
        searchText,
        systemFilter,
        equipmentFilter,
        locationFilter,
        resetPageLimit,
    ]);

    const isUsingFilters = useMemo(() => {
        return searchText || systemFilter || equipmentFilter || locationFilter || listType !== 'all'
            ? true
            : false;
    }, [searchText, systemFilter, equipmentFilter, locationFilter, listType]);

    const checkContainsCritical = useCallback((equipmentIds: string[] | undefined) => {
        if (!equipmentIds) return false;
        for (const id of equipmentIds) {
            if (equipment?.byId[id]?.isCritical) {
                return true;
            }
        }
        return false;
    }, [equipment?.byId]);

    // Refine sparePartsBeforeSearch --> spareParts
    useEffect(() => {
        let isMounted = true;
        setSparePartsFiltered([]);
        if (spareParts?.all) {
            const passesFilters = (sparePart: SparePart) => {
                if (
                    systemFilter &&
                    (sparePart.systemId === undefined ||
                        sparePart.systemId !== systemFilter)
                ) {
                    return false;
                }
                if (
                    equipmentFilter &&
                    (sparePart.equipmentIds === undefined ||
                        sparePart.equipmentIds.indexOf(equipmentFilter) === -1)
                ) {
                    return false;
                }
                if (
                    locationFilter &&
                    (sparePart.locationId === undefined ||
                        sparePart.locationId !== locationFilter)
                ) {
                    return false;
                }
                if (listType === 'shoppingList' && !sparePart.orderQuantity) {
                    return false;
                }
                if (listType === 'critical' && (!sparePart.equipmentIds || !checkContainsCritical(sparePart.equipmentIds))) {
                    return false;
                }
                return true;
            };

            if (searchText && searchText.trim().length > 0) {
                const terms = extractSearchTerms(searchText, true);
                const _spareParts = [] as SparePart[];
                for (let i = 0; i < spareParts.all.length; i++) {
                    if (!isMounted) break;
                    const sparePart = spareParts.all[i];

                    if (!passesFilters(sparePart)) {
                        continue;
                    }

                    let isMatch = true;
                    for (let j = 0; j < terms.length; j++) {
                        if (sparePart.searchText?.indexOf(terms[j]) === -1) {
                            isMatch = false;
                            break;
                        }
                    }
                    if (isMatch) {
                        _spareParts.push(sparePart);
                    }
                }

                if (isMounted) {
                    setSparePartsFiltered(_spareParts);
                }
            } else if (isUsingFilters) {
                const _spareParts = [] as SparePart[];
                for (let i = 0; i < spareParts.all.length; i++) {
                    if (!isMounted) break;
                    const sparePart = spareParts.all[i];
                    if (passesFilters(sparePart)) {
                        _spareParts.push(sparePart);
                    }
                }
                if (isMounted) {
                    setSparePartsFiltered(_spareParts);
                }
            } else {
                setSparePartsFiltered(spareParts.all);
            }
        }
        return () => {
            isMounted = false;
        };
    }, [isUsingFilters, spareParts, searchText, systemFilter, equipmentFilter, locationFilter, listType, checkContainsCritical]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (
            viewSparePartModal?.selectedItem?.id &&
            spareParts?.byId[viewSparePartModal.selectedItem.id]
        ) {
            setViewSparePartModal({
                ...viewSparePartModal,
                selectedItem:
                    spareParts.byId[viewSparePartModal.selectedItem.id],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spareParts, viewSparePartModal?.selectedItem?.id]);

    const onAddNewItem = () => {
        setViewSparePartModal({
            show: true,
            selectedItem: undefined,
            level: 1,
        })
        setShowEditItemModal(true);
    };
    const onEditSettings = () => {
        setShowSparePartsListSettings(true);
    }
    const onGeneratePdf = () => {
        setGeneratingPdf(true);
    };
    const onViewItem = (item: SparePart) => {
        // setShowViewItemModal(true);
        // setSelectedItem(item)
        setViewSparePartModal({
            show: true,
            selectedItem: item,
            level: 1,
        });
    };

    const equipmentFilterOptions = useMemo(() => {
        const _equipment: string[] = [];
        if (spareParts?.filterOptions?.equipmentIds?.length) {
            for (const id of spareParts.filterOptions.equipmentIds) {
                if (systemFilter && equipment?.byId[id]?.systemId !== systemFilter) {
                    continue;
                }
                _equipment.push(id);
            }
        }
        return _equipment;
    }, [
        spareParts?.filterOptions?.equipmentIds,
        equipment?.byId,
        systemFilter,
    ]);


    return (
        <RequirePermissions
            role="sparePartsList"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="spare-parts-list page-head">
                <div>
                    <h2>
                        {vessel?.isShoreFacility
                            ? "Inventory List"
                            : "Spare Parts List"}
                    </h2>
                </div>
                <div className="actions">
                    <SeaSelect
                        name="listType"
                        value={listType}
                        width="240px"
                        zone="grey"
                        onchange={(e) => {
                            setListType(e.detail.value);
                            resetPageLimit();
                        }}
                    >
                        <IonSelectOption value="all">All</IonSelectOption>
                        <IonSelectOption value="shoppingList">Shopping List</IonSelectOption>
                        <IonSelectOption value="critical">Critical</IonSelectOption>
                    </SeaSelect>
                    <RequirePermissions
                        role="sparePartsList"
                        level={permissionLevels.CREATE}
                    >
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add" />
                            {vessel?.isShoreFacility
                                ? "Add New Item"
                                : "Add New Part"}
                        </SeaButton>
                    </RequirePermissions>
                    <div className="spacer"></div>
                    <SeaButton
                        onClick={(e) => onGeneratePdf()}
                        zone="grey"
                        shape="circle"
                    >
                        <SeaIcon slot="icon-only" icon="pdf" />
                    </SeaButton>
                    <div className="spacer"></div>
                    <RequirePermissions
                        role="vesselSettings"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton
                            zone="grey"
                            shape="circle"
                            onClick={(e) => onEditSettings()}
                        >
                            <SeaIcon slot="icon-only" icon="settings" />
                        </SeaButton>
                    </RequirePermissions>
                </div>
            </div>

            {/* Filters */}
            <div className={`columns wrap filters`}>
                <div>
                    <SeaSearchbar value={searchText} setValue={setSearchText} />
                </div>
                {spareParts && spareParts.filterOptions.systemIds.length > 0 && (
                    <div>
                        <SeaSelect
                            name="systemFilter"
                            value={systemFilter}
                            width="195px"
                            zone="grey"
                            onchange={(e) => setSystemFilter(e.detail.value)}
                        >
                            <IonSelectOption value="">
                                Filter by System
                            </IonSelectOption>
                            {spareParts.filterOptions.systemIds.map(
                                (id: string) => {
                                    return (
                                        <IonSelectOption key={id} value={id}>
                                            {renderCategoryName(
                                                id,
                                                vesselSystems
                                            )}
                                        </IonSelectOption>
                                    );
                                }
                            )}
                        </SeaSelect>
                    </div>
                )}
                {equipmentFilterOptions?.length > 0 && (
                    <div>
                        <SeaSelect
                            name="equipmentFilter"
                            value={equipmentFilter}
                            width="195px"
                            zone="grey"
                            onchange={(e) => setEquipmentFilter(e.detail.value)}
                        >
                            <IonSelectOption value="">
                                Filter by Equipment
                            </IonSelectOption>
                            {equipmentFilterOptions?.map(
                                (id: string) => {
                                    return (
                                        <IonSelectOption key={id} value={id}>
                                            {equipment?.byId[id]?.equipment}
                                        </IonSelectOption>
                                    );
                                }
                            )}
                        </SeaSelect>
                    </div>
                )}
                {spareParts && spareParts.filterOptions.locationIds.length > 0 && (
                    <div>
                        <SeaSelect
                            name="locationFilter"
                            value={locationFilter}
                            width="195px"
                            zone="grey"
                            onchange={(e) => setLocationFilter(e.detail.value)}
                        >
                            <IonSelectOption value="">
                                Filter by Location
                            </IonSelectOption>
                            {spareParts.filterOptions.locationIds.map(
                                (id: string) => {
                                    return (
                                        <IonSelectOption key={id} value={id}>
                                            {renderCategoryName(
                                                id,
                                                vesselLocations
                                            )}
                                        </IonSelectOption>
                                    );
                                }
                            )}
                        </SeaSelect>
                    </div>
                )}
                {isUsingFilters && (
                    <div>
                        <SeaButton
                            onClick={(e) => {
                                setSearchText("");
                                setSystemFilter("");
                                setEquipmentFilter("");
                                setLocationFilter("");
                            }}
                            zone="grey"
                        >
                            Reset
                        </SeaButton>
                    </div>
                )}
            </div>

            <SeaNoData
                dataName={
                    vessel?.isShoreFacility ? "inventory items" : "spare parts"
                }
                isLoading={!sparePartsFiltered}
                hasNoData={
                   sparePartsFiltered.length === 0
                }
                isUsingFilter={isUsingFilters}
            />

            <div
                className={`spare-parts-list has-thumbs ${
                    sparePartsFiltered?.length > 0 ? "reveal" : "conceal"
                }`}
            >
                <div className="sea-row headings">
                    <div></div>
                    <div className="item">Item</div>
                    <div className="quantity">Quantity</div>
                    {listType === 'shoppingList' && (<div className="orderQuantity">Order Quantity</div>)}
                    <div className="equipmentList">Equipment</div>
                    <div className="location">Location</div>
                    <div className="partNum">Part #</div>
                    <div className="critical">Critical</div>
                </div>
                <Profiler
                    id="maintenance.spareParts"
                    onRender={onProfilerRender}
                >
                    {mapArrayWithLimit(sparePartsFiltered, (item: SparePart) => {

                        const isCritical = checkContainsCritical(item.equipmentIds)
                        return (
                            <div
                                key={item.id}
                                className="sea-card sea-row"
                                onClick={(e) => onViewItem(item)}
                            >
                                <div>
                                    <SeaFileImage
                                        files={item.files || []}
                                        size="tiny"
                                    />
                                </div>
                                <div className={`bold truncate-2 item ${isCritical ? 'has-icon' : ''}`}>{item.item}</div>
                                <div className={`truncate-2 quantity ${(item.orderQuantity) ? 'error' : ''}`}>
                                    {formatValue(item.quantity)}
                                </div>
                                {listType === 'shoppingList' && (
                                    <div className="truncate-2 orderQuantity">
                                        {formatValue(item.orderQuantity)}
                                    </div>
                                )}
                                <div className="truncate-2 equipmentList">
                                    {formatValue(item.equipmentList)}
                                </div>
                                <div className="truncate-2 location ">
                                    {formatValue(
                                        renderCategoryName(
                                            item.locationId,
                                            vesselLocations
                                        )
                                    )}
                                </div>
                                <div className="truncate-2 partNum">
                                    {formatValue(item.partNum)}
                                </div>
                                <div className="truncate is-icon">
                                    {isCritical && (
                                        <SeaStatusIcon
                                            icon="flag"
                                            className="critical"
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </Profiler>
                {limitTriggerElement}
            </div>
            {visible && (
                <>
                    {viewSparePartModal.selectedItem && <ViewSparePart
                        showModal={viewSparePartModal.show}
                        setShowModal={(showModal: boolean) =>
                            setViewSparePartModal({
                                ...viewSparePartModal,
                                show: showModal,
                            })
                        }
                        selectedItem={viewSparePartModal.selectedItem}
                        level={viewSparePartModal.level}
                    />}
                    <EditSparePart
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        level={1}
                        itemToUpdate={viewSparePartModal.selectedItem}
                    />
                    <EditSparePartsListSettings
                        showModal={showSparePartsListSettings}
                        setShowModal={setShowSparePartsListSettings}
                    />
                    <SparePartsListPdf
                        generatingPdf={generatingPdf}
                        setGeneratingPdf={setGeneratingPdf}
                        spareParts={sparePartsFiltered}
                    />
                </>
            )}
        </RequirePermissions>
    );
};

export default SparePartsList;

import React, { useEffect } from 'react';
import { sharedState } from '../../shared-state/shared-state';
import { physicalDeviceInfo } from '../../shared-state/Core/deviceInfo';
import { formatDatetime, generateCode, toInt } from '../../lib/util';
import { WriteBatch, collection, doc, getDocs, increment, query, serverTimestamp, setDoc, where } from 'firebase/firestore';
import { SplittableBatch, firestore } from '../../lib/firebase';
import { appActivityToJson } from '../../shared-state/General/appActivity';
import { loadErrorsOnHold, saveErrorsOnHold } from '../../shared-state/General/errorsOnHold';
import SeaButton from '../../components/SeaButton/SeaButton';
import packageJson from '../../../package.json';
import './ErrorsManager.css';

//
// Handles errorReports and traceReports
//

export type SeaFluxError = {
    whatFailed: string,
    reason: string,
    error?: Error,
    data: any,
    errorId?: string,
    reference?: string
};

export interface Action {
    collection: string,
    type: 'create' | 'update' | 'delete' | 'archive' | 'unarchive' | 'complete' | undefined,
    docId: string | undefined,
    whenAction: number, // This ssould be used whenever possible within associated firestore batches
    errorId: string | undefined,
    name: string,
    data: any,
    save: (name: string, batch?: WriteBatch | SplittableBatch) => void,
    reportSuccess: () => void,
    reportError: (reason: string, error?: Error) => void
};

const showError = (errorToShow: SeaFluxError) => {
    sharedState.errorsToShow.set((previousErrors) => {
        // Note: If we did straight forward setErrors(newValue) we would always be seeing [] (initial value)
        return [...(previousErrors as SeaFluxError[]), errorToShow];
    });
    // saveError(errorToShow); // TODO! Should replace when fileSync is revamped
};

export const makeDefaultErrorReport = () => {
    return {
        userId: (sharedState.user.current && (sharedState.user.current as any).id ? (sharedState.user.current as any).id : 'unknown'),
        licenseeId: sharedState.licenseeId.current,
        deviceId: sharedState.deviceId.current,
        vesselId: sharedState.vesselId.current,
        whenReported: Date.now(),
        version: packageJson.version,
        build: toInt(packageJson.build),
        device: physicalDeviceInfo,
        userClaims: sharedState.securityClaims.current,
        isOnline: sharedState.onlineStatus.current?.isOnline,
        onlineHistory: sharedState.onlineStatus.current?.history,
        isAppActive: sharedState.appState.current?.isActive,
        appActivity: appActivityToJson(JSON.stringify(sharedState.appActivity.current)),
        state: 'unknown'
    };
};

const addError = (
    whatFailed: string,
    reason?: string,
    error?: Error,
    data = undefined,
    errorId?: string
) => {
    const reference = generateCode(8);

    const newErrorReportRef = (
        errorId
        ?
        doc(firestore, 'errorReports', errorId)
        :
        doc(collection(firestore, 'errorReports'))
    );

    // Decide whether this errorReport should be shown to the user
    // or held if we suspect it could be a fake error.
    // We are suspicious the error could be fake if:
    // * It's a security error
    //     Yes, Firestore can lie when saying there are missing or insufficient permissions...
    //     It should be saying something went wrong with the connection while trying to test permissions
    // * We're offline or have only been online for less than 5 seconds
    // * Is an error generated by an action (i.e. action.reportError rather than ReportingContext.reportError directly)
    const holdError = (
        (
            !sharedState.onlineStatus.current?.isOnline ||
            (Date.now() - sharedState.onlineStatus.current.lastWentOnline) < (5 * 1000)
        ) &&
        reason === 'Missing or insufficient permissions.' && // Security violations are the only errors that we might want to hold on to
        errorId // A defined errorId means that the original error was generated via an action
    );

    const newError = {
        errorId: newErrorReportRef.id,
        whatFailed: whatFailed,
        reason: reason,
        reference: reference,
        errorStack: error?.stack ? error.stack : undefined,
        data: data,
        shownToUser: !holdError
    } as SeaFluxError;
    console.log('ReportingContext.addError!', newError);

    setDoc(
        newErrorReportRef,
        {
            ...makeDefaultErrorReport(),
            ...newError
        }
    ).then(() => {
        console.log(`Error Reported id=${newErrorReportRef.id} reference=${reference}`);
    }).catch((error) => {
        console.error('Failed to report error to firestore', error);
    });

    if (holdError) {
        sharedState.errorsOnHold.set((previousErrors) => {
            return [...(previousErrors as SeaFluxError[]), newError];
        });
        saveErrorsOnHold();
    } else {
        // Show this error to the user right now
        showError(newError);
    }
};

const saveTrace = (
    action: any,
    name: string,
    batch: WriteBatch | SplittableBatch | undefined // The batch that was used to write the action data itself
) => {
    action.name = name;
    action.errorId = doc(collection(firestore, 'errorReports')).id;
    if (action.docId === undefined) {
        console.log('----------------- No docId was set!!!!', action);
        return;
    }

    let batches = [];
    if (batch) {
        if ('getBatches' in batch) { // SplittableBatch
            batches = batch.getBatches();
        } else { // Regular WriteBatch
            batches = [batch];
        }
        batches.forEach((_batch: WriteBatch) => {
            _batch.set(
                doc(firestore, 'actionsConfirmed', action.errorId),
                {
                    userId: (sharedState.user.current && (sharedState.user.current as any).id ? (sharedState.user.current as any).id : 'unknown'),
                    licenseeId: sharedState.licenseeId.current,
                    errorReportId: action.errorId,
                    collection: action.collection,
                    docId: action.docId,
                    whenAction: action.whenAction,
                    whenSynced: serverTimestamp(),
                    batches: batches.length,
                    writeCount: increment(1)
                },
                { merge: true }
            );
        });
    }

    setDoc(
        doc(firestore, 'traceReports', action.errorId),
        {
            userId: (sharedState.user.current && (sharedState.user.current as any).id ? (sharedState.user.current as any).id : 'unknown'),
            licenseeId: sharedState.licenseeId.current,
            collection: action.collection,
            docId: action.docId,
            type: action.type,
            whenAction: action.whenAction,
            state: 'unknown', // Server will later determine if success | error | lost | bug
            errorReportId: action.errorId,
            deviceId: sharedState.deviceId.current,
            whenTraced: Date.now(),
            whenSynced: serverTimestamp(),
            action: action.name,
            data: action.data,
            batches: batches.length,
            version: packageJson.version,
            build: toInt(packageJson.build),
            device: physicalDeviceInfo,
            appActivity: appActivityToJson(JSON.stringify(sharedState.appActivity.current)),
            userClaims: sharedState.securityClaims.current
        },
        { merge: true }
    ).then(() => {
        console.log(`Traced Action ${action.errorId}: ${action.name}`);
    }).catch((error) => {
        console.log(`Error saving action trace: ${action.name}`, error);
        console.log('Failed action', action);
    });
};

const reportSuccess = (action: Action) => {
    console.log('Success: '+action.name);
};

export const reportError = (
    whatFailed: string,
    reason?: string,
    error?: Error,
    data?: any,
    errorId?: string
) => {
    addError(whatFailed, reason, error, data, errorId);
};

const reportTraceError = (action: Action, reason: string, error?: Error) => {
    reportError(
        `Failed: ${action.name}`,
        reason,
        error,
        {
            docId: action.docId,
            collection: action.collection,
            ...action.data
        },
        action.errorId
    );
};

export const traceAction = (
    _collection: string,
    _type?: 'create' | 'update' | 'delete' | 'archive' | 'unarchive' | 'complete' | undefined
): Action => {
    const action = {
        collection: _collection,
        docId: undefined,
        type: _type,
        whenAction: Date.now(),
        name: '',
        errorId: undefined,
        data: {},
        save: (name: string, batch?: WriteBatch | SplittableBatch) => saveTrace(action, name, batch),
        reportSuccess: () => reportSuccess(action),
        reportError: (reason: string, error?: Error) => reportTraceError(action, reason, error)
    };
    return action as Action;
};





const ErrorsManager: React.FC = () => {
    const licenseeId = sharedState.licenseeId.use();
    const errorsOnHold = sharedState.errorsOnHold.use();
    const errorsToShow = sharedState.errorsToShow.use();
    const onlineStatus = sharedState.onlineStatus.use();
    const triggerProcessErrorsOnHold = sharedState.triggerProcessErrorsOnHold.use();

    useEffect(() => {
        if (licenseeId) {
            loadErrorsOnHold();
        }
    }, [licenseeId]);

    useEffect(() => {
        if (
            triggerProcessErrorsOnHold &&
            errorsOnHold &&
            errorsOnHold.length > 0
        ) {
            const testErrorReports = [...errorsOnHold];
            // Remove errorsOnHold right away so they wont be processed again
            sharedState.errorsOnHold.clear();
            saveErrorsOnHold();

            testErrorReports.forEach((errorOnHold) => {
                getDocs(
                    query(
                        collection(firestore, 'actionsConfirmed'),
                        where('errorReportId', '==', errorOnHold.errorId),
                        where('licenseeId', '==', sharedState.licenseeId.current)
                    )
                ).then((snap) => {
                    if (snap.docs.length > 0) {
                        const actionConfirmed = snap.docs[0].data();
                        if (
                            actionConfirmed.batches &&
                            actionConfirmed.writeCount &&
                            actionConfirmed.writeCount >= actionConfirmed.batches
                        ) {
                            // errorOnHold is FAKE
                            console.log('Fake error dropped from errorsOnHold', errorOnHold);
                            return;
                        }
                    }
                    // errorOnHold is probably REAL
                    // Show errorOnHold to user
                    console.log('Promoting errorOnHold to be shown to the user', errorOnHold);
                    showError(errorOnHold);
                    // Update shownToUser to true in errorReports document
                    return setDoc(
                        doc(firestore, 'errorReports', errorOnHold.errorId as string),
                        {
                            shownToUser: true
                        },
                        { merge: true }
                    ).catch((error) => {
                        console.error(`Failed to update errorReports to have shownToUser errorId=${errorOnHold.errorId}`, error);
                    });
                }).catch((error: any) => {
                    console.error(`Error getting actionsConfirmed for errorId=${errorOnHold.errorId}`, error);
                });
            });
        }
    }, [errorsOnHold, triggerProcessErrorsOnHold]);

    return <>
        {errorsToShow && errorsToShow.length > 0 && (
            <div className="sea-error-report-backdrop">
                <div className="sea-error-report">
                    <h3>{(errorsToShow.length === 1) ? 'An error has occurred' : 'Some errors have occurred'}</h3>
                    {errorsToShow.map((error, index) => {
                        return (
                            <p key={index}>
                                <b>{error.whatFailed}</b>
                                <br/>
                                {error.reason}
                                <br/>
                                Error reference: {error.reference}
                            </p>
                        );
                    })}
                    <div style={{ margin: '20px 0px 15px 0px' }}>
                        Sending an error report has been sent to the admin team.
                        <p>
                            If you quote an error reference to the Sea Flux team it will help them diagnose the problem faster.'
                        </p>
                    </div>
                    <div>
                        {`\n\nVersion ${packageJson.version}, build ${packageJson.build}`}{
                            physicalDeviceInfo && `, ${physicalDeviceInfo.osVersion} ${(onlineStatus?.isOnline) ? 'online' : 'offline'} @ ${formatDatetime()}`
                        }
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <SeaButton onClick={(e) => {
                            sharedState.errorsToShow.clear();
                        }}>
                            OK
                        </SeaButton>
                    </div>
                </div>
            </div>
        )}
    </>;
};

export default ErrorsManager;

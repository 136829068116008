import React, { useState, useCallback } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { firestore, functions, splittableBatch } from '../../../../lib/firebase';
import { haveCategoriesChanged, makeCategoryIdsForEditing, makeCategoryNamesForEditing, saveCategoryChanges } from '../../../../lib/categories';
import { httpsCallable } from 'firebase/functions';
import { sharedState } from '../../../../shared-state/shared-state';
import { Action, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaInputList from '../../../../components/SeaInputList/SeaInputList';

interface EditCustomFormSettingsProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}

const EditCustomFormSettings: React.FC<EditCustomFormSettingsProps> = ({showModal, setShowModal}) => {
    const licenseeId = sharedState.licenseeId.use(showModal)!;
    const superAdmin = sharedState.superAdmin.use(showModal);
    const customFormCategories = sharedState.customFormCategories.use(showModal);
    const [categoryIds, setCategoryIds] = useState<string[]>();
    const [categoryNames, setCategoryNames] = useState<string[]>();

    const onOpened = () => {
        if (!customFormCategories) {
            return;
        }
        setCategoryIds(makeCategoryIdsForEditing(customFormCategories));
        setCategoryNames(makeCategoryNamesForEditing(customFormCategories));
    };
    
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('categoryNames', categoryNames)
        const action = traceAction('customFormCategories', 'update') as Action;
        action.docId = licenseeId;
        const batch = splittableBatch(firestore, 20 - 0);
        saveCategoryChanges(
            batch,
            categoryIds,
            categoryNames,
            undefined,
            customFormCategories,
            'customFormCategories',
            'licenseeId',
            licenseeId,
            action
        );

        action.save(`Update form/checklist categories`, batch);
        batch.commit().then(() => {
            action.reportSuccess();
            if (superAdmin) {
                // Maintain template categories in case they've been affected
                httpsCallable(functions, 'adminMaintainCustomFormTemplates')({}).then((result) => {
                    console.log('Succesfully ran adminMaintainCustomFormTemplates', result);
                }).catch((error) => {
                    console.log('Failed to run adminMaintainCustomFormTemplates! error', error);
                });
            }
        }).catch((error) => {
            action.reportError(error.message, error);
        });

        setShowModal(false);
    };

    const isModalDirty = useCallback(() => {
        return haveCategoriesChanged(categoryIds, categoryNames, customFormCategories);
    }, [categoryIds, categoryNames, customFormCategories]);

    return (
        <SeaModal
            title={`Form/Checklist Settings`}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size="semi-thin"
        >
            <form onSubmit={(e) => onSubmit(e)}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaInputList
                                name="formCategories"
                                label="Custom Form Categories"
                                maxWidth="350px"
                                ids={categoryIds}
                                setIds={setCategoryIds}
                                values={categoryNames}
                                setValues={setCategoryNames}
                                addNewText="Add Form Category"
                                confirmDelete={true}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div style={{ height: '60px' }}></div>
                <SeaButton zone="white" type="submit">{`Save Settings`}</SeaButton>
            </form>
        </SeaModal>
    );
};

export default EditCustomFormSettings;

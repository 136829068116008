import { SharedStateConfig, sharedState } from "../shared-state";
import { CategoriesData, Category, onCategoriesSnapshot } from "../../lib/categories";

//
// Loads incidentCauses
//

export interface IncidentCause extends Category {
    licenseeId: string;
}

export const incidentCausesConfig: SharedStateConfig<CategoriesData> = {
    isAlwaysActive: false,
    dependencies: ['licenseeId', 'licenseeSettings'],
    countLiveDocs: () => sharedState.incidentCauses.current?.ids.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        if (licenseeId && sharedState.licenseeSettings.current?.hasIncidents) {
            return onCategoriesSnapshot(
                'incidentCauses',
                'licenseeId',
                licenseeId,
                (data) => { // onLoaded
                    done();
                    set(data);
                },
                (error) => { // onError
                    done();
                    clear();
                    console.log(`Error getting incidentCauses`, error);
                }
            );
        }
    }
};


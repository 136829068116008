import { Filesystem } from "@capacitor/filesystem";
import { sharedState } from "../shared-state";
import { CachedFile, filesDirectory } from "./cachedFiles";

//
// Handles the queueing and deletion of files in the background.
//

const filesToDelete = [] as string[];
let filesBeingDeleted = 0;
const maxSimultaneousFilesBeingDeleted = 1;


// Cache file to local storage by fetching from Storage
export const deleteFile = (fileToDelete: CachedFile) => {
    filesToDelete.push(fileToDelete);
    processFilesToDelete();
};
const processFilesToDelete = () => {
    if (!sharedState.isFileSyncReady.current || filesBeingDeleted >= maxSimultaneousFilesBeingDeleted) {
        return; // Enough other threads already working on this
    }
    const fileToDelete = filesToDelete.shift();
    if (fileToDelete === undefined) {
        return;
    }
    filesBeingDeleted++;
    const processDeletion = (fileNumber: number): Promise<any> => {
        return Filesystem.deleteFile({
            path: `${sharedState.licenseeId.current}/${fileToDelete}${fileNumber > 1 ? `.${fileNumber}.__chunk` : ''}`,
            directory: filesDirectory
        }).then(() => {
            return processDeletion(fileNumber + 1);
        }).catch((error) => {
            if (fileNumber === 1) {
                console.error(`[FileSync] Error deleting file fileNumber=${fileNumber} (ignoring)`, error);
            }
            return Promise.resolve();
        });
    };

    return processDeletion(1).then(() => {
        console.log(`[FileSync] Deleted file ${fileToDelete}`);
        filesBeingDeleted--;
        processFilesToDelete();
        return Promise.resolve();
    });
};


import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { createGesture, isPlatform } from '@ionic/core';
import { SharedStateConfig, sharedState } from '../../shared-state/shared-state';
import { useNavigate } from 'react-router';
import { refreshApp } from '../../App';
import { IonSpinner } from '@ionic/react';
import { UserType, logoutUser } from '../../shared-state/Core/user';
import { SeaScrollable } from '../../components/SeaScrollableArea/SeaScrollableArea';
import { confirmAction } from '../../managers/ConfirmDialogManager/ConfirmDialogManager';
import SeaHeaderBar from '../../components/SeaHeaderBar/SeaHeaderBar';
import SeaSideBar from '../../components/SeaSideBar/SeaSideBar';
import ViewUserModal from '../../modals/Crew/CrewParticulars/ViewUserModal/ViewUserModal';
import ActionLogModal from '../../modals/Utilities/ActionLogModal/ActionLogModal';

//
// Handles top level layout tasks
// * Header and Sidebar depending on what the layoutMode is
// * Gestures that can handle side menu interactions
//

export type LayoutMode = 'standard' | 'centeredBox' | 'superAdmin';

export type ModalSpace = {
    width: number,
    horizontalPadding: number,
    innerWidth: number
};

export const layoutModeConfig: SharedStateConfig<LayoutMode> = {
    isAlwaysActive: true,
    default: 'centeredBox'
};

export const selectedSectionConfig: SharedStateConfig<string> = {
    isAlwaysActive: true,
    notes: 'Is changed by clicking within the sidebar.'
};

export const selectedSectionTabConfig: SharedStateConfig<string> = {
    isAlwaysActive: true,
    notes: 'Is changed by clicking within the sidebar or clicking tabs within a base page.'
};

export const modalSpaceConfig: SharedStateConfig<ModalSpace> = {
    isAlwaysActive: true,
    default: {
        width: 500,
        horizontalPadding: 16,
        innerWidth: (500 - 16 - 16),
    },
    notes: 'Describes how a modal should be sized on the screen given the current screen space available.'
};

export const showBlankScreenConfig: SharedStateConfig<boolean> = {
    isAlwaysActive: true,
    default: false,
    notes: 'Used to show a blank loading screen while the app is restarting or reloading itself.'
};

export const scrollableContentConfig: SharedStateConfig<SeaScrollable> = {
    isAlwaysActive: true,
    notes: 'Stores the current active page\'s scrollable area enabling scrollToTop & scrollToTarget.'
};


export const scrollPageToTop = (t = 0) => {
    if (sharedState.scrollableContent.current) {
        sharedState.scrollableContent.current.scrollToTop(t);
    }
};

export const scrollToTarget = (target: any, offset = 0, t = 0) => {
    if (sharedState.scrollableContent.current && target?.getBoundingClientRect) {
        sharedState.scrollableContent.current.scrollByPoint(0, target.getBoundingClientRect().top + offset, t);
    }
};

interface AppLayoutProps {
    children: ReactNode
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
    const users = sharedState.users.use();
    const layoutMode = sharedState.layoutMode.use();
    const navigateTo = sharedState.navigateTo.use();
    const showBlankScreen = sharedState.showBlankScreen.use();
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [isMobileView, setIsMobileView] = useState(false);
    const [selectedUser, setSelectedUser] = useState<UserType>();
    const [showViewUserModal, setShowViewUserModal] = useState(false);
    const [showViewActionLogModal, setShowViewActionLogModal] = useState(false);
    const gestureRef = useRef<any>();
    const pageRef = useRef<any>(null);

    // Keep selectedUser fresh
    useEffect(() => {
        if (selectedUser?.id && users?.byId[selectedUser.id]) {
            setSelectedUser(users.byId[selectedUser.id])
        };
    }, [users, selectedUser?.id]);

    // Handle navigateTo
    useEffect(() => {
        if (navigateTo && navigateTo.length > 1) {
            // Trigger navigateTo
            navigate(navigateTo);
            sharedState.navigateTo.clear();
        }
    }, [navigateTo, navigate]);

    // Handle window resizing
    // Updates sharedState.modalSpace
    useEffect(() => {
        const handleResize = () => {
            let horizontalPadding; // See: SeaModal.css --modal-padding-horizontal definitions

            if (window.innerWidth <= 850) {
                setIsMobileView(true);
                horizontalPadding = (window.innerWidth <= 450) ? 14 : 16;
            } else {
                setIsMobileView(false);
                horizontalPadding = 32;
            }
            sharedState.modalSpace.set({
                width: window.innerWidth,
                horizontalPadding,
                innerWidth: window.innerWidth - (2 * horizontalPadding)
            });
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Handle gestures that open the side menu (not for desktop)
    useEffect(() => {
        gestureRef.current = createGesture({
            gestureName: 'Swipe Right',
            el: pageRef.current as any,
            direction: 'x',
            passive: false,
            gesturePriority: 50, // we want to be higher than back and foward swiping with priority=40
            onEnd: (detail) => {
                if (
                    detail.startX < 100 && // must have started close to the left edge of the screen
                    detail.deltaX > 100 && // must have swiped at least this amount to the right
                    (detail.currentTime - detail.startTime) < 500 && // must be fast enought
                    !isPlatform('desktop') && // must not be desktop i.e. no mouse, just touch
                    sharedState.isSwipeEnabled.current
                ) {
                    // SWIPE RIGHT!
                    detail.event.preventDefault();
                    detail.event.stopPropagation();
                    setShowMenu(true);
                }
            }
        });
        gestureRef.current.enable(true);
        return () => {
            if (gestureRef.current) {
                gestureRef.current.destroy();
            }
        };
    }, []);

    const attemptLogout = useCallback((e: any) => {
        e.preventDefault();
        confirmAction(`Are you sure you want to log out?`, `Yes, logout`).then(() => {
            sharedState.showBlankScreen.set(true);
            logoutUser().then(() => {
                console.log('Logged out!');
                if (isPlatform('ios') && isPlatform('hybrid')) {
                    // ios app appears to be flakey when just the webview is reloaded... for instance, at can freeze during login
                    // setTimeout(() => {
                    //     window.location.href = '/'; // how about a delay?
                    // }, 5000);
                    //App.exitApp();
                    window.location.href = '/restart?reason=loggedOut';
                } else {
                    //window.location.href = '/'; // We want to reload app so nothing from previous session remains
                    refreshApp('Logout reload.');
                }
            }).catch((error: any) => {
                console.log('failed to log out', error);
                //window.location.href = '/';
                refreshApp('Failed to logout');
                //setError(error.message);
            });
        }).catch(() => {});
    }, []);

    const openViewUserModal = useCallback((_user: UserType) => {
        setSelectedUser(_user);
        setShowViewUserModal(true);
    }, []);

    const openActionLog = useCallback(() => {
        setShowViewActionLogModal(true);
    }, []);

    return (
        <>
            {(layoutMode === 'standard' || layoutMode === 'superAdmin') &&
                <SeaHeaderBar
                    showMenu={showMenu}
                    setShowMenu={setShowMenu}
                    openViewUserModal={openViewUserModal}
                    attemptLogout={attemptLogout}
                    openActionLog={openActionLog}
                />
            }
            {layoutMode === 'standard' &&
                <SeaSideBar
                    showMenu={showMenu}
                    setShowMenu={setShowMenu}
                    openViewUserModal={openViewUserModal}
                    attemptLogout={attemptLogout}
                />
            }
            <div
                ref={pageRef}
                className={`${showMenu ? 'show-menu' : 'hide-menu'} ${isMobileView ? 'mobile-view' : 'desktop-view'}`}
            >
               {children}
           </div>
            <ViewUserModal
                showModal={showViewUserModal}
                setShowModal={setShowViewUserModal}
                selectedUser={selectedUser}
            />
            <ActionLogModal
                showModal={showViewActionLogModal} 
                setShowModal={setShowViewActionLogModal}
            />
            {showBlankScreen && <div className="sea-blank"><IonSpinner name="crescent" className="sea-spinner"/></div>}
        </>
    );
};

export default AppLayout;

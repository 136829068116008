import { SharedStateConfig, sharedState } from "../shared-state";
import { CategoriesData, onCategoriesSnapshot } from "../../lib/categories";

//
// Loads vesselCertificateCategories
//

export const vesselCertificateCategoriesConfig: SharedStateConfig<CategoriesData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId'],
    countLiveDocs: () => sharedState.vesselCertificateCategories.current?.ids.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        if (vesselId) {
            return onCategoriesSnapshot(
                'vesselCertificateCategories',
                'vesselId',
                vesselId,
                (data: CategoriesData) => { // onLoaded
                    set(data);
                },
                (error) => { // onError
                    console.log(`Error getting vesselCertificateCategories`, error);
                }
            );
        } else {
            done();
        }
    }
};
import React, { useState } from 'react';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { doc, serverTimestamp } from "firebase/firestore";
import { formatTextAreaText, formatValue } from '../../../../lib/util';
import { permissionLevels } from '../../../../shared-state/Core/userPermissions';
import { renderCategoryName } from '../../../../lib/categories';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { Action, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import { Contact } from '../../../../shared-state/Crew/contacts';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import EditContact from '../EditContact/EditContact';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import './ViewContact.css';

interface ViewContactProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem: Contact,
}

const ViewContact: React.FC<ViewContactProps> = ({showModal, setShowModal, selectedItem}) => {
    const userId = sharedState.userId.use(showModal);
    const contactCategories = sharedState.contactCategories.use(showModal);
    const [showEditItemModal, setShowEditItemModal] = useState(false);

    const onEditItem = () => {
        setShowEditItemModal(true);
    };
    const onDeleteItem = () => {
        confirmAction('Are you sure you want to delete this contact?', 'Yes, delete').then(() => { 
            if (!selectedItem || !selectedItem.id || !selectedItem.name) {
                console.error('No contact selected');
                return;
            }
            const action = traceAction('contacts', 'delete') as Action;
            action.docId = selectedItem.id;
            const batch = splittableBatch(firestore, 20 - 0);
            batch.set(
                doc(firestore, 'contacts', selectedItem.id),
                {
                    deletedBy: userId,
                    whenDeleted: action.whenAction,
                    state: 'deleted',
                    touched: serverTimestamp(),
                },
                { merge: true }
            );

            logAction(
                batch,
                'Delete',
                'contacts',
                selectedItem.id,
                selectedItem.name
            );

            onCollectionUpdated(batch, 'contacts');

            action.data = {selectedItem};
            action.save(`Delete contact ${selectedItem?.name}`, batch);
            batch.commit().then(() => {
                action.reportSuccess();
            }).catch((error) => {
                action.reportError(error.message, error);
            });

            showToast('Contact has now been deleted');
            setShowModal(false);
        }).catch(() => {});
    };
    
    return (
        <SeaModal
            title={selectedItem?.name}
            showModal={showModal}
            setShowModal={setShowModal}
            size='standard'
        >
            <div className="contact-info">
                <SeaGrid>
                    <SeaGridCell label="Company" w="50">
                        {formatValue(selectedItem?.company)}
                    </SeaGridCell>
                    <SeaGridCell label="Address" w="50">
                        {formatValue(selectedItem?.address)}
                    </SeaGridCell>
                    <SeaGridCell label="Contact Number" w="50">
                        {formatValue(selectedItem?.number)}
                    </SeaGridCell>
                    <SeaGridCell label="Email" w="50">
                        {formatValue(selectedItem?.email)}
                    </SeaGridCell>
                    <SeaGridCell label="Vendor Number" w="50">
                        {formatValue(selectedItem?.vendorNumber)}
                    </SeaGridCell>
                    <SeaGridCell label="Category" w="50">
                        {renderCategoryName(selectedItem?.categoryId, contactCategories)}
                    </SeaGridCell>
                    <SeaGridCell label="Notes" w="100">
                        {formatValue(formatTextAreaText(selectedItem?.notes))}
                    </SeaGridCell>
                </SeaGrid>
            </div>

            <div className="view-modal-buttons">
                <RequirePermissions
                    role="contacts"
                    level={permissionLevels.EDIT}
                >
                    <SeaButton zone="white" onClick={(e) => onEditItem()}><SeaIcon icon="edit" slot="start" />Edit Contact</SeaButton>
                    <RequirePermissions
                        role="contacts"
                        level={permissionLevels.FULL}
                    >
                        <div className="spacer-wide"></div>
                        <SeaLinkButton mode="standard-link" onClick={(e) => onDeleteItem()}>Delete Contact</SeaLinkButton>
                    </RequirePermissions>
                </RequirePermissions>
            </div>

            {showModal &&
                <EditContact
                    showModal={showEditItemModal}
                    setShowModal={setShowEditItemModal}
                    itemToUpdate={selectedItem}
                    level={2}
                />
            }
        </SeaModal>
    );
};

export default ViewContact;

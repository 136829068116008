import { QueryConstraint, collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { Voyage } from './voyages';
import { useState, useEffect } from 'react';
import { SafetyCheckCompleted } from '../VesselSafety/completedSafetyCheckItems';
import { registerFiles } from "../FileSyncSystem/filesToCache";
import { sharedState } from '../shared-state';
import { renderCategoryName } from '../../lib/categories';

const voyageTimeLeeway = 5 * 60 * 1000; // Allow 5 minutes either side of voyage to match completed checks and tasks

// This returns the completedSafetyChecks during the selectedVoyage
export const useCompletedSafetyChecksDuringVoyage = (selectedVoyage: Voyage | undefined, includeLeeway?: boolean) => {
    const safetyCheckItems = sharedState.safetyCheckItems.use();
    const vesselSafetyItems = sharedState.vesselSafetyItems.use();
    const [completedSafetyChecksDuringVoyage, setCompletedSafetyChecksDuringVoyage] = useState<SafetyCheckCompleted[]>();

    useEffect(() => {
        setCompletedSafetyChecksDuringVoyage(undefined);
        if (selectedVoyage && safetyCheckItems && vesselSafetyItems) {
            const queries: QueryConstraint[] = [
                where('vesselId', '==', selectedVoyage.vesselId),
                where('state', '==', 'active')
            ];

            if (includeLeeway) {
                queries.push(where('whenCompleted', '>=', selectedVoyage.whenDeparted - voyageTimeLeeway));
                queries.push(where('whenCompleted', '<=', (selectedVoyage.whenArrived || 0) + voyageTimeLeeway));
            } else {
                queries.push(where('whenCompleted', '>=', selectedVoyage.whenDeparted));
            }

            return onSnapshot(
                query(collection(firestore, 'safetyCheckCompleted'), ...queries),
                (snap) => {
                    const items: SafetyCheckCompleted[] = []
                    snap.docs.forEach((doc) => {
                        const safetyCheckItem = safetyCheckItems.byId[doc.data().safetyCheckId];
                        safetyCheckItem.name = renderCategoryName(safetyCheckItem?.itemId, vesselSafetyItems)
                        const item = {
                            id: doc.id,
                            item: safetyCheckItem,
                            ...doc.data()
                        } as SafetyCheckCompleted;
                        registerFiles(item.files, 'safetyCheckCompleted', item);
                        items.push(item);
                    });
                    setCompletedSafetyChecksDuringVoyage(items);
                }, (error) => {
                    console.log(`Failed to access safetyCheckCompleted for voyageId=${selectedVoyage?.id} (tab)`, error.message, error, {
                        selectedVoyage
                    });
                }
            );
        }
    }, [includeLeeway, safetyCheckItems, selectedVoyage, vesselSafetyItems]);

    return completedSafetyChecksDuringVoyage;
};

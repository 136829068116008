import {  collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useState, useEffect } from 'react';
import { CreateableDocument, UpdateableDocument } from '../shared-state';
import { SafetyEquipmentItem } from './safetyEquipmentItems';
import { registerFiles } from "../FileSyncSystem/filesToCache";

export interface SafetyEquipmentTaskCompleted extends CreateableDocument, UpdateableDocument {
    completedBy: string;
    files: string[];
    notes?: string;
    safetyEquipmentId: string;
    state: string;
    vesselId: string;
    whenCompleted: number;
    whenExpires?: number;
}

export type CompletedSafetyEquipmentItemsData = SafetyEquipmentTaskCompleted[];

export const useCompletedSafetyEquipmentItems = (selectedSafetyEquipment: SafetyEquipmentItem | undefined) => {
    const [completedSafetyEquipmentItems, setCompletedSafetyEquipmentItems] = useState<CompletedSafetyEquipmentItemsData>();

    useEffect(() => {
        setCompletedSafetyEquipmentItems(undefined);
        if (selectedSafetyEquipment?.id) {
            return onSnapshot(
                query(
                    collection(firestore, 'safetyEquipmentTaskCompleted'),
                    where('safetyEquipmentId', '==', selectedSafetyEquipment.id),
                    where('state', '==', 'active'),
                    orderBy('whenCompleted', 'desc')
                ),
                (snap) => {
                    const all = snap.docs.map((doc) => {
                        const item = {
                            id: doc.id,
                            ...doc.data()
                        } as SafetyEquipmentTaskCompleted;
                        registerFiles(item.files, 'safetyEquipmentTaskCompleted', item);
                        return item;
                    });
                    setCompletedSafetyEquipmentItems(all);
                }, (error) => {
                    console.log(`Failed to access Safety Check Expiry Completed id=${selectedSafetyEquipment.id}`, error);
                }
            );
        }
    }, [selectedSafetyEquipment]);

    return completedSafetyEquipmentItems;

};

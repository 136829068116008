import { getBlob, ref as storageRef } from "firebase/storage";
import { formatDatetime, toInt } from "../../lib/util";
import { sharedState, SharedStateConfig } from "../shared-state";
import { storage } from "../../lib/firebase";
import { filesWaitingToBeRegistered } from "./initFileSync";
import { FileExt, FileId, FileReference, FileState } from "../../lib/files";
import { CachedFile, CachedFileType, FileCacheEntry, FileCollection, cachedFiles, saveCachedFiles, saveFileToLocalStorage } from "./cachedFiles";
import { shouldCacheFiles } from "./fileSyncDeviceSettings";
import { deleteFile } from "./filesToDelete";
import { debugApp } from "../Core/debugging";

//
// Handles the downloading files to cache in the background.
//

type FileToCache = {
    file: CachedFile,
    priority: number
};
export type FileTypesToCache = 'all' | 'thumbnailsOnly' | 'none';

export const filesToCache = [] as FileToCache[]; // Ordered list of FileToCache (by priority)
const filesToCacheById = {} as {
    [id: CachedFile]: FileToCache
}; // To prevent duplicate files being added to filesToCache
let filesBeingCached = 0;
const maxSimultaneousFilesBeingCached = 1; // This must be 1 so that "no more space" handling can work

export type FileSyncStatus = {
    totalFilesToCache: number;
    filesLeft: number;
};

export const fileSyncStatusConfig: SharedStateConfig<FileSyncStatus> = {
    isAlwaysActive: true,
    default: {
        totalFilesToCache: 0,
        filesLeft: 0
    },
    equals: (a, b) => (a.totalFilesToCache === b.totalFilesToCache && a.filesLeft === b.filesLeft)
};

export const triggerProcessFilesToCacheConfig: SharedStateConfig<string> = {
    isAlwaysActive: true,
    default: 'Never run',
    dependencies: ['isFileSyncReady', 'onlineStatus', 'firestoreState'],
    notes: 'Trigger processFilesToCache when FileSync is ready and am online',
    run: (done, set, clear) => {
        done();
        if (!sharedState.isFileSyncReady.current) {
            //debugApp('File Caching', 'FileSync is not ready yet.');
            set('FileSync not ready yet.');
        } if (!sharedState.firestoreState.current!.isEnabled) {
            //debugApp('File Caching', 'FileSync is waiting for firestoreState.isEnabled...');
            set('FileSync waiting for firestore network to be enabled.');
        } else if (!sharedState.onlineStatus.current?.isOnline) {
            //debugApp('File Caching', 'FileSync waiting to come online.');
            set('Waiting to come online.');
        } else {
            queueCachedFilesStillWaitingToBeDownloaded();
            //debugApp('File Caching', 'triggering processFilesToCache...');
            processFilesToCache();
            // triggerCaching();
            set(`Triggered ${formatDatetime()}`);
        }
    }
};

// Completely resets fileToCache
// resetCachedDataInfo() should be called after this so that wanted downloads are requeued
export const resetFilesToCache = () => {
    if (sharedState.isFileSyncReady.current) {
        // Force FileSync to be disabled temporarily
        sharedState.isFileSyncReady.set(false); // This will stop any further downloads from beginning
        // Clear the filesToCache queue
        filesToCache.length = 0;
        // Clear filesToCacheById
        Object.keys(filesToCacheById).forEach((key) => {
            delete filesToCacheById[key];
        });
        // Reset status to reflect changes
        sharedState.fileSyncStatus.clear();
        // Clear all intentions to download
        Object.values(cachedFiles).forEach((entry: FileCacheEntry) => {
            Object.keys(entry[5]).forEach((t) => {
                if (entry[5][t as CachedFileType] === null) {
                    delete entry[5][t as CachedFileType]; // Remove intention to download
                }
            });
        });
        saveCachedFiles();
        // Re-enable FileSync
        sharedState.isFileSyncReady.set(true); // This will triggerProcessFilesToCache which will run queueCachedFilesStillWaitingToBeDownloaded()
    }
};

let retryTimeout: any;
const retryProcessFilesToCacheLater = () => {
    console.log(`[FileSync] Not all filesToCache were successfully cached. Retrying again in an hour...`, cachedFiles);
    clearTimeout(retryTimeout);
    retryTimeout = setTimeout(() => {
        console.log(`[FileSync] Retrying processFilesToCache!`);
        queueCachedFilesStillWaitingToBeDownloaded();
        processFilesToCache();
    }, 60 * 60 * 1000);
};


const extractWhen = (whenOrData: number | any): number => {
    if (typeof whenOrData === 'number') {
        return whenOrData; // Already a when (a number)
    }
    let latestWhen = 0;
    ['whenAdded', 'whenCompleted', 'whenUpdated', 'whenArchived', 'whenUnarchived', 'whenDeleted'].forEach((key) => {
        if (whenOrData[key] && typeof whenOrData[key] === 'number' && whenOrData[key] > latestWhen) {
            latestWhen = whenOrData[key]
        }
    });
    return latestWhen ? latestWhen : 0
}

export const registerFiles = (
    files: FileReference[] | undefined,
    collection: FileCollection,
    whenOrData: number | object,
    fileTypesToCache: FileTypesToCache = 'all',
    viaDataSync = false
) => {
    const when = extractWhen(whenOrData);
    if (files && files.length > 0) {
        if (!sharedState.isFileSyncReady.current) {
            filesWaitingToBeRegistered.files.push({files, collection, when});
            return;
        }
        const shouldCache = shouldCacheFiles(collection, when) && fileTypesToCache === 'all';
        for (let i = 0; i < files.length; i++) {
            const state = toInt(files[i][0], 0) as FileState;
            if (state === 0) {
                continue; // Not available to be downloaded (stuck waiting on another device)
            }
            const id = files[i].substring(1, 21) as FileId;

            const ext = files[i].substring(files[i].lastIndexOf('.') + 1) as FileExt;
            let entry = cachedFiles[id] as FileCacheEntry;
            if (entry === undefined) {
                // Record new entry in our cachedFiles database
                entry = [state, id, ext, collection, when, {}];
                cachedFiles[id] = entry;
            }
            if (state === 2) {
                if (entry[5].F === undefined || entry[5].F === null) { // Full size image not downloaded yet
                    if (shouldCache) {
                        entry[5].F = null; // Indicate we are waiting to download this file
                        cacheFile(id, ext, 'F', when, viaDataSync); // Cache optimised full size image
                    } else if (entry[5].F === null) {
                        delete entry[5].F; // No longer want to download this file
                    }
                } else if (!shouldCache) {
                    // No longer want this file cached
                    deleteFile(`F${id}.${ext}`);
                    delete entry[5].F;
                }
                // We always want to cache thumbnails because they're so small (unless fileTypesToCache == nothing)
                if ((entry[5].T === undefined || entry[5].T === null) && fileTypesToCache !== 'none') {
                    entry[5].T = null;
                    cacheFile(id, ext, 'T', when, viaDataSync); // Cache optimised thumbnail image
                }
            } else {
                if (entry[5].O === undefined || entry[5].O === null) {
                    if (shouldCache) {
                        entry[5].O = null; // Indicate we are waiting to download this file
                        cacheFile(id, ext, 'O', when, viaDataSync); // Cache original image that was uploaded
                    } else if (entry[5].O === null) {
                        delete entry[5].O; // No longer want to download this file
                    }
                } else if (!shouldCache) {
                    // No longer want this file cached
                    deleteFile(`O${id}.${ext}`);
                    delete entry[5].O;
                }
            }
            if (entry[3] === null || when > (entry[4] ?? 0)) {
                // This happens with files that were migrated from the old FileSync system (entries were created missing collection and when values)
                // Or, when when is more recent
                entry[3] = collection;
                entry[4] = when;
            }
            saveCachedFiles();
        }
    }
};
export const registerSignature = (
    file: string | undefined,
    collection: FileCollection,
    whenOrData: number | object,
    fileTypesToCache: FileTypesToCache = 'all',
    viaDataSync = false
) => {
    const when = extractWhen(whenOrData);
    if (file) {
        if (!sharedState.isFileSyncReady.current) {
            filesWaitingToBeRegistered.signatures.push({file, collection, when});
            return;
        }
        const shouldCache = shouldCacheFiles(collection, when) && fileTypesToCache === 'all';
        const state = toInt(file[0], 0);
        if (state === 0) {
            return; // Not available to be downloaded (stuck waiting on another device)
        }
        const id = file.substring(1, 21) as FileId;
        let entry = cachedFiles[id] as FileCacheEntry;
        if (entry === undefined) {
            // Record new entry in our cachedFiles database
            entry = [state, id, 'png', collection, extractWhen(when), {}];
            cachedFiles[id] = entry;
        }
        if (state === 2) {
            // We always want to cache optimised signatures because they're so small (unless fileTypesToCache == nothing)
            if ((entry[5].S === undefined || entry[5].S === null) && fileTypesToCache !== 'none') {
                entry[5].S = null; // Indicate we are waiting to download this file
                cacheFile(id, 'png', 'S', when, viaDataSync); // Cache optimised signature png
            }
        } else {
            if (entry[5].O === undefined || entry[5].O === null) {
                if (shouldCache) {
                    entry[5].O = null; // Indicate we are waiting to download this file
                    cacheFile(id, 'png', 'O', when, viaDataSync); // Cache original signature png that was uploaded
                } else if (entry[5].O === null) {
                    delete entry[5].O; // No longer want to download this file
                }
            } else if (!shouldCache) {
                // No longer want this file cached
                deleteFile(`O${id}.png`);
                delete entry[5].O;
            }
        }
        if (entry[3] === null || when > (entry[4] ?? 0)) {
            // This happens with files that were migrated from the old FileSync system (entries were created missing collection and when values)
            // Or, when when is more recent
             entry[3] = collection;
            entry[4] = when;
        }
        saveCachedFiles();
    }
};
export const registerRichText = (
    sfdoc: { [when: number]: string } | undefined,
    collection: FileCollection,
    fileTypesToCache: FileTypesToCache = 'all',
    viaDataSync = false
) => {
    // Note: Doesn't need when as the keys within the sfdoc map are datetimes (numbers)
    if (sfdoc) {
        const keys = Object.keys(sfdoc);
        if (keys && keys.length > 0) {
            if (!sharedState.isFileSyncReady.current) {
                filesWaitingToBeRegistered.richText.push({sfdoc, collection});
                return;
            }
            keys.sort();
            const latestKey = keys[keys.length - 1]; // Just cache the latest version
            const when = toInt(latestKey);
            const file = sfdoc[latestKey as any] as FileReference;
            const state = toInt(file[0], 0) as FileState;
            if (state === 0) {
                return; // Not available to be downloaded (stuck waiting on another device)
            }
            const shouldCache = shouldCacheFiles(collection, when) && fileTypesToCache === 'all';
            const id = file.substring(1, 21) as FileId;
            let entry = cachedFiles[id] as FileCacheEntry;
            if (entry === undefined) {
                // Record new entry in our cachedFiles database
                entry = [state, id, 'sfdoc', collection, toInt(latestKey), {}];
                cachedFiles[id] = entry;
            }
            if (state === 2) {
                if (entry[5].R === undefined || entry[5].R === null) {
                    if (shouldCache) {
                        entry[5].R = null; // Indicate we are waiting to download this file
                        cacheFile(id, 'sfdoc', 'R', when, viaDataSync); // Cache optimised rich text document
                    } else if (entry[5].R === null) {
                        delete entry[5].R; // No longer want to download this file
                    }
                } else { // We have the latest version cached
                    // Remove any older versions of this sfdoc from the cache (if present)
                    for (let i = 0; i < keys.length - 1; i++) {
                        const file = sfdoc[keys[i] as any] as FileReference;
                        const state = toInt(file[0], 0) as FileState;
                        if (state === 0) { // Hasn't been uploaded yet
                            continue;
                        }
                        const id = file.substring(1, 21) as FileId;
                        const entry = cachedFiles[id] as FileCacheEntry;
                        if (entry && entry[5]) { // We have an old version of a cached sfdoc we no longer need
                            if (entry[5].O) {
                                deleteFile(`O${id}.sfdoc`); // Delete cached file
                            }
                            if (entry[5].R) {
                                deleteFile(`R${id}.sfdoc`); // Delete cached file
                            }
                            delete cachedFiles[id]; // Remove entry from database
                        }
                    }
                    if (!shouldCache) {
                        // No longer want this file cached
                        deleteFile(`R${id}.sfdoc`);
                        delete entry[5].R;
                    }
                }
            } else {
                if (entry[5].O === undefined || entry[5].O === null) {
                    if (shouldCache) {
                        entry[5].O = null; // Indicate we are waiting to download this file
                        cacheFile(id, 'sfdoc', 'O', when, viaDataSync); // Cache original rich text document that was uploaded
                    } else if (entry[5].O === null) {
                        delete entry[5].O; // No longer want to download this file
                    }
                } else if (!shouldCache) {
                    // No longer want this file cached
                    deleteFile(`O${id}.sfdoc`);
                    delete entry[5].O;
                }
            }
            if (entry[3] === null || when > (entry[4] ?? 0)) {
                // This happens with files that were migrated from the old FileSync system (entries were created missing collection and when values)
                // Or, when when is more recent
                entry[3] = collection;
                entry[4] = when;
            }
            saveCachedFiles();
        }
    }
};

const findFilesToCacheIndex = (priority: number) => {
    let indexLow = 0;
    let indexHigh = filesToCache.length;
    while (indexLow < indexHigh) {
        const indexMid = (indexLow + indexHigh) >>> 1;
        if (filesToCache[indexMid].priority < priority) {
            indexLow = indexMid + 1;
        } else {
            indexHigh = indexMid;
        }
    }
    return indexLow;
}

const prioritySettings = {
    // The lower the number, the higher the priority
    filesTypes: {
        O: 15000,
        F: 12000,
        S: 9000,
        T: 0,
        R: 10000
    },
    viaSharedState: 0,
    viaDataSync: 5000, // Files being cached via DataSync rather than sharedState are lower priority
    timeUnit: 60 * 60 * 1000,
    perTimeUnit: 1 // Each hour ago reduces priority by 1
}
// Cache file to local storage by fetching from Storage.
// Adds to a queue to be processed later.
export const cacheFile = (
    id: FileId,
    ext: FileExt,
    fileType: CachedFileType = 'O',
    when: number,
    viaDataSync = false
) => {
    //console.log(`<<< cacheFile ${fileType}${id}.${ext} when=${when} viaDataSync=${viaDataSync}`);
    if (
        (
            !sharedState.diskSpaceStatus.current!.haveEnoughSpace ||
            !sharedState.licenseeSettings.current?.hasOffline
        ) &&
        fileType !== 'T' &&
        fileType !== 'S'
    ) {
        debugApp('File Caching', `Not enough space! bytesFree=${sharedState.diskSpaceStatus.current?.bytesFree} enough=${sharedState.diskSpaceStatus.current?.haveEnoughSpace} hasOffline=${sharedState.licenseeSettings.current?.hasOffline} ${fileType} ${id}.${ext} via=${viaDataSync}`);
        return; // Not enough space to cache files, therefore no caching allowed
    }
    const fileToCache = {
        file: `${fileType}${id}.${ext}` as CachedFile,
        priority: (
            prioritySettings.filesTypes[fileType] + // Certain file types are higher priority
            (viaDataSync ? prioritySettings.viaDataSync : prioritySettings.viaSharedState) + // Source of registration affects prioritu
            (Math.floor((Date.now() - when) / prioritySettings.timeUnit) * prioritySettings.perTimeUnit) // Recent file have a higher priority
        )
    };

    let updateStatus = true;
    if (filesToCacheById[fileToCache.file]) {
        // Already queued. Let's check priority is the same
        if (filesToCacheById[fileToCache.file].priority > fileToCache.priority) {
            // Existing fileToCache is now a higher priority
            // Therefore, remove old fileToCache so it can be reinserted into the queue
            for (let i = 0; i < filesToCache.length; i++) {
                if (filesToCache[i].file === fileToCache.file) {
                    filesToCache.splice(i, 1);
                    updateStatus = false; // No need to increment totalFilesToCache because we're just moving an existing fileToCache to a different index
                    break;
                }
            }
        } else {
            return; // Already being handled correctly
        }
    }

    filesToCacheById[fileToCache.file] = fileToCache; // Need to record that we are handling this fileToCache so we don't double up

    // Insert fileToCache based on priority
    filesToCache.splice(findFilesToCacheIndex(fileToCache.priority), 0, fileToCache);

    if (updateStatus) {
        sharedState.fileSyncStatus.set((current) => {
            return {
                ...current,
                totalFilesToCache: (current?.totalFilesToCache ?? 0) + 1,
                filesLeft: filesToCache.length + filesBeingCached
            } as FileSyncStatus
        });
    }
    processFilesToCache();
};

const queueCachedFilesStillWaitingToBeDownloaded = () => {
    Object.values(cachedFiles).forEach((entry: FileCacheEntry) => {
        Object.keys(entry[5]).forEach((t) => {
            if (entry[5][t as CachedFileType] === null) {
                // Note: We could do a shouldCacheFiles test here to see if we STILL want to cache this file.
                // However, if a file becomes unwanted to be cached, this will be reflected through a new register*() call that will then delete the CachedFileType entry
                //debugApp('File Caching', `queueCachedFilesStillWaitingToBeDownloaded cacheFile ${t}${entry[1]}.${entry[2]}`);
                cacheFile(
                    entry[1], // id
                    entry[2], // ext
                    t as CachedFileType, // fileType
                    entry[4] ?? 0, // when
                    true // as this is not coming from a sharedState registration, it should be treated as coming via DataSync
                );
            }
        });
    });
};

const processFilesToCache = () => {
    //console.log(`processFilesToCache length=${filesToCache.length} filesBeingCached=${filesBeingCached}`);
    if (
        !sharedState.isFileSyncReady.current ||
        !sharedState.onlineStatus.current?.isOnline ||
        !sharedState.licenseeId.current ||
        !sharedState.firestoreState.current?.isEnabled ||
        filesBeingCached >= maxSimultaneousFilesBeingCached
    ) {
        return;
    }
    const fileToCache = filesToCache.shift();

    if (fileToCache === undefined) {
        console.log('[FileSync] Caching files all done.');
        sharedState.fileSyncStatus.set({
            totalFilesToCache: 0,
            filesLeft: 0
        });
        // Check if there are any outstanding errors, and if so, set a timeout to retry later
        const entries = Object.values(cachedFiles);
        for (let i = 0; i < entries.length; i++) {
            if (entries[i][6]) {
                const types = Object.values(entries[i][5]);
                for (let j = 0; j < types.length; j++) {
                    if (types[j] === null) {
                        // There is at least one outstanding error
                        retryProcessFilesToCacheLater();
                        return;
                    }
                }
            }
        }
        return;
    }

    filesBeingCached++;
    sharedState.fileSyncStatus.set((current) => {
        return {
            ...current,
            filesLeft: filesToCache.length + filesBeingCached
        } as FileSyncStatus;
    });

    return processFileToCache(fileToCache).catch((error) => {
        debugApp('File Caching', `Failed to cache file ${fileToCache?.file} message=${(error?.message) ? error.message : error}`);
        console.error(`[FileSync] Failed to cache file ${fileToCache?.file}`, error);
        if (error === 'Timed out') {
            fileToCache.priority += 5000; // Make lower priority
            // Reinsert fileToCache based on priority
            filesToCache.splice(findFilesToCacheIndex(fileToCache.priority), 0, fileToCache);
            //debugApp('File Caching', `Requeued fileToCache ${fileToCache?.file} with a lower priority=${fileToCache.priority}`);
            // Should we check for max timeouts?
            return;
            // if (updateStatus) {
            //     sharedState.fileSyncStatus.set((current) => {
            //         return {
            //             ...current,
            //             totalFilesToCache: (current?.totalFilesToCache ?? 0) + 1,
            //             filesLeft: filesToCache.length + filesBeingCached
            //         } as FileSyncStatus
            //     });
            // }
        }
        delete filesToCacheById[fileToCache.file];
        if (fileToCache) {
            // Record error
            const id = fileToCache.file.substring(1, 21) as FileId;
            cachedFiles[id][6] = (cachedFiles[id][6] ?? 0) + 1;
            cachedFiles[id][7] = ''+error?.message;
            saveCachedFiles();
        }
        // if (cacheErrorsCount > cacheErrorsAllowed) {
        //     cacheErrorsCount = 0;
        //     console.log(`[FileSync] processFileToCache failed ${cacheErrorsAllowed} times for fileToCache=${cacheErrorsAllowed}`);
        //     // Hmmm... not sure what to do here ???
        //     // onDownloadInfo({
        //     //     error: error,
        //     //     data: {
        //     //         cause: `cacheErrorsCount exceeded ${cacheErrorsAllowed}`
        //     //     }
        //     // });
        // } else {
        //     //logDebug('Pushing '+fileToCache+' to cache queue');
        //     if (sharedState.onlineStatus.current?.isOnline) {
        //         cacheErrorsCount++;
        //     }
        //     if (fileToCache) {
        //         filesToCache.push(fileToCache); // Queue up file to be attempted to be cached again
        //         filesToCacheById[fileToCache as string] = true;
        //         // Let's record if the file has an error
        //         const id = fileToCache.substring(1, 21) as FileId;
        //         cachedFiles[id][6] = (cachedFiles[id][6] ?? 0) + 1;
        //         saveCachedFiles();
        //     }
        // }
    }).finally(() => {
        filesBeingCached--;
        //console.log('Finished caching a file. filesToCache.length='+filesToCache.length);
        processFilesToCache();
    });
};

const processFileToCache = (fileToCache: FileToCache): Promise<any> => { // ${fileType}${id}.${ext}
    const fileType = fileToCache.file[0] as CachedFileType;
    if (
        (
            !sharedState.diskSpaceStatus.current!.haveEnoughSpace ||
            !sharedState.licenseeSettings.current?.hasOffline
        ) &&
        fileType !== 'T' &&
        fileType !== 'S'
    ) {
        return Promise.resolve(); // Not enough space to cache files, therefore ignore
    }
    const id = fileToCache.file.substring(1, 21) as FileId;
    let storagePostfix = '';
    switch (fileType) {
        case 'T':
            storagePostfix = '_tiny'; break;
        case 'S':
            storagePostfix = '_sig'; break;
        case 'R':
            storagePostfix = '_opt'; break; // (optimised Rich Text *.sfdoc file)
        case 'F':
            storagePostfix = '_full'; break;
        case 'O':
        default:
            storagePostfix = '';
    }

    const ext = fileToCache.file.substring(fileToCache.file.lastIndexOf('.') + 1);

    const fileRef = storageRef(storage, `files/${id}${storagePostfix}.${ext}`);
    // Download via a blob EVERY TIME
    //debugApp('File Caching', `Download ${fileType}: ${id}${storagePostfix}.${ext} priority=${fileToCache.priority}`);
    //const whenStarted = Date.now();
    return getBlob(fileRef).then((blob: Blob) => {

        // Before we save the downloaded blob locally,
        // let's make sure we were supposed to have cached this file in the first place.
        // This is because fileSyncDeviceSettings may have changed since we started downloading

        const entry = cachedFiles[id];
        if (
            entry === undefined ||
            entry[5] === undefined ||
            entry[5][fileType] !== null
        ) {
            // fileToCache is no longer meant to have been cached
            //debugApp('File Caching', `Was no longer meant to have been cached.`);
            console.log(`[FileSync] CANCELLED fileToCache after having been downloaded! ${fileToCache.file}`);
            return Promise.resolve();
        } else {
            //debugApp('File Caching', `Downloaded ${formatTimeDuration(Date.now() - whenStarted)} size=${blob.size}`);

            return new Promise((resolve, reject) => {
                saveFileToLocalStorage(
                    id,
                    ext,
                    fileType,
                    blob,
                    false, // isState0
                    () => {
                        //debugApp('File Caching', `saveFileToLocalStorage timed out. Skip it for now.`);
                        reject('Timed out');
                    }
                ).then(() => {
                    //debugApp('File Caching', `Saved to local storage ${formatTimeDuration(Date.now() - whenStarted)}`);
                    console.log('[FileSync] Cached file!');
                    resolve(undefined);
                }).catch((error) => {
                    reject(error);
                });
            });
        }
    }).then(() => {
        // Successfully cached file
        delete filesToCacheById[fileToCache.file];
    });
};


import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { canView } from "../../shared-state/Core/userPermissions";
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { registerFiles } from "../FileSyncSystem/filesToCache";

export interface EquipmentManualDocument extends CreateableDocument, UpdateableDocument {
    deletedBy?: string;
    files: string[];
    state: string;
    title: string;
    vesselId: string;
    whenDeleted?: number;
    equipmentIds: string[];
    systemIds: string[];
}

export type EquipmentManualDocumentsData = { 
    byId: {
        [id: string]: EquipmentManualDocument
    },
    documents: EquipmentManualDocument[],
    filterOptions: {
        systemIds: string[],
        equipmentIds: string[]
    }
};

export const equipmentManualDocumentsConfig: SharedStateConfig<EquipmentManualDocumentsData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId', 'vesselSystems', 'equipment'], 
    countLiveDocs: () => sharedState.equipmentManualDocuments.current?.documents.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        const vesselSystems = sharedState.vesselSystems.current;
        const equipment = sharedState.equipment.current;
        if (
            vesselId &&
            equipment &&
            vesselSystems &&
            canView('equipmentManualDocuments')
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'equipmentManualDocuments'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'active'),
                    orderBy('title', 'asc')
                ),
                (snap) => {
                    done();
                    const rawEquipmentManualDocuments = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                        } as EquipmentManualDocument;
                    });
                    if (rawEquipmentManualDocuments) {
                        const documents: EquipmentManualDocument[] = [];
                        const byId = {} as {
                            [id: string]: EquipmentManualDocument
                        };
                        const filterOptions = {
                            systemIds: [] as string[],
                            equipmentIds: [] as string[],
                        };
                        const hasFilter = {
                            systemIds: {} as { [id: string]: boolean },
                            equipmentIds: {} as { [id: string]: boolean },
                        };
            
                        rawEquipmentManualDocuments.forEach((document) => {
                            byId[document.id] = document;
                            registerFiles(document.files, 'equipmentManualDocuments', document);
                            documents.push(document);
                        });
            
                        // Decorate manuals with equipment
                        equipment.all.forEach((item) => {
                            if (
                                item.equipmentDocumentIds &&
                                item.equipmentDocumentIds.length > 0
                            ) {
                                // Add equipment item to referenced equipmentManualDocuments
                                item.equipmentDocumentIds.forEach((manualId: string) => {
                                    const manual = byId[manualId];
                                    if (manual && manual.state === 'active') {
                                        // Add to equipment.equipment/system to filters if not already present
                                        hasFilter.equipmentIds[item.id] = true;
                                        if (manual.equipmentIds === undefined) {
                                            manual.equipmentIds = [];
                                        }
                                        manual.equipmentIds.push(item.id);
                                        // if (item.state === 'deleted') {
                                        //     manual.equipmentList += ' (deleted)';
                                        // }
                                        if (item.systemId) {
                                            hasFilter.systemIds[item.systemId] = true;
                                            if (manual.systemIds === undefined) {
                                                manual.systemIds = [];
                                            }
                                            if (
                                                manual.systemIds.indexOf(item.systemId) ===
                                                -1
                                            ) {
                                                manual.systemIds.push(item.systemId);
                                            }
                                        }
                                    }
                                });
                            }
                        });
            
                        equipment.all?.forEach((item) => {
                            if (hasFilter.equipmentIds[item.id]) {
                                filterOptions.equipmentIds.push(item.id);
                            }
                        });
            
                        vesselSystems.ids?.forEach((id: string) => {
                            if (hasFilter.systemIds[id]) {
                                filterOptions.systemIds.push(id);
                            }
                        });
            
                        set({
                            byId,
                            documents,
                            filterOptions,
                        });
                    } else {
                        done();
                    }
                },
                (error) => {
                    done();
                    // This should be very rare
                    console.log(
                        `Failed to access equipmentManualDocuments for vessel ${vesselId}`,
                        error
                    );
                }
            );
        } else {
            done();
        }
    }
};
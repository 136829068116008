import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, preventMultiTap, hasArrayChanged } from '../../../../lib/util';
import { categoriesToSeaMultiSelectOptions } from '../../../../lib/categories';
import { addedToRiskRegister, auhoritiesNotifiedForReviewByRegion, authoritiesNotifiedForReview, controlStrategies, incidentSeverities, injuryConclusions, lostTimes, yesNoNa } from '../../../../pages/HealthSafety/Incidents/incidents-util';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { Action, reportError, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { hasSignatureChanged, haveFilesChanged, makeSeaFiles, makeSignature, saveFileRefs, SeaFile, seaFilesToValue, signatureToValue } from '../../../../lib/files';
import { Incident } from '../../../../shared-state/HealthSafety/incidents';
import { IncidentReview } from '../../../../shared-state/HealthSafety/incidentReviews';
import Yup from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaSignature from '../../../../components/SeaSignature/SeaSignature';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import CommonIncidentContent from '../ViewIncident/CommonIncidentContent';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import './ReviewIncident.css';


const strategyOptions = Object.keys(controlStrategies).map((key) => {
    return {
        id: key,
        name: controlStrategies[key]
    };
});

const injuryOutcomeOptions = Object.keys(injuryConclusions).map((key) => {
    return {
        id: key,
        name: injuryConclusions[key]
    };
});

interface ReviewIncidentProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    incident?: Incident,
    reviewToUpdate?: IncidentReview
}

const ReviewIncident: React.FC<ReviewIncidentProps> = ({
    showModal,
    setShowModal,
    level,
    incident,
    reviewToUpdate
}) => {
    const userId = sharedState.userId.use(showModal);
    const licenseeId = sharedState.licenseeId.use(showModal);
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);
    const incidentCauses = sharedState.incidentCauses.use(showModal);
    const [causeIds, setCauseIds] = useState<string[]>();
    const [strategies, setStrategies] = useState<string[]>();
    const [injuryConclusions, setInjuryConclusions] = useState<string[]>();
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [signature, setSignature] = useState<SeaFile>();
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const hasSubmittedRef = useRef(false);
    const formRef = useRef<HTMLFormElement>(null);
    const saveAsDraftRef = useRef(false);
    const reviewHeadingRef = useRef<HTMLHeadingElement>(null);

    const initialValues = useMemo(() => {
        if (reviewToUpdate) {
            return {
                severity: reviewToUpdate.severity ? ''+reviewToUpdate.severity : '0',
                notifiedAuthorities: reviewToUpdate.notifiedAuthorities ? ''+reviewToUpdate.notifiedAuthorities : '',
                lostTime: reviewToUpdate.lostTime ? ''+reviewToUpdate.lostTime : '',
                addedToRiskRegister: reviewToUpdate.addedToRiskRegister ? ''+reviewToUpdate.addedToRiskRegister : '',
                externalRequired: reviewToUpdate.externalRequired ? ''+reviewToUpdate.externalRequired : '',
                prevention: reviewToUpdate.prevention ? ''+reviewToUpdate.prevention : '',
                analysis: reviewToUpdate.analysis ? ''+reviewToUpdate.analysis : '',
            };
        } else {
            return {
                severity: '0',
                notifiedAuthorities: '',
                lostTime: '',
                addedToRiskRegister: '',
                externalRequired: '',
                prevention: '',
                analysis: '',
            };
        }
    }, [reviewToUpdate]);

    const onOpened = () => {
        hasSubmittedRef.current = false;
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setCauseIds((reviewToUpdate?.causeIds) ? reviewToUpdate.causeIds : []);
        setStrategies((reviewToUpdate?.strategies) ? reviewToUpdate.strategies : []);
        setInjuryConclusions((reviewToUpdate?.injuryConclusions) ? reviewToUpdate.injuryConclusions : []);
        setFiles(makeSeaFiles(reviewToUpdate?.files));
        setSignature(makeSignature(reviewToUpdate?.signature));

        const scrollToHeading = () => {
            reviewHeadingRef.current?.scrollIntoView({
                behavior: 'smooth', //'auto',
                block: 'start'
            });
        };
        setTimeout(() => scrollToHeading(), 100);
        setTimeout(() => scrollToHeading(), 200);
        setTimeout(() => scrollToHeading(), 500);
        //setTimeout(() => scrollToHeading(), 1000);
    }

    const {handleSubmit, handleChange, handleBlur, values, errors, isValid, touched, setValues, resetForm, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            severity: Yup.number(),
            notifiedAuthorities: Yup.string().max(200),
            authority: Yup.string().max(200),
            lostTime: Yup.string().max(200),
            addedToRiskRegister: Yup.string().max(200),
            externalRequired: Yup.string().max(200),
            prevention: Yup.string().max(5000),
            analysis: Yup.string().max(5000),
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('incidentReview') || (signature === undefined && !saveAsDraftRef.current)) {
                return;
            }
            if (!incident) {
                throw new Error('Incident not set');
            }
            // Attempt upload first....
            let filesToUpload = [] as SeaFile[];
            if (files) {
                filesToUpload = [...files];
            }
            if (signature) {
                filesToUpload.push(signature);
            }
            uploadFiles(filesToUpload).then(() => {
                // Process form
                const action = traceAction('incidents') as Action;
                const batch = splittableBatch(firestore, 20 - 0);
                if (reviewToUpdate) {
                    action.type = 'update';
                    action.docId = reviewToUpdate.id;
                    batch.set(
                        doc(firestore, 'incidentReviews', reviewToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: action.whenAction,
                            severity: data.severity ? parseInt(data.severity) : deleteValue,
                            notifiedAuthorities: data.notifiedAuthorities ? data.notifiedAuthorities : deleteValue,
                            causeIds: causeIds,
                            strategies: strategies,
                            injuryConclusions: (injuryConclusions?.length) ? injuryConclusions : deleteValue,
                            lostTime: data.lostTime ? data.lostTime : deleteValue,
                            addedToRiskRegister: data.addedToRiskRegister ? data.addedToRiskRegister : deleteValue,
                            externalRequired: data.externalRequired ? data.externalRequired : deleteValue,
                            prevention: data.prevention ? data.prevention : deleteValue,
                            analysis: data.analysis ? data.analysis : deleteValue,
                            files: seaFilesToValue(files),
                            signature: signatureToValue(signature),
                            state: saveAsDraftRef.current ? 'draft' : 'completed',
                            completedBy: (saveAsDraftRef.current || reviewToUpdate.state !== 'draft') ? undefined : userId,
                            whenCompleted: (saveAsDraftRef.current || reviewToUpdate.state !== 'draft') ? undefined : action.whenAction,
                            touched: serverTimestamp(),
                        },
                        { merge: true }
                    );

                    saveFileRefs(batch, filesToUpload, 'incidentReviews', reviewToUpdate.id);
                    if (!saveAsDraftRef.current) {
                        logAction(
                            batch,
                            (reviewToUpdate.state === 'draft') ? 'Add' : 'Update',
                            'incidentReviews',
                            reviewToUpdate.id,
                            incident.name,
                            [incident.vesselId]
                        );
                    }

                } else {
                    const newRef = doc(firestore, 'incidentReviews', incident.id);
                    action.type = 'create';
                    action.docId = newRef.id;
                    // Because an incident review may have already existed and deleted, we need to make sure a merge completely wipes over any pre-existing values
                    batch.set(newRef, {
                        licenseeId: licenseeId,
                        vesselId: incident.vesselId,
                        addedBy: userId,
                        whenAdded: action.whenAction,
                        updatedBy: deleteValue,     // Wipe over any pre-existing value
                        whenUpdated: deleteValue,   // Wipe over any pre-existing value
                        deletedBy: deleteValue,     // Wipe over any pre-existing value
                        whenDeleted: deleteValue,   // Wipe over any pre-existing value
                        severity: data.severity ? parseInt(data.severity) : deleteValue,
                        notifiedAuthorities: data.notifiedAuthorities ? data.notifiedAuthorities : deleteValue,
                        causeIds: causeIds ? causeIds : deleteValue,
                        strategies: strategies ? strategies : deleteValue,
                        injuryConclusions: (injuryConclusions?.length) ? injuryConclusions : deleteValue,
                        lostTime: data.lostTime ? data.lostTime : deleteValue,
                        addedToRiskRegister: data.addedToRiskRegister ? data.addedToRiskRegister : deleteValue,
                        externalRequired: data.externalRequired ? data.externalRequired : deleteValue,
                        prevention: data.prevention ? data.prevention : deleteValue,
                        analysis: data.analysis ? data.analysis : deleteValue,
                        files: seaFilesToValue(files),
                        signature: signatureToValue(signature) ? signatureToValue(signature) : deleteValue,
                        state: saveAsDraftRef.current ? 'draft' : 'completed',
                        completedBy: saveAsDraftRef.current ? deleteValue : userId,
                        whenCompleted: saveAsDraftRef.current ? deleteValue : action.whenAction,
                        touched: serverTimestamp(),
                    }, { merge: true });

                    saveFileRefs(batch, filesToUpload, 'incidentReviews', newRef.id);
                    if (!saveAsDraftRef.current) {
                        logAction(
                            batch,
                            'Add',
                            'incidentReviews',
                            newRef.id,
                            incident.name,
                            [incident.vesselId]
                        );
                    }
                }

                // Update the status of the incident this review is based on
                batch.set(doc(firestore, 'incidents', incident.id), {
                    state: (saveAsDraftRef.current ? 'inReview' : 'completed'),
                    touched: serverTimestamp()
                }, { merge: true });

                onCollectionUpdated(batch, 'incidents');
                onCollectionUpdated(batch, 'incidentReviews');
                action.data = {
                    data,
                    causeIds,
                    strategies,
                    injuryConclusions,
                    files: seaFilesToValue(files),
                    signature: signatureToValue(signature)
                };
                action.save(`${reviewToUpdate ? 'Update' : 'Review' } incident report ${incident?.name}`, batch);
                batch.commit().then(() => {
                    action.reportSuccess();
                }).catch((error) => {
                    action.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload incident/event review files`, error.message, error, {
                        data,
                        reviewToUpdate,
                        files: seaFilesToValue(files)
                    });
                }
            });
        }
    });

    const causeOptions = useMemo(() => {
        return categoriesToSeaMultiSelectOptions(incidentCauses);
    }, [incidentCauses]);

    useEffect(() => {
        hasSubmittedRef.current = true;
        setHasSubmitted(true);
    }, [isSubmitting])

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            hasArrayChanged(causeIds, reviewToUpdate?.causeIds) ||
            hasArrayChanged(strategies, reviewToUpdate?.strategies) ||
            hasArrayChanged(injuryConclusions, reviewToUpdate?.injuryConclusions) ||
            haveFilesChanged(files, reviewToUpdate?.files) ||
            hasSignatureChanged(signature, reviewToUpdate?.signature)
        );
    }, [values, files, reviewToUpdate, signature, causeIds, initialValues, injuryConclusions, strategies]);

    return incident ? (
        <SeaModal
            title={`Reviewing Report - ${incident?.name}`}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={level}
            size="wide"
        >
            <CommonIncidentContent
                showModal={showModal}
                incident={incident}
            />

            <form ref={formRef} onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <div className="form-line" style={{ marginTop: '0px', marginBottom: '20px' }}></div>
                            <h2 ref={reviewHeadingRef}>
                                Review
                            </h2>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6">
                            <SeaSelect
                                label="Severity of Event"
                                name="severity"
                                zone="white"
                                value={values.severity}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.severity ? errors.severity : ''}
                            >
                                {Object.keys(incidentSeverities).map((key) => {
                                    const severity = Number(key);
                                    return <IonSelectOption key={severity} value={severity}>{incidentSeverities[severity]}</IonSelectOption>
                                })}
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelect
                                label="Is this event notifiable to authorities?"
                                name="notifiedAuthorities"
                                zone="white"
                                popoverSize="wide"
                                value={values.notifiedAuthorities}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.notifiedAuthorities ? errors.notifiedAuthorities : ''}
                            >
                                <IonSelectOption value="">Not Set</IonSelectOption>
                                {licenseeSettings && auhoritiesNotifiedForReviewByRegion[licenseeSettings.region]?.map((authority: string) => {
                                    return <IonSelectOption key={authority} value={authority}>{authoritiesNotifiedForReview[authority]}</IonSelectOption>
                                })}
                                {(licenseeSettings && values.notifiedAuthorities && auhoritiesNotifiedForReviewByRegion[licenseeSettings.region]?.indexOf(values.notifiedAuthorities) === -1) &&
                                    <IonSelectOption value={values.notifiedAuthorities}>{authoritiesNotifiedForReview[values.notifiedAuthorities]}</IonSelectOption>
                                }
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="6">
                            <SeaMultiSelect
                                mode="popover"
                                label="Causes of this Incident/Event"
                                values={causeIds}
                                setValues={setCauseIds}
                                options={causeOptions}
                                useAllOption={false}
                                isSubmitting={hasSubmitted}
                                emptyText="Not Set"
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaMultiSelect
                                mode="popover"
                                label="Control Strategies"
                                values={strategies}
                                setValues={setStrategies}
                                options={strategyOptions}
                                useAllOption={false}
                                isSubmitting={hasSubmitted}
                                emptyText="Not Set"
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelect
                                label="Has this risk been added to Risk Assessments?"
                                name="addedToRiskRegister"
                                zone="white"
                                value={values.addedToRiskRegister}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.addedToRiskRegister ? errors.addedToRiskRegister : ''}
                            >
                                <IonSelectOption value="">Not Set</IonSelectOption>
                                {Object.keys(addedToRiskRegister).map((item) => {
                                    return <IonSelectOption key={item} value={item}>{addedToRiskRegister[item]}</IonSelectOption>
                                })}
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelect
                                label="Is an external investigation required?"
                                name="externalRequired"
                                zone="white"
                                value={values.externalRequired}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.externalRequired ? errors.externalRequired : ''}
                            >
                                <IonSelectOption value="">Not Set</IonSelectOption>
                                {Object.keys(yesNoNa).map((item) => {
                                    return <IonSelectOption key={item} value={item}>{yesNoNa[item]}</IonSelectOption>
                                })}
                            </SeaSelect>
                        </IonCol>
                        {incident?.type === 'injury' &&
                            <>
                                <IonCol size="6">
                                    <SeaMultiSelect
                                        mode="popover"
                                        label="Injury conclusion"
                                        values={injuryConclusions}
                                        setValues={setInjuryConclusions}
                                        options={injuryOutcomeOptions}
                                        useAllOption={false}
                                        isSubmitting={hasSubmitted}
                                        emptyText="Not Set"
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaSelect
                                        label="How much time has been lost to injury?"
                                        name="lostTime"
                                        zone="white"
                                        value={values.lostTime}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        error={touched.lostTime ? errors.lostTime : ''}
                                    >
                                        <IonSelectOption value="">Not Set</IonSelectOption>
                                        {Object.keys(lostTimes).map((item) => {
                                            return <IonSelectOption key={item} value={item}>{lostTimes[item]}</IonSelectOption>
                                        })}
                                    </SeaSelect>
                                </IonCol>
                            </>
                        }
                        <IonCol size="12">
                            <SeaTextarea
                                label="Corrective actions or tasks required"
                                name="prevention"
                                value={values.prevention}
                                height={80}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                error={touched.prevention ? errors.prevention : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                label="Analysis of Review"
                                name="analysis"
                                value={values.analysis}
                                height={100}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                error={touched.analysis ? errors.analysis : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="incidents"
                                field="files"
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaSignature
                                collection="incidentReviews"
                                file={signature}
                                setFile={setSignature}
                                label="Sign or initial below"
                                isRequired={hasSubmittedRef.current && !saveAsDraftRef.current}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <div style={{ height: '20px' }}></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid && (signature !== undefined || saveAsDraftRef.current)}
                />
                <div className="view-modal-buttons">
                    {(reviewToUpdate === undefined || reviewToUpdate.state === 'draft') &&
                        <>
                            <SeaButton
                                size="wide"
                                onClick={(e) => {
                                    console.log('Save Progress');
                                    saveAsDraftRef.current = true;
                                    formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                                }}
                            >
                                Save Progress
                            </SeaButton>
                            <div className="spacer"></div>
                        </>
                    }
                    <SeaButton
                        size="wide"
                        onClick={(e) => {
                            console.log('Normal submit!');
                            saveAsDraftRef.current = false;
                            formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                        }}
                    >
                        {(reviewToUpdate && reviewToUpdate.state !== 'draft') ? 'Update Review' : 'Complete Review'}
                    </SeaButton>
                </div>
            </form>
        </SeaModal>
    ): <></>;
};

export default ReviewIncident;

import { isPlatform } from "@ionic/react";
import { makeDateTime, regions } from "../../lib/util";
import { runSharedState, sharedState, SharedStateConfig } from "../shared-state";
import { FileCollection } from "./cachedFiles";
import { isDesktopPretendingToBeHybrid } from "../../App";

// Idea:
// Have todayMillis as prerequisite
// Load json from localStorage
// Manually run sharedState when new settings are saved

export type FileSyncDeviceSettings = { // Settings used to determine which files should be cached (based upon FileSyncDeviceSettings, platform, todayMillis)
    [collection in FileCollection]: {
        isEnabled: boolean,
        minWhen: number // when a file was last updated must be >= to this to be cached. If not a history type collection this should be 0
    }
};

export type FileSyncDeviceSettingsJson = { // Settings which are device specific and are stored within localStorage
    [collection in FileCollection]: {
        isEnabled: boolean,
        historyMonths?: number // represents number of months worth of history to cache
    }
};

export const fileSyncDeviceSettingsUi = [
    {
        name: 'Vessels',
        types: [
            {
                name: 'Vessel Images',
                collections: ['vessels']
            }
        ]
    }, {
        name: 'Vessel Logbook',
        types: [
            {
                name: 'Voyages',
                collections: ['voyages', 'voyageDocuments'] // voyages only have signatures, voyageDocuments have files
            }
        ]
    }, {
        name: 'Vessel Safety',
        types: [
            {
                name: 'Safety Checks',
                collections: ['safetyCheckItems'],
                history: {
                    name: 'completion history',
                    collection: 'safetyCheckCompleted'
                }
            }, {
                name: 'Safety Equipment Expiries',
                collections: ['safetyEquipmentItems'],
                history: {
                    name: 'completion history',
                    collection: 'safetyEquipmentTaskCompleted'
                }
            }, {
                name: 'Drills',
                collections: [],
                history: {
                    name: 'reporting history',
                    collection: 'drillReports'
                }
            }
        ]
    }, {
        name: 'Vessel Maintenance',
        types: [
            {
                name: 'Job List',
                collections: ['jobs']
            }, {
                name: 'Spare Parts',
                collections: ['spareParts']
            }, {
                name: 'Equipment',
                collections: ['equipment']
            }, {
                name: 'Equipment Manuals',
                collections: ['equipmentManualDocuments']
            }, {
                name: 'Maintenance History',
                history: {
                    name: 'history',
                    collection: 'maintenanceTasksCompleted'
                }
            }
        ]
    }, {
        name: 'Vessel Document Register',
        types: [
            {
                name: 'Vessel Certificates',
                collections: ['vesselCertificates']
            }, {
                name: 'Vessel Documents',
                collections: ['vesselDocuments']
            }, {
                name: 'Survey Documents',
                collections: ['surveyReports']
            }, {
                name: 'Standard Operating Procedures',
                collections: ['SOPs']
            }
        ]
    }, {
        name: 'Health & Safety',
        types: [
            {
                name: 'Incident / Event Reports',
                collections: ['incidents'],
                history: {
                    name: 'review history',
                    collection: 'incidentReviews'
                }
            }, {
                isValid: () => (sharedState.licenseeSettings.current!.riskRegister.version === 1),
                name: 'Hazards',
                collections: ['hazards']
            }, {
                isValid: () => (sharedState.licenseeSettings.current!.riskRegister.version === 2),
                name: 'Risk Assesments',
                collections: ['risks'],
                history: {
                    name: 'review history',
                    collection: 'risksReviewed'
                }
            }, {
                name: 'Health & Safety Meetings',
                collections: [],
                history: {
                    name: 'reporting history',
                    collection: 'safetyMeetingReports'
                }
            }, {
                name: 'Dangerous Goods Register',
                collections: ['dangerousGoods']
            }
        ]
    }, {
        name: 'Company Documents Register',
        types: [
            {
                name: () => regions[sharedState.licenseeSettings.current!.region].companyPlan,
                collections: ['companyPlans']
            },
            {
                name: 'Company Documents',
                collections: ['companyDocuments']
            }, {
                name: 'Forms / Checklists',
                collections: ['customFormVersions'],
                history: {
                    name: 'completion history',
                    collection: 'customFormsCompleted'
                }
            }
        ]
    }, {
        name: 'Crew',
        types: [
            {
                name: 'Crew Forms & Documents',
                collections: ['userDocuments']
            }, {
                name: 'Crew Certificates',
                collections: ['crewCertificates']
            }, {
                name: 'Crew Training',
                collections: ['trainingTasks'],
                history: {
                    name: 'reporting history',
                    collection: 'trainingTaskReports'
                }
            }
        ]
    }
];

export const makeDefaultFileSyncDeviceSettingsJson = () => {
    const json = {} as FileSyncDeviceSettingsJson;
    fileSyncDeviceSettingsUi.forEach((section) => {
        section.types.forEach((type) => {
            type.collections?.forEach((collection) => {
                json[collection as FileCollection] = {
                    isEnabled: true
                };
            });
            if (type.history?.collection) {
                json[type.history.collection as FileCollection] = {
                    isEnabled: true,
                    historyMonths: 6
                };
            }
        });
    });
    return json;
};

export const loadFileSyncDeviceSettingsJson = ():FileSyncDeviceSettingsJson => {
    const json = JSON.parse(window.localStorage.getItem(`_${sharedState.licenseeId.current}_fileSyncSettings`) ?? '{}') as FileSyncDeviceSettingsJson;
    // Fill in any missing settings with default values (isEnabled, and 6 months if there is a history)
    const defaultJson = makeDefaultFileSyncDeviceSettingsJson();
    Object.keys(defaultJson).forEach((collection) => {
        if (json[collection as FileCollection] === undefined) {
            json[collection as FileCollection] = {
                ...defaultJson[collection as FileCollection]
            };
        }
    });
    return json;
};

export const saveFileSyncDeviceSettingsJson = (settings: FileSyncDeviceSettingsJson) => {
    window.localStorage.setItem(`_${sharedState.licenseeId.current}_fileSyncSettings`, JSON.stringify(settings));
    runSharedState('fileSyncDeviceSettings'); // Trigger fileSyncDeviceSettings to run
};

export const fileSyncDeviceSettingsConfig: SharedStateConfig<FileSyncDeviceSettings> = {
    isAlwaysActive: true,
    dependencies: ['licenseeId', 'todayMillis'],
    default: {} as FileSyncDeviceSettings,
    run: (done, set, clear) => {
        done();
        const licenseeId = sharedState.licenseeId.current;
        const todayMillis = sharedState.todayMillis.current;
        if (licenseeId && todayMillis) {

            const json = loadFileSyncDeviceSettingsJson();

            const settings = {} as FileSyncDeviceSettings;

            Object.keys(json).forEach((collection) => {
                const setting = json[collection as FileCollection];
                if (setting.historyMonths !== undefined) {
                    // Configure history collection
                    settings[collection as FileCollection] = {
                        isEnabled: (setting.isEnabled && setting.historyMonths > 0), // (0 historyMonths means we don't want history at all)
                        minWhen: makeDateTime(todayMillis).minus({
                            months: setting.historyMonths
                        }).toMillis()
                    };
                } else {
                    // Configue normal collection
                    settings[collection as FileCollection] = {
                        isEnabled: setting.isEnabled,
                        minWhen: 0
                    };
                }
            });

            set(settings);
        }
    },
    notes: 'Settings which help determine which files should be cached',
};

// Should the app be caching files from a particular collection?
// Note: Thumbnails and signatures are always cached regardless.
export const shouldCacheFiles = (
    collection: FileCollection,
    when: number
) => {
    if (!isPlatform('hybrid') && !isDesktopPretendingToBeHybrid) {
        return false;
    }
    return (
        sharedState.fileSyncDeviceSettings.current?.[collection]?.isEnabled &&
        when >= sharedState.fileSyncDeviceSettings.current[collection].minWhen
    );
};

import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, formatSeaDate, toMillis, addInterval, preventMultiTap } from '../../../../lib/util';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { RiskReviewed, useRisksReviewed } from '../../../../shared-state/HealthSafety/risksReviewed';
import { Action, reportError, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import { Risk } from '../../../../shared-state/HealthSafety/risks';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';

interface ReviewRiskAssessmentProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    setShowParentModal: (showModal: boolean) => void, // Allows us to also close parent modal
    selectedRisk: Risk,
    historyItemToUpdate: RiskReviewed | undefined,
    level?: number
}

const ReviewRiskAssessment: React.FC<ReviewRiskAssessmentProps> = ({
    showModal,
    setShowModal,
    setShowParentModal,
    selectedRisk,
    historyItemToUpdate,
    level
}) => {
    const isMounted = useRef(false);
    const userId = sharedState.userId.use(showModal);
    const todayMillis = sharedState.todayMillis.use(showModal);
    const vessels = sharedState.vessels.use();
    const risksReviewed = useRisksReviewed(showModal ? selectedRisk : undefined);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false; };
    }, []);


    const initialValues = useMemo(() => {
        if (historyItemToUpdate) {
            return {
                whenReviewed: historyItemToUpdate.whenReviewed ? formatSeaDate(historyItemToUpdate.whenReviewed) : '',
                notes: historyItemToUpdate.notes ? ''+historyItemToUpdate.notes : ''
            };
        } else {
            return {
                whenReviewed: formatSeaDate(),
                notes: ''
            };
        }
    }, [historyItemToUpdate, todayMillis]); // eslint-disable-line

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setFiles(makeSeaFiles(historyItemToUpdate?.files));
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues, 
        validationSchema: Yup.object({
            whenReviewed: Yup.date().max(formatSeaDate()).required().min(...notTooOld),
            notes: Yup.string().max(5000)
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('riskAssessment')) { return; }
            // Attempt upload first.... ?
            uploadFiles(files).then(() => {
  
                const action = traceAction('risksReviewed') as Action;
                const batch = splittableBatch(firestore, 20 - 1); // -1 because risksReviewed uses 2 security access calls

                // Determine whenLatestReviewed
                let whenLatestReviewed = toMillis(data.whenReviewed) as number;

                // TODO! This could be removed after the next mandatory update.
                const activeVesselIds = selectedRisk.vesselIds.filter(vesselId => {
                    if (!vessels) { return false; }
                    const vessel = vessels.byId[vesselId];
                    if (!vessel) { return false; }
                    if (vessel.state !== 'active') { return false; }
                    return true;
                });

                if (historyItemToUpdate && historyItemToUpdate.whenReviewed === selectedRisk.whenLastReviewed) {
                    // History item being edited is the latest one,
                    // so it's possible the second most recent one (if it exists) is now the latest
                    if (risksReviewed && risksReviewed.length > 1) {
                        if (risksReviewed[1].whenReviewed > whenLatestReviewed) {
                            // The second most recent one is now the lastest
                            whenLatestReviewed = risksReviewed[1].whenReviewed;
                        }
                    }
                } else {
                    // The most recent completed could still be the latest
                    if (selectedRisk.whenLastReviewed > whenLatestReviewed) {
                        whenLatestReviewed = selectedRisk.whenLastReviewed;
                    }
                }

                batch.set(
                    doc(firestore, 'risks', selectedRisk.id),
                    {
                        whenLastReviewed: whenLatestReviewed,
                        whenDue: addInterval(whenLatestReviewed, selectedRisk.interval),
                        touched: serverTimestamp(),
                        vesselIds: activeVesselIds
                    },
                    { merge: true }
                );

                if (historyItemToUpdate) {
                    action.type = 'update';
                    action.docId = historyItemToUpdate.id;
                    batch.set(
                        doc(firestore, 'risksReviewed', historyItemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: action.whenAction,
                            notes: data.notes ? data.notes : deleteValue,
                            whenReviewed: toMillis(data.whenReviewed),
                            files: seaFilesToValue(files),
                            touched: serverTimestamp()
                        },
                        { merge: true }
                    );
                    logAction(
                        batch,
                        'Update',
                        'risksReviewed',
                        historyItemToUpdate.id,
                        selectedRisk.name,
                        historyItemToUpdate.vesselIds
                    );
                    saveFileRefs(batch, files, 'risksReviewed', historyItemToUpdate.id);
                } else {
                    const newRef = doc(collection(firestore, 'risksReviewed'));
                    action.type = 'create';
                    action.docId = newRef.id;
                    batch.set(newRef, {
                        riskId: selectedRisk.id,
                        reviewedBy: userId,
                        vesselIds: activeVesselIds,
                        whenAdded: action.whenAction,
                        addedBy: userId,
                        notes: data.notes ? data.notes : undefined,
                        whenReviewed: toMillis(data.whenReviewed), 
                        files: seaFilesToValue(files),
                        state: 'active',
                        touched: serverTimestamp(),
                    });
                    saveFileRefs(batch, files, 'risksReviewed', newRef.id);
                    logAction(
                        batch,
                        'Add',
                        'risksReviewed',
                        newRef.id,
                        selectedRisk.name,
                        selectedRisk.vesselIds
                    );
                }

                onCollectionUpdated(batch, 'risksReviewed');
                onCollectionUpdated(batch, 'risks');

                action.data = {
                    data,
                    files: seaFilesToValue(files),
                    selectedRisk
                };

                action.save(`${historyItemToUpdate?'Update':'Add'} risk assessment review ${selectedRisk?.name}`, batch);
                batch.commit().then(() => {
                    action.reportSuccess();
                }).catch((error) => {
                    action.reportError(error.message, error);
                });

                setShowModal(false);
                if (!historyItemToUpdate) {
                    setTimeout(() => {
                        if (!isMounted.current) return;
                        setShowParentModal(false);
                    }, 250);
                }
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload risk review files`, error.message, error, {
                        files: seaFilesToValue(files),
                        data,
                        selectedRisk
                    });
                }
            });

        }
    });
    
    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            haveFilesChanged(files, historyItemToUpdate?.files)
        );
    }, [values, files, historyItemToUpdate?.files, initialValues]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={historyItemToUpdate ? 'Edit Risk Review' : 'Complete Risk Review'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size='thin'
            level={level}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaDate
                                label="Date Reviewed"
                                name="whenReviewed"
                                value={values.whenReviewed}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.whenReviewed ? errors.whenReviewed : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                label="Notes"
                                placeholder="Add notes..."
                                name="notes"
                                value={values.notes}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                height={150}
                                inputmode="text"
                                error={touched.notes ? errors.notes : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="risksReviewed"
                                field="files"
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" size="wide" type="submit">{historyItemToUpdate ? 'Update Risk Review' : 'Save Risk Review'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default ReviewRiskAssessment;

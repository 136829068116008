import React, { useState, useEffect, useCallback } from 'react';
import { editFileFullscreen } from '../../managers/FileViewerManager/FileViewerManager';
import { getFileTrappedSrc, getImgSrcFromExt, getImgSrcFromSeaFile, SeaFile } from '../../lib/files';
import { getCachedImgSrcs } from '../../shared-state/FileSyncSystem/filesToLoad';
import { FileCollection } from '../../shared-state/FileSyncSystem/cachedFiles';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaIcon from '../SeaIcon/SeaIcon';
import SeaFileSelector from '../SeaFileSelector/SeaFileSelector';
import './SeaFileUpload.css';

interface SeaFileUploadProps {
    files: SeaFile[],
    setFiles: (files: SeaFile[]) => void,
    collection: FileCollection, // collection this file will be attached to
    field: string,      // collection.field this will be attached to
    label?: string,
    allowMultiple?: boolean,
    maxUploadMB?: number
}

const SeaFileUpload: React.FC<SeaFileUploadProps> = ({
    files,
    setFiles,
    collection,
    field,
    label,
    allowMultiple = true,
    maxUploadMB = 100
}) => {
    const [addFileClickEvent, setAddFileClickEvent] = useState<React.MouseEvent | undefined>(undefined);
    const [srcs, setSrcs] = useState<string[]>();

    // useEffect(() => {
    //     if (fileToTrash && files?.length > 0) {
    //         for (let i = 0; i < files.length; i++) {
    //             if (files[i] === fileToTrash) {
    //                 //console.log('Found file to trash', fileToTrash);
    //                 const _files = [...files];
    //                 _files.splice(i, 1);
    //                 setFiles(_files);
    //                 exitFullscreen();
    //                 return;
    //             }
    //         }
    //     }
    // }, [fileToTrash, exitFullscreen, files, setFiles]);

    const onTrashFile = useCallback((fileToTrash: SeaFile | undefined) => {
        if (!fileToTrash) return;
        for (let i = 0; i < files.length; i++) {
            if (files[i] === fileToTrash) {
                //console.log('Found file to trash', fileToTrash);
                const _files = [...files];
                _files.splice(i, 1);
                setFiles(_files);
                // exitFullscreen(); <-- do this in FileViewerManager
                return;
            }
        }
    }, [files, setFiles]);

    const onEditFile = useCallback((fileToEdit: SeaFile) => {
        editFileFullscreen(fileToEdit, onTrashFile);
    }, [onTrashFile]);

    useEffect(() => {
        //setSrcs(undefined);
        if (files && files.length > 0) {
            const filesToLoad = [] as string[];
            for (let i = 0; i < files.length; i++) {
                if (files[i].id) {
                    // Previously uploaded, therefore could have been cached
                    filesToLoad.push(`${files[i].state}${files[i].id}.${files[i].ext}`);
                } else {
                    filesToLoad.push('');
                }
            }
            return getCachedImgSrcs(filesToLoad, 'F', (_srcs) => {
                const promises = [] as Promise<void>[];
                const newSrcs = [] as string[];
                _srcs.forEach((src, index) => {
                    if (src === 'nonImage') {
                        newSrcs[index] = getImgSrcFromExt(files[index].ext, 'medium');
                    } else if (src === 'noCache') {
                        if (files[index].state === 0) { // Not yet uploaded by another device
                            newSrcs[index] = getFileTrappedSrc('medium');
                        } else {
                            promises.push(
                                getImgSrcFromSeaFile(files[index], 'medium').then((_src: string) => {
                                    newSrcs[index] = _src;
                                })
                            );
                        }
                    } else {
                        newSrcs[index] = src;
                    }
                });

                Promise.all(promises).then(() => {
                    setSrcs(newSrcs);
                });
            });
        }
    }, [files]);

    const fileToUnique = (file: SeaFile) => {
        if (file.id) {
            return file.id;
        }
        return file.unique;
    }

    const onFilesSelected = ((_files: SeaFile[]) => {   
        if (_files && _files.length > 0) {
            // Add to any existing files
            setFiles([...files, ..._files]);
        }
    });

    return (<>
        {label && <SeaLabel>{label}</SeaLabel>}
        <div className="sea-previews">
            {srcs && files?.map((file: SeaFile, index) => {
                return (
                    <img
                        key={fileToUnique(file)}
                        src={srcs[index]}
                        className="card"
                        onClick={(e) => onEditFile(files[index])}
                        alt="Preview"
                    />
                );
            })}
            {(allowMultiple || files.length === 0) &&
                <div className="card" onClick={(e: React.MouseEvent) => setAddFileClickEvent(e)}>
                    <div>
                        <div className="doc-icon"><SeaIcon icon="add"/></div>
                        Add
                    </div>
                </div>
            }
        </div>
        <SeaFileSelector
            clickEvent={addFileClickEvent}
            onFilesSelected={onFilesSelected}
            collection={collection}
            field={field}
            allowMultiple={allowMultiple}
            maxUploadMB={maxUploadMB}
        />
    </>);
};

export default SeaFileUpload;

import { SharedStateConfig, sharedState } from "../shared-state";

//
// Determines if the app's core data is loaded.
// If isReady is false, the user will see a loading spinner with message notReadyMessage.
// Whether the core data is loaded or not will be determined differently depending on what the login state is: not logged in, normal user logged in, or superAdmin loggedin.
//

export type AppReadyState = {
    isReady: boolean,
    notReadyMessage: string
};

let fileSyncMessage = 'Preparing File Cache...';
export const setFileSyncMessage = (message: string) => {
    fileSyncMessage = message;
    if (!sharedState.appReadyState.current!.isReady) { // Update sharedState so it shows to the user.
        sharedState.appReadyState.set({
            isReady: false,
            notReadyMessage: fileSyncMessage
        })
    }
};

export const appReadyStateConfig: SharedStateConfig<AppReadyState> = {
    isAlwaysActive: true,
    dependencies: [
        'userPending',
        'user',
        'superAdmin',
        'serverInfo',
        'userPermissions',
        'deviceInfo',
        //'licenseeSettings', must have loaded if isDataSyncActive is defined
        'users',
        'vessels',
        'customFormVersions',
        'isDataSyncActive',
        'isFileSyncReady',
        'whenLicenseeTouched',
        'whenVesselTouched'
    ],
    default: {
        isReady: false,
        notReadyMessage: 'Authenticating...'
    },
    notes: 'Determines if the app\'s core data is loaded.',
    run: (done, set, clear) => {
        done();

        if (sharedState.userPending.current) {
            // Login state is up in the air.
            // Reset to false.
            set({
                isReady: false,
                notReadyMessage: 'Authenticating...'
            });
            return;
        }

        if (sharedState.appReadyState.current?.isReady) {
            // We've already determined that the app is ready for use. (We'll only need to recalculate when userPending becomes true)
            return;
        }

        // Determine if isReady is now true - depending on login state
        if (sharedState.user.current) {
            // Login state: normal user is logged in
            if (
                sharedState.serverInfo.current &&
                sharedState.userPermissions.current &&
                sharedState.deviceInfo.current &&
                sharedState.users.current &&
                sharedState.vessels.current &&
                sharedState.customFormVersions.current &&
                sharedState.isDataSyncActive.current !== undefined && (
                    sharedState.isDataSyncActive.current === false || (
                        sharedState.whenLicenseeTouched.current &&
                        sharedState.whenVesselTouched.current
                    )
                )
            ) {
                if (sharedState.serverInfo.current.underMaintenance) {
                    set({
                        isReady: false,
                        notReadyMessage: 'Sea Flux is currently down for maintenance.'
                    });
                } else if (sharedState.isFileSyncReady.current) {
                    set({
                        isReady: true,
                        notReadyMessage: ''
                    });
                } else {
                    set({
                        isReady: false,
                        notReadyMessage: fileSyncMessage
                    });
                }
            } else {
                set({
                    isReady: false,
                    notReadyMessage: 'Loading Core Data...'
                })
            }
        } else if (sharedState.superAdmin.current) {
            // Login state: superAdmin is logged in
            if (
                sharedState.serverInfo.current &&
                sharedState.isFileSyncReady.current
            ) {
                set({
                    isReady: true,
                    notReadyMessage: ''
                });
            } else {
                set({
                    isReady: true,
                    notReadyMessage: 'Loading File Cache...'
                });
            }
        } else {
            // Login state: not logged in
            set({
                isReady: true,
                notReadyMessage: ''
            }); // There is no core data to be loaded when you're not logged in
        }
    }
};

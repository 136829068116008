import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { SharedStateConfig, sharedState } from "../shared-state";
import { canView } from "../../shared-state/Core/userPermissions";
import { Job } from "../VesselMaintenance/jobs";
import { registerFiles } from "../FileSyncSystem/filesToCache";

export type DashboardJobsData = {
    top5: Job[],
    numHighestPriority: number
};

export const dashboardJobsConfig: SharedStateConfig<DashboardJobsData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId'],
    countLiveDocs: () => sharedState.dashboardJobs.current?.numHighestPriority ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;

        if (vesselId && canView('jobList')) {
            return onSnapshot(
                query(
                    collection(firestore, 'jobs'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'active'),
                    where('priority', 'in', ['8urgent', '6high']),
                    orderBy('priority', 'desc')
                ),
                (snap) => {
                    done();
                    let upcoming = snap.docs.map((doc) => {
                        registerFiles(doc.data().files, 'jobs', doc.data());
                        return {
                            id: doc.id,
                            ...doc.data(),
                        } as Job;
                    });

                    upcoming.sort((a, b) => {
                        // jobs with whenDue will be sorted higher than default alphabetical
                        if (a.priority === b.priority) {
                            return (
                                (a.whenDue ? a.whenDue : Number.MAX_SAFE_INTEGER) -
                                (b.whenDue ? b.whenDue : Number.MAX_SAFE_INTEGER)
                            );
                        }
                        return 0;
                    });

                    if (upcoming) {
                        set({
                            top5: upcoming.slice(0, 5),
                            numHighestPriority: upcoming.length
                        });
                    }
                },
                (error) => {
                    done();
                    // This should be very rare
                    console.log(
                        `Failed to access jobs for vessel ${vesselId} on dashboard`,
                        error
                    );
                }
            )
        } else {
            done();
        }
    },
};

import React from 'react';
import { canView } from '../../shared-state/Core/userPermissions';
import { sharedState } from '../../shared-state/shared-state';
import SeaBreadcrumb from '../../components/SeaBreadcrumb/SeaBreadcrumb';
import SeaLink from '../../components/SeaLink/SeaLink';
import StandardPageLayout from '../../layouts/StandardPageLayout/StandardPageLayout';
import SeaTabsGroup from '../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../components/SeaTab/SeaTab';
import SeaTabContent from '../../components/SeaTabContent/SeaTabContent';
import CrewParticulars from './CrewParticulars/CrewParticulars';
import CrewCertificates from './CrewCertificates/CrewCertificates';
import CrewTraining from './CrewTraining/CrewTraining';
import Contacts from './Contacts/Contacts';
import './Crew.css';

const Crew: React.FC = () => {
    const selectedSectionTab = sharedState.selectedSectionTab.use();

    return (
        <StandardPageLayout section="crew">
            <SeaBreadcrumb>
                <SeaLink to="/crew">Crew</SeaLink>
            </SeaBreadcrumb>

            <SeaTabsGroup id="Crew" selectedTab={selectedSectionTab} setTab={sharedState.selectedSectionTab.set}>
                <SeaTab tab="CrewParticulars" requireRole="crewParticulars" setTab={sharedState.selectedSectionTab.set}>Crew Particulars</SeaTab>
                <SeaTab tab="CrewCertificates" setTab={sharedState.selectedSectionTab.set}>{!canView('crewCertificates') ? 'My' : 'Crew'} Certificates</SeaTab>
                <SeaTab tab="CrewTraining" requireRole="crewTraining" setTab={sharedState.selectedSectionTab.set}>Crew Training</SeaTab>
                <SeaTab tab="Contacts" requireRole="contacts" setTab={sharedState.selectedSectionTab.set}>Contacts/Suppliers</SeaTab>
            </SeaTabsGroup>

            <SeaTabContent tab="CrewParticulars" selectedTab={selectedSectionTab}>
                <CrewParticulars visible={selectedSectionTab === 'CrewParticulars'}/>
            </SeaTabContent>
            <SeaTabContent tab="CrewCertificates" selectedTab={selectedSectionTab}>
                <CrewCertificates visible={selectedSectionTab === 'CrewCertificates'}/>
            </SeaTabContent>
            <SeaTabContent tab="CrewTraining" selectedTab={selectedSectionTab}>
                <CrewTraining visible={selectedSectionTab === 'CrewTraining'}/>
            </SeaTabContent>
            <SeaTabContent tab="Contacts" selectedTab={selectedSectionTab}>
                <Contacts visible={selectedSectionTab === 'Contacts'}/>
            </SeaTabContent>

        </StandardPageLayout>
    );
};

export default Crew;

import React, { useState, useEffect } from 'react';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { doc, increment, serverTimestamp } from "firebase/firestore";
import { formatDatetime } from '../../../../lib/util';
import { canEdit, canAccessAllVessels, canDelete } from '../../../../shared-state/Core/userPermissions';
import { makeFormElements } from '../../../../lib/customForms';
import { logAction } from '../../../../shared-state/General/actionLog';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { CustomForm } from '../../../../shared-state/CompanyDocuments/CustomForms/customForms';
import { CustomFormCompleted } from '../../../../shared-state/CompanyDocuments/CustomForms/customFormsCompleted';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { Action, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import { CustomFormVersion } from '../../../../shared-state/CompanyDocuments/CustomForms/customFormVersions';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import CustomFormElement, { CustomFormElementType } from '../../../../components/CustomFormElement/CustomFormElement';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import CompletedCustomFormPdf from '../../../../pdfs/CustomForms/CompletedCustomFormPdf';
import './ViewCompletedCustomForm.css';


interface ViewCompletedCustomFormProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    setShowCompleteCustomForm: (showModal: boolean) => void,
    level?: number,
    customForm?: CustomForm,
    selectedCompletedCustomForm?: CustomFormCompleted
}

const ViewCompletedCustomForm: React.FC<ViewCompletedCustomFormProps> = ({
    showModal,
    setShowModal,
    setShowCompleteCustomForm,
    selectedCompletedCustomForm,
    customForm,
    level
}) => {
    const user = sharedState.user.use(showModal)!;
    const customFormVersions = sharedState.customFormVersions.use(showModal);
    const [selectedVersion, setSelectedVersion] = useState<CustomFormVersion>();
    const [formElements, setFormElements] = useState<CustomFormElementType[]>([]);
    const [generatingPdf, setGeneratingPdf] = useState(false);

    useEffect(() => {
        if (selectedCompletedCustomForm && showModal) {
            if (!customForm) {
                console.log('No custom form found for completed form', selectedCompletedCustomForm.id);
                setShowModal(false);
                return;
            }
            let _selectedVersion;
            if (selectedCompletedCustomForm) {
                _selectedVersion = customFormVersions?.byFormIdAndVersion[customForm.id][selectedCompletedCustomForm.version];
            } else {
                _selectedVersion = customFormVersions?.byFormIdAndVersion[customForm.id][customForm.latestVersion];
            }
            setSelectedVersion(_selectedVersion);
            if (!_selectedVersion) {
                console.log('No version found for custom form', customForm.id, selectedCompletedCustomForm.version);
                setShowModal(false);
                return;
            }
            setFormElements(
                makeFormElements(_selectedVersion, selectedCompletedCustomForm)
            );
        }
    }, [selectedCompletedCustomForm, showModal, customForm, customFormVersions]);

    const onDeleteCompletedForm = (e: React.MouseEvent<Element, MouseEvent>) => {
        e.preventDefault();
        confirmAction('Are you sure you want to delete this completed form', 'Yes, delete').then(() => {
            if (!selectedCompletedCustomForm) {
                console.log('No selected completed custom form');
                return;
            }
            if (!customForm) {
                console.log('No custom form found for completed form', selectedCompletedCustomForm.id);
                return;
            }
            if (!selectedVersion) {
                console.log('No version found for custom form', customForm.id, selectedCompletedCustomForm.version);
                return;
            }
            const action = traceAction('customFormsCompleted', 'delete') as Action;
            action.docId = selectedCompletedCustomForm.id;
            const batch = splittableBatch(firestore, 20 - 1);
            batch.set(
                doc(firestore, 'customFormsCompleted', selectedCompletedCustomForm.id),
                {
                    state: 'deleted',
                    deletedBy: user.id,
                    whenDeleted: action.whenAction,
                    touched: serverTimestamp()
                },
                { merge: true }
            );

            batch.set(
                doc(firestore, 'customFormVersions', selectedVersion.id),
                { 
                    numCompleted: increment(-1),
                    touched: serverTimestamp()
                },
                { merge: true }
            );

            let actionDetail = `${customForm?.title}${selectedCompletedCustomForm.isDraft ? ' (DRAFT)' : ''}`;
            if (selectedCompletedCustomForm.personnelIds && selectedCompletedCustomForm.personnelIds.length > 0) {
                actionDetail += ' - ';
                for (let i = 0; i < selectedCompletedCustomForm.personnelIds.length; i++) {
                    if (i > 0) {
                        actionDetail += ', ';
                    }
                    actionDetail += renderFullNameForUserId(selectedCompletedCustomForm.personnelIds[i]);
                }
            }
            logAction(
                batch,
                'Delete',
                'customFormsCompleted',
                selectedCompletedCustomForm.id,
                actionDetail,
                selectedCompletedCustomForm.vesselIds,
                selectedCompletedCustomForm.personnelIds
            );

            
            onCollectionUpdated(batch, 'customFormsCompleted');
            onCollectionUpdated(batch, 'customFormVersions');

            action.data = {
                selectedCompletedCustomForm
            };
            action.save(`Delete completed custom form ${customForm?.title}`, batch);
            batch.commit().then(() => {
                action.reportSuccess();
            }).catch((error) => {
                action.reportError(error.message, error);
            });

            setShowModal(false);
        }).catch(() => {});
    };

    const onEditCompletedForm = () => {
        setShowCompleteCustomForm(true);
    };

    const alignSelf = (id: string) => {
        return 'normal';
    }

    const onGeneratePdf = () => {
        setGeneratingPdf(true);
    };

    return (
        <SeaModal
            title={`${customForm?.title}${(selectedCompletedCustomForm?.isDraft) ? ' (Draft)' : ''}`}
            showModal={showModal}
            setShowModal={setShowModal}
            //onOpened={onOpened}
            size="semi-wide"
            level={level}
            noContentPadding={true}
            onPdf={onGeneratePdf}
        >
            <div className="custom-form-container complete-form">
                {selectedCompletedCustomForm && customForm && selectedVersion && selectedVersion.version !== customForm.latestVersion &&
                    <div style={{
                        padding: '6px 6px 12px 6px',
                        color: 'var(--ion-color-danger)',
                        fontWeight: 'bold',
                        width: '100%'
                    }}>
                        <i>Note: This is an older version of the form dated {formatDatetime(selectedVersion.version)}.</i>
                    </div>
                }
                {customForm?.forVesselIds && customForm.forVesselIds[0] !== 'none' &&
                    <div
                        className={`w${(customForm?.vesselsElement?.width) ? Math.floor(customForm.vesselsElement.width) : 100}`}
                        style={{ width: `${(customForm?.vesselsElement?.width) ? Math.floor(customForm.vesselsElement.width) : 100}%` }}
                    >
                        <CustomFormElement
                            element={{
                                id: 'vessels',
                                label: (customForm?.vesselsElement?.label) ? customForm.vesselsElement.label : 'SELECT VESSELS / FACILITIES',
                                width: (customForm?.vesselsElement?.width) ? customForm.vesselsElement.width : 100,
                                value: (selectedCompletedCustomForm?.vesselIds) ? selectedCompletedCustomForm.vesselIds : []
                            }}
                            mode="view"
                            selectedElement={undefined}
                            onSelectElement={(element) => {
                                // do nothing
                            }}
                        />
                    </div>
                }
                {customForm?.forCrew &&
                    <div
                        className={`w${(customForm?.crewElement?.width) ? Math.floor(customForm.crewElement.width) : 100}`}
                        style={{ width: `${(customForm?.crewElement?.width) ? Math.floor(customForm.crewElement.width) : 100}%` }}
                    >
                        <CustomFormElement
                            element={{
                                id: 'crew',
                                label: (customForm?.crewElement?.label) ? customForm.crewElement.label : 'SELECT PERSONNEL',
                                width: (customForm?.crewElement?.width) ? customForm.crewElement.width : 100,
                                value: (selectedCompletedCustomForm?.personnelIds) ? selectedCompletedCustomForm.personnelIds : []
                            }}
                            mode="view"
                            selectedElement={undefined}
                            onSelectElement={(element) => {
                                // do nothing
                            }}
                        />
                    </div>
                }
                {formElements?.map((element) => {
                    return (
                        <div
                            key={element.n}
                            style={{ width: `${element.width}%`, alignSelf: alignSelf(element.id) }} className={`w${Math.floor(element.width)} ${(element.width.toString() === '50' && element.id === 'signature') ? 'signature-cell' : ''}`}
                        >
                            <CustomFormElement
                                element={element}
                                mode="view"
                                selectedElement={undefined}
                                onSelectElement={(element) => {
                                    // do nothing
                                }}
                            />
                        </div>
                    );
                })}
                {
                    (
                        selectedCompletedCustomForm &&
                        (
                            (
                                canEdit('customForms') && (
                                    selectedCompletedCustomForm.vesselIds[0] === 'none' ||
                                    canAccessAllVessels(selectedCompletedCustomForm.vesselIds)
                                )
                            ) || (
                                selectedCompletedCustomForm.attachTo === 'voyage' &&
                                canEdit('logbook')
                            )
                            // || (
                            //     selectedCompletedCustomForm.personnelIds &&
                            //     selectedCompletedCustomForm.personnelIds.length > 0 && (
                            //         selectedCompletedCustomForm.personnelIds[0] === user.id ||
                            //         canEdit('crewParticulars')
                            //     )
                            // )
                        )
                    ) && (
                        <div className="view-modal-buttons">
                            <SeaButton size="wide" onClick={(e) => onEditCompletedForm()}>
                                {selectedCompletedCustomForm?.isDraft ? 'Edit Draft' : 'Edit Completed Form'}
                            </SeaButton>
                            {
                                (
                                    canDelete('customForms') || (
                                        selectedCompletedCustomForm.attachTo === 'voyage' &&
                                        canEdit('logbook')
                                    )
                                    // || (
                                    //     selectedCompletedCustomForm.personnelIds &&
                                    //     selectedCompletedCustomForm.personnelIds.length > 0 && (
                                    //         selectedCompletedCustomForm.personnelIds[0] === user.id ||
                                    //         canDelete('crewParticulars')
                                    //     )
                                    // )
                                ) && (
                                    <>
                                        <div className="spacer-wide"></div>
                                        <SeaLinkButton mode="standard-link" onClick={(e) => onDeleteCompletedForm(e)}>Delete</SeaLinkButton>
                                    </>
                                )
                            }
                        </div>
                    )
                }
            </div>

            <CompletedCustomFormPdf
                generatingPdf={generatingPdf}
                setGeneratingPdf={setGeneratingPdf}
                customForm={customForm}
                completedCustomForm={selectedCompletedCustomForm}
            />

        </SeaModal>
    );
};

export default ViewCompletedCustomForm;

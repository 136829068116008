import React, { useState, useEffect, Profiler, useMemo, useCallback } from 'react';
import { formatDate, formatInterval, formatValue, getDayOffsetMillis, warnDays, whenDueToClassName } from '../../../lib/util';
import { permissionLevels } from '../../../shared-state/Core/userPermissions';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { logPageView } from '../../../lib/firebase';
import { onProfilerRender } from '../../../lib/performance';
import { IonSelectOption } from '@ionic/react';
import { renderCategoryName } from '../../../lib/categories';
import { sharedState } from '../../../shared-state/shared-state';
import { SafetyCheckItem } from '../../../shared-state/VesselSafety/safetyCheckItems';
import { renderFullNameForUserId } from '../../../shared-state/Core/users';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import EditSafetyCheck from '../../../modals/VesselSafety/SafetyEquipmentChecks/EditSafetyCheck/EditSafetyCheck';
import ViewSafetyCheck from '../../../modals/VesselSafety/SafetyEquipmentChecks/ViewSafetyCheck/ViewSafetyCheck'
import SeaStatusDueDate from '../../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaSelect from '../../../components/SeaSelect/SeaSelect';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import SafetyEquipmentChecksPdf from '../../../pdfs/VesselSafety/SafetyEquipmentChecksPdf';
import SeaStatusIcon from '../../../components/SeaStatusIcon/SeaStatusIcon';
import EditSafetyEquipmentCheckSettings from '../../../modals/VesselSafety/SafetyEquipmentChecks/EditSafetyEquipmentCheckSettings/EditSafetyEquipmentCheckSettings';
import './SafetyEquipmentChecks.css';

interface SafetyEquipmentChecksProps {
    visible: boolean
}

const SafetyEquipmentChecks: React.FC<SafetyEquipmentChecksProps> = ({ visible }) => {
    const safetyCheckItems = sharedState.safetyCheckItems.use(visible ? 1 : 101);
    const vesselSafetyItems = sharedState.vesselSafetyItems.use(visible ? 1 : 101);
    const vesselLocations = sharedState.vesselLocations.use(visible ? 1 : 101);
    const vesselSafetyCheckCategories = sharedState.safetyCheckCategories.use(visible ? 1 : 101);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter();
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [showEquipmentCheckSettings, setShowEquipmentCheckSettings] = useState(false);
    const [selectedItem, setSelectedItem] = useState<SafetyCheckItem>();
    const [listType, setListType] = useState(warnDays.safetyEquipmentChecks[0].toString());
    const [generatingPdf, setGeneratingPdf] = useState(false);

    useEffect(() => {
        if (visible) {
            logPageView('VesselSafety/SafetyEquipmentChecks');
        }
        resetPageLimit();
    }, [visible, resetPageLimit]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (selectedItem?.id && safetyCheckItems?.byId[selectedItem.id]) {
            setSelectedItem(safetyCheckItems.byId[selectedItem.id])
        };
    }, [safetyCheckItems, selectedItem?.id]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };
    const onEditSettings = () => {
        setShowEquipmentCheckSettings(true);
    };
    // const onAddViewReports = () => {
    //     todo('add/view reports');
    // };
    const onViewItem = (item: SafetyCheckItem) => {
        console.log(`Viewing safety item ${item?.id}`);
        setShowViewItemModal(true);
        setSelectedItem(item)
    };

    const onGeneratePdf = () => {
        setGeneratingPdf(true);
    };

    const isCritical = useCallback((id: string) => {
        if (vesselSafetyItems?.byId[id]?.isCritical) {
            return true;
        }
        return false;
    }, [vesselSafetyItems]);

    const filteredSafetyCheckItems = useMemo(() => {        
        if (!safetyCheckItems) return {};
        if (listType === 'all') {
            const filteredItems = {} as {
                [key: string]: SafetyCheckItem[]
            };
            const maxWhenDue = getDayOffsetMillis(Number(listType));
            for (let i = 0; i < safetyCheckItems.all.length; i++) {
                const item = safetyCheckItems.all[i];
                if (item.whenDue >= maxWhenDue && !item.hasFault) {
                    break;
                }
                if (!filteredItems[item.categoryId]) {
                    filteredItems[item.categoryId] = [];
                }
                filteredItems[item.categoryId].push(item);
            }
            return filteredItems;
        }
        if (listType === 'critical') {
            const filteredItems: SafetyCheckItem[] = [];
            for (let i = 0; i < safetyCheckItems.all.length; i++) {
                const item = safetyCheckItems.all[i];
                if (item.itemId && isCritical(item.itemId)) {
                    filteredItems.push(item);
                }
            }
            return filteredItems;
        } 

        const maxWhenDue = getDayOffsetMillis(Number(listType));
        const filteredItems: SafetyCheckItem[] = []
        for (let i = 0; i < safetyCheckItems.prioritised.length; i++) {
            const item = safetyCheckItems.prioritised[i];
            if (item.whenDue >= maxWhenDue && !item.hasFault) {
                break;
            }
            filteredItems.push(item);
        }
        return filteredItems;
    }, [safetyCheckItems, listType, isCritical]);

    return (
        <RequirePermissions
            role="safetyEquipmentChecks"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="safety-equipment-checks page-head">
                <div><h2>Safety Checks</h2></div>
                <div className="actions">
                    <SeaSelect
                        name="listType"
                        value={listType}
                        width="240px"
                        zone="grey"
                        onchange={(e) => {
                            resetPageLimit();
                            setListType(e.detail.value);
                        }}
                    >
                        {warnDays.safetyEquipmentChecks.map((check) => {
                            return <IonSelectOption key={check} value={check.toString()}>Overdue &amp; Due Within {check} Days</IonSelectOption>
                        })}
                        <IonSelectOption value="critical">Critical</IonSelectOption>
                        <IonSelectOption value="all">All</IonSelectOption>
                    </SeaSelect>
                    <RequirePermissions
                        role="safetyEquipmentChecks"
                        level={permissionLevels.VIEW}
                    >
                        <RequirePermissions
                            role="safetyEquipmentChecks"
                            level={permissionLevels.CREATE}
                        >
                            <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                                <SeaIcon slot="start" icon="add"/>
                                Add New Item
                            </SeaButton>
                        </RequirePermissions>
                        <SeaButton onClick={(e) => onGeneratePdf()} zone="grey" shape="circle"><SeaIcon slot="icon-only" icon="pdf"/></SeaButton>
                        <RequirePermissions
                            role="safetyEquipmentChecks"
                            level={permissionLevels.EDIT}
                        >
                            <SeaButton
                                zone="grey"
                                shape="circle"
                                onClick={(e) => onEditSettings()}
                                >
                                <SeaIcon slot="icon-only" icon="settings" />
                            </SeaButton>
                        </RequirePermissions>
                    </RequirePermissions>
                </div>
            </div>

            <SeaNoData
                dataName="safety check tasks"
                isLoading={!safetyCheckItems}
                hasNoData={Object.keys(filteredSafetyCheckItems).length === 0}
                isUsingFilter={listType !== 'all'}
            />

            <div className={`safety-equipment-checks has-thumbs has-status ${(Object.keys(filteredSafetyCheckItems).length > 0) ? 'reveal' : 'conceal'}`}>
                <div className="sea-row headings">
                    <div></div>
                    <div>Safety Item</div>
                    <div>Location</div>
                    <div>Interval</div>
                    <div>Next Check</div>
                    <div>Assigned To</div>
                    <div>Status</div>
                    <div>Critical</div>
                </div>
                <Profiler id="safety.equipmentChecks" onRender={onProfilerRender}>
                    {listType === 'all' && filteredSafetyCheckItems && vesselSafetyCheckCategories?.ids?.map((categoryId: string) => {
                        if (isLimitReached() || !(filteredSafetyCheckItems as { [key: string]: SafetyCheckItem[] })[categoryId]) {
                            return undefined;
                        }
                        return (
                            <React.Fragment key={categoryId}>
                                <div className="category-heading">{renderCategoryName(categoryId, vesselSafetyCheckCategories)}</div>
                                {/* All documents */}
                                {mapArrayWithLimit((filteredSafetyCheckItems as { [key: string]: SafetyCheckItem[] })[categoryId], (item : SafetyCheckItem) => (
                                    <div key={item.id} className={`sea-card sea-row ${item.hasFault ? 'fail fault' : whenDueToClassName(item.whenDue, warnDays.safetyEquipmentChecks[0])}`} onClick={(e) => onViewItem(item)}>
                                        <div>
                                            <SeaFileImage files={item.files} size="tiny"/>
                                        </div>
                                        <div className="bold truncate-2">{renderCategoryName(item.itemId, vesselSafetyItems)}</div>
                                        <div className="truncate-2">{formatValue(renderCategoryName(item.locationId, vesselLocations))}</div>
                                        <div>{formatInterval(item.interval)}</div>
                                        <div>{formatDate(item.whenDue)}</div>
                                        <div className="truncate-2">{formatValue(item.assignedTo?.map((id) => renderFullNameForUserId(id))?.join(', '))}</div>
                                        <div><SeaStatusDueDate whenDue={item.whenDue} warnDays={warnDays.safetyEquipmentChecks[0]} hasFault={item.hasFault}/></div>
                                        <div>
                                            {isCritical(item.itemId) && (
                                                <SeaStatusIcon
                                                    icon="flag"
                                                    className="critical bottom"
                                                />
                                            )}
                                        </div>
                                    </div>
                            ))}
                            </React.Fragment>
                        );
                    })}
                    {listType !== 'all' && mapArrayWithLimit(filteredSafetyCheckItems as SafetyCheckItem[], (item : SafetyCheckItem) => {
                        return (
                            <div key={item.id} className={`sea-card sea-row ${item.hasFault ? 'fail fault' : whenDueToClassName(item.whenDue, warnDays.safetyEquipmentChecks[0])}`} onClick={(e) => onViewItem(item)}>
                                <div>
                                    <SeaFileImage files={item.files} size="tiny"/>
                                </div>
                                <div className="bold truncate-2">{renderCategoryName(item.itemId, vesselSafetyItems)}</div>
                                <div className="truncate-2">{formatValue(renderCategoryName(item.locationId, vesselLocations))}</div>
                                <div>{formatInterval(item.interval)}</div>
                                <div>{formatDate(item.whenDue)}</div>
                                <div className="truncate-2">{formatValue(item.assignedTo?.map((id) => renderFullNameForUserId(id))?.join(', '))}</div>
                                <div><SeaStatusDueDate whenDue={item.whenDue} warnDays={warnDays.safetyEquipmentChecks[0]} hasFault={item.hasFault}/></div>
                                <div>
                                    {isCritical(item.itemId) && (
                                        <SeaStatusIcon
                                            icon="flag"
                                            className="critical bottom"
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                    {limitTriggerElement}
                </Profiler>
            </div>
            {visible &&
                <>
                    <EditSafetyCheck
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                    />
                    <ViewSafetyCheck
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                    />
                    <EditSafetyEquipmentCheckSettings
                        showModal={showEquipmentCheckSettings}
                        setShowModal={setShowEquipmentCheckSettings}
                        safetyCheckCategories={vesselSafetyCheckCategories}
                    />
                    <SafetyEquipmentChecksPdf
                        generatingPdf={generatingPdf}
                        setGeneratingPdf={setGeneratingPdf}
                        listType={listType}
                        safetyChecks={filteredSafetyCheckItems}
                    />
                </>
            }
        </RequirePermissions>
    );
};

export default SafetyEquipmentChecks;

import React, { useState } from 'react';
import { formatDatetime, formatValue } from '../../../lib/util';
import { ErrorReport } from '../../../shared-state/Admin/errorReports';
import SeaModal from '../../../components/SeaModal/SeaModal';
import SeaTabsGroup from '../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../../components/SeaTab/SeaTab';
import SeaTabContent from '../../../components/SeaTabContent/SeaTabContent';
import AppActivityViewer from '../../../components/AppActivityViewer/AppActivityViewer';
import SeaGrid from '../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../components/SeaGridCell/SeaGridCell';

interface ViewErrorReportModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    errorReport: ErrorReport,
}

const ViewErrorReportModal: React.FC<ViewErrorReportModalProps> = ({showModal, setShowModal, errorReport}) => {
    const [tab, setTab] = useState('details');

    return (
        <SeaModal
            title={errorReport?.whatFailed ?? 'Error Report'}
            showModal={showModal}
            setShowModal={setShowModal}
            size="extra-wide"
            tabsPanel={
                <SeaTabsGroup selectedTab={tab} setTab={setTab} mode="forms" mini>
                    <SeaTab tab="details" mode="forms">Details</SeaTab>
                    <SeaTab tab="appActivity" mode="forms">App Activity</SeaTab>
                    <SeaTab tab="errorStack" mode="forms">Error Stack</SeaTab>
                    <SeaTab tab="fullDetails" mode="forms">Full Details</SeaTab>
                </SeaTabsGroup>
            }
        >
            <SeaTabContent tab="details" selectedTab={tab}>
                {errorReport &&
                    <SeaGrid>
                        <SeaGridCell label="When Reported" w="50">
                            {formatDatetime(errorReport.whenReported)}
                        </SeaGridCell>
                        <SeaGridCell label="Type" w="50">
                            <div className="error-reports columns">
                                <div style={{
                                    color: errorReport.screenColor,
                                    fontWeight: 'bold'
                                }}>
                                    {formatValue(errorReport.type)}
                                </div>
                            </div>
                        </SeaGridCell>
                        <SeaGridCell label="What Failed" w="50">
                            {formatValue(errorReport.whatFailed)}
                        </SeaGridCell>
                        <SeaGridCell label="Reason" w="50">
                            {formatValue(errorReport.reason)}
                        </SeaGridCell>
                        <SeaGridCell label="Version / Build" w="50">
                            {errorReport.version} {errorReport.build}
                        </SeaGridCell>
                        <SeaGridCell label="Platform" w="50">
                            {formatValue(errorReport.device?.platform)}
                        </SeaGridCell>
                        <SeaGridCell label="Licensee / User" w="50">
                            {formatValue(errorReport.user)} / {formatValue(errorReport.licensee)}
                        </SeaGridCell>
                        <SeaGridCell label="Operating System" w="50">
                            {formatValue(errorReport.device?.operatingSystem)}
                        </SeaGridCell>
                        <SeaGridCell label="Operating System Version" w="50">
                            {formatValue(errorReport.device?.osVersion)}
                        </SeaGridCell>
                        <SeaGridCell label="Vessel" w="50">
                            {formatValue(errorReport.vessel ?? '?')}
                        </SeaGridCell>
                        <SeaGridCell label="Reference" w="50">
                            {formatValue(errorReport.reference)}
                        </SeaGridCell>
                        <SeaGridCell label="ID" w="50">
                            {formatValue(errorReport.id)}
                        </SeaGridCell>
                    </SeaGrid>
                }
            </SeaTabContent>
            <SeaTabContent tab="appActivity" selectedTab={tab}>
                <AppActivityViewer
                    appActivityJson={errorReport?.appActivity ? errorReport.appActivity : '{}'}
                    overrideLatestTime={errorReport?.whenReported}
                />
            </SeaTabContent>
            <SeaTabContent tab="errorStack" selectedTab={tab}>
                <pre style={{ whiteSpace: 'pre-wrap' }}>
                    {errorReport?.errorStack}
                </pre>
            </SeaTabContent>
            <SeaTabContent tab="fullDetails" selectedTab={tab}>
                <pre style={{ whiteSpace: 'pre-wrap' }}>
                    {JSON.stringify(errorReport, undefined, 4)}
                </pre>
            </SeaTabContent>
        </SeaModal>
    );
};

export default ViewErrorReportModal;

import { Device } from "@capacitor/device";
import { sharedState, SharedStateConfig } from "../shared-state";

export const mb = 1048576;
export const minSpaceOk = 500 * mb; // 500MB

export type DiskSpaceStatus = {
    bytesFree: number,
    haveEnoughSpace: boolean
};

let alreadyRunning = false;

export const diskSpaceStatusConfig: SharedStateConfig<DiskSpaceStatus> = {
    isAlwaysActive: true,
    dependencies: ['appState', 'fileSyncStatus', 'dataSyncStatus'],
    default: {
        bytesFree: 0,
        haveEnoughSpace: true
        // bytesFree: 32189745,
        // haveEnoughSpace: false
    },
    run: (done, set, clear) => {
        done();
        if (alreadyRunning) {
            return;
        }
        if (sharedState.appState.current?.isActive) {
            alreadyRunning = true;
            setTimeout(() => { // Limit diskSpaceStatus polling to a maximum frequency of once per second
                Device.getInfo().then((info) => {
                    if (info.realDiskFree !== undefined) {
                        set({
                            bytesFree: info.realDiskFree,
                            haveEnoughSpace: (info.realDiskFree >= minSpaceOk)
                        });
                    } else {
                        clear();
                    }
                    alreadyRunning = false;
                });
            }, 1000);
        }
    }
};

import { initializeApp } from "firebase/app";
import {
    getAuth,
    indexedDBLocalPersistence,
    //browserLocalPersistence,
    //browserSessionPersistence,
    initializeAuth,
} from "firebase/auth";
import { Capacitor } from "@capacitor/core";
import {
    initializeFirestore,
    enableIndexedDbPersistence,
    writeBatch,
    CollectionReference,
    QueryConstraint,
    onSnapshot,
    query,
    where,
    QueryDocumentSnapshot,
    DocumentData,
    QueryOrderByConstraint,
    getDocs,
    deleteField,
    CACHE_SIZE_UNLIMITED,
    WriteBatch,
} from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";
import { isPlatform } from "@ionic/react";
import { profileQuery } from "./performance";
import { onPageViewed } from "../shared-state/General/appActivity";




export const functionsRegion = 'australia-southeast1';
//export const functionsRegion = 'us-central1';
export const firebaseSettings = isPlatform('hybrid') ? {
    // Uncomment below for production build to ios/android
    apiKey: 'AIzaSyDLRHc5XwKt1IcDFdWO1-iR91nresovlWk',
    authDomain: 'sea-flux-3c853.firebaseapp.com',
    projectId: 'sea-flux-3c853',
    storageBucket: 'sea-flux-3c853.appspot.com',
    messagingSenderId: '653465357807',
    appId: '1:653465357807:web:5a2a57093193eb9385ba1a',
    measurementId: 'G-QR5B65NXQT'

    // Uncomment below for staging test build to ios/android
    // apiKey: 'AIzaSyCEJTooMkaGaISMCd1z14fKV363DJuBmX8',
    // authDomain: 'sea-flux-staging.firebaseapp.com',
    // projectId: 'sea-flux-staging',
    // storageBucket: 'sea-flux-staging.appspot.com',
    // messagingSenderId: '668969870326',
    // appId: '1:668969870326:web:e5f3c37b5708761c369dd7',
    // measurementId: 'G-02YBZY5QM7'

    // Uncomment below for development test build to ios/android
    // apiKey: 'AIzaSyDjh1sUdvK7FOI0Oxmtid7fTrLBHtTrAeQ',
    // authDomain: 'sea-flux-development.firebaseapp.com',
    // projectId: 'sea-flux-development',
    // storageBucket: 'sea-flux-development.appspot.com',
    // messagingSenderId: '1080199825213',
    // appId: '1:1080199825213:web:3ba577cbb11b0db28ea754',
    // measurementId: 'G-R5QCC2XNYM',
} : {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
export const isProduction = (firebaseSettings.projectId === 'sea-flux-3c853'); // <-- should be true when releasing to production!

const firebaseApp = initializeApp(firebaseSettings);
export const analytics = getAnalytics(firebaseApp);

//export const firestore = firebaseApp.firestore();
export const firestore = initializeFirestore(firebaseApp, {
    cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    ignoreUndefinedProperties: true,
    //merge: true
});

// This is just for testing offline mode
if (isPlatform('hybrid')) {
    enableIndexedDbPersistence(firestore)
    .then(() => {
        console.log('Firestore persistence enabled');
    })
    .catch((error) => {
        console.log('Failed to enable firestore persistence', error);
    });
}

export const logPageView = (pageName: string) => {
    logEvent(analytics, 'screen_view', {
        screen_name: pageName,
        page_title: pageName,
        screen_class: pageName,
    } as any);
    onPageViewed(pageName);
};

export const logModalView = (modalName: string, logToAnalytics = false) => {
    if (logToAnalytics) {
        logEvent(analytics, 'screen_view', {
            screen_name: modalName,
            page_title: modalName,
            screen_class: modalName,
        } as any);
    }
};

//
// Will not add more than maxQueriesPerBatch queries to a single batch.
// To avoid this we will handle multiple batches.
export interface SplittableBatch {
    set: (documentRef: any, data: any, options?: any) => void;
    commit: () => Promise<any>;
    getBatches: () => WriteBatch[];
}
// Note: maxQueriesPerBatch is limited by 20 document access calls see: https://firebase.google.com/docs/firestore/manage-data/transactions > Security rules limits
// To calculate maxQueriesPerBatch:
//   extraAccessCalls = +1 for each batch.set that triggers a security rule with a get() in it, like hasVesselVia(), hasAnyVesselVia(), hasAllVesselsVia(), hasLicenseeVia(), hasLicenseeViaUser(), isLicensee()
//   maxQueriesPerBatch = 20 - extraAccessCalls
export const splittableBatch = (
    firestore: any,
    maxQueriesPerBatch = 20
): SplittableBatch => {
    const batches = [writeBatch(firestore)];
    let count = 1;
    //const batch = writeBatch(firestore);
    const getBatch = () => {
        if (count > maxQueriesPerBatch) {
            batches.push(writeBatch(firestore));
            count = 1;
        } else {
            count++;
        }
        return batches[batches.length - 1];
    };
    return {
        set: (documentRef: any, data: any, options?: any) => {
            getBatch().set(documentRef, data, options);
        },
        commit: () => {
            const promises = [] as any[];
            batches.forEach((batch) => {
                promises.push(batch.commit());
            });
            return Promise.all(promises);
        },
        getBatches: () => {
            return batches;
        },
    };
};

// This function serves to handle array queries (in, not-in, or array-contains-any)
// that can have more than 10 elements in the array (Firestore limit)
//
export type ArrayComparison = 'in' | 'not-in' | 'array-contains-any';
export const setupArrayQueryListener = (
    what: string,
    collectionReference: CollectionReference,
    baseConstraints: QueryConstraint[],
    arrayField: any,
    arrayComparison: ArrayComparison,
    array: string[],
    orderByConstraints: QueryOrderByConstraint[],
    processDocs: (
        docs: QueryDocumentSnapshot<DocumentData>[],
        isCombined: boolean
    ) => any | undefined,
    onError?: (error: any) => void
) => {
    // Work out number of possible of options (each contraints possibility count multiplied together)
    // The total number of possible options can not be more than 30
    // See: https://firebase.google.com/docs/firestore/query-data/queries#limits_on_or_queries
    let numBasePossibleOptions = 1;
    baseConstraints?.forEach((constraint: any, index) => {
        //console.log(`${what} [${index}] isArray=${Array.isArray(constraint._value)} typeof ._value`, typeof constraint._value);
        if (Array.isArray(constraint._value) && constraint._value.length > 1) {
            numBasePossibleOptions *= constraint._value.length;
        }
    });
    let maxArrayItems = Math.min(10, Math.floor(30 / numBasePossibleOptions));
    //console.log(`${what} numBasePossibleOptions=${numBasePossibleOptions} maxArrayItems=${maxArrayItems}`);

    if (array.length <= maxArrayItems) {
        const constraints = [...baseConstraints];
        constraints.push(where(arrayField, arrayComparison, array));
        constraints.push(...orderByConstraints);
        // let query = baseQuery.where(arrayField, arrayComparison as any, array);
        // for (let i = 0; i < orderBy.length; i += 2) {
        //     query = query.orderBy(orderBy[i], orderBy[i+1] as any);
        // }
        const profiler = profileQuery(what);
        return onSnapshot(
            query(collectionReference, ...constraints),
            (snap) => {
                profiler.record(snap);
                processDocs(snap.docs, false);
            },
            (error) => {
                // This should be very rare
                console.error(`Failed to access ${what}`, error);
            }
        );
        // return query.onSnapshot((snapshot) => {
        //     profiler.record(snapshot);
        //     processDocs(snapshot.docs, false);
        // }, (error) => {
        //     // This should be very rare
        //     console.error(`Failed to access ${what}`, error);
        // });
    } else {
        // We need to listen to multiple queries and combine the results
        // (This is the complicated case)
        let isActive = true;
        const numParts = Math.ceil(array.length / maxArrayItems);
        const cleanUp = [] as any[];
        const results = [] as any[];
        let combineTaskN = 0; // unique identifier for combining results tasks

        const combineResults = (_combineTaskN: number) => {
            if (_combineTaskN !== combineTaskN) {
                return; // There is a newer combine task being requested, therefore ignore this one
            }
            for (let i = 0; i < numParts; i++) {
                if (results[i] === undefined) {
                    return; // Not all results are ready yet
                }
            }
            if (numParts === 1) {
                // no combination necessary
                return results[0];
            }
            // Combine!
            // It's possible there are duplicates, so we'll need to remove them
            // Sometimes duplicates are introduced because array-contains-any can match multiple times
            // Can also be due to parts of the query reevaluating
            const combinedDocs = [...results[0]];
            const ids = {} as any;
            results[0].forEach((doc: any) => {
                ids[doc.id] = true;
            });
            for (let i = 1; i < numParts; i++) {
                const r = results[i];
                const n = r.length;
                for (let j = 0; j < n; j++) {
                    if (ids[r[j].id] === undefined) {
                        combinedDocs.push(r[j]);
                        ids[r[j].id] = true;
                        //console.log(`combine doc (result ${i}) ${r[j].id}`, r[j].data());
                    }
                }
            }
            if (_combineTaskN === combineTaskN) {
                // This is still the latest task to be done?
                processDocs(combinedDocs, true);
            }
        };

        const handlePart = (
            index: number,
            arrayFrom: number,
            arrayTo: number
        ) => {
            const subArray: string[] = [];
            for (let i = arrayFrom; i <= arrayTo; i++) {
                subArray.push(array[i]);
            }

            const constraints = [...baseConstraints];
            constraints.push(where(arrayField, arrayComparison, subArray));
            constraints.push(...orderByConstraints);
            // queries[index] = baseQuery.where(arrayField, arrayComparison as any, subArray);
            // for (let i = 0; i < orderBy.length; i += 2) {
            //     queries[index] = queries[index].orderBy(orderBy[i], orderBy[i+1] as any);
            // }

            const profiler = profileQuery(`${what}:${index}`);
            cleanUp[index] = onSnapshot(
                query(collectionReference, ...constraints),
                (snap) => {
                    profiler.record(snap);
                    results[index] = snap.docs;
                    combineTaskN++;
                    const _combineTaskN = combineTaskN;
                    // Combine results after a short delay.
                    // This is too prevent multiple combine tasks being processed at the same time
                    setTimeout(() => {
                        if (!isActive) return;
                        combineResults(_combineTaskN);
                    }, 10);
                },
                (error) => {
                    // This should be very rare
                    console.error(
                        `Failed to access ${what} (index=${index})`,
                        error
                    );
                    if (onError) onError(error);
                }
            );
            // cleanUp[index] = queries[index].onSnapshot((snapshot) => {
            //     profiler.record(snapshot);
            //     results[index] = snapshot.docs;
            //     combineTaskN++;
            //     const _combineTaskN = combineTaskN;
            //     // Combine results after a short delay.
            //     // This is too prevent multiple combine tasks being processed at the same time
            //     setTimeout(() => {
            //         if (!isActive) return;
            //         combineResults(_combineTaskN);
            //     }, 10);
            // }, (error) => {
            //     // This should be very rare
            //     console.error(`Failed to access ${what} (index=${index})`, error);
            // });
        };

        for (let i = 0; i < array.length; i += maxArrayItems) {
            handlePart(
                Math.floor(i / maxArrayItems),
                i,
                Math.min(array.length - 1, i + maxArrayItems - 1)
            );
        }

        // Return cleanup function
        return () => {
            isActive = false;
            combineTaskN++; // Prevent any further combine tasks from running
            for (let i = 0; i < numParts; i++) {
                if (cleanUp[i]) {
                    try {
                        cleanUp[i](); // Call snapshot listener cleanup functions
                    } catch (e) {} // We want to keep going if one snapshot's cleanup failed
                }
            }
        };
    }
};

// Note: Currently composite queries may not be sorted
export const getArrayQueryResults = (
    what: string,
    collectionReference: CollectionReference,
    baseConstraints: QueryConstraint[],
    arrayField: any,
    arrayComparison: ArrayComparison,
    array: string[],
    orderByConstraints: QueryOrderByConstraint[]
): Promise<void | QueryDocumentSnapshot<DocumentData, DocumentData>[]> => {
    //console.log('setupArrayQueryListener array', array);

    // Work out number of possible of options (each contraints possibility count multiplied together)
    // The total number of possible options can not be more than 30
    // See: https://firebase.google.com/docs/firestore/query-data/queries#limits_on_or_queries
    let numBasePossibleOptions = 1;
    baseConstraints?.forEach((constraint: any, index) => {
        //console.log(`${what} [${index}] isArray=${Array.isArray(constraint._value)} typeof ._value`, typeof constraint._value);
        if (Array.isArray(constraint._value) && constraint._value.length > 1) {
            numBasePossibleOptions *= constraint._value.length;
        }
    });
    let maxArrayItems = Math.min(10, Math.floor(30 / numBasePossibleOptions));

    if (array.length <= maxArrayItems) {
        const constraints = [...baseConstraints];
        constraints.push(where(arrayField, arrayComparison, array));
        constraints.push(...orderByConstraints);

        return getDocs(query(collectionReference, ...constraints)).then((snap) => {
            return Promise.resolve(snap.docs);
        }).catch((error) => {
            // This should be very rare
            console.error(`Failed to access ${what}`, error);
        });
        // let query = baseQuery.where(arrayField, arrayComparison as any, array);
        // if (orderBy) {
        //     for (let i = 0; i < orderBy.length; i += 2) {
        //         query = query.orderBy(orderBy[i], orderBy[i+1] as any);
        //     }
        // }
        // return query.get().then((snap) => {
        //     return Promise.resolve(snap.docs);
        // }, (error) => {
        //     // This should be very rare
        //     console.error(`Failed to access ${what}`, error);
        // });
    } else {
        // We need to listen to multiple queries and combine the results
        // (This is the complicated case)
        const numParts = Math.ceil(array.length / maxArrayItems);
        const results = [] as any[];

        const processQuery = (i: number) => {
            const _array: string[] = [];
            for (
                let j = i * maxArrayItems;
                j < (i + 1) * maxArrayItems && j < array.length;
                j++
            ) {
                _array.push(array[j]);
            }

            const constraints = [...baseConstraints];
            constraints.push(where(arrayField, arrayComparison, _array));
            constraints.push(...orderByConstraints);

            return getDocs(query(collectionReference, ...constraints)).then(
                (snap) => {
                    //console.log(`i=${i} got snap.docs`, snap.docs);
                    results[i] = snap.docs;
                    return Promise.resolve();
                }
            );
            // let query = baseQuery.where(arrayField, arrayComparison as any, _array);
            // for (let i = 0; i < orderBy.length; i += 2) {
            //     query = query.orderBy(orderBy[i], orderBy[i+1] as any);
            // }
            // return query.get().then((snap) => {
            //     //console.log(`i=${i} got snap.docs`, snap.docs);
            //     results[i] = snap.docs;
            //     return Promise.resolve();
            // });
        };

        const promises: any[] = [];
        for (let i = 0; i < numParts; i++) {
            promises.push(processQuery(i));
        }

        return Promise.all(promises).then(() => {
            const docs = [] as any[];
            for (let i = 0; i < numParts; i++) {
                results[i].forEach((doc: any) => {
                    docs.push(doc);
                });
            }
            //console.log('composite docs', docs);
            return Promise.resolve(docs);
        }).catch((error) => {
            console.error(
                `Failed to access ${what} (composite query)`,
                error
            );
        });
    }
};

let _auth;
if (Capacitor.isNativePlatform()) {
    // console.log('native platform. use persistence=indexedDBLocalPersistence.');
    _auth = initializeAuth(firebaseApp, {
        persistence: indexedDBLocalPersistence,
        //persistence: [indexedDBLocalPersistence, browserLocalPersistence, browserSessionPersistence],
        // browserLocalPersistence uses localStorage which is the old way firestore did things < 4.12.0
    });
} else {
    // console.log('not native platform.');
    _auth = getAuth(firebaseApp);
}

export const database = getDatabase(firebaseApp); // only used for detecting if online or not
export const functions = getFunctions(firebaseApp, functionsRegion);
export const auth = _auth;
export const storage = getStorage(firebaseApp);
export const deleteValue = deleteField();
export default firebaseApp;

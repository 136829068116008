import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { formatDate, formatDatetime, formatDatetimeForCsv, formatDatetimeISO, getDayOffsetMillis, toMillis } from '../../../../lib/util';
import { ErrorReport, useErrorReports } from '../../../../shared-state/Admin/errorReports';
import { useAllUsers } from '../../../../shared-state/Admin/allUsers';
import { IonSelectOption, IonSpinner } from '@ionic/react';
import { useAllVessels } from '../../../../shared-state/Admin/allVessels';
import { CSVLink } from 'react-csv';
import { AppActivity } from '../../../../shared-state/General/appActivity';
import SeaSearchbar from '../../../../components/SeaSearchbar/SeaSearchbar';
import SeaDatetime from '../../../../components/SeaDatetime/SeaDatetime';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import ViewErrorReportModal from '../../../../modals/Admin/ViewErrorReportModal/ViewErrorReportModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import './ErrorReports.css';

const reportTypes = [
    'Unknown',
    'Fake Firestore Error (Seen)',
    'Fake Firestore Error (Ignored)',
    'Firestore Error',
    'Basic Error',
    'Crash',
    'Unintentional Refresh',
    '?'
];
const defaultSelectedTypes = [
    'Unknown',
    // 'Fake Firestore Error (Seen)',
    // 'Fake Firestore Error (Ignored)',
    'Firestore Error',
    'Basic Error',
    'Crash',
    'Unintentional Refresh',
    '?'
];

const platforms = ['web', 'ios', 'android'];
const platformOptions = platforms.map((platform) => {
    return { id: platform, name: platform };
});

const orderBys = [
    'When Reported',
    'What Failed',
    'Reason'
];

const ErrorReports: React.FC<{visible: boolean}> = ({visible: boolean}) => {
    const [searchText, setSearchText] = useState('');
    const [whenFrom, setWhenFrom] = useState(formatDatetimeISO(getDayOffsetMillis(-7) + 60000));
    const [whenTo, setWhenTo] = useState(formatDatetimeISO());
    const [selectedTypes, setSelectedTypes] = useState(defaultSelectedTypes);
    const [selectedPlatforms, setSelectedPlatforms] = useState([...platforms]);
    const [orderBy, setOrderBy] = useState('When Reported');
    const [showErrorReportModal, setShowErrorReportModal] = useState(false);
    const [selectedErrorReport, setSelectedErrorReport] = useState<ErrorReport>();
    const [csvData, setCsvData] = useState<any>('');
    const [isLoadingCsv, setIsLoadingCsv] = useState(false);
    const csvRef = useRef<any>(null);

    const errorReports = useErrorReports(
        toMillis(whenFrom),
        toMillis(whenTo)
    );

    const allUsers = useAllUsers();
    const allVessels = useAllVessels();

    const selectedTypeOptions = useMemo(() => {
        return reportTypes.map((type) => {
            return {
                id: type,
                name: type
            };
        });
    }, []);

    const filteredErrorReports = useMemo(() => {
        const filtered = [] as any[];
        errorReports?.forEach((report) => {
            const o = {
                ...report
            } as any;

            // Work out type, bgColor & screenColor
            switch (report.state) {
                case 'unknown':
                    o.type = 'Unknown';
                    o.bgColor = '#c4e5e8';
                    o.screenColor = '#ffffff';
                    break;
                case 'tracedFakeError':
                    o.type = report.shownToUser ? 'Fake Firestore Error (Seen)' : 'Fake Firestore Error (Ignored)';
                    o.bgColor = report.shownToUser ? '#f6ddc5' : '#e8e8e8';
                    o.screenColor = report.shownToUser ? '#ff0000' : 'transparent';
                    break;
                case 'tracedError':
                    o.type = 'Firestore Error';
                    o.bgColor = '#ffe3e3';
                    o.screenColor = '#ff0000';
                    break;
                case 'untracedError':
                    o.type = 'Basic Error'
                    o.bgColor = '#e7dbf8';
                    o.screenColor = '#ff0000';
                    break;
                case 'unexpectedCrash':
                    o.type = 'Crash';
                    o.bgColor = '#fffacc';
                    o.screenColor = '#ffe400';
                    break;
                case 'unintentionalRefresh':
                    o.type = 'Unintentional Refresh';
                    o.bgColor = '#E4E5E7';
                    o.screenColor = '#9C9EA7';
                    break;
                default:
                    o.type = '?';
                    o.bgColor = '#d3f1f4';
                    o.screenColor = 'transparent';
            }

            o.licensee = '';
            if (allUsers?.byId[report.licenseeId]) {
                o.licensee = `${allUsers?.byId[report.licenseeId]?.firstName} ${allUsers?.byId[report.licenseeId]?.lastName}`;
            }
            o.user = '';
            if (allUsers?.byId[report.userId]) {
                o.user = `${allUsers?.byId[report.userId]?.firstName} ${allUsers?.byId[report.userId]?.lastName}`;
            }
            o.vessel = '';
            if (allVessels?.byId[report.vesselId]) {
                o.vessel = allVessels.byId[report.vesselId]?.name;
            }

            // Filter out unwanted reports
            const terms = (searchText ?? '').trim().toLowerCase();
            if (
                selectedTypes.includes(o.type) &&
                selectedPlatforms.includes(o.device?.platform) && (
                    terms === '' || (
                        (o.id ?? '').toLowerCase().indexOf(terms) !== -1 ||
                        (o.type ?? '').toLowerCase().indexOf(terms) !== -1 ||
                        (o.whatFailed ?? '').toLowerCase().indexOf(terms) !== -1 ||
                        (o.reason ?? '').toLowerCase().indexOf(terms) !== -1 ||
                        (o.user ?? '').toLowerCase().indexOf(terms) !== -1 ||
                        (o.licensee ?? '').toLowerCase().indexOf(terms) !== -1 ||
                        (o.version ?? '').indexOf(terms) !== -1 ||
                        String(o.build ?? '').indexOf(terms) !== -1 ||
                        (o.device?.platform && o.device.platform.toLowerCase().indexOf(terms) !== -1) ||
                        (o.device?.operatingSystem && o.device.operatingSystem.toLowerCase().indexOf(terms) !== -1) ||
                        (o.device?.osVersion && o.device.osVersion.toLowerCase().indexOf(terms) !== -1) ||
                        (o.vessel && o.vessel.toLowerCase().indexOf(terms) !== -1) ||
                        (o.reference?.toLowerCase() && o.reference.toLowerCase().indexOf(terms) !== -1)
                    )
                )
            ) {
                filtered.push(o);
            }
        });

        // Order by
        if (orderBy === 'What Failed') {
            filtered.sort((a, b) => {
                if (a.whatFailed && b.whatFailed) {
                    return a.whatFailed.localeCompare(b.whatFailed);
                } else if (a.whatFailed === undefined && b.whatFailed === undefined) {
                    return 0;
                } else if (a.whatField === undefined) {
                    return -1;
                } else {
                    return 1;
                }
            });
        } else if (orderBy === 'Reason') {
            filtered.sort((a, b) => {
                if (a.reason && b.reason) {
                    return a.reason.localeCompare(b.reason);
                } else if (a.reason === undefined && b.reason === undefined) {
                    return 0;
                } else if (a.reason === undefined) {
                    return -1;
                } else {
                    return 1;
                }
            });
        }

        return filtered;
    }, [errorReports, selectedTypes, selectedPlatforms, orderBy, searchText, allUsers, allVessels]);

    const onDownloadCSV = useCallback(() => {
        console.log(`Generating ErrorReports CSV`);
        setIsLoadingCsv(true);

        const data = [[
            'Reported',
            'Id',
            'Reference',
            'Type',
            'What Failed',
            'Reason',
            'App Build',
            'Platform',
            'OS',
            'OS version',
            'Licensee',
            'User',
            'Vessel',
            'First Foreground',
            'Last Foreground',
            'Last Online',
            'Last User Action',
            'Last Minute Foregrounds',
            'Last 10min Foregrounds',
            'Last Minute Onlines',
            'Last 10min Onlines'
        ]];

        filteredErrorReports.forEach((report) => {
            const appActivity = JSON.parse(report.appActivity) as AppActivity;
            // console.log('report', report);
            // console.log('appActivity', appActivity);

            let whenLastMinute = report.whenReported - (60 * 1000);
            let whenLastTenMinutes = report.whenReported - (10 * 60 * 1000);

            let whenFirstForeground = 0;
            let whenLastForeground = 0;
            let countLastMinuteForegrounds = 0;
            let countLastTenMinutesForegrounds = 0;
            Object.keys(appActivity.isActive).forEach((when: any) => {
                if (appActivity.isActive[when]) {
                    if (when > whenLastForeground) {
                        whenLastForeground = when;
                    }
                    if (whenFirstForeground === 0 || when < whenFirstForeground) {
                        whenFirstForeground = when;
                    }
                    if (when >= whenLastMinute) {
                        countLastMinuteForegrounds++;
                    }
                    if (when >= whenLastTenMinutes) {
                        countLastTenMinutesForegrounds++;
                    }
                }
            });

            let whenLastOnline = 0;
            let countLastMinuteOnlines = 0;
            let countLastTenMinutesOnlines = 0;
            Object.keys(appActivity.isOnline).forEach((when: any) => {
                if (appActivity.isOnline[when] && when > whenLastOnline) {
                    whenLastOnline = when;
                }
                if (when >= whenLastMinute) {
                    countLastMinuteOnlines++;
                }
                if (when >= whenLastTenMinutes) {
                    countLastTenMinutesOnlines++;
                }
            });

            let whenLastUserAction = 0;
            ['action', 'page', 'modal'].forEach((userActionType) => {
                Object.keys((appActivity as any)[userActionType]).forEach((when: any) => {
                    if (when > whenLastUserAction) {
                        whenLastUserAction = when;
                    }
                });
            });

            data.push([
                formatDatetimeForCsv(report.whenReported),
                report.id,
                report.reference,
                report.type,
                report.whatFailed,
                report.reason,
                `${report.version} ${report.build}`,
                report.device?.platform,
                report.device?.operatingSystem,
                report.device?.osVersion,
                report.licensee,
                report.user,
                report.vessel ? report.vessel : '?',
                whenFirstForeground ? ((report.whenReported - whenFirstForeground) / 1000) : -1,
                whenLastForeground ? ((report.whenReported - whenLastForeground) / 1000) : -1,
                whenLastOnline ? ((report.whenReported - whenLastOnline) / 1000) : -1,
                whenLastUserAction ? ((report.whenReported - whenLastUserAction) / 1000) : -1,
                countLastMinuteForegrounds,
                countLastTenMinutesForegrounds,
                countLastMinuteOnlines,
                countLastTenMinutesOnlines
            ]);

        });
        setCsvData(data);
        setIsLoadingCsv(false);
    }, [filteredErrorReports]);

    useEffect(() => {
        if (csvData && csvData !== '') {
            csvRef.current.link.click();
        }
    }, [csvData]);

    return (
        <>
            <div className="error-reports columns">
                <div>
                    <SeaSearchbar
                        label="Search"
                        value={searchText}
                        setValue={setSearchText}
                    />
                </div>
                <div style={{ flex: '0 1 160px' }}>
                    <SeaDatetime
                        label="From"
                        value={whenFrom}
                        onchange={(e) => {
                            setWhenFrom(e.detail.value);
                        }}
                    />
                </div>
                <div style={{ flex: '0 1 160px' }}>
                    <SeaDatetime
                        label="To"
                        value={whenTo}
                        onchange={(e) => {
                            setWhenTo(e.detail.value);
                        }}
                    />
                </div>
                <div style={{ flex: '0 1 160px' }}>
                    <SeaMultiSelect
                        label="Types"
                        values={selectedTypes}
                        setValues={setSelectedTypes}
                        //setValues={setForVesselIds}
                        options={selectedTypeOptions}
                        useAllOption={true}
                        mode="popover"
                    />
                </div>
                <div style={{ flex: '0 1 160px' }}>
                    <SeaMultiSelect
                        label="Platforms"
                        values={selectedPlatforms}
                        setValues={setSelectedPlatforms}
                        //setValues={setForVesselIds}
                        options={platformOptions}
                        useAllOption={true}
                        mode="popover"
                    />
                </div>
                <div style={{ flex: '0 1 160px' }}>
                    <SeaSelect
                        label="Order By"
                        value={orderBy}
                        onchange={(e) => {
                            setOrderBy(e.detail.value);
                        }}
                    >
                        {orderBys.map((value) => {
                            return <IonSelectOption key={value} value={value}>{value}</IonSelectOption>;
                        })}
                    </SeaSelect>
                </div>
                <div style={{ paddingTop: '12px'}}>
                    <CSVLink
                        data={csvData}
                        filename={`SeaFlux_ErrorReports_${formatDate()}.csv`}
                        style={{ display: 'none' }}
                        ref={csvRef}
                        target="_blank"
                    />
                    <SeaButton
                        onClick={(e) => {
                            onDownloadCSV();
                        }}
                        zone="grey"
                        disabled={isLoadingCsv}
                    >
                        <SeaIcon slot="start" icon="download"/>{isLoadingCsv ? 'Generating...' : 'CSV' }
                    </SeaButton>
                </div>
            </div>
            {errorReports === undefined &&
                <IonSpinner name="crescent" className="sea-spinner"/>
            }
            {errorReports && errorReports.length === 0 &&
                <h2>
                    No results.
                </h2>
            }
            {errorReports && errorReports.length > 0 &&
                <div className="error-reports">
                    <b>{filteredErrorReports.length}</b> Filtered Reports
                    <div className="sea-row headings">
                        <div>Reported</div>
                        <div></div>
                        <div>Type</div>
                        <div>What Failed</div>
                        <div>Reason</div>
                        <div>App Build</div>
                        <div>Platform</div>
                        <div>OS</div>
                        <div></div>
                        <div>Licensee / User</div>
                        <div>Vessel</div>
                        <div>Reference</div>
                    </div>
                    {filteredErrorReports?.map((report) => {
                        return (
                            <div
                                key={report.id}
                                className="sea-card sea-row"
                                style={{ backgroundColor: report.bgColor }}
                                onClick={(e) => {
                                    setSelectedErrorReport(report);
                                    setShowErrorReportModal(true);
                                }}
                            >
                                <div className="truncate-2">{formatDatetime(report.whenReported)}</div>
                                <div>
                                    <div className="screen-indicator" style={{ backgroundColor: report.screenColor }}></div>
                                </div>
                                <div className="truncate-2">{report.type}</div>
                                <div className="truncate-2">{report.whatFailed}</div>
                                <div className="truncate-2">{report.reason}</div>
                                <div className="truncate-2">{report.version} {report.build}</div>
                                <div className="truncate-2">{report.device?.platform}</div>
                                <div className="truncate-2">{report.device?.operatingSystem}</div>
                                <div className="truncate-2">{report.device?.osVersion}</div>
                                <div className="truncate-2">
                                    {report.licensee} /
                                    <br/>{report.user}
                                </div>
                                <div className="truncate-2">
                                    {report.vessel ? report.vessel : '?'}
                                </div>
                                <div>{report.reference}</div>
                            </div>
                        );
                    })}
                </div>
            }
            <ViewErrorReportModal
                showModal={showErrorReportModal}
                setShowModal={setShowErrorReportModal}
                errorReport={selectedErrorReport}
            />
        </>
    );
};

export default ErrorReports;

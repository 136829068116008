import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { canView } from "../../shared-state/Core/userPermissions";
import { getDayOffsetMillis, warnDays } from "../../lib/util";
import { registerFiles, registerRichText } from "../FileSyncSystem/filesToCache";

//
// Loads company documents
//

export interface SFDoc {
    id: string,
    state: string,
    ext: string
    [key: string | number]: any
}

export interface CompanyDocument extends CreateableDocument, UpdateableDocument {
    categoryId: string;
    deletedBy?: string;
    emailReminder?: string;
    files?: string[];
    interval?: string;
    licenseeId: string;
    name?: string;
    sfdoc?: SFDoc;
    state: string;
    title: string;
    type: string;
    whenDeleted?: number;
    whenExpires?: number;
    whenToRemind?: number;
}

export type CompanyDocumentsData = {
    all: CompanyDocument[], // ordered by title
    prioritised: CompanyDocument[], // ordered whenExpires asc, renewable first
    top5: CompanyDocument[],
    byId: {
        [companyDocumentId: string]: CompanyDocument
    },
    byCategoryId: {
        [categoryId: string]: CompanyDocument[] // ordered by title
    },
    numHighestPriority: number
};

export const companyDocumentsConfig: SharedStateConfig<CompanyDocumentsData> = {
    isAlwaysActive: false,
    dependencies: ['licenseeId', 'todayMillis'], // todayMillis is used due to calculating around days
    countLiveDocs: () => sharedState.companyDocuments.current?.all.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;

        if (licenseeId && canView('companyDocuments')) {
            return onSnapshot(
                query(
                    collection(firestore, 'companyDocuments'),
                    where('licenseeId', '==', licenseeId),
                    where('state', '==', 'active'),
                    orderBy('title', 'asc')
                ),
                (snap) => {
                    done();
                    const documents = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as CompanyDocument;
                    });

                    // all is categorised by renewable and nonExpiring
                    const all = [] as CompanyDocument[];
                    const byId = {} as {
                        [companyDocumentId: string]: CompanyDocument
                    };
                    documents.forEach((document: CompanyDocument) => {
                        registerFiles(document.files, 'companyDocuments', document);
                        registerRichText(document.sfdoc, 'companyDocuments');
                        byId[document.id] = document;
                        all.push(document);
                    });
                    let prioritised = [...documents] as CompanyDocument[];
                    prioritised.sort((a, b) => {
                        return ((a.type === 'renewable' ? a.whenExpires : Number.MAX_SAFE_INTEGER) ?? Number.MAX_SAFE_INTEGER) - 
                               ((b.type === 'renewable' ? b.whenExpires : Number.MAX_SAFE_INTEGER) ?? Number.MAX_SAFE_INTEGER);
                    });

                    // prioritised should only contain whenExpires up to warnDays.vesselDocuments days in the future
                    // (and should not contain and nonExpiring either)
                    const maxWhenExpires = getDayOffsetMillis(warnDays.vesselDocuments[warnDays.vesselDocuments.length - 1] - 1);
                    const minWhenExpires = getDayOffsetMillis(warnDays.vesselDocuments[0] - 1);
                    let numHighestPriority = 0;
                    for (let i = 0; i < prioritised.length; i++) {
                        if (prioritised[i].whenExpires && prioritised[i].whenExpires as number < minWhenExpires && prioritised[i].type !== 'nonExpiring') {
                            numHighestPriority++
                        }
                        if (prioritised[i].type === 'nonExpiring' || (prioritised[i].whenExpires && prioritised[i].whenExpires as number > maxWhenExpires)) {
                            prioritised = prioritised.slice(0, i);
                            break;
                        }
                    }
                    
                    const byCategoryId = {} as {
                        [categoryId: string]: CompanyDocument[]
                    };

                    all.forEach((companyDocument: CompanyDocument) => {
                        byId[companyDocument.id] = companyDocument;
                        if (byCategoryId[companyDocument.categoryId] === undefined) {
                            byCategoryId[companyDocument.categoryId] = [];
                        }
                        byCategoryId[companyDocument.categoryId].push(companyDocument);
                    });
                    const top5 = prioritised.slice(0, 5).filter((p) => p.whenExpires && p.whenExpires < minWhenExpires);
                    set({
                        all,
                        prioritised,
                        top5,
                        byId,
                        byCategoryId,
                        numHighestPriority
                    });

                }, (error) => {
                    // This should be very rare
                    done();
                    console.log(`Failed to access company documents`, error);
                }
            );
        }

    }
};

import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useEffect, useState } from "react";
import { DeviceInfo } from "../Core/deviceInfo";

//
// Loads all devices used by users.
// This can only be used by superAdmin.
//

export const useDevices = (whenFrom: number, whenTo: number) => {
    const [devices, setDevices] = useState<DeviceInfo[]>();
    
    useEffect(() => {
        setDevices(undefined);
        return onSnapshot(
            query(
                collection(firestore, 'deviceInfo'),
                where('whenUpdated', '>=', whenFrom),
                where('whenUpdated', '<=', whenTo),
                orderBy('whenUpdated', 'desc')
            ),
            (snap) => {
                setDevices(
                    snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as any;
                    })
                );
            },
            (error) => {
                console.log(`error getting devices`, error);
            }
        );
    }, [whenFrom, whenTo]);

    return devices;
};

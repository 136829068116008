import { SharedStateConfig, sharedState } from "../shared-state";
import { canView } from "../../shared-state/Core/userPermissions";
import { getDayOffsetMillis, warnDays } from "../../lib/util";
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { Drill } from "../VesselSafety/drills";

export type DashboardVesselDrillsData = {
    byId: {
        [id: string]: Drill
    },
    top5: Drill[],
    numHighestPriority: number
};

export const dashboardVesselDrillsConfig: SharedStateConfig<DashboardVesselDrillsData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId'],
    countLiveDocs: () => sharedState.dashboardVesselDrills.current?.numHighestPriority ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        if (
            vesselId &&
            canView('drills')
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'drills'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'active'),
                    where('whenDue', '<', getDayOffsetMillis(warnDays.drills[0])),
                    orderBy('whenDue', 'asc')
                ),
                (snap) => {
                    done();
                    const byId: { [id: string]: Drill } = {};
                    const upcoming = snap.docs.map((doc) => {
                        const item = {
                            id: doc.id,
                            ...doc.data(),
                        } as Drill;
                        byId[doc.id] = item;
                        return item;
                    });
                    
                    set({
                        byId,
                        top5: upcoming.slice(0, 5),
                        numHighestPriority: upcoming.length,
                    });
                },
                (error) => {
                    done();
                    console.log(`Failed to access drills for vessel ${vesselId} on vessel dashboard`, error);
                }
            );
        } else {
            done();
        }
    }
};
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { sharedState } from "../shared-state";
import { useEffect, useState } from 'react';
import { UserType } from '../Core/user';
import { CustomFormCompleted } from '../CompanyDocuments/CustomForms/customFormsCompleted';

export type CrewCompletedCustomFormsData = {
    byId: { [id: string]: CustomFormCompleted },
    all: CustomFormCompleted[]
}

export const useCrewCompletedCustomForms = (selectedUser?: UserType) => {
    const licenseeId = sharedState.licenseeId.current;
    const [completedCustomForms, setCompletedCustomForms] = useState<CrewCompletedCustomFormsData>();
    
    useEffect(() => {
        setCompletedCustomForms(undefined);
        if (selectedUser) {
            return onSnapshot(
                query(
                    collection(firestore, 'customFormsCompleted'),
                    where('personnelIds', 'array-contains', selectedUser.id),
                    where('licenseeId', '==', licenseeId),
                    where('state', '==', 'active'),
                    orderBy('whenAdded', 'desc')
                ),
                (snap) => {
                    const byId = {} as {
                        [id: string]: CustomFormCompleted
                    };
                    const all = snap.docs.map((doc) => {
                        const o = {
                            id: doc.id,
                            ...doc.data()
                        } as CustomFormCompleted;
                        byId[o.id] = o;
                        return o;
                    });
                    setCompletedCustomForms({
                        byId,
                        all
                    });


                    /*
                    const formVersion = customFormVersions?.byFormIdAndVersion[selectedCustomForm.id][form.version];
                    if (formVersion) {
                        for (let key in formVersion.form) {
                            if (formVersion.form.hasOwnProperty(key)) {
                                if (formVersion.form[key].id === 'files') {
                                    if (form?.data[formVersion.form[key].n]) {
                                        registerFiles(form.data[formVersion.form[key].n], 'customFormsCompleted', formVersion);
                                    }
                                } else if (formVersion.form[key].id === 'signature') {
                                    if (form?.data[formVersion.form[key].n]) {
                                        registerSignature(form.data[formVersion.form[key].n], 'customFormCompleted, formVersion);
                                    }
                                }
                            }
                        }
                    }
                    */


    
                }, (error) => {
                    // This should be very rare
                    console.log(`Failed to access crew custom forms completed for user ${selectedUser.id} `, error);
                }
            );
        }
    }, [selectedUser, licenseeId])

    return completedCustomForms;
}

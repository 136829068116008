import React, { ReactNode } from 'react';
import './SeaStatus.css';

export type SeaStatusOption = 'ok' | 'now' | 'warn' | 'fail' | 'missing';

interface SeaStatusProps {
    children: ReactNode,
    status: SeaStatusOption,
    inline?: boolean
}

const SeaStatus: React.FC<SeaStatusProps> = ({ children, status, inline }) => {
    return (
        <div className={`sea-status ${status} ${inline ? 'inline' : ''}`}>
            {children}
        </div>
    );
};

export default SeaStatus;

import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, splittableBatch } from '../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, preventMultiTap } from '../../../lib/util';
import { canCreate } from '../../../shared-state/Core/userPermissions';
import { logAction } from '../../../shared-state/General/actionLog';
import { sharedState } from '../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../shared-state/DataSyncSystem/dataSync';
import { confirmAction } from '../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { Action, reportError, traceAction } from '../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../lib/files';
import SeaModal from '../../../components/SeaModal/SeaModal';
import Yup from '../../../lib/yup'
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaFileUpload from '../../../components/SeaFileUpload/SeaFileUpload';
import SeaInput from '../../../components/SeaInput/SeaInput';
import SeaInputError from '../../../components/SeaInputError/SeaInputError';
import SeaLinkButton from '../../../components/SeaLinkButton/SeaLinkButton';

interface EditVoyageDocumentsModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedVoyage: any,
    itemToUpdate: any,
    level?: number
}

const EditVoyageDocumentsModal: React.FC<EditVoyageDocumentsModalProps> = ({
    showModal,
    setShowModal,
    selectedVoyage,
    itemToUpdate,
    level
}) => {
    const userId = sharedState.userId.use(showModal);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                name: itemToUpdate.name ? ''+itemToUpdate.name : ''
            };
        } else {
            return {
                name: ''
            };
        }
    }, [itemToUpdate]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setFiles(makeSeaFiles(itemToUpdate?.files));
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue, setValues, resetForm, isSubmitting } = useFormik({
        initialValues: initialValues, 
        validationSchema: Yup.object({
            name: Yup.string().max(500).required()
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('voyageDocuments') || files === undefined || files.length === 0) {
                return;
            }

            // Attempt upload first.... ?
            uploadFiles(files).then(() => {
                const action = traceAction('voyageDocuments') as Action;
                const batch = splittableBatch(firestore, 20 - 1); // -1 because voyageDocuments requires an extra security access call

                if (itemToUpdate) {
                    action.type = 'update';
                    action.docId = itemToUpdate.id;
                    batch.set(
                        doc(firestore, 'voyageDocuments', itemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: action.whenAction,
                            name: data.name,
                            files: seaFilesToValue(files),
                            touched: serverTimestamp(),
                        },
                        { merge: true }
                    );

                    logAction(
                        batch,
                        'Update',
                        'voyageDocuments',
                        itemToUpdate.id,
                        `${data.name} - ${selectedVoyage.name}`,
                        [selectedVoyage.vesselId]
                    );

                    saveFileRefs(batch, files, 'voyageDocuments', itemToUpdate.id);
                } else {
                    const newRef = doc(collection(firestore, 'voyageDocuments'));
                    action.type = 'create';
                    action.docId = newRef.id;
                    batch.set(newRef, {
                        vesselId: selectedVoyage.vesselId,
                        voyageId: selectedVoyage.id,
                        whenAdded: action.whenAction,
                        addedBy: userId,
                        name: data.name,
                        files: seaFilesToValue(files),
                        state: 'active',
                        touched: serverTimestamp(),
                    });
                    saveFileRefs(batch, files, 'voyageDocuments', newRef.id);

                    logAction(
                        batch,
                        'Add',
                        'voyageDocuments',
                        newRef.id,
                        `${data.name} - ${selectedVoyage.name}`,
                        [selectedVoyage.vesselId]
                    );
                }
                
                onCollectionUpdated(batch, 'voyageDocuments');

                action.data = {
                    data,
                    files: seaFilesToValue(files),
                    voyageId: selectedVoyage?.id,
                    vesselId: selectedVoyage?.vesselId,
                    itemToUpdate
                };
                action.save(`${itemToUpdate ? 'Update' : 'Add'} voyageDocuments ${selectedVoyage?.id}`, batch);
                batch.commit().then(() => {
                    action.reportSuccess();
                }).catch((error) => {
                    action.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload voyageDocuments`, error.message, error, {
                        files: seaFilesToValue(files),
                        itemToUpdate,
                        data
                    });
                }
            });

        }
    });

    useEffect(() => {
        if (files && files.length > 0) {
            if (
                (values.name === undefined || values.name.length === 0) &&
                files[0].name &&
                files[0].name.length > 0
            ) {
                let s = files[0].name;
                if (s.indexOf('.') > 0) {
                    s = s.substring(0, s.lastIndexOf('.'));
                }
                setFieldValue('name', s);
            }
        }
    }, [files, setFieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            haveFilesChanged(files, itemToUpdate?.files)
        );
    }, [initialValues, values, files, itemToUpdate?.files]);

    const onDeleteVoyageDocuments = (e: any) => {
        e.preventDefault();
        confirmAction('Are you sure you want to delete this', 'Yes, delete').then(() => {
            const action = traceAction('voyageDocuments', 'delete') as Action;
            action.docId = itemToUpdate.id;

            const batch = splittableBatch(firestore, 20 - 1); // -1 because voyageDocuments requires an extra security access call
            batch.set(
                doc(firestore, 'voyageDocuments', itemToUpdate.id),
                {
                    state: 'deleted',
                    deletedBy: userId,
                    whenDeleted: action.whenAction,
                    touched: serverTimestamp(),
                },
                { merge: true }
            );

            logAction(
                batch,
                'Delete',
                'voyageDocuments',
                itemToUpdate.id,
                `${itemToUpdate.name} - ${selectedVoyage.name}`,
                [selectedVoyage.vesselId]
            );

            onCollectionUpdated(batch, 'voyageDocuments');

            action.data = {itemToUpdate};
            action.save(`Delete vessel document ${itemToUpdate?.id}`, batch);
            batch.commit().then(() => {
                action.reportSuccess();
            }).catch((error) => {
                action.reportError(error.message, error);
            });

            setShowModal(false);
        }).catch(() => {});
    };

    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Voyage Documents' : 'Add Voyage Documents'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size='thin'
            level={level}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="voyageDocuments"
                                field="files"
                            />
                            {hasSubmitted && (files === undefined || files.length === 0) &&
                                <SeaInputError alignLeft={true}>
                                    Please choose at least one image/document to upload
                                </SeaInputError>
                            }
                        </IonCol>
                        <IonCol size="12">
                            <SeaInput
                                label="Name / Description"
                                //placeholder="Add notes..."
                                name="name"
                                value={values.name}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.name ? errors.name : ''}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                {canCreate('logbook') &&
                    <div className="view-modal-buttons">
                        <SeaButton zone="white" size="wide" type="submit">
                            {itemToUpdate ? 'Update Documents' : 'Save Documents'}
                        </SeaButton>
                        <div className="spacer-wide"></div>
                        <SeaLinkButton mode="standard-link" onClick={(e) => onDeleteVoyageDocuments(e)}>Delete</SeaLinkButton>
                    </div>
                }
                <div style={{ height: '30px' }}></div>
            </form>
        </SeaModal>
    );
};

export default EditVoyageDocumentsModal;

import React, { useEffect, useState } from 'react';
import { revealImage } from '../../lib/util';
import { FileReference, getFileTrappedSrc, getImgSrcFromString } from '../../lib/files';
import { getCachedFileSrc } from '../../shared-state/FileSyncSystem/cachedFiles';
import './SeaSignatureImage.css';

interface SeaSignatureImageProps {
    file?: FileReference, // "<state><id>.<ext>"
}

const SeaSignatureImage: React.FC<SeaSignatureImageProps> = ({ file }) => {
    const [imgSrc, setImgSrc] = useState<string>();

    useEffect(() => {
        if (file) {
            getCachedFileSrc(file, 'S').then((src) => {
                setImgSrc(src);
            }).catch((e) => {
                if (file[0] === '0') {
                    setImgSrc(getFileTrappedSrc('sig'));
                } else {
                    //setImgSrc(getImgSrcFromString(file, 'sig'));
                    getImgSrcFromString(file, 'sig').then((_imgSrc: string) => {
                        setImgSrc(_imgSrc);
                    });
                }
            });
        }
    }, [file]);

    if (file === undefined) {
        return <></>;
    }

    return (
        <img
            className={`sea-file-image fade signature`}
            src={imgSrc}
            onLoad={revealImage}
            alt="Signature"
        />
    );

};

export default SeaSignatureImage;

import { FileInfo, Filesystem } from "@capacitor/filesystem";
import { sharedState, SharedStateConfig } from "../shared-state";
import { cachedFiles, CachedFileType, FileCollection, filesDirectory, filesFolder, loadCachedFiles, saveCachedFiles } from "./cachedFiles";
import { filesToCache, registerFiles, registerRichText, registerSignature } from "./filesToCache";
import { setFileSyncMessage } from "../Core/appReadyState";
import { FileReference, FileState } from "../../lib/files";
import { toInt } from "../../lib/util";

let initStarted = false;
export const isFileSyncReadyConfig: SharedStateConfig<boolean> = {
    isAlwaysActive: true,
    dependencies: ['licenseeSettings'],
    default: false,
    run: (done, set, clear) => {
        done();
        if (sharedState.licenseeSettings.current && !initStarted) {
            initStarted = true;
            //debugApp('File Caching', `initFileSync...`);
            initFileSync().then(() => {
                console.log(`[FileSync] FileSync is ready!`);
                //debugApp('File Caching', `FileSynce ready!`);
                set(true);
                processFilesWaitingToBeRegistered();
            });
        } else {
            set(false);
        }
    },
    notes: 'Is true once initFileSync has run for a paricular licenseeId',
};

// Register files that have been found during loading data
export const filesWaitingToBeRegistered = {
    files: [] as {
        files: FileReference[],
        collection: FileCollection,
        when: number | object
    }[],
    signatures: [] as {
        file: FileReference,
        collection: FileCollection,
        when: number | object
    }[],
    richText: [] as {
        sfdoc: {
            [when: number]: string
        },
        collection: FileCollection
    }[],
};

const initFileSync = () => {
    console.log(`[FileSync] initialising...`);
    const whenFileSyncStarted = Date.now();
    loadCachedFiles();
    return Filesystem.mkdir({ // Make sure filesDirectory exists
        path: `${sharedState.licenseeId.current}`,
        directory: filesDirectory,
        recursive: true
    }).catch((error) => {
        // Ignore error, it's probably just that the directory already exists
    }).then(() => {
        return migrateOldFileSync();
    }).then(() => {
        console.log(`[FileSync] init completed in ${Date.now() - whenFileSyncStarted}ms. ${Object.keys(cachedFiles).length} cachedFiles. ${filesToCache.length} filesToCache.`, cachedFiles);
    });
};

const processFilesWaitingToBeRegistered = () => {
    // Register files that occurred before file cache was ready
    filesWaitingToBeRegistered.files.forEach((o) => {
        registerFiles(o.files, o.collection, o.when);
    });
    filesWaitingToBeRegistered.signatures.forEach((o) => {
        registerSignature(o.file, o.collection, o.when);
    });
    filesWaitingToBeRegistered.richText.forEach((o) => {
        registerRichText(o.sfdoc, o.collection);
    });
    // Clear filesWaitingToBeRegistered
    filesWaitingToBeRegistered.files = [];
    filesWaitingToBeRegistered.signatures = [];
    filesWaitingToBeRegistered.richText = [];
}

// const recordCachedFile = (fileName: string) => {
//     if (fileName.endsWith('.__chunk')) {
//         return; // Overflow files don't affect cachedFiles maps
//     }
//     // Deal with previously cached file
//     const state = toInt(fileName[0], 0);
//     let id: string;
//     let fileType = '';
//     if (state === 2) {
//         id = fileName.substring(2, 22);
//         fileType = fileName[1];
//     } else {
//         id = fileName.substring(1, 21);
//     }
//     if (state === 2) {
//         // Check if this replaces any lesser states
//         if (cachedFiles[id]) {
//             deleteFile(cachedFiles[id]);
//         }
//         cachedFiles[`${fileType}${id}`] = fileName;
//     } else {
//         if (
//             cachedFiles[`F${id}`] ||
//             cachedFiles[`T${id}`] ||
//             cachedFiles[`S${id}`]
//         ) {
//             // This is replaced by state=2 version
//             deleteFile(fileName);
//         } else if (cachedFiles[id]) {
//             const existingState = toInt(cachedFiles[id][0], 0);
//             if (state > existingState) {
//                 // This replaces state=0
//                 deleteFile(cachedFiles[id]);
//                 cachedFiles[id] = fileName;
//             } else {
//                 // This is replaced by state=1
//                 deleteFile(fileName);
//             }
//         } else { // not in cachedFiles yet
//             cachedFiles[id] = fileName;
//             if (state === 0) { // Waiting to be uploaded
//                 if (isPlatform('hybrid')) { // Only hybrid apps upload while offline
//                     uploadFileInBackground(fileName); //filesToUpload.push(file.name);
//                 } else {
//                     // Shouldn't be files waiting to be uploaded
//                     deleteFile(fileName);
//                 }
//             }
//         }
//     }
// };

const migrateOldFileSync = () => {
    // * Convert normal file into CachedFile naming format
    // * Delete any _* files ... we don't do that anymore. Triggering a full data sync at the end will clean everything up
    const filesToMove = [] as FileInfo[];
    const filesToDelete = [] as string[];
    let filesMoved = 0;
    let filesDeleted = 0;

    const processFilesToMove = (): Promise<void> => {
        if (filesToMove.length === 0) {
            return Promise.resolve();
        }
        const fileToMove = filesToMove.shift();
        const name = fileToMove!.name;
        const state = toInt(name[0], 0);
        let id: string;
        let fileType: CachedFileType;
        const ext = name.substring(name.lastIndexOf('.') + 1);
        if (state === 2) {
            id = name.substring(2, 22);
            fileType = name[1] as CachedFileType;
        } else {
            id = name.substring(1, 21);
            fileType = 'O';
        }
        return Filesystem.rename({
            directory: filesDirectory,
            from: `${filesFolder}/${name}`,
            to: `${sharedState.licenseeId.current}/${fileType}${id}.${ext}`
        }).then(() => {
            console.log(`[FileSync] Migrated file ${name} --> ${sharedState.licenseeId.current}/${fileType}${id}.${ext}`);
            filesMoved++;
            // Register file in cache
            if (!name.endsWith('.__chunk')) {
                let entry = cachedFiles[id];
                if (entry === undefined) {
                    // Init FileCacheEntry
                    entry = [state, id, ext, null, null, {}]
                    cachedFiles[id] = entry;
                }
                entry[0] = Math.max(entry[0], state) as FileState; // Assume highest state is the real state
                const files = entry[5];
                return Filesystem.stat({
                    path: `${sharedState.licenseeId.current}/${fileType}${id}.${ext}`,
                    directory: filesDirectory
                }).then((result) => {
                    if (result) {
                        (files as any)[fileType] = result.size; // Record file size (which means it exists)
                    }
                }).catch((error) => {
                    console.error(`[FileSync] Failed to get Filesystem.stat for ${sharedState.licenseeId.current}/${fileType}${id}.${ext}`, error);
                }).finally(() => {
                    saveCachedFiles();
                    return processFilesToMove();
                });
            }
            return processFilesToMove();
        }).catch((error) => {
            console.error(`[FileSync] Failed to migrated file ${sharedState.licenseeId.current}/${name}`);
            return processFilesToMove();
        });
    };

    const processFilesToDelete = (): Promise<void> => {
        if (filesToDelete.length === 0) {
            return Promise.resolve();
        }
        const fileToDelete = filesToDelete.shift();
        return Filesystem.deleteFile({
            path: `${filesFolder}/${fileToDelete}`,
            directory: filesDirectory
        }).catch((error) => {
            console.error(`[FileSync] Failed to delete file ${filesFolder}/${fileToDelete}`);
        }).then(() => {
            filesDeleted++;
            console.log(`[FileSync] Migration deleted file ${fileToDelete} (${filesDeleted})`);
            return processFilesToDelete();
        });
    };

    return Filesystem.readdir({
        path: filesFolder,
        directory: filesDirectory
    }).then((result) => {
        const extractId = (fileName: string) => {
            if (fileName[0] === '2') {
                return fileName.substring(2, 22);
            }
            return fileName.substring(1, 21);
        };
        // Record which files have been split (have *.__chunk files)
        const isSplit = {} as { [fileId: string]: true };
        result.files.forEach((file) => {
            if (file.name.endsWith('.__chunk')) {
                isSplit[extractId(file.name)] = true;
            }
        });
        
        result.files.forEach((file) => {
            if (file.type === 'file') {
                if (file.name.startsWith('_') || isSplit[extractId(file.name)]) {
                    filesToDelete.push(file.name);
                } else {
                    filesToMove.push(file);
                }
            }
        });

        if (filesToMove.length) {
            setFileSyncMessage('Installing an update. This may take more than a minute...');
        }
        return processFilesToMove().then(() => {
            return processFilesToDelete();
        }).then(() => {
            if (filesDeleted + filesMoved > 0) {
                console.log(`[FileSync] Successfully finished migration. ${filesMoved} files moved. ${filesDeleted} files deleted.`);
            }
        });
    }).catch((e) => {
        // Ignore error... no need to migrate
    });
};

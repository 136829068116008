import { SharedStateConfig, sharedState } from "../shared-state";
import { canView } from "../../shared-state/Core/userPermissions";
import { getDayOffsetMillis, warnDays } from "../../lib/util";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { CrewCertificate } from "../Crew/crewCertificates";
import { registerFiles } from "../FileSyncSystem/filesToCache";

export type DashboardVesselCrewCertificatesData = {
    byId: {
        [id: string]: CrewCertificate;
    };
    top5: CrewCertificate[];
    numHighestPriority: number;
};

export const dashboardVesselCrewCertificatesConfig: SharedStateConfig<DashboardVesselCrewCertificatesData> = {
    isAlwaysActive: false,
    dependencies: ['licenseeId', 'vesselId', 'users'],
    countLiveDocs: () => sharedState.dashboardVesselCrewCertificates.current?.numHighestPriority ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        const licenseeId = sharedState.licenseeId.current;
        const users = sharedState.users.current;
        if (
            licenseeId &&
            vesselId &&
            users &&
            canView('crewCertificates')
        ) {
            return onSnapshot(
                query(collection(firestore, 'crewCertificates'),
                where('licenseeId', '==', licenseeId),
                where('state', '==', 'active'),
                where('whenExpires', '<', getDayOffsetMillis(warnDays.crewCertificates[0])),
                orderBy('whenExpires', 'asc')
            ),
                (snap) => {
                    done();
                    const byId: { [id: string]: CrewCertificate } = {};
                    const upcoming = snap.docs.map((doc) => {
                        if (users?.byId[doc.data().heldBy] && users?.byId[doc.data().heldBy].state === 'active' && users.byId[doc.data().heldBy]?.vesselIds?.includes(vesselId)) {
                            const item = {
                                id: doc.id,
                                ...doc.data(),
                            } as CrewCertificate;
                            registerFiles(doc.data().files, 'crewCertificates', item);
                            byId[doc.id] = item;
                            return item;
                        } else {
                            return null;
                        }
                    }).filter((x) => x !== null) as CrewCertificate[];
                    set({
                        byId,
                        top5: upcoming.slice(0, 5),
                        numHighestPriority: upcoming.length,
                    });
                },
                (error) => {
                    done();
                    // TODO - Figure out why this is failing
                    console.log(`Failed to access crew certificates for vessel ${vesselId} on vessel dashboard`, error);
                }
            );
        } else {
            done();
        }
    },
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { sharedState } from '../../shared-state/shared-state';
import PleaseWait from '../../pages/AccountManagement/PleaseWait/PleaseWait';

const UnknownPage: React.FC = () => {
    const user = sharedState.user.use();
    const superAdmin = sharedState.superAdmin.use();
    const appReadyState = sharedState.appReadyState.use()!;
    const navigate = useNavigate();

    if (!appReadyState.isReady) {
        return <PleaseWait message={appReadyState.notReadyMessage}/>
    }

    if (superAdmin) {
        setTimeout(() => {
            navigate('/admin');
        });
    } else if (user) {
        setTimeout(() => {
            navigate('/fleet');
        });
    } else {
        setTimeout(() => {
            navigate('/login');
        });
    }

    return <></>;
};

export default UnknownPage;

import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, arrayUnion, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, areObjectsEqual, formatSeaDate, toMillis, subtractInterval, formatDp, hasArrayChanged, cleanupStringArray, formatCurrency, toFloat, preventMultiTap } from '../../../../lib/util';
import { makeCategoryId, renderCategoryName } from '../../../../lib/categories';
import { logAction } from '../../../../shared-state/General/actionLog';
import { renderFullName } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { sendVesselNotification } from '../../../../shared-state/Core/vessel';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { Job, jobPriorities } from '../../../../shared-state/VesselMaintenance/jobs';
import { Action, reportError, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import { Equipment } from '../../../../shared-state/VesselMaintenance/equipment';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaSelectEmailReminder from '../../../../components/SeaSelectEmailReminder/SeaSelectEmailReminder';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaEquipment from '../../../../components/SeaEquipment/SeaEquipment';
import SeaSelectCrewOrContact from '../../../../components/SeaSelectCrewOrContact/SeaSelectCrewOrContact';
import SeaTagsInput from '../../../../components/SeaTagsInput/SeaTagsInput';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';

interface EditJobListProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    itemToUpdate?: Job,
    fromEquipmentId?: string;
    fromLocationId?: string;
    level?: number,
}

const EditJobList: React.FC<EditJobListProps> = ({showModal, setShowModal, itemToUpdate, fromEquipmentId, fromLocationId, level}) => {
    const licenseeId = sharedState.licenseeId.use(showModal);
    const userId = sharedState.userId.use(showModal);
    const users = sharedState.users.use(showModal);
    const contacts = sharedState.contacts.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const vessel = sharedState.vessel.use(showModal);
    const equipment = sharedState.equipment.use(showModal);
    const vesselLocations = sharedState.vesselLocations.use(showModal);
    const vesselSystems = sharedState.vesselSystems.use(showModal);
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const [equipmentId, setEquipmentId] = useState<string>();
    const [locationId, setLocationId] = useState<string>();
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [assignedTo, setAssignedTo] = useState<{userId?: string, contactId?: string, name?: string} | undefined>();
    const [jobTags, setJobTags] = useState<string[]>([]);
    const [maintenanceTags, setMaintenanceTags] = useState<string[]>([]);

    const initialValues = useMemo(() => {
        if (!itemToUpdate) {
            return {
                task: '',
                description: '',
                priority: '',
                //personResponsible: '',
                whenDue: '',
                emailReminder: '',
                estimatedCost: ''
            };
        } else {
            return {
                task: (itemToUpdate?.task) ? ''+itemToUpdate.task : '',
                description: (itemToUpdate?.description) ? ''+itemToUpdate.description : '',
                priority: (itemToUpdate?.priority) ? ''+itemToUpdate.priority : '',
                //personResponsible: (itemToUpdate?.personResponsible) ? ''+itemToUpdate.personResponsible : '',
                whenDue: (itemToUpdate?.whenDue) ? formatSeaDate(itemToUpdate.whenDue) : '',
                emailReminder: (itemToUpdate?.emailReminder) ? ''+itemToUpdate.emailReminder : '',
                estimatedCost: (itemToUpdate?.estimatedCost !== undefined) ? formatDp(itemToUpdate.estimatedCost, 2) : ''
            };
        }
    }, [itemToUpdate]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setAssignedTo((itemToUpdate?.assignedTo) ? itemToUpdate.assignedTo : undefined);
        setJobTags((itemToUpdate?.tags) ? itemToUpdate.tags : []);
        setMaintenanceTags((itemToUpdate?.maintenanceTags) ? itemToUpdate.maintenanceTags : []);
        setEquipmentId(itemToUpdate?.equipmentId ?? fromEquipmentId ?? '');
        setLocationId(itemToUpdate?.locationId ?? fromLocationId ?? '');
        setTimeout(() => {
            // Prevent equipment.locationId overriding itemToUpdate.locationId, unless fromLocationId has been supplied
            setLocationId(itemToUpdate?.locationId ?? fromLocationId ?? '');
        }, 100);
        setFiles(makeSeaFiles(itemToUpdate?.files));

        if (
            itemToUpdate?.equipmentId ||
            itemToUpdate?.locationId ||
            itemToUpdate?.whenDue ||
            itemToUpdate?.emailReminder ||
            itemToUpdate?.estimatedCost ||
            fromLocationId
        ) {
            setShowMoreInfo(true);
        } else {
            setShowMoreInfo(false);
        }
    }

    const onClosed = () => {
        setEquipmentId(undefined);
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isSubmitting, isValid } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            task: Yup.string().max(5000).required(),
            description: Yup.string().max(5000),
            priority: Yup.string().max(200).required(),
            //personResponsible: Yup.string().max(500),
            whenDue: Yup.date().when('emailReminder', {is: (value: string) => value, then: (schema) => schema.required().min(...notTooOld)}),
            emailReminder: Yup.string().max(200),
            estimatedCost: Yup.number().max(1000000000000)
        }), onSubmit: (data) => {
            if (preventMultiTap('job')) { return; }
            if (assignedTo?.userId === undefined && assignedTo?.contactId === undefined) {
                return;
            }
            if (!vesselId) {
                reportError('Vessel ID not found');
                return;
            }

            // Attempt upload first.... ?
            uploadFiles(files).then(() => {
                // Process form
                const action = traceAction('jobs') as Action;
                const batch = splittableBatch(firestore, 20 - 0);

                let whenToRemind: number | undefined = undefined;
                if (data.whenDue && data.emailReminder) {
                    whenToRemind = subtractInterval(data.whenDue, data.emailReminder);
                };

                let equipmentItem: Equipment | undefined = undefined;
                if (equipmentId && equipment?.byId && equipment.byId[equipmentId]) {
                    equipmentItem = equipment.byId[equipmentId];
                }
                let assignedToName = '';
                if (assignedTo?.userId) {
                    assignedToName = renderFullName(users?.byId[assignedTo.userId]);
                } else if (assignedTo?.contactId) {
                    assignedToName = `${contacts?.byId[assignedTo.contactId]?.name} (Contact)`;
                }

                if (itemToUpdate) {
                    action.type = 'update';
                    action.docId = itemToUpdate.id;
                    batch.set(
                        doc(firestore, 'jobs', itemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: action.whenAction,
                            task: data.task,
                            description: data.description,
                            priority: data.priority,
                            //personResponsible: data.personResponsible ? data.personResponsible : deleteValue,
                            assignedTo: {
                                userId: (assignedTo?.userId) ? assignedTo.userId : deleteValue,
                                contactId: (assignedTo?.contactId) ? assignedTo.contactId : deleteValue
                            },
                            tags: jobTags ? cleanupStringArray(jobTags) : deleteValue,
                            maintenanceTags: maintenanceTags ? cleanupStringArray(maintenanceTags) : deleteValue,
                            whenDue: data.whenDue ? toMillis(data.whenDue) : deleteValue,
                            equipmentId: equipmentId ? equipmentId : deleteValue,
                            locationId: makeCategoryId(
                                locationId,
                                vesselLocations,
                                deleteValue,
                                batch,
                                'vesselLocations',
                                'vesselId',
                                vesselId,
                                {}
                            ),
                            emailReminder: data.emailReminder ? data.emailReminder : deleteValue,
                            estimatedCost: toFloat(data.estimatedCost, deleteValue),
                            whenToRemind: whenToRemind,
                            files: seaFilesToValue(files),
                            touched: serverTimestamp()
                        },
                        { merge: true }
                    );

                    saveFileRefs(batch, files, 'jobs', itemToUpdate.id);
                    logAction(
                        batch,
                        'Update',
                        'jobs',
                        itemToUpdate.id,
                        data.task,
                        [itemToUpdate.vesselId]
                    );

                    sendVesselNotification(batch, 'jobCreated', 'jobs', {
                        isUpdate: true,
                        id: itemToUpdate.id,
                        task: data.task,
                        description: data.description,
                        jobNum: itemToUpdate.jobNum ? itemToUpdate.jobNum : undefined,
                        priority: data.priority, 
                        //personResponsible: data.personResponsible ? data.personResponsible : undefined,
                        assignedToName: assignedToName ? assignedToName : undefined,
                        tags: jobTags ? cleanupStringArray(jobTags)?.join(', ') : undefined,
                        maintenanceTags: maintenanceTags ? cleanupStringArray(maintenanceTags)?.join(', ') : undefined,
                        equipmentId: equipmentId,
                        system: renderCategoryName(equipmentItem?.systemId, vesselSystems),
                        equipment: equipmentItem ? equipmentItem.equipment : undefined,
                        location: renderCategoryName(locationId, vesselLocations),
                        isCritical: (equipmentItem?.isCritical) ? equipmentItem.isCritical : undefined,
                        whenDue: data.whenDue ? toMillis(data.whenDue) : undefined,
                        estimatedCost: (data.estimatedCost !== '') ? formatCurrency(toFloat(data.estimatedCost, 0), 2) : undefined
                    }, files);

                } else {
                    const newRef = doc(collection(firestore, 'jobs'));
                    action.type = 'create';
                    action.docId = newRef.id;
                    batch.set(newRef, {
                        vesselId: vesselId,
                        licenseeId: licenseeId,
                        addedBy: userId,
                        task: data.task,
                        description: data.description,
                        priority: data.priority, 
                        //personResponsible: data.personResponsible ? data.personResponsible : undefined,
                        assignedTo: assignedTo ? assignedTo : undefined,
                        tags: jobTags ? cleanupStringArray(jobTags) : undefined,
                        maintenanceTags: maintenanceTags ? cleanupStringArray(maintenanceTags) : undefined,
                        whenDue: data.whenDue ? toMillis(data.whenDue) : undefined,
                        equipmentId: equipmentId ? equipmentId : undefined,
                        locationId: makeCategoryId(
                            locationId,
                            vesselLocations,
                            undefined,
                            batch,
                            'vesselLocations',
                            'vesselId',
                            vesselId,
                            {}
                        ),
                        emailReminder: data.emailReminder ? data.emailReminder : undefined,
                        estimatedCost: toFloat(data.estimatedCost, undefined),
                        whenToRemind: whenToRemind,
                        whenAdded: action.whenAction,
                        state: 'active',
                        files: seaFilesToValue(files),
                        touched: serverTimestamp()
                    });

                    saveFileRefs(batch, files, 'jobs', newRef.id);
                    logAction(
                        batch,
                        'Add',
                        'jobs',
                        newRef.id,
                        data.task,
                        [vesselId]
                    );

                    sendVesselNotification(batch, 'jobCreated', 'jobs', {
                        id: newRef.id,
                        task: data.task,
                        description: data.description,
                        priority: data.priority, 
                        //personResponsible: data.personResponsible ? data.personResponsible : undefined,
                        assignedToName: assignedToName ? assignedToName : undefined,
                        tags: jobTags ? cleanupStringArray(jobTags)?.join(', ') : undefined,
                        maintenanceTags: maintenanceTags ? cleanupStringArray(maintenanceTags)?.join(', ') : undefined,
                        equipmentId: equipmentId ? equipmentId : undefined,
                        system: renderCategoryName(equipmentItem?.systemId, vesselSystems),
                        equipment: equipmentItem ? equipmentItem.equipment : undefined,
                        location: renderCategoryName(locationId, vesselLocations),
                        isCritical: (equipmentItem?.isCritical) ? equipmentItem.isCritical : undefined,
                        whenDue: data.whenDue ? toMillis(data.whenDue) : undefined,
                        estimatedCost: (data.estimatedCost !== '') ? formatCurrency(toFloat(data.estimatedCost, 0), 2) : undefined
                    }, files);
                }
                onCollectionUpdated(batch, 'jobs');
                let tagsChanged = false;
                if (jobTags && vessel) {
                    cleanupStringArray(jobTags)?.forEach((tag) => {
                        if (vessel.possibleTags === undefined || vessel.possibleTags.indexOf(tag) === -1) {
                            tagsChanged = true;
                            batch.set(
                                doc(firestore, 'vessels', vesselId),
                                { 
                                    possibleTags: arrayUnion(tag),
                                    touched: serverTimestamp()
                                },
                                { merge: true }
                            );
                        }
                    });
                }
                if (maintenanceTags && vessel) {
                    cleanupStringArray(maintenanceTags)?.forEach((tag) => {
                        if (vessel.possibleMaintenanceTags === undefined || vessel.possibleMaintenanceTags.indexOf(tag) === -1) {
                            tagsChanged = true;
                            batch.set(
                                doc(firestore, 'vessels', vesselId),
                                { 
                                    possibleMaintenanceTags: arrayUnion(tag),
                                    touched: serverTimestamp(),
                                },
                                { merge: true }
                            );
                        }
                    });
                }

                if (tagsChanged) {
                    onCollectionUpdated(batch, 'vessels');
                }
                action.data = {
                    data,
                    locationId,
                    equipmentItem,
                    equipmentId,
                    jobTags,
                    maintenanceTags,
                    assignedTo,
                    files: seaFilesToValue(files)
                };
                action.save(`${itemToUpdate ? 'Update' : 'Add'} job ${data?.task}`, batch);
                batch.commit().then(() => {
                    action.reportSuccess();
                }).catch((error) => {
                    action.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload job files`, error.message, error, {
                        itemToUpdate,
                        data,
                        files: seaFilesToValue(files)
                    });
                }
            });
        }
    });

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    const toggleShowMoreInfo = () => {
        setShowMoreInfo(!showMoreInfo);
    };

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            equipmentId !== ((itemToUpdate?.equipmentId) ? itemToUpdate.equipmentId : '') ||
            locationId !== ((itemToUpdate?.locationId) ? itemToUpdate.locationId : '') ||
            !areObjectsEqual(assignedTo, itemToUpdate?.assignedTo) ||
            hasArrayChanged(jobTags, itemToUpdate?.tags) ||
            hasArrayChanged(maintenanceTags, itemToUpdate?.maintenanceTags) ||
            haveFilesChanged(files, itemToUpdate?.files)
        );
    }, [values, initialValues, equipmentId, itemToUpdate?.equipmentId, itemToUpdate?.locationId, itemToUpdate?.assignedTo, itemToUpdate?.tags, itemToUpdate?.maintenanceTags, itemToUpdate?.files, locationId, assignedTo, jobTags, maintenanceTags, files]);

    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Job' : 'Add New Job'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            onClosed={onClosed}
            level={level}
            size="wide"
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaInput
                                label="Task"
                                name="task"
                                value={values.task}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                error={touched.task ? errors.task : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                label="Description"
                                name="description"
                                value={values.description}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                error={touched.description ? errors.description : ''}
                                height={100}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelect
                                label="Priority"
                                name="priority"
                                value={values.priority}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.priority ? errors.priority : ''}
                            >
                                {Object.keys(jobPriorities).map((priorityId) => {
                                    return (
                                        <IonSelectOption
                                            key={priorityId}
                                            value={priorityId}
                                        >
                                            {jobPriorities[priorityId as keyof typeof jobPriorities]}
                                        </IonSelectOption>
                                    );
                                })}
                            </SeaSelect>
                        </IonCol>
                        {/* <IonCol size="6">
                            <SeaInput
                                label="Person Responsible"
                                name="personResponsible"
                                value={values.personResponsible}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.personResponsible ? errors.personResponsible : ''}
                            />
                        </IonCol> */}
                        <IonCol size="6">
                            <SeaSelectCrewOrContact
                                label="Assigned to"
                                modalTitle="Assigned to"
                                value={assignedTo}
                                setValue={setAssignedTo}
                                crew={users?.byVesselId[vesselId as string]}
                                isSubmitting={isSubmitting}
                                required={true}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaTagsInput
                                label="Job Tags"
                                tags={jobTags}
                                setTags={setJobTags}
                                options={vessel?.possibleTags}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="jobs"
                                field="files"
                            />
                        </IonCol>
                        <IonCol size="12">
                            <div className="more-info-dropdown no-select" onClick={(e) => toggleShowMoreInfo()}>Add more information (optional) <SeaIcon icon={showMoreInfo ? 'moveUp' : 'moveDown'} /></div>
                        </IonCol>

                        <IonGrid className="form-grid" style={{ display: showMoreInfo ? 'block' : 'none', marginBottom: 1 }}>
                            <IonRow>
                                <SeaEquipment
                                    equipmentId={equipmentId}
                                    setEquipmentId={setEquipmentId}
                                    initialLocationId={(itemToUpdate?.locationId) ? itemToUpdate.locationId : ''}
                                    locationId={locationId}
                                    setLocationId={setLocationId}
                                    hasSubmitted={hasSubmitted}
                                    required={false}
                                    columnSize="4"
                                />
                                <IonCol size="4">
                                    <SeaTagsInput
                                        label="Maintenance Tags"
                                        tags={maintenanceTags}
                                        setTags={setMaintenanceTags}
                                        options={vessel?.possibleMaintenanceTags}
                                    />
                                </IonCol>
                                <IonCol size="4">
                                    <SeaDate
                                        label="Due Date"
                                        name="whenDue"
                                        value={values.whenDue}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        error={touched.whenDue ? errors.whenDue : ''}
                                    />
                                </IonCol>
                                <IonCol size="4">
                                    <SeaSelectEmailReminder
                                        label="Set Email Reminder"
                                        name="emailReminder"
                                        value={values.emailReminder}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        error={touched.emailReminder ? errors.emailReminder : ''}
                                    />
                                </IonCol>
                                <IonCol size="4">
                                    <SeaInput
                                        label="Estimated job cost"
                                        name="estimatedCost"
                                        value={values.estimatedCost}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        //type="number"
                                        inputmode="decimal"
                                        prefix="$"
                                        error={touched.estimatedCost ? errors.estimatedCost : ''}
                                    />
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{itemToUpdate ? 'Update Job' : 'Save New Job'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditJobList;

import React, { useEffect, useState, useRef } from 'react';
import { IonSpinner, isPlatform } from '@ionic/react';
import { Directory } from '@capacitor/filesystem';
import { FileOpener } from '@ionic-native/file-opener';
import { pdf } from '@react-pdf/renderer';
import { reportError } from '../../managers/ErrorsManager/ErrorsManager';
import { alertMessage } from '../../managers/AlertManager/AlertManager';
import { convertBlobToBase64, toSafeFilename, writeBlobFile } from '../../lib/files';
import SeaAlert from '../SeaAlert/SeaAlert';
import './SeaPdfGenerator.css';

interface SeaPdfGeneratorProps {
    //show: boolean,
    //setShow: (showAlert: boolean) => void,
    generatingPdf: boolean,
    setGeneratingPdf: (generating: boolean) => void,
    MakePdf: () => JSX.Element | null
    pdfTitle?: string,
    requestedFrom: string // Where was this PDF generation requested from?
}

const SeaPdfGenerator: React.FC<SeaPdfGeneratorProps> = ({ generatingPdf, setGeneratingPdf, MakePdf, pdfTitle, requestedFrom }) => {
    const [showAlert, setShowAlert] = useState(false);
    const [pdfReady, setPdfReady] = useState(false); // Only useful for desktop (not hybrid)
    const [pdfBase64, setPdfBase64] = useState<any>(undefined);
    const showRef = useRef(false);

    useEffect(() => {
        if (generatingPdf) {
            setShowAlert(true);
            showRef.current = true;
        }
    }, [generatingPdf]);

    useEffect(() => {
        showRef.current = showAlert;
    }, [showAlert]);

    useEffect(() => {
        if (generatingPdf && MakePdf && MakePdf()) {

            setPdfReady(false);
            setPdfBase64(undefined);
            pdf(<MakePdf/>).toBlob().catch((e) => {
                throw e;
            }).then((blob) => {
                if (!showRef.current) return Promise.reject('cancelled');
                if (isPlatform('hybrid')) {
                    return writeBlobFile(
                        blob,
                        `${toSafeFilename(pdfTitle)}.pdf`,
                        Directory.Cache
                    ).catch((error) => {
                        console.log(`Failed to write blob!`, error);
                        alertMessage(`Failed to write blob! ${error.message}`);
                    }).then((uri: string | void) => {
                        if (!showRef.current) return Promise.reject('cancelled');
                        setGeneratingPdf(false);
                        setShowAlert(false);
                        if (uri) {
                            return FileOpener.open(uri, 'application/pdf');
                        } else {
                            alertMessage(`Failed to open PDF. File not found.`);
                        }
                    });
                } else { // not hybrid
                    return convertBlobToBase64(blob).then((base64) => {
                        if (!showRef.current) return Promise.reject('cancelled');
                        setPdfBase64(base64);
                    });
                }
            }).then(() => {
                if (!showRef.current) return Promise.reject('cancelled');
                setPdfReady(true);
                if (isPlatform('hybrid')) {
                    setGeneratingPdf(false);
                    setShowAlert(false);
                }
                console.log('PDF ready');
            }).catch((error) => {
                if (error && error === 'cancelled') {
                    console.log('PDF generation cancelled');
                    setGeneratingPdf(false);
                    setShowAlert(false);
                } else {
                    reportError(`Failed to generate PDF from ${requestedFrom} ${pdfTitle}`, error.message, error);
                }
            }).finally(() => {
                setGeneratingPdf(false);
            });
        }
    }, [generatingPdf, MakePdf]);

    return (
        <SeaAlert
            showAlert={showAlert}
            setShowAlert={setShowAlert}
            type="pdf"
        >
            {generatingPdf &&
                <>
                    <IonSpinner name="crescent"
                        style={{
                            color: 'var(--ion-color-primary)',
                            width: '64px',
                            height: '64px'
                        }}
                    />
                    <br/>
                    <p>
                    Generating PDF, please wait...
                    </p>
                </>
            }
            {!generatingPdf && pdfReady && !isPlatform('hybrid') &&
                <>
                    PDF successfully generated.
                    <br/>
                    {/* Please use the following link...
                    <br/> */}
                    <p style={{ marginTop: '16px' }}>
                        <a
                            href={pdfBase64}
                            download={`${pdfTitle}.pdf`}
                            style={{
                                fontWeight: 500,
                                fontSize: '18px'
                            }}
                        >
                            Download <b>
                                {`${pdfTitle}.pdf`}
                            </b>
                        </a>
                    </p>
                </>
            }
        </SeaAlert>
    );
};

export default SeaPdfGenerator;

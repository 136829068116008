import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { SharedStateConfig, sharedState } from "../shared-state";
import { canView } from "../../shared-state/Core/userPermissions";
import { getDayOffsetMillis, warnDays } from "../../lib/util";
import { SafetyEquipmentItem } from "../VesselSafety/safetyEquipmentItems";
import { registerFiles } from "../FileSyncSystem/filesToCache";

export type DashboardSafetyEquipmentItemsData = {
    byId: {
        [id: string]: SafetyEquipmentItem
    },
    top5: SafetyEquipmentItem[],
    numHighestPriority: number
};

export const dashboardSafetyEquipmentItemsConfig: SharedStateConfig<DashboardSafetyEquipmentItemsData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId'],
    countLiveDocs: () => sharedState.dashboardSafetyEquipmentItems.current?.numHighestPriority ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;

        if (vesselId && canView('safetyEquipmentList')) {
            return onSnapshot(
                query(
                    collection(firestore, 'safetyEquipmentItems'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'active'),
                    where('whenDue', '<', getDayOffsetMillis(warnDays.safetyEquipmentExpiries[0])),
                    orderBy('whenDue', 'asc')
                ),
                (snap) => {
                    done();
                    const byId: { [id: string]: SafetyEquipmentItem } = {};
                    let upcoming = snap.docs.map((doc) => {
                        const item = {
                            id: doc.id,
                            ...doc.data(),
                        } as SafetyEquipmentItem;
                        byId[doc.id] = item;
                        registerFiles(item.files, 'safetyEquipmentItems', item);
                        return item;
                    });

                    set({
                        byId,
                        top5: upcoming.slice(0, 5),
                        numHighestPriority: upcoming.length,
                    });
                },
                (error) => {
                    done();
                    console.log(`Failed to access safety equipment expiries for vessel ${vesselId} on vessel dashboard`, error);
                }
            );
        } else {
            done();
        }
    },
};
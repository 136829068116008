import React, { useState, useMemo, useCallback } from 'react';
import { canEdit } from '../../../../shared-state/Core/userPermissions';
import { pulseElementById, pulseElementsByClassName, haveObjectsChanged, getBasedOnBackgroundColour } from '../../../../lib/util';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { doc } from "firebase/firestore";
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { sharedState } from '../../../../shared-state/shared-state';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { Action, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import { LicenseeSettings, RiskRegisterSettings } from '../../../../shared-state/Core/licenseeSettings';
import { ControlType } from '../EditRiskAssessment/EditRiskAssessment';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaModalScrollableArea from '../../../../components/SeaModalScrollableArea/SeaModalScrollableArea';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaLabel from '../../../../components/SeaLabel/SeaLabel';
import SeaColour from '../../../../components/SeaColour/SeaColour';
import './EditRiskMatrix.css';

interface EditRiskMatrixProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}

const EditRiskMatrix: React.FC<EditRiskMatrixProps> = ({showModal, setShowModal}) => {
    const licenseeId = sharedState.licenseeId.use(showModal);
    const userId = sharedState.userId.use(showModal);
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);
    const [data, setData] = useState<Pick<RiskRegisterSettings, 'consequences' | 'likelihoods' | 'matrix'>>();
    const [evaluationDescriptions, setEvaluationDescriptions] = useState<{
        [key: string]: string
    }>();
    const [editCategory, setEditCategory] = useState({
        showModal: false,
        letter: '',
        id: -1,
        type: '',
        title: '',
        action: '',
        name: '',
        description: '',
        initialValues: {
            name: '',
            description: ''
        }
    });
    const [editCell, setEditCell] = useState({
        showModal: false,
        rowId: 0,
        columnId: 0,
        name: '',
        color: 'ffffff',
        initialValues: {
            name: '',
            color: 'ffffff'
        }
    });
    const [editEvaluation, setEditEvaluation] = useState({
        showModal: false,
        id: '',
        name: '',
        color: 'ffffff',
        description: '',
        initialDescription: ''
    });

    const colorSwatches = useMemo(() => {
        const map: {
            [key: string]: boolean
        } = {};
        const array = [];
        if (
            data?.likelihoods?.rows && data.likelihoods.rows.length > 0 &&
            data?.consequences.columns.length > 0
        ) {
            for (let i = 0; i < data?.likelihoods.rows.length; i++) {
                for (let j = 0; j < data?.consequences.columns.length; j++) {
                    const color = '#'+data?.matrix[`${data.likelihoods.rows[i]}-${data.consequences.columns[j]}`].substring(0,6);
                    if (color && map[color] === undefined) {
                        map[color] = true;
                        array.push(color);
                    }
                }
            }
        }
        ['#92d051','#fdf054','#fbbe43','#fb4322','#9bc2e6', '#eeeeee', '#ffffff'].forEach((color) => {
            if (map[color] === undefined) {
                map[color] = true;
                array.push(color);
            }
        });
        return array;
    }, [data]);

    const evaluations = useMemo(() => {
        const byId: {
            [key: string]: {
                colors: {
                    [key: string]: number
                },
                names: {
                    [key: string]: number
                }
            }
        } = {};
        const ids = [] as string[];
        if (
            data?.matrix &&
            data.likelihoods?.rows.length > 0 &&
            data.consequences?.columns.length > 0
        ) {
            // Gather various color + name evaluations
            for (let i = data.likelihoods.rows.length - 1; i >= 0; i--) { // likelihoods in reverse order
                const likelihoodId = data.likelihoods.rows[i];
                for (let j = 0; j < data.consequences.columns.length; j++) { // consequences in forward order
                    const consequenceId = data.consequences.columns[j];
                    const raw = data.matrix[`${likelihoodId}-${consequenceId}`];
                    if (raw) {
                        const color = raw.substring(0, 6);
                        const name = raw.substring(6).trim();
                        const id = name.toLowerCase();
                        if (byId[id] === undefined) {
                            byId[id] = {
                                colors: {},
                                names: {}
                            };
                            ids.push(id);
                        }
                        const o = byId[id];
                        if (o.names[name] === undefined) {
                            o.names[name] = 0;
                        }
                        o.names[name]++;
                        if (o.colors[color] === undefined) {
                            o.colors[color] = 0;
                        }
                        o.colors[color]++;
                    }
                }
            }

            // Process color + name evaluations into final array
            const evaluations: {
                id: string,
                name: string,
                color: string
            }[] = [];
            ids.forEach((id: string) => {
                let bestName = '';
                let bestNameCount = 0;
                const o = byId[id];
                Object.keys(o.names).forEach((name) => {
                    if (o.names[name] > bestNameCount) {
                        bestNameCount = o.names[name];
                        bestName = name;
                    }
                });
                let bestColor = 'ffffff';
                let bestColorCount = 0;
                Object.keys(o.colors).forEach((color) => {
                    if (o.colors[color] > bestColorCount) {
                        bestColorCount = o.colors[color];
                        bestColor = color;
                    }
                });
                evaluations.push({
                    id: id,
                    name: bestName,
                    color: bestColor
                });
            });

            return evaluations;
        }
        return undefined;
    }, [data]);

    const onOpened = () => {
        // const _data = {
        //     likelihoods: {},
        //     consequences: {},
        //     matrix: {}
        // };
        if (!licenseeSettings?.riskRegister) {
            return;
        }
        setData({
            likelihoods: {
                ...licenseeSettings?.riskRegister.likelihoods
            },
            consequences: {
                ...licenseeSettings?.riskRegister.consequences
            },
            matrix: {
                ...licenseeSettings?.riskRegister.matrix
            }
        });
        const descriptions: {
            [key: string]: string
        } = {};
        licenseeSettings?.riskRegister.evaluations?.forEach((item) => {
            descriptions[item.name.toLowerCase()] = item.description;
        });
        setEvaluationDescriptions(descriptions);
    };

    const onMoveItem = (type: 'likelihoods' | 'consequences', arrayName: string, letter: string, itemId: number, direction: number) => {
        if (!data) {
            return;
        }
        const _data = {...data};
        const newArray = [...data?.[type][arrayName] as number[]];
        for (let i = 0; i < newArray.length; i++) {
            if (newArray[i] === itemId) {
                const dummy = newArray[i];
                newArray[i] = newArray[i + direction];
                newArray[i + direction] = dummy;
                break;
            }
        }
        pulseElementsByClassName(`rMx${letter}${itemId}`);
        _data[type][arrayName] = newArray;
        setData(_data);
    };

    const onRemoveItem = (name: string, type: 'likelihoods' | 'consequences' | 'matrix', arrayName: string, selectedId: number) => {
        confirmAction(
            `Are you sure you want to remove this ${name}?`,
            `Yes, remove ${name}`,
            'Cancel'
        ).then(() => {
            if (!data) {
                return;
            }
            const _data = {...data};
            const newArray = [] as number[];
            const items = data?.[type][arrayName];
            if (Array.isArray(items)) {
                items.forEach((id: number) => {
                    if (id !== selectedId) {
                        newArray.push(id);
                    }
                });
            }
            _data[type][arrayName] = newArray;
            setData(_data);
        }).catch(() => {});
    };

    const onAddLikelihood = () => {
        setEditCategory({
            showModal: true,
            letter: 'L',
            id: -1,
            type: 'Likelihood',
            title: 'Add Likelihood Category',
            action: 'Add Likelihood',
            name: '',
            description: '',
            initialValues: {
                name: '',
                description: ''
            }
        });
    };

    const onEditLikelihood = (rowId: number, name: string, description: string) => {
        setEditCategory({
            showModal: true,
            letter: 'L',
            id: rowId,
            type: 'Likelihood',
            title: 'Edit Likelihood Category',
            action: 'Update Likelihood',
            name: name,
            description: description,
            initialValues: {
                name: name,
                description: description
            }
        });
    };

    const onAddConsequence = () => {
        setEditCategory({
            showModal: true,
            letter: 'C',
            id: -1,
            type: 'Consequence',
            title: 'Add Consequence Category',
            action: 'Add Consequence',
            name: '',
            description: '',
            initialValues: {
                name: '',
                description: ''
            }
        });
    };

    const onEditConsequence = (columnId: number, name: string, description: string) => {
        setEditCategory({
            ...editCategory,
            showModal: true,
            letter: 'C',
            id: columnId,
            type: 'Consequence',
            title: 'Edit Consequence Category',
            action: 'Update Consequence',
            name: name,
            description: description,
            initialValues: {
                name: name,
                description: description
            }
        });
    };

    const onEditMatrixCell = (rowId: number, columnId: number, name: string, color: string) => {
        setEditCell({
            showModal: true,
            rowId,
            columnId,
            name,
            color,
            initialValues: {
                name,
                color
            }
        });
    };

    const onEditEvaluation = (evaluationId: string, color: string, name: string) => {
        const description = (evaluationDescriptions && evaluationDescriptions[evaluationId]) ? evaluationDescriptions[evaluationId] : ''
        setEditEvaluation({
            showModal: true,
            id: evaluationId,
            color: color,
            name: name,
            description: description,
            initialDescription: description
        });
    };

    const onSaveMatrix = () => {

        if (!licenseeId) {
            throw new Error('No licenseeId');
        }
        if (!data) {
            throw new Error('No data');
        }
        // Cleanup data first
        const cleanedData = {
            likelihoods: {
                rows: data.likelihoods.rows
            },
            consequences: {
                columns: data.consequences.columns
            },
            matrix: {}
        } as Pick<RiskRegisterSettings, 'likelihoods' | 'consequences' | 'matrix'>;
        // Check all likelihood and consequence keys and make sure they exist either in rows / columns or original data
        const addCategoryData = (type: 'likelihoods' | 'consequences', letter: string, arrayName: string) => {
            if (!data[type]) {
                return;
            }
            Object.keys(data[type]).forEach((key: string) => {
                if (key[0] === letter) {
                    if (
                        (data[type][arrayName] as number[]).indexOf(parseInt(key.substring(1))) !== -1 ||
                        licenseeSettings?.riskRegister[type][key]
                    ) {
                        cleanedData[type][key] = data[type][key];
                    }
                }
            });
        }
        addCategoryData('likelihoods', 'L', 'rows');
        addCategoryData('consequences', 'C', 'columns');

        Object.keys(data?.matrix).forEach((key: string) => {
            const rowId = parseInt(key.substring(0, key.indexOf('-')));
            const columnId = parseInt(key.substring(key.indexOf('-') + 1));
            if (
                (data.likelihoods.rows.indexOf(rowId) !== -1 && data.consequences.columns.indexOf(columnId) !== -1) ||
                licenseeSettings?.riskRegister.matrix[key]
            ) {
                cleanedData.matrix[key] = data.matrix[key];
            }
        });

        const preparedEvaluations: {
            name: string;
            color: string;
            description?: string
        }[] = [];
        evaluations?.forEach((evaluation) => {
            preparedEvaluations.push({
                name: evaluation.name,
                color: evaluation.color,
                description: evaluationDescriptions?.[evaluation.id]
            });
        });

        const action = traceAction('licenseeSettings', 'update') as Action;
        action.docId = licenseeId;
        action.data = {
            cleanedData,
            preparedEvaluations
        };
        const batch = splittableBatch(firestore, 20 - 0);
        batch.set(
            doc(firestore, 'licenseeSettings', licenseeId),
            {
                riskRegister: {
                    ...cleanedData,
                    evaluations: preparedEvaluations
                },
                whenUpdated: action.whenAction,
                updatedBy: userId
            } as LicenseeSettings,
            { merge: true }
        );

        action.save(`Save risk matrix`, batch);
        batch.commit().then(() => {
            action.reportSuccess();
        }).catch((error) => {
            action.reportError(error.message, error);
        });

        setShowModal(false);
    };

    const isModalDirty = useCallback(() => {
        return (
            haveObjectsChanged(data?.likelihoods, licenseeSettings?.riskRegister?.likelihoods) ||
            haveObjectsChanged(data?.consequences, licenseeSettings?.riskRegister?.consequences) ||
            haveObjectsChanged(data?.matrix, licenseeSettings?.riskRegister?.matrix)
        );
    }, [licenseeSettings, data]);

    const isEditCategoryModalDirty = useCallback(() => {
        return (
            editCategory.name !== editCategory.initialValues.name ||
            editCategory.description !== editCategory.initialValues.description
        );
    }, [editCategory]);

    const isEditCellModalDirty = useCallback(() => {
        return (
            editCell.name !== editCell.initialValues.name ||
            editCell.color !== editCell.initialValues.color
        );
    }, [editCell]);

    const isEditEvaluationModalDirty = useCallback(() => {
        return (
            editEvaluation.description !== editEvaluation.initialDescription
        );
    }, [editEvaluation]);



    return (
        <SeaModal
            title="Edit Risk Matrix"
            showModal={showModal}
            setShowModal={setShowModal}
            size="extra-wide"
            onOpened={onOpened}
            isDirty={isModalDirty}
            level={2}
        >
            <SeaModalScrollableArea>
                {data &&
                    <table className="risk-matrix edit">
                        <tbody>
                            <tr>
                                <td className="no-border"></td>
                                <td
                                    className="type"
                                    colSpan={data.consequences.columns.length}
                                >
                                    Consequence
                                </td>
                            </tr>
                            <tr>
                                <td className="type">
                                    Likelihood
                                </td>
                                {data.consequences.columns.map((columnId: number) => {
                                    const consequence = data.consequences[`C${columnId}`] as ControlType;
                                    return (
                                        <td
                                            key={columnId}
                                            className={`label no-select rMxC${columnId}`}
                                            onClick={(e) => {
                                                onEditConsequence(columnId, consequence.name, consequence.description)
                                            }}
                                        >
                                            {consequence.name}
                                        </td>
                                    );
                                })}
                                <td className="no-border no-select" style={{ whiteSpace: 'nowrap' }}>
                                    <div
                                        className="new-item pushy"
                                        onClick={(e) => onAddConsequence()}
                                        style={{
                                            textAlign: 'left',
                                            paddingLeft: '6px'
                                        }}
                                    >
                                        <div>
                                            <SeaIcon
                                                //slot="icon-only"
                                                icon="add"
                                                marginTop={9}
                                                forceFontSize="28px"
                                            />
                                        </div>
                                        <span>
                                            Add
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            {data.likelihoods.rows.map((rowId: number, rowIndex: number) => {
                                const likelihood = data.likelihoods[`L${rowId}`] as ControlType;
                                return (
                                    <tr key={rowId} className={`rMxL${rowId}`}>
                                        <td
                                            className="label no-select"
                                            onClick={(e) => {
                                                onEditLikelihood(rowId, likelihood.name, likelihood.description)
                                                // openContextualHelp(e, {
                                                //     text: likelihood.description
                                                // });
                                            }}
                                        >
                                            {likelihood.name}
                                        </td>
                                        {data.consequences.columns.map((columnId: number) => {
                                            const raw = data.matrix[`${rowId}-${columnId}`];
                                            const color = raw.substring(0, 6);
                                            const name = raw.substring(7);
                                            return (
                                                <td
                                                    key={columnId}
                                                    id={`rMx${rowId}-${columnId}`}
                                                    className={`rMxC${columnId} no-select`}
                                                    style={{
                                                        backgroundColor: `#${color}`,
                                                        color: getBasedOnBackgroundColour(color)
                                                    }}
                                                    onClick={(e) => onEditMatrixCell(rowId, columnId, name, `#${color}`)}
                                                >
                                                    {name}
                                                </td>
                                            );
                                        })}
                                        <td className="actions no-select">
                                            <div>
                                                <div
                                                    className={`matrix-button ${(rowIndex === 0 ? 'disabled' : 'pushy')}`}
                                                    onClick={(e) => onMoveItem('likelihoods', 'rows', 'L', rowId, -1)}
                                                >
                                                    <SeaIcon
                                                        slot="icon-only"
                                                        icon="moveUp"
                                                        marginTop={9}
                                                        forceFontSize="28px"
                                                    />
                                                </div>
                                                <div
                                                    className={`matrix-button ${(rowIndex === (data.likelihoods.rows.length - 1) ? 'disabled' : 'pushy')}`}
                                                    onClick={(e) => onMoveItem('likelihoods', 'rows', 'L', rowId, 1)}
                                                >
                                                    <SeaIcon
                                                        slot="icon-only"
                                                        icon="moveDown"
                                                        marginTop={9}
                                                        forceFontSize="28px"
                                                    />
                                                </div>
                                                <div
                                                    className="matrix-button pushy"
                                                    onClick={(e) => onRemoveItem('row', 'likelihoods', 'rows', rowId)}
                                                >
                                                    <SeaIcon
                                                        slot="icon-only"
                                                        icon="trash"
                                                        marginTop={11}
                                                        forceFontSize="22px"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td className="no-border no-select" style={{ whiteSpace: 'nowrap' }}>
                                    <div
                                        className="new-item pushy"
                                        onClick={(e) => onAddLikelihood()}
                                    >
                                        <div>
                                            <SeaIcon
                                                //slot="icon-only"
                                                icon="add"
                                                marginTop={9}
                                                forceFontSize="28px"
                                            />
                                        </div>
                                        <span>
                                            Add
                                        </span>
                                    </div>
                                </td>
                                {data.consequences.columns.map((columnId: number, columnIndex: number) => {
                                    return (
                                        <td key={columnId} className={`actions no-select rMxC${columnId}`}>
                                            <div>
                                                <div
                                                    className={`matrix-button ${(columnIndex === 0 ? 'disabled' : 'pushy')}`}
                                                    onClick={(e) => onMoveItem('consequences', 'columns', 'C', columnId, -1)}
                                                >
                                                    <SeaIcon
                                                        slot="icon-only"
                                                        icon="moveLeft"
                                                        marginTop={9}
                                                        forceFontSize="28px"
                                                    />
                                                </div>
                                                <div
                                                    className="matrix-button pushy"
                                                    onClick={(e) => onRemoveItem('column', 'consequences', 'columns', columnId)}
                                                >
                                                    <SeaIcon
                                                        slot="icon-only"
                                                        icon="trash"
                                                        marginTop={11}
                                                        forceFontSize="22px"
                                                    />
                                                </div>
                                                <div
                                                    className={`matrix-button ${(columnIndex === (data.consequences.columns.length - 1) ? 'disabled' : 'pushy')}`}
                                                    onClick={(e) => onMoveItem('consequences', 'columns', 'C', columnId, 1)}
                                                >
                                                    <SeaIcon
                                                        slot="icon-only"
                                                        icon="moveRight"
                                                        marginTop={9}
                                                        forceFontSize="28px"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        </tbody>
                    </table>
                }
            </SeaModalScrollableArea>


            <div style={{ height: '40px' }}>
            </div>
            <SeaModalScrollableArea>
                {data &&
                    <table className="risk-matrix evaluations edit">
                        <tbody>
                            <tr>
                                <td className="label">Risk Evaluation</td>
                                <td className="label">Description</td>
                            </tr>
                            {evaluations?.map((evaluation) => {
                                return (
                                    <tr key={evaluation.id}>
                                        <td
                                            className="name"
                                            style={{
                                                backgroundColor: `#${evaluation.color}`,
                                                color: getBasedOnBackgroundColour(evaluation.color)
                                            }}
                                        >
                                            {evaluation.name}
                                        </td>
                                        <td onClick={(e) => onEditEvaluation(evaluation.id, evaluation.color, evaluation.name)}>
                                            {evaluationDescriptions && evaluationDescriptions[evaluation.id]}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                }
            </SeaModalScrollableArea>


            <div className="view-modal-buttons">
                {canEdit('hazardRegister') &&
                    <SeaButton
                        zone="white"
                        onClick={(e) => onSaveMatrix()}
                    >
                        Save Changes
                    </SeaButton>
                }
            </div>
            <SeaModal
                title={editCategory.title}
                showModal={editCategory.showModal}
                setShowModal={(showModal: boolean) => setEditCategory({...editCategory, showModal: showModal})}
                size="thin"
                isDirty={isEditCategoryModalDirty}
                level={3}
            >
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaInput
                                name="categoryName"
                                label={editCategory.type}
                                value={editCategory.name}
                                onchange={(e) => {
                                    setEditCategory({
                                        ...editCategory,
                                        name: e.detail.value,
                                    });
                                }}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                name="categoryDescription"
                                label="Description"
                                value={editCategory.description}
                                onchange={(e) => {
                                    setEditCategory({
                                        ...editCategory,
                                        description: e.detail.value
                                    });
                                }}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div style={{ height: '60px' }}></div>
                <SeaButton zone="white" onClick={(e) => {
                    const config: {
                        type: 'likelihoods' | 'consequences',
                        arrayName: 'rows' | 'columns'
                    } = (editCategory.letter === 'L') ? {
                        type: 'likelihoods',
                        arrayName: 'rows'
                    } : {
                        type: 'consequences',
                        arrayName: 'columns'
                    };

                    if (editCategory.id === -1) { // Add new likelihood/consequence
                        if (!data) {
                            throw new Error('No data');
                        }
                        let newId = 0;
                        Object.keys(data[config.type]).forEach((key) => {
                            if (key.startsWith(editCategory.letter)) {
                                const id = parseInt(key.substring(1));
                                if (id > newId) {
                                    newId = id;
                                }
                            }
                        });
                        newId++;
                        const newTypeData = {
                            ...data[config.type]
                        };
                        newTypeData[config.arrayName] = [...newTypeData[config.arrayName] as number[], newId];
                        newTypeData[`${editCategory.letter}${newId}`] = {
                            name: editCategory.name.trim(),
                            description: editCategory.description.trim()
                        };
                        const newMatrix = {...data.matrix};

                        if (editCategory.letter === 'L') { // Fill likelihood row cells with defaults
                            for (let i = 0; i < data.consequences.columns.length; i++) {
                                newMatrix[`${newId}-${data.consequences.columns[i]}`] = 'ffffff ';
                            }
                        } else { // Fill consequence column cells with defaults
                            for (let i = 0; i < data.likelihoods.rows.length; i++) {
                                newMatrix[`${data.likelihoods.rows[i]}-${newId}`] = 'ffffff ';
                            }
                        }

                        const newData: Pick<RiskRegisterSettings, 'consequences' | 'likelihoods' | 'matrix'> = {
                            ...data,
                            matrix: newMatrix
                        };
                        if (config.type === 'likelihoods') {
                            newData.likelihoods = newTypeData as RiskRegisterSettings['likelihoods'];
                        }
                        if (config.type === 'consequences') {
                            newData.consequences = newTypeData as RiskRegisterSettings['consequences'];
                        }
                        setData(newData);
                        setTimeout(() => {
                            pulseElementsByClassName(`rMx${editCategory.letter}${newId}`, 200);
                        }, 100);
                    } else { // Update likelihood/consequence
                        if (!data) {
                            throw new Error('No data');
                        }
                        const newTypeData = {
                            ...data[config.type]
                        };
                        newTypeData[`${editCategory.letter}${editCategory.id}`] = {
                            name: editCategory.name.trim(),
                            description: editCategory.description.trim()
                        };
                        const newData = {...data};
                        if (config.type === 'likelihoods') {
                            newData.likelihoods = newTypeData as RiskRegisterSettings['likelihoods'];
                        }
                        if (config.type === 'consequences') {
                            newData.consequences = newTypeData as RiskRegisterSettings['consequences'];
                        }
                        setData(newData);
                        pulseElementsByClassName(`rMx${editCategory.letter}${editCategory.id}`, 200);
                    }

                    setEditCategory({
                        ...editCategory,
                        showModal: false
                    });
                }}>
                    {editCategory.action}
                </SeaButton>
            </SeaModal>
            <SeaModal
                title="Edit Risk Factor"
                showModal={editCell.showModal}
                setShowModal={(showModal: boolean) => setEditCell({...editCell, showModal: showModal})}
                size="thin"
                isDirty={isEditCellModalDirty}
                level={3}
            >
                <IonGrid className="form-grid">
                    <IonRow>
                        {/* <IonCol size="12">
                            <SeaInput
                                label="Consequence"
                                value={data?.consequences[`C${editCell.columnId}`]?.name}
                                disabled={true}
                            />
                        </IonCol> */}
                        <IonCol size="12">
                            <SeaLabel>Consequence</SeaLabel>
                            {(data?.consequences[`C${editCell.columnId}`] as ControlType | undefined)?.name}
                            {(data?.consequences[`C${editCell.columnId}`] as ControlType | undefined)?.description &&
                                <p>
                                    {(data?.consequences[`C${editCell.columnId}`] as ControlType | undefined)?.description}
                                </p>
                            }
                        </IonCol>
                        {/* <IonCol size="12">
                            <SeaInput
                                label="Likelihood"
                                value={data?.likelihoods[`L${editCell.rowId}`]?.name}
                                disabled={true}
                            />
                        </IonCol> */}
                        <IonCol size="12">
                            <SeaLabel>Likelihood</SeaLabel>
                            {(data?.likelihoods[`L${editCell.rowId}`] as ControlType | undefined)?.name}
                            {(data?.likelihoods[`L${editCell.rowId}`] as ControlType | undefined)?.description &&
                                <p>
                                    {(data?.likelihoods[`L${editCell.rowId}`] as ControlType | undefined)?.description}
                                </p>
                            }
                        </IonCol>
                        <IonCol size="12">
                            <SeaInput
                                name="categoryName"
                                label="Risk Factor"
                                value={editCell.name}
                                onchange={(e) => {
                                    setEditCell({
                                        ...editCell,
                                        name: e.detail.value,
                                    });
                                }}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaColour
                                label="Colour"
                                color={editCell.color}
                                setColor={(color: string) => setEditCell({
                                    ...editCell,
                                    color: color
                                })}
                                colorSwatches={colorSwatches}
                            />
                        </IonCol>

                    </IonRow>
                </IonGrid>
                <div style={{ height: '60px' }}></div>
                <SeaButton zone="white" onClick={(e) => {
                    if (!data) {
                        throw new Error('No data');
                    }
                    const newMatrix = {...data.matrix};
                    newMatrix[`${editCell.rowId}-${editCell.columnId}`] = `${editCell.color.substring(1)} ${editCell.name.trim()}`;
                    setData({
                        ...data,
                        matrix: newMatrix
                    });
                    setEditCell({
                        ...editCell,
                        showModal: false
                    });
                    pulseElementById(`rMx${editCell.rowId}-${editCell.columnId}`, 200);
                }}>
                    Update Risk Factor
                </SeaButton>
            </SeaModal>
            <SeaModal
                title="Edit Evaluation Description"
                showModal={editEvaluation.showModal}
                setShowModal={(showModal: boolean) => setEditEvaluation({...editEvaluation, showModal: showModal})}
                size="thin"
                isDirty={isEditEvaluationModalDirty}
                level={3}
            >
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaLabel>Risk Evaluation</SeaLabel>
                            <div
                                className="sea-input risk-rating no-select"
                                style={{
                                    backgroundColor: `#${editEvaluation.color}`
                                }}
                            >
                                <div style={{
                                    color: getBasedOnBackgroundColour(editEvaluation.color)
                                }}>
                                    {editEvaluation.name}
                                </div>
                            </div>
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                name="evaluationDescription"
                                label="Description"
                                value={editEvaluation.description}
                                onchange={(e) => {
                                    setEditEvaluation({
                                        ...editEvaluation,
                                        description: e.detail.value,
                                    });
                                }}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div style={{ height: '60px' }}></div>
                <SeaButton zone="white" onClick={(e) => {
                    setEvaluationDescriptions((current) => {
                        const descriptions = {...current};
                        descriptions[editEvaluation.id] = editEvaluation.description;
                        return descriptions;
                    });
                    setEditEvaluation({
                        ...editEvaluation,
                        showModal: false
                    });
                }}>
                    Update Description
                </SeaButton>
            </SeaModal>
        </SeaModal>
    );
};

export default EditRiskMatrix;

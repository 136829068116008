import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, toMillis, formatSeaDate, subtractInterval, preventMultiTap } from '../../../../lib/util';
import { logAction } from '../../../../shared-state/General/actionLog';
import { CategoriesData, getDefaultCategoryId, makeCategoryId } from '../../../../lib/categories';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { sharedState } from '../../../../shared-state/shared-state';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { Action, reportError, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { saveFileRefs, seaFilesToValue } from '../../../../lib/files';
import { VesselDocument } from '../../../../shared-state/VesselDocuments/vesselDocuments';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaEditDocumentation, { SeaDocumentation, confirmAnyDocumentationLoss, getFilesToUploadForSeaDocumentation, hasSeaDocumentationChanged, makeSeaDocumentation, seaDocumentationToFilesValue, seaDocumentationToSfdocValue } from '../../../../components/SeaEditDocumentation/SeaEditDocumentation';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaSelectEmailReminder from '../../../../components/SeaSelectEmailReminder/SeaSelectEmailReminder';
import SeaSelectInterval from '../../../../components/SeaSelectInterval/SeaSelectInterval';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import SeaSelectCategory from '../../../../components/SeaSelectCategory/SeaSelectCategory';

interface EditVesselDocumentProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    itemToUpdate?: VesselDocument,
    vesselDocumentCategories: CategoriesData
}

const EditVesselDocument: React.FC<EditVesselDocumentProps> = ({showModal, setShowModal, itemToUpdate, level, vesselDocumentCategories}) => {
    const userId = sharedState.userId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [documentation, setDocumentation] = useState<SeaDocumentation>({
        type: 'sfdoc',
        files: [],
        sfdoc: {}
    });

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                title: itemToUpdate.title ? ''+itemToUpdate.title : '',
                type: itemToUpdate.type ? ''+itemToUpdate.type : '',
                whenExpires: itemToUpdate.whenExpires ? formatSeaDate(itemToUpdate.whenExpires) : '',
                interval: itemToUpdate.interval ? ''+itemToUpdate.interval : '',
                emailReminder: itemToUpdate.emailReminder ? ''+itemToUpdate.emailReminder : '',
                categoryId: itemToUpdate.categoryId ? ''+itemToUpdate.categoryId : '',
            };
        } else {
            return {
                title: '',
                type: 'renewable',
                whenExpires: '',
                interval: '',
                emailReminder: '',
                categoryId: getDefaultCategoryId('General', vesselDocumentCategories)
            };
        }
    }, [itemToUpdate, vesselDocumentCategories]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setDocumentation(makeSeaDocumentation(itemToUpdate));
    }

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            title: Yup.string().max(500).required(),
            type: Yup.string().max(500).required(),
            whenExpires: Yup.date().when('type', {is: 'renewable', then: (schema) => schema.required().min(...notTooOld)}),
            interval: Yup.string().when('type', {is: 'renewable', then: (schema) => schema.max(4).required()}),
            emailReminder: Yup.string().when('type', {is: 'renewable', then: (schema) => schema.max(200)}),
            categoryId: Yup.string().max(500).required()
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('vesselDocument')) { return; }
            confirmAnyDocumentationLoss(documentation, makeSeaDocumentation(itemToUpdate), confirmAction).then(() => {
                const filesToUpload = getFilesToUploadForSeaDocumentation(documentation);
                uploadFiles(filesToUpload).then(() => {
                    if (!vesselId) {
                        throw new Error('vesselId is not defined');
                    }
                    // Process form
                    const action = traceAction('vesselDocuments') as Action;
                    const batch = splittableBatch(firestore, 20 - 0);
                    let whenToRemind = undefined;
                    if (data.type === 'renewable' && data.whenExpires && data.emailReminder) {
                        whenToRemind = subtractInterval(data.whenExpires, data.emailReminder);
                    };
                    const filesValue = seaDocumentationToFilesValue(documentation);
                    const sfdocValue = seaDocumentationToSfdocValue(documentation);
                    if (itemToUpdate) {
                        action.type = 'update';
                        action.docId = itemToUpdate.id;
                        batch.set(
                            doc(firestore, 'vesselDocuments', itemToUpdate.id),
                            {
                                updatedBy: userId,
                                whenUpdated: action.whenAction,
                                title: data.title,
                                type: data.type,
                                categoryId: makeCategoryId(
                                    data.categoryId,
                                    vesselDocumentCategories,
                                    deleteValue,
                                    batch,
                                    'vesselDocumentCategories',
                                    'vesselId',
                                    vesselId,
                                    {}
                                ),
                                whenExpires: (data.type === 'renewable' && data.whenExpires) ? toMillis(data.whenExpires) : deleteValue,
                                emailReminder: (data.type === 'renewable' && data.emailReminder) ? data.emailReminder : deleteValue,
                                interval: (data.type === 'renewable' && data.interval) ? data.interval : deleteValue,
                                whenToRemind: whenToRemind ? whenToRemind : deleteValue,
                                files: filesValue ? filesValue : deleteValue,
                                sfdoc: sfdocValue ? sfdocValue : deleteValue,
                                touched: serverTimestamp(),
                            },
                            { merge: true }
                        );

                        saveFileRefs(batch, filesToUpload, 'vesselDocuments', itemToUpdate.id);
                        logAction(
                            batch,
                            'Update',
                            'vesselDocuments',
                            itemToUpdate.id,
                            data.title,
                            [itemToUpdate.vesselId]
                        );
                    } else {
                        const newRef = doc(collection(firestore, 'vesselDocuments'));
                        action.type = 'create';
                        action.docId = newRef.id;
                        batch.set(newRef, {
                            vesselId: vesselId,
                            addedBy: userId,
                            whenAdded: action.whenAction,
                            title: data.title,
                            type: data.type,
                            categoryId: makeCategoryId(
                                data.categoryId,
                                vesselDocumentCategories,
                                undefined,
                                batch,
                                'vesselDocumentCategories',
                                'vesselId',
                                vesselId,
                                {}
                            ),
                            whenExpires: (data.type === 'renewable' && data.whenExpires) ? toMillis(data.whenExpires) : undefined,
                            emailReminder: (data.type === 'renewable' && data.emailReminder) ? data.emailReminder : undefined,
                            interval: (data.type === 'renewable' && data.interval) ? data.interval : undefined,
                            whenToRemind: whenToRemind,
                            state: 'active',
                            files: filesValue ? filesValue : undefined,
                            sfdoc: sfdocValue ? sfdocValue : undefined,
                            touched: serverTimestamp(),
                        });

                        saveFileRefs(batch, filesToUpload, 'vesselDocuments', newRef.id);
                        logAction(
                            batch,
                            'Add',
                            'vesselDocuments',
                            newRef.id,
                            data.title,
                            [vesselId]
                        );
                    }

                    onCollectionUpdated(batch, 'vesselDocuments');

                    action.data = {
                        data,
                        files: filesValue,
                        sfdoc: sfdocValue,
                        itemToUpdate
                    };
                    action.save(`${itemToUpdate ? 'Update' : 'Add'} vessel document ${data.title}`, batch);
                    batch.commit().then(() => {
                        action.reportSuccess();
                    }).catch((error) => {
                        action.reportError(error.message, error);
                    });

                    setShowModal(false);
                }).catch((error: any) => {
                    if (!handleUploadError(error)) {
                        reportError(`Failed to upload vessel Documents files`, error.message, error, {
                            filesToUpload: seaFilesToValue(filesToUpload),
                            data,
                            itemToUpdate
                        });
                    }
                });
            }).catch(() => {
                // Ignore cancellation of documentation loss
            });
        }
    });
    
    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            hasSeaDocumentationChanged(documentation, makeSeaDocumentation(itemToUpdate))
        );
    }, [values, initialValues, documentation, itemToUpdate]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Vessel Document' : 'Add New Vessel Document'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={level}
            size="wide"
            confirmDismissDirty={{
                title: "Are you sure you want to leave this form without saving first?",
                no: "Cancel",
                yes: "Yes, leave form"
            }}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                          	<SeaInput
								label="Document Title"
								name="title"
								value={values.title}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.title ? errors.title : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
							<SeaSelect
                                //disabled={itemToUpdate ? true : false}
                                label="Document Type"
                                name="type"
                                value={values.type}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.type ? errors.type : ''}
                            >
                                <IonSelectOption value="renewable">Renewable Document</IonSelectOption>
                                <IonSelectOption value="nonExpiring">Non-Expiring Document</IonSelectOption>
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelectCategory
                                categories={vesselDocumentCategories}
                                label="Category"
                                name="categoryId"
                                initialCategoryId={initialValues.categoryId}
                                categoryId={values.categoryId}
                                otherPlaceholder="Add New Category"
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.categoryId ? errors.categoryId : ''}
                            />
                        </IonCol>
                        {values.type === 'renewable' && 
                            <>
                                <IonCol size="6">
                                    <SeaDate
                                        label="Expiry Date"
                                        name="whenExpires"
                                        value={values.whenExpires}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        error={touched.whenExpires ? errors.whenExpires : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaSelectInterval
                                        label="Renewal Interval"
                                        name="interval"
                                        value={values.interval}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        error={touched.interval ? errors.interval : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaSelectEmailReminder
                                        label="Set Email Reminder"
                                        name="emailReminder"
                                        value={values.emailReminder}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        error={touched.emailReminder ? errors.emailReminder : ''}
                                    />
                                </IonCol>
                            </>
                        }
                        <IonCol size="12">
                            <SeaEditDocumentation
                                label="Documentation"
                                title={values.title}
                                documentation={documentation}
                                setDocumentation={setDocumentation}
                                collection="vesselDocuments"
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{itemToUpdate ? 'Save Changes' : 'Save Document'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditVesselDocument;

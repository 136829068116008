import React, { useState, useEffect } from 'react';
import { formatValue, formatTextAreaText, subtractInterval, addInterval, formatDatetime, formatVessels } from '../../../../lib/util';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { SplittableBatch, firestore } from '../../../../lib/firebase';
import { doc, WriteBatch } from "firebase/firestore";
import { canEdit, permissionLevels, canAccessAllVessels } from '../../../../shared-state/Core/userPermissions';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { markVesselOverdueStatStale, onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { SafetyMeetingReport } from '../../../../shared-state/HealthSafety/safetyMeetingReports';
import { Job } from '../../../../shared-state/VesselMaintenance/jobs';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import EditHealthSafetyMeeting from '../EditHealthSafetyMeeting/EditHealthSafetyMeeting'
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import ViewJobList from '../../../VesselMaintenance/JobList/ViewJobList/ViewJobList';
import SeaSignatureImage from '../../../../components/SeaSignatureImage/SeaSignatureImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';

interface ViewHealthSafetyMeetingProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem: SafetyMeetingReport
}

const ViewHealthSafetyMeeting: React.FC<ViewHealthSafetyMeetingProps> = ({showModal, setShowModal, selectedItem}) => {
    const safetyMeetingReports = sharedState.safetyMeetingReports.use(showModal);
    const safetyMeetingJobs = sharedState.safetyMeetingJobs.use(showModal);
    const vessels = sharedState.vessels.use(showModal);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewJobModal, setShowViewJobModal] = useState(false);
    const [selectedJob, setSelectedJob] = useState<Job>();

    useEffect(() => {
        if (selectedJob && safetyMeetingJobs?.byId[selectedJob.id]) { 
            setSelectedJob(safetyMeetingJobs.byId[selectedJob.id]);
        };
    }, [safetyMeetingJobs]); // eslint-disable-line react-hooks/exhaustive-deps

    const onViewJob = (e: React.MouseEvent, job: Job) => {
        e.stopPropagation();
        setShowViewJobModal(true);
        setSelectedJob(job)
    };

    const onEditItem = () => {
        setShowEditItemModal(true);
    };

    const onDeleteItem = () => {
        deleteIfConfirmed(
            'safetyMeetingReports',
            selectedItem.id,
            (batch: WriteBatch | SplittableBatch) => {
                selectedItem.vesselIds.forEach((vesselId: string) => {
                    onCollectionUpdated(batch, 'safetyMeetingReports');
                    markVesselOverdueStatStale(batch, 'safetyMeetings', vesselId);

                    // If we're deleting the most recent meeting, we'll need to update safetyMeetingSettings.whenDue and whenToRemind
                    if (
                        safetyMeetingReports?.byVesselId &&
                        safetyMeetingReports.byVesselId[vesselId] &&
                        safetyMeetingReports.byVesselId[vesselId][0] &&
                        safetyMeetingReports.byVesselId[vesselId][0].id === selectedItem.id
                    ) {
                        const vessel = vessels?.byId[vesselId];
                        const latestMeeting = safetyMeetingReports.byVesselId[vesselId][0];

                        let whenDue;
                        if (safetyMeetingReports.byVesselId[vesselId].length > 1) {
                            // Set whenDue & whenToRemind using the second most recent meeting
                            const secondMostRecentMeeting = safetyMeetingReports.byVesselId[vesselId][0];
                            whenDue = secondMostRecentMeeting.whenMeeting;
                            if (vessel?.safetyMeetingSettings?.interval) {
                                whenDue = addInterval(
                                    whenDue,
                                    vessel?.safetyMeetingSettings.interval
                                );
                            }
                        } else {
                            // There will be no meetings left, so just set whenDue to latestMeeting.whenMeeting
                            whenDue = latestMeeting.whenMeeting;
                        }

                        let whenToRemind: number | undefined = undefined;
                        if (vessel?.safetyMeetingSettings?.emailReminder) {
                            whenToRemind = subtractInterval(whenDue, vessel.safetyMeetingSettings.emailReminder);
                        }
                        // Save new safetyMeetingSettings.whenDue & whenToRemind here
                        batch.set(
                            doc(firestore, 'vessels', vessel?.id as string),
                            {
                                safetyMeetingSettings: {
                                    whenDue: whenDue,
                                    whenToRemind: whenToRemind
                                }
                            },
                            { merge: true }
                        );
                    }

                });

                setShowModal(false);
            },
            'meeting report',
            selectedItem.notes,
            selectedItem.vesselIds,
            selectedItem.personnelPresentIds
        );
    };

    let hasJobs = false;

    return (
        <SeaModal
            title={'Meeting Report'}
            showModal={showModal}
            setShowModal={setShowModal}
            size="standard"
        >
              <SeaGrid>
                <SeaGridCell label="Date" w="100">
                    {(formatDatetime(selectedItem?.whenMeeting))}
                </SeaGridCell>
                <SeaGridCell label="Vessels / Facilities" w="100">
                    {(formatVessels(selectedItem?.vesselIds, vessels))}
                </SeaGridCell>
                <SeaGridCell label="Personnel Present" w="100">
                    {selectedItem?.personnelPresentIds?.map((crewId: string, index: number) => {
                        if (renderFullNameForUserId(crewId)) {
                            if (index === 0) {
                                return renderFullNameForUserId(crewId)
                            } else {
                                return `, ${renderFullNameForUserId(crewId)}`
                            }
                        } else {
                            return '-'
                        }
                    })}
                </SeaGridCell>
                <SeaGridCell label="Notes from meeting" w="100">
                    {formatValue(formatTextAreaText(selectedItem?.notes))}
                </SeaGridCell>
                <SeaGridCell label="Jobs Created" w="50">
                    {hasJobs = false}
                    {selectedItem?.jobIds && selectedItem.jobIds.map((jobId: string) => {
                        if (safetyMeetingJobs?.byId[jobId]) {
                            hasJobs = true;
                            return <div key={jobId}>
                                <a
                                    onClick={(e) => onViewJob(e, safetyMeetingJobs.byId[jobId])}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <u>{safetyMeetingJobs.byId[jobId].task}</u>
                                </a>
                            </div>;
                        }
                    })}
                    {!hasJobs && '-'}
                </SeaGridCell>
                <SeaGridCell label="Images / Files" w="100">
                    {selectedItem?.files && selectedItem?.files?.length > 0 ? <SeaFileImage files={selectedItem?.files} size="tiny" showOthers={true}/> : '-'}
                </SeaGridCell>
                <SeaGridCell label="Signed off by" w="100">
                    <SeaSignatureImage file={selectedItem?.signature}/>
                </SeaGridCell>
            </SeaGrid>

            <div className="view-modal-buttons">
                {canEdit('healthSafetyMeetings') &&
                    selectedItem?.vesselIds &&
                    canAccessAllVessels(selectedItem.vesselIds) &&
                    <>
                        <SeaButton zone="white" onClick={(e) => onEditItem()}><SeaIcon icon="edit" slot="start" />Edit Report</SeaButton>
                        <RequirePermissions
                                role="healthSafetyMeetings"
                                level={permissionLevels.FULL}
                            >
                            <div className="spacer-wide"></div>
                            <SeaLinkButton
                                mode="standard-link"
                                onClick={(e) => onDeleteItem()}
                            >
                                Delete Report
                            </SeaLinkButton>
                        </RequirePermissions>
                    </>
                }
            </div>

            {showModal &&
                <>
                    <ViewJobList
                        showModal={showViewJobModal}
                        setShowModal={setShowViewJobModal}
                        selectedItem={selectedJob}
                    />
                    <EditHealthSafetyMeeting
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        itemToUpdate={selectedItem}
                        level={2}
                    />
                </>
            }
        </SeaModal>
    );
};

export default ViewHealthSafetyMeeting;

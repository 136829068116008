import React, { useState, useCallback, useMemo, useRef, useEffect } from "react";
import { firestore, splittableBatch } from "../../../lib/firebase";
import { doc, serverTimestamp } from "firebase/firestore";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import { pdfStyles, columnifyData, pixelsToCm, styleItemColumn } from "../../../lib/pdf";
import { formatTextAreaText, formatValue, formatDatetime, formatCurrency, formatDate, formatTime } from "../../../lib/util";
import { canView, permissionLevels } from "../../../shared-state/Core/userPermissions";
import { formatCoords } from "../../../lib/mapping";
import { renderTripType, renderDayName, calculateTotalHours, calculateTotalVoyageHours, combineVoyageCrewHours } from "../voyage-util";
import { getImgSrc, getImgSrcFromExt, getImgSrcFromString, makeSeaFiles } from "../../../lib/files";
import { logAction } from "../../../shared-state/General/actionLog";
import { renderCrewList, renderFullNameForUserId } from "../../../shared-state/Core/users";
import { sharedState } from "../../../shared-state/shared-state";
import { onCollectionUpdated } from "../../../shared-state/DataSyncSystem/dataSync";
import { getEngineName } from "../../../shared-state/Core/vessel";
import { useVoyageCompletedCustomForms } from "../../../shared-state/VesselLogbook/voyageCompletedCustomForms";
import { VoyageDocument, useVoyageDocuments } from "../../../shared-state/VesselLogbook/voyageDocuments";
import { confirmAction } from "../../../managers/ConfirmDialogManager/ConfirmDialogManager";
import { showToast } from "../../../managers/ToastManager/ToastManager";
import { Voyage, VoyageCheck } from '../../../shared-state/VesselLogbook/voyages';
import { CustomForm } from '../../../shared-state/CompanyDocuments/CustomForms/customForms';
import { CustomFormCompleted } from '../../../shared-state/CompanyDocuments/CustomForms/customFormsCompleted';
import { Action, traceAction } from "../../../managers/ErrorsManager/ErrorsManager";
import { useCompletedSafetyChecksDuringVoyage } from '../../../shared-state/VesselLogbook/completedSafetyChecksDuringVoyage';
import { useCompletedMaintenanceTasksDuringVoyage } from '../../../shared-state/VesselLogbook/completedMaintenanceTasksDuringVoyage';
import SeaModal from "../../../components/SeaModal/SeaModal";
import SeaSignatureImage from "../../../components/SeaSignatureImage/SeaSignatureImage";
import SeaLinkButton from "../../../components/SeaLinkButton/SeaLinkButton";
import SeaLabel from "../../../components/SeaLabel/SeaLabel";
import SeaGrid from "../../../components/SeaGrid/SeaGrid";
import SeaGridCell from "../../../components/SeaGridCell/SeaGridCell";
import RequirePermissions from "../../../components/RequirePermissions/RequirePermissions";
import SeaPdfGenerator from "../../../components/SeaPdfGenerator/SeaPdfGenerator";
import SeaFileImage from "../../../components/SeaFileImage/SeaFileImage";
import CompletedCustomFormPdf from "../../../pdfs/CustomForms/CompletedCustomFormPdf";
import SeaModalScrollableArea from "../../../components/SeaModalScrollableArea/SeaModalScrollableArea";
import SeaWindyForecastTable from "../../../components/SeaWindyForecastTable/SeaWindyForecastTable";
import "./ViewCompletedVoyage.css";

const styleTripStopHeading = (w: number) => {
    return {
        ...styleItemColumn(w),
        ...pdfStyles.label,
    };
};

interface ViewCompletedVoyageProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    selectedItem: Voyage;
}

interface Tide {
    stage: string;
    time: string;
    height: number;
}

const ViewCompletedVoyage: React.FC<ViewCompletedVoyageProps> = ({ showModal, setShowModal, selectedItem }) => {
    const userId = sharedState.userId.use(showModal);
    const customForms = sharedState.customForms.use(showModal);
    const vessel = sharedState.vessel.use(showModal);
    const voyageDocuments = useVoyageDocuments(showModal ? selectedItem : undefined);
    const completedCustomForms = useVoyageCompletedCustomForms(showModal ? selectedItem : undefined);
    const safetyCheckCompleteds = useCompletedSafetyChecksDuringVoyage(showModal ? selectedItem : undefined, true);
    const maintenanceTaskCompleteds = useCompletedMaintenanceTasksDuringVoyage(showModal ? selectedItem : undefined, true);
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const [selectedCustomForm, setSelectedCustomForm] = useState<CustomForm>();
    const [selectedCompletedCustomForm, setSelectedCompletedCustomForm] = useState<CustomFormCompleted>();
    const [generatingFormPdf, setGeneratingFormPdf] = useState(false);
    const imagesRef = useRef<any[]>([]);

    const reportTitlePostfix = useMemo(() => {
        if (selectedItem) {
            return ` - ${formatDate(selectedItem.whenDeparted)}`;
        }
        return '';
    }, [selectedItem]);

    const formsAndFiles = useMemo(() => {
        if (completedCustomForms !== undefined && voyageDocuments !== undefined) {
            const _formsAndFiles: (CustomFormCompleted | VoyageDocument)[] = [];
            completedCustomForms.all.forEach((form) => {
                _formsAndFiles.push(form);
            });
            voyageDocuments.all.forEach((userDocument) => {
                _formsAndFiles.push(userDocument);
            });
            _formsAndFiles.sort((a, b) => {
                return b.whenAdded - a.whenAdded;
            });
            return _formsAndFiles;
        }
        return undefined;
    }, [completedCustomForms, voyageDocuments]);

    // Keep selectedCustomForm fresh
    useEffect(() => {
        if (
            //selectedCustomForm &&
            selectedCompletedCustomForm?.customFormId &&
            customForms?.byId[selectedCompletedCustomForm.customFormId]
        ) {
            setSelectedCustomForm(customForms.byId[selectedCompletedCustomForm.customFormId]);
        }
    }, [customForms, selectedCompletedCustomForm?.customFormId]);

    const showTides = useMemo(() => {
        return !vessel?.logbookSettings?.hideTides;
    }, [vessel]);

    const formatWeatherObservation = (weatherData: { windDirection: string; windStrength: string; seaState: string; visibility: string; }) => {
        const { windDirection, windStrength, seaState, visibility } = weatherData;
        const returnString: string[] = [];
        windDirection && returnString.push(`Direction: ${windDirection}`);
        windStrength && returnString.push(`Strength: ${windStrength}`);
        seaState && returnString.push(`State: ${seaState.substring(2).charAt(0).toUpperCase() + seaState.substring(2).slice(1)}`);
        visibility && returnString.push(`Visibility: ${visibility.substring(2).charAt(0).toUpperCase() + visibility.substring(2).slice(1)}`);
        return returnString.join(' | ');
    };

    const formatCheckValue = (value?: string) => {
        return value === 'pass' ? 'PASS' : value === 'fail' ? 'FAIL' : 'N/A';
    };
    const formatStartupShutDownCheckString = (checkItem: VoyageCheck) => {
        return (
            <div key={checkItem.check} style={{ color: checkItem.value === 'fail' ? '#EB3232' : 'inherit' }}>
                <strong>{formatCheckValue(checkItem.value)}</strong> - {checkItem.check}
            </div>
        );
    };

    const formatTides = (tideData: { stationName: string; tides: Tide[]; }) => {
        if (!tideData) return '-';
        const { stationName, tides } = tideData;
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <strong>{stationName}</strong>:
                {tides?.map((tide: Tide, index: number) => {
                    const { stage, time, height } = tide;
                    if (index === 0) {
                        return (
                            <div key={time}>
                                &nbsp;{stage} {time} ({height}m)
                            </div>
                        );
                    }
                    return (
                        <div key={time}>
                            &nbsp;| {stage} {time} ({height}m)
                        </div>
                    );
                })}
            </div>
        );
    };

    const onDeleteVoyage = (event: React.MouseEvent<Element, MouseEvent>, item: Voyage) => {
        event.stopPropagation();
        confirmAction('Are you sure you want to delete this completed voyage?', 'Yes, delete voyage')
            .then(() => {
                const action = traceAction('voyages', 'delete') as Action;
                action.docId = item.id;
                const batch = splittableBatch(firestore, 20 - 0);
                batch.set(
                    doc(firestore, 'voyages', item.id),
                    {
                        deletedBy: userId,
                        whenDeleted: action.whenAction,
                        state: 'deleted',
                        touched: serverTimestamp(),
                    },
                    { merge: true }
                );

                batch.set(
                    doc(firestore, 'seaTimeRecords', item.id),
                    {
                        state: 'deleted',
                        whenDeleted: action.whenAction,
                        deletedBy: userId,
                        touched: serverTimestamp(),
                    },
                    { merge: true }
                );
                onCollectionUpdated(batch, 'seaTimeRecords');
                onCollectionUpdated(batch, 'voyages');
                logAction(batch, 'Delete', 'voyages', item.id, item.name ? item.name : '', [item.vesselId], item.personnelInvolved);

                action.data = { item };
                action.save(`Delete completed voyage ${item.name ? item.name : ''}`, batch);
                batch
                    .commit()
                    .then(() => {
                        action.reportSuccess();
                    })
                    .catch((error) => {
                        action.reportError(error.message, error);
                    });

                setShowModal(false);
                showToast('Voyage has now been deleted');
            })
            .catch(() => {});
    };

    const onOpenFormPdf = (e:  React.MouseEvent<HTMLImageElement, MouseEvent>, customForm: CustomForm | undefined, completedCustomForm: CustomFormCompleted) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedCustomForm(customForm);
        setSelectedCompletedCustomForm(completedCustomForm);
        setGeneratingFormPdf(true);
    };

    const onGeneratePdf = () => {
        setGeneratingPdf(true);
    };

    // Report PDF node structure based on report
    const MakeVoyagePDF = useCallback(() => {
        if (selectedItem === undefined) {
            return null; // requires a voyage to construct
        }
        return (
            <Document title={`Voyage${reportTitlePostfix}`} author="Sea Flux" creator="Sea Flux" producer="Sea Flux">
                {makeVoyagePage(selectedItem)}
                {imagesRef.current.length > 0 && (
                    <Page
                        style={{
                            ...pdfStyles.page,
                        }}
                        orientation="portrait"
                        size="A4"
                        wrap={true}
                    >
                        {imagesRef.current.map((img: { fileName: string; src: string }, index: number) => {
                            return (
                                <View
                                    key={index}
                                    style={{
                                        maxWidth: "19cm",
                                        margin: "0 1cm 0 1cm",
                                        //flexDirection: 'column',
                                        paddingBottom: "0.5cm",
                                    }}
                                    wrap={false}
                                >
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text
                                            style={{
                                                alignSelf: 'center',
                                                padding: '0 0.02cm 0.25cm 0',
                                                fontSize: '7pt',
                                            }}
                                        >
                                            {index + 1}
                                        </Text>
                                        <Text>{img.fileName}</Text>
                                    </View>
                                    <Image
                                        src={img.src}
                                        style={{
                                            objectFit: 'scale-down',
                                            //maxWidth: '19cm',
                                            //maxHeight: '10cm',
                                            objectPositionX: 0,
                                            objectPositionY: 0,
                                            //backgroundColor: '#eeff99'
                                        }}
                                    />
                                </View>
                            );
                        })}
                    </Page>
                )}
            </Document>
        );
    }, [selectedItem, vessel, showTides, safetyCheckCompleteds, maintenanceTaskCompleteds]);

    const makeVoyagePage = useCallback(
        (voyage: Voyage) => {
            imagesRef.current = [];
            return (
                <Page
                    key={voyage.id}
                    style={pdfStyles.page}
                    orientation="portrait"
                    size="A4" // 21cm x 29.7cm
                    wrap={true}
                >
                    <View wrap={false} style={pdfStyles.header}>
                        <Text style={pdfStyles.heading}>Voyage Report{reportTitlePostfix}</Text>
                    </View>
                    {selectedItem.tripType === 'multiTrip' ? (
                        <>
                            <View wrap={false} style={pdfStyles.row}>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>Trip Type</Text>
                                    <Text>{renderTripType(selectedItem.tripType)}</Text>
                                </View>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>Vessel</Text>
                                    <Text>{vessel?.name}</Text>
                                </View>
                            </View>
                            <View wrap={false} style={pdfStyles.row}>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>Operation</Text>
                                    <Text style={{ textTransform: 'capitalize' }}>{formatValue(selectedItem.operation)}</Text>
                                </View>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>{selectedItem.skipper ? 'Skipper' : `Master${selectedItem.masterIds.length === 1 ? '' : 's'}`}</Text>
                                    <Text>{selectedItem.skipper ? formatValue(selectedItem.skipper) : renderCrewList(selectedItem.masterIds, '-')}</Text>
                                </View>
                            </View>
                            <View wrap={false} style={pdfStyles.row}>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>Total PAX Carried</Text>
                                    <Text>{selectedItem.pax !== undefined ? formatValue('' + selectedItem.pax) : '-'}</Text>
                                </View>
                            </View>
                        </>
                    ) : (
                        <>
                            <View wrap={false} style={pdfStyles.row}>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>Name</Text>
                                    <Text>{formatValue(selectedItem.name)}</Text>
                                </View>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>Trip Type</Text>
                                    <Text>{renderTripType(selectedItem.tripType)}</Text>
                                </View>
                            </View>
                            <View wrap={false} style={pdfStyles.row}>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>Vessel</Text>
                                    <Text>{vessel?.name}</Text>
                                </View>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>Operation</Text>
                                    <Text style={{ textTransform: 'capitalize' }}>{formatValue(selectedItem.operation)}</Text>
                                </View>
                            </View>
                            <View wrap={false} style={pdfStyles.row}>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>{selectedItem.skipper ? 'Skipper' : `Master${selectedItem.masterIds.length === 1 ? '' : 's'}`}</Text>
                                    <Text>{selectedItem.skipper ? formatValue(selectedItem.skipper) : renderCrewList(selectedItem.masterIds, '-')}</Text>
                                </View>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>Crew Onboard</Text>
                                    <Text>
                                        {formatValue(
                                            voyage.personnelInvolved
                                                ?.map((userId) => {
                                                    return renderFullNameForUserId(userId);
                                                })
                                                .join(', ')
                                        )}
                                    </Text>
                                </View>
                            </View>
                            <View wrap={false} style={pdfStyles.row}>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>Departure</Text>
                                    <Text>
                                        {voyage.departureFrom ? `${voyage.departureFrom} - ` : ''} {formatDatetime(voyage.whenDeparted, ', ')}
                                    </Text>
                                </View>
                                {selectedItem.stopsOrRouteDetails ? (
                                    <View style={pdfStyles.column2}>
                                        <Text style={pdfStyles.label}>Stops or route details</Text>
                                        <Text>{formatValue(voyage.stopsOrRouteDetails)}</Text>
                                    </View>
                                ) : (
                                    <View style={pdfStyles.column2}>
                                        <Text style={pdfStyles.label}>Stops / Anchorages</Text>
                                        <Text>{formatValue(formatTextAreaText(selectedItem.stops ? selectedItem.stops.join(', ') : '-'))}</Text>
                                    </View>
                                )}
                            </View>
                            <View wrap={false} style={pdfStyles.row}>
                                <View style={pdfStyles.column1}>
                                    <Text style={pdfStyles.label}>Arrival</Text>
                                    <Text>
                                        {voyage.destinationTo ? `${voyage.destinationTo} - ` : ''} {formatDatetime(voyage.whenArrived, ', ')}
                                    </Text>
                                </View>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>PAX Carried</Text>
                                    <Text>{selectedItem.pax !== undefined ? formatValue('' + selectedItem.pax) : '-'}</Text>
                                </View>
                            </View>
                            {selectedItem.safetyBriefCompleted !== undefined && (
                                <View wrap={false} style={pdfStyles.row}>
                                    <View style={pdfStyles.column2}>
                                        <Text style={pdfStyles.label}>Safety Briefing Completed?</Text>
                                        <Text>{selectedItem.safetyBriefCompleted ? 'Yes' : 'No'}</Text>
                                    </View>
                                </View>
                            )}
                        </>
                    )}
                    {(selectedItem.tidalData || selectedItem.showSafetyBriefing !== undefined) && (
                        <View wrap={false} style={pdfStyles.row}>
                            {showTides && selectedItem.tidalData && (
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>Tides</Text>
                                    <Text>{formatTides(selectedItem.tidalData)}</Text>
                                </View>
                            )}
                            <View style={showTides && selectedItem.tidalData ? pdfStyles.column2 : pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Safety Briefing</Text>
                                <Text>{selectedItem.showSafetyBriefing ? 'Yes' : 'No'}</Text>
                            </View>
                        </View>
                    )}
                    {voyage.additionalPassengersOrDetails && (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Additional Passengers &amp; Details</Text>
                                <Text>{formatValue(voyage.additionalPassengersOrDetails)}</Text>
                            </View>
                        </View>
                    )}
                    {selectedItem.weatherForecast || selectedItem.weatherObservation ? (
                        <React.Fragment>
                            <View wrap={false} style={pdfStyles.row}>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>Weather Forecast</Text>
                                    <Text>{formatValue(formatTextAreaText(selectedItem.weatherForecast))}</Text>
                                </View>
                            </View>
                            <View wrap={false} style={pdfStyles.row}>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>Weather Observation</Text>
                                    <Text>{selectedItem.weatherObservation && (Object.keys(selectedItem.weatherObservation).length ? formatWeatherObservation(selectedItem.weatherObservation) : '-')}</Text>
                                </View>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>Trip Report</Text>
                                    <Text>{formatValue(selectedItem.tripReported ? `${selectedItem.tripReportedTo} - ${formatDatetime(selectedItem.whenTripReported)}` : undefined)}</Text>
                                </View>
                            </View>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {selectedItem.tripReported && (
                                <View wrap={false} style={pdfStyles.row}>
                                    <View style={pdfStyles.column1}>
                                        <Text style={pdfStyles.label}>Trip Report</Text>
                                        <Text>{formatValue(selectedItem.tripReported && `${selectedItem.tripReportedTo} - ${formatDatetime(selectedItem.whenTripReported)}`)}</Text>
                                    </View>
                                </View>
                            )}
                            {selectedItem.forecast && (
                                <>
                                    <View wrap={false} style={pdfStyles.rowNoSpace}>
                                        <View style={pdfStyles.column1}>
                                            <Text style={pdfStyles.label}>Weather Forecast {selectedItem.forecast.lat && selectedItem.forecast.lon && <>({formatCoords(selectedItem.forecast.lat, selectedItem.forecast.lon)})</>}</Text>
                                        </View>
                                    </View>
                                    <SeaWindyForecastTable mode="pdf" forecastData={selectedItem.forecast} />
                                </>
                            )}
                        </React.Fragment>
                    )}

                    <View style={pdfStyles.greyLine}></View>

                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Startup Checks</Text>
                            {voyage.startupChecks && voyage.startupChecks.length > 0 ? (
                                voyage.startupChecks.map((check, index) => {
                                    return (
                                        <View key={index}>
                                            <Text style={check.value === 'fail' ? pdfStyles.fail : {}}>
                                                <Text style={pdfStyles.field}>{formatCheckValue(check.value)}</Text> - {check.check}
                                            </Text>
                                        </View>
                                    );
                                })
                            ) : (
                                <Text>-</Text>
                            )}
                        </View>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Shutdown Checks</Text>
                            {voyage.shutdownChecks && voyage.shutdownChecks.length > 0 ? (
                                voyage.shutdownChecks.map((check, index) => {
                                    return (
                                        <View key={index}>
                                            <Text style={check.value === 'fail' ? pdfStyles.fail : {}}>
                                                <Text style={pdfStyles.field}>{formatCheckValue(check.value)}</Text> - {check.check}
                                            </Text>
                                        </View>
                                    );
                                })
                            ) : (
                                <Text>-</Text>
                            )}
                        </View>
                    </View>

                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Startup Notes</Text>
                            <Text>{formatValue(voyage.startupNotes)}</Text>
                        </View>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Shutdown Notes</Text>
                            <Text>{formatValue(voyage.shutdownNotes)}</Text>
                        </View>
                    </View>

                    {selectedItem.tripType !== 'multiDay' && (
                        <>
                            <View style={pdfStyles.greyLine}></View>
                            <View wrap={false} style={pdfStyles.row}>
                                <View style={pdfStyles.column1}>
                                    <Text style={pdfStyles.label}>Voyage Notes</Text>
                                    <Text>{formatValue(voyage.voyageNotes)}</Text>
                                </View>
                            </View>
                        </>
                    )}
                    {selectedItem.tripType === 'singleDay' && ((selectedItem.customTextFields && selectedItem.customTextFields?.length > 0 )|| (selectedItem.customHourFields && selectedItem.customHourFields?.length > 0)) && (
                        <>
                            <View wrap={false} style={pdfStyles.rowNoSpace}>
                                <View style={pdfStyles.column1}>
                                    <Text style={pdfStyles.label}>Voyage Data</Text>
                                </View>
                            </View>
                            {voyage.customTextFields && voyage.customTextFields.length > 0 && (
                                <View wrap={false}>
                                    {(() => {
                                        const rows = columnifyData(voyage.customTextFields, 3);
                                        if (!rows) return undefined;
                                        return rows.map((columns, index) => {
                                            return (
                                                <View key={index} style={index < rows.length - 1 ? pdfStyles.rowInBetween : pdfStyles.row}>
                                                    {columns.map((item, index) => {
                                                        return (
                                                            <View key={index} style={pdfStyles.column3}>
                                                                <Text>
                                                                    <Text style={pdfStyles.field}>{item.name}: </Text>
                                                                    {formatValue(item.value)}
                                                                </Text>
                                                            </View>
                                                        );
                                                    })}
                                                </View>
                                            );
                                        });
                                    })()}
                                </View>
                            )}
                            {voyage.customTextFields && voyage.customTextFields.length > 0 && (
                                <View wrap={false}>
                                    {(() => {
                                        const rows = columnifyData(voyage.customHourFields, 3);
                                        if (!rows) return undefined;
                                        return rows.map((columns, index) => {
                                            return (
                                                <View key={index} style={index < rows.length - 1 ? pdfStyles.rowInBetween : pdfStyles.row}>
                                                    {columns.map((item, index: number) => {
                                                        return (
                                                            <View key={index} style={pdfStyles.column3}>
                                                                <Text>
                                                                    <Text style={pdfStyles.field}>{item.name}: </Text>
                                                                    {formatValue(item.value)}
                                                                </Text>
                                                            </View>
                                                        );
                                                    })}
                                                </View>
                                            );
                                        });
                                    })()}
                                    <View style={pdfStyles.row}>
                                        <View style={pdfStyles.column1}>
                                            <Text style={pdfStyles.label}>Total Task Hours</Text>
                                            <Text>{calculateTotalHours(voyage.customHourFields)}</Text>
                                        </View>
                                    </View>
                                </View>
                            )}
                        </>
                    )}
                    {selectedItem.tripType === 'multiDay' &&
                        selectedItem.days?.map((day, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <View style={pdfStyles.greyLine}></View>
                                    <View wrap={false} style={pdfStyles.row}>
                                        <View style={pdfStyles.column1}>
                                            <Text style={pdfStyles.labelHeading}>{renderDayName(selectedItem.whenDeparted, index)}</Text>
                                        </View>
                                    </View>
                                    <View wrap={false} style={pdfStyles.row}>
                                        <View style={pdfStyles.column1}>
                                            <Text style={pdfStyles.label}>Notes</Text>
                                            <Text>{formatValue(day.notes)}</Text>
                                        </View>
                                    </View>
                                    {day.customText && day.customText.length > 0 && (
                                        <View wrap={false}>
                                            {(() => {
                                                const rows = columnifyData(day.customText, 3);
                                                if (!rows) return undefined;
                                                return rows.map((columns, index) => {
                                                    return (
                                                        <View key={index} style={index < rows.length - 1 ? pdfStyles.rowInBetween : pdfStyles.row}>
                                                            {columns.map((item, index: number) => {
                                                                return (
                                                                    <View key={index} style={pdfStyles.column3}>
                                                                        <Text>
                                                                            <Text style={pdfStyles.field}>{item.name}: </Text>
                                                                            {formatValue(item.value)}
                                                                        </Text>
                                                                    </View>
                                                                );
                                                            })}
                                                        </View>
                                                    );
                                                });
                                            })()}
                                        </View>
                                    )}
                                    {day.customHours && day.customHours.length > 0 && (
                                        <View wrap={false}>
                                            {(() => {
                                                const rows = columnifyData(day.customHours, 3);
                                                if (!rows) return undefined;
                                                return rows.map((columns, index) => {
                                                    return (
                                                        <View key={index} style={index < rows.length - 1 ? pdfStyles.rowInBetween : pdfStyles.row}>
                                                            {columns.map((item, index) => {
                                                                return (
                                                                    <View key={index} style={pdfStyles.column3}>
                                                                        <Text>
                                                                            <Text style={pdfStyles.field}>{item.name}: </Text>
                                                                            {formatValue(item.value)}
                                                                        </Text>
                                                                    </View>
                                                                );
                                                            })}
                                                        </View>
                                                    );
                                                });
                                            })()}
                                            <View style={pdfStyles.row}>
                                                <View style={pdfStyles.column1}>
                                                    <Text style={pdfStyles.label}>Total Task Hours</Text>
                                                    <Text>{calculateTotalHours(day.customHours)}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    )}
                                    {selectedItem.days && index === selectedItem.days.length - 1 && index > 0 && (
                                        <View style={pdfStyles.row}>
                                            <View style={pdfStyles.column1}>
                                                <Text style={{ ...pdfStyles.label, ...pdfStyles.bold }}>Total Voyage Task Hours</Text>
                                                <Text>{calculateTotalVoyageHours(selectedItem.days)}</Text>
                                            </View>
                                        </View>
                                    )}
                                    {Array.isArray(day.crewHours) && day.crewHours.length > 0 && !(vessel?.logbookSettings?.crewHours === 'hidden') && (
                                        <View wrap={false}>
                                            {(() => {
                                                const rows = columnifyData(day.crewHours, 3);
                                                if (!rows) return undefined;
                                                return rows.map((columns, index) => {
                                                    return (
                                                        <View key={index} style={index < rows.length - 1 ? pdfStyles.rowInBetween : pdfStyles.row}>
                                                            {columns.map((item, index) => {
                                                                return (
                                                                    <View key={index} style={pdfStyles.column3}>
                                                                        <Text>
                                                                            <Text style={pdfStyles.field}>{renderFullNameForUserId(item.id)}: </Text>
                                                                            {formatValue(item.hours)}
                                                                        </Text>
                                                                    </View>
                                                                );
                                                            })}
                                                        </View>
                                                    );
                                                });
                                            })()}
                                        </View>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    {selectedItem.tripType === 'multiTrip' &&
                        selectedItem.trips?.map((trip, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <View style={pdfStyles.greyLine}></View>
                                    <View wrap={false} style={pdfStyles.row}>
                                        <View style={pdfStyles.column1}>
                                            <Text style={pdfStyles.labelHeading}>Trip {index + 1}</Text>
                                        </View>
                                    </View>
                                    <View wrap={false} style={pdfStyles.row}>
                                        <View style={pdfStyles.column3}>
                                            <Text style={pdfStyles.label}>Master</Text>
                                            <Text>{renderFullNameForUserId(trip.masterId)}</Text>
                                        </View>
                                        <View style={pdfStyles.column2x3}>
                                            <Text style={pdfStyles.label}>Crew Onboard</Text>
                                            <Text>{renderCrewList(trip.crewInvolved)}</Text>
                                        </View>
                                    </View>
                                    <View wrap={false} style={pdfStyles.row}>
                                        <View style={pdfStyles.column3}>
                                            <Text style={pdfStyles.label}>Departure Location</Text>
                                            <Text>{formatValue(trip.departureFrom)}</Text>
                                        </View>
                                        <View style={pdfStyles.column3}>
                                            <Text style={pdfStyles.label}>Departure Time</Text>
                                            <Text>{formatTime(trip.whenDeparted)}</Text>
                                        </View>
                                        <View style={pdfStyles.column3}>
                                            <Text style={pdfStyles.label}>PAX On</Text>
                                            <Text>{trip.paxOn !== undefined ? formatValue('' + trip.paxOn) : '-'}</Text>
                                        </View>
                                    </View>
                                    {trip.stops && trip.stops.length > 0 && (
                                        <>
                                            <View wrap={false} style={pdfStyles.rowNoSpace}>
                                                <View style={styleTripStopHeading(1.2)}>
                                                    <Text>Stops / Anchorages</Text>
                                                </View>
                                                <View style={styleTripStopHeading(0.5)}>
                                                    <Text>Arrival Time</Text>
                                                </View>
                                                <View style={styleTripStopHeading(0.4)}>
                                                    <Text>PAX Off</Text>
                                                </View>
                                                <View style={styleTripStopHeading(0.4)}>
                                                    <Text>PAX On</Text>
                                                </View>
                                                <View style={styleTripStopHeading(0.5)}>
                                                    <Text>Departure Time</Text>
                                                </View>
                                            </View>
                                            {trip.stops.map((stop, index) => {
                                                return (
                                                    <View key={index} wrap={false} style={trip.stops && index === trip.stops.length - 1 ? pdfStyles.row : pdfStyles.rowInBetween}>
                                                        <View style={styleItemColumn(1.2)}>
                                                            <View wrap={false} style={{ flexDirection: 'row' }}>
                                                                <View style={{ minWidth: '0.5cm' }}>
                                                                    <Text>{index + 1}.&nbsp;</Text>
                                                                </View>
                                                                <View>
                                                                    <Text>{stop.location}</Text>
                                                                </View>
                                                            </View>
                                                        </View>
                                                        <View style={styleItemColumn(0.5)}>
                                                            <Text>{formatTime(stop.whenArrived)}</Text>
                                                        </View>
                                                        <View style={styleItemColumn(0.4)}>
                                                            <Text>{stop.paxOff}</Text>
                                                        </View>
                                                        <View style={styleItemColumn(0.4)}>
                                                            <Text>{stop.paxOn}</Text>
                                                        </View>
                                                        <View style={styleItemColumn(0.5)}>
                                                            <Text>{formatTime(stop.whenDeparted)}</Text>
                                                        </View>
                                                    </View>
                                                );
                                            })}
                                        </>
                                    )}
                                    <View wrap={false} style={pdfStyles.row}>
                                        <View style={pdfStyles.column3}>
                                            <Text style={pdfStyles.label}>Arrival Location</Text>
                                            <Text>{formatValue(trip.destinationTo)}</Text>
                                        </View>
                                        <View style={pdfStyles.column3}>
                                            <Text style={pdfStyles.label}>Arrival Time</Text>
                                            <Text>{formatTime(trip.whenArrived)}</Text>
                                        </View>
                                    </View>
                                    {trip.customText?.length ? (
                                        <>
                                            <View wrap={true} style={pdfStyles.row}>
                                                <View style={pdfStyles.column1}>
                                                    <Text style={pdfStyles.labelSubHeading}>TRIP { index + 1 } DATA</Text>
                                                </View>
                                            </View>

                                            {(() => {
                                                const chunkSize = 3;
                                                const rows = [];
                                                for (let i = 0; i < trip.customText.length; i += chunkSize) {
                                                    const chunk = trip.customText.slice(i, i + chunkSize);
                                                    rows.push(
                                                        <View wrap={true} style={pdfStyles.row}>
                                                            {chunk.map((text, index) => (
                                                                <View key={index} style={pdfStyles.column3}>
                                                                    <Text style={pdfStyles.label}>{text.name}</Text>
                                                                    <Text>{text.value}</Text>
                                                                </View>
                                                            ))}
                                                        </View>
                                                    );
                                                }
                                                return rows;
                                            })()}
                                        </>
                                    ) : null}
                                </React.Fragment>
                            );
                        })}

                    <View style={pdfStyles.greyLine}></View>
                    <View wrap={false} style={pdfStyles.rowNoSpace}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.label}>Forms / Documents</Text>
                        </View>
                    </View>
                    {formsAndFiles && formsAndFiles.length > 0 ? (
                        formsAndFiles.map((item) => {
                            const customFormCompleted = completedCustomForms?.byId[item.id]
                            const voyageDocument = voyageDocuments?.byId[item.id]

                            if (customFormCompleted?.customFormId && customFormCompleted?.data) {
                                const customForm = customForms?.byId[customFormCompleted.customFormId] ? customForms.byId[customFormCompleted.customFormId] : undefined;
                                return (
                                    <React.Fragment key={customFormCompleted.id}>
                                        <View wrap={false} style={pdfStyles.itemsRow}>
                                            <View style={pdfStyles.thumbColumn}>
                                                <Image src={getImgSrcFromExt('pdf', 'medium')} style={pdfStyles.thumbImg} />
                                            </View>
                                            <View style={styleItemColumn(1.0)}>
                                                <Text wrap={true}>{customForm?.title}</Text>
                                            </View>
                                        </View>
                                        <View style={pdfStyles.itemsRowSpacerNoLine}></View>
                                    </React.Fragment>
                                );
                            } else if (voyageDocument) {
                                const references = [] as number[];
                                return (
                                    <React.Fragment key={voyageDocument.id}>
                                        <View wrap={false} style={pdfStyles.itemsRow}>
                                            {voyageDocument.files.map((_file: string, index: number) => {
                                                const file = makeSeaFiles([_file])[0];
                                                const ext = file.ext?.toLowerCase();
                                                const isImage = ext === 'jpg' || ext === 'jpeg' || ext === 'png';
                                                const imgSrc = (
                                                    isImage ?
                                                    getImgSrc(file.state!, file.id!, file.ext!, 'full') :
                                                    getImgSrcFromExt(file.ext, 'medium')
                                                );

                                                const fileName = file.name ? file.name : `${file.id}.${file.ext}`;
                                                if (isImage) {
                                                    imagesRef.current.push({
                                                        src: imgSrc,
                                                        fileName: fileName,
                                                    });
                                                    references.push(imagesRef.current.length);
                                                }
                                                return (
                                                    <View key={_file} style={index === voyageDocument.files.length - 1 ? pdfStyles.thumbColumn : pdfStyles.thumbSeriesColumn}>
                                                        <Image src={imgSrc} style={pdfStyles.thumbImg} />
                                                    </View>
                                                );
                                            })}
                                            <View style={styleItemColumn(1.0)}>
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        //paddingBottom: '0.1543cm'
                                                    }}
                                                >
                                                    <Text wrap={true}>{voyageDocument.name}</Text>
                                                    {references.map((reference: number) => {
                                                        return (
                                                            <Text
                                                                key={reference}
                                                                style={{
                                                                    alignSelf: 'center',
                                                                    padding: '0 0 0.25cm 0.02cm',
                                                                    fontSize: '7pt',
                                                                }}
                                                            >
                                                                &nbsp;{reference}
                                                            </Text>
                                                        );
                                                    })}
                                                </View>
                                            </View>
                                        </View>
                                        <View style={pdfStyles.itemsRowSpacerNoLine}></View>
                                    </React.Fragment>
                                );
                            } else {
                                return null;
                            }
                            // return (
                            //     <View wrap={false} style={pdfStyles.itemsRow}>
                            //         <View style={pdfStyles.thumbColumn}>
                            //             {imgSrc &&
                            //                 <Image
                            //                     src={imgSrc}
                            //                     style={pdfStyles.thumbImg}
                            //                 />
                            //             }
                            //         </View>
                            //     </View>
                            // );
                        })
                    ) : (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text>-</Text>
                            </View>
                        </View>
                    )}

                    {canView('safetyEquipmentChecks') && (
                        <>
                            <View style={pdfStyles.greyLine}></View>
                            <View wrap={false} style={pdfStyles.rowNoSpace}>
                                <View style={pdfStyles.column1}>
                                    <Text style={pdfStyles.label}>Safety Checks Completed</Text>
                                </View>
                            </View>
                            {safetyCheckCompleteds && safetyCheckCompleteds.length > 0 ? (
                                <View wrap={false}>
                                    {safetyCheckCompleteds.map((item, index) => {
                                        if (item.item) {
                                        return (
                                            <View key={item.id} style={index < safetyCheckCompleteds.length - 1 ? pdfStyles.rowInBetween : pdfStyles.row} wrap={false}>
                                                <View style={pdfStyles.column1}>
                                                    <Text>
                                                        <Text style={pdfStyles.bold}>{item.item.name}</Text> completed by {renderFullNameForUserId(item.completedBy)},{formatDatetime(item.whenCompleted, ', ')}
                                                        {item.shouldReportFault ? <Text style={pdfStyles.bold}> (FAULT REPORTED) </Text> : ' '}
                                                        {item.notes && ` - ${item.notes}`}
                                                    </Text>
                                                </View>
                                            </View>
                                        );
                                    }})}
                                </View>
                            ) : (
                                <View wrap={false} style={pdfStyles.row}>
                                    <View style={pdfStyles.column1}>
                                        <Text>-</Text>
                                    </View>
                                </View>
                            )}
                        </>
                    )}

                    {canView('maintenanceSchedule') && (
                        <>
                            <View style={pdfStyles.greyLine}></View>
                            <View wrap={false} style={pdfStyles.rowNoSpace}>
                                <View style={pdfStyles.column1}>
                                    <Text style={pdfStyles.label}>Scheduled Maintenance Tasks Completed</Text>
                                </View>
                            </View>
                            {maintenanceTaskCompleteds && maintenanceTaskCompleteds.length > 0 ? (
                                <View wrap={false}>
                                    {maintenanceTaskCompleteds.map((item, index) => {
                                        return (
                                            <View key={item.id} style={index < maintenanceTaskCompleteds.length - 1 ? pdfStyles.rowInBetween : pdfStyles.row} wrap={false}>
                                                <View style={pdfStyles.column1}>
                                                    <Text>
                                                        <Text style={pdfStyles.bold}>{item.task}</Text> completed by {renderFullNameForUserId(item.completedBy)}, {formatDatetime(item.whenCompleted, ', ')}
                                                        {item.notes && ` - ${item.notes}`}
                                                    </Text>
                                                </View>
                                            </View>
                                        );
                                    })}
                                </View>
                            ) : (
                                <View wrap={false} style={pdfStyles.row}>
                                    <View style={pdfStyles.column1}>
                                        <Text>-</Text>
                                    </View>
                                </View>
                            )}
                        </>
                    )}

                    <View style={pdfStyles.greyLine}></View>
                    <View wrap={false} style={pdfStyles.rowNoSpace}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.label}>Fuel Usage</Text>
                        </View>
                    </View>
                    <View wrap={false} style={pdfStyles.rowInBetween}>
                        <View style={pdfStyles.column3}>
                            <Text>
                                <Text style={pdfStyles.field}>Fuel onboard (start): </Text>
                                {formatValue(voyage.fuelStart)}
                            </Text>
                        </View>
                        <View style={pdfStyles.column3}>
                            <Text>
                                <Text style={pdfStyles.field}>Fuel bunkered: </Text>
                                {formatValue(voyage.fuelBunkered)}
                            </Text>
                        </View>
                        <View style={pdfStyles.column3}>
                            <Text>
                                <Text style={pdfStyles.field}>Fuel bunkered cost: </Text>
                                {voyage.fuelBunkeredCost ? formatCurrency(voyage.fuelBunkeredCost, 2) : '-'}
                            </Text>
                        </View>
                    </View>
                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column3}>
                            <Text>
                                <Text style={pdfStyles.field}>Fuel onboard (end): </Text>
                                {formatValue(voyage.fuelEnd)}
                            </Text>
                        </View>
                        <View style={pdfStyles.column3}>
                            <Text>
                                <Text style={pdfStyles.field}>Fuel used: </Text>
                                {selectedItem && selectedItem.fuelStart !== undefined && selectedItem.fuelBunkered !== undefined && selectedItem.fuelEnd !== undefined
                                    ? '' + (selectedItem.fuelStart - selectedItem.fuelEnd + selectedItem.fuelBunkered)
                                    : '-'}
                            </Text>
                        </View>
                    </View>
                    <View wrap={false} style={pdfStyles.rowNoSpace}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.label}>Engine Hours on Arrival</Text>
                        </View>
                    </View>
                    {voyage.engineHours && voyage.engineHours.length > 0 ? (
                        (() => {
                            const rows = columnifyData(voyage.engineHours, 3);
                            if (!rows) return undefined;
                            return rows.map((columns, index) => {
                                return (
                                    <View wrap={false} key={`${voyage.id}${columns[0].id}`} style={index < rows.length - 1 ? pdfStyles.rowInBetween : pdfStyles.row}>
                                        {columns.map((item) => {
                                            return (
                                                <View key={item.id} style={pdfStyles.column3}>
                                                    <Text>
                                                        <Text style={pdfStyles.field}>{getEngineName(item.id)}: </Text> {item.hours}
                                                    </Text>
                                                </View>
                                            );
                                        })}
                                    </View>
                                );
                            });
                        })()
                    ) : (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text>-</Text>
                            </View>
                        </View>
                    )}

                    {!(vessel?.logbookSettings?.crewHours === 'hidden') && (
                        <>
                            <View wrap={false} style={pdfStyles.rowNoSpace}>
                                <View style={pdfStyles.column1}>
                                    <Text style={pdfStyles.label}>{selectedItem.tripType === 'multiDay' ? 'Total ' : ''}Crew Hours</Text>
                                </View>
                            </View>
                            {selectedItem.tripType === 'multiDay' ? (
                                (() => {
                                    const rows = columnifyData(combineVoyageCrewHours(selectedItem.days), 3);
                                    if (!rows)
                                        return (
                                            <View wrap={false} style={pdfStyles.row}>
                                                <View style={pdfStyles.column1}>
                                                    <Text>-</Text>
                                                </View>
                                            </View>
                                        );
                                    return rows.map((columns, index) => {
                                        return (
                                            <View wrap={false} key={`${voyage.id}${columns[0].id}`} style={index < rows.length - 1 ? pdfStyles.rowInBetween : pdfStyles.row}>
                                                {columns.map((item) => {
                                                    return (
                                                        <View key={`${voyage.id}${item.id}`} style={pdfStyles.column3}>
                                                            <Text>
                                                                <Text style={pdfStyles.field}>{renderFullNameForUserId(item.id)}: </Text> {item.hours}
                                                            </Text>
                                                        </View>
                                                    );
                                                })}
                                            </View>
                                        );
                                    });
                                })()
                            ) : voyage.crewHours && voyage.crewHours.length > 0 ? (
                                (() => {
                                    const rows = columnifyData(voyage.crewHours, 3);
                                    if (!rows) return undefined;
                                    return rows.map((columns, index) => {
                                        return (
                                            <View wrap={false} key={`${voyage.id}${columns[0].id}`} style={index < rows.length - 1 ? pdfStyles.rowInBetween : pdfStyles.row}>
                                                {columns.map((item) => {
                                                    return (
                                                        <View key={`${voyage.id}${item.id}`} style={pdfStyles.column3}>
                                                            <Text>
                                                                <Text style={pdfStyles.field}>{renderFullNameForUserId(item.id)}: </Text> {item.hours}
                                                            </Text>
                                                        </View>
                                                    );
                                                })}
                                            </View>
                                        );
                                    });
                                })()
                            ) : (
                                <View wrap={false} style={pdfStyles.row}>
                                    <View style={pdfStyles.column1}>
                                        <Text>-</Text>
                                    </View>
                                </View>
                            )}
                        </>
                    )}

                    {voyage.files && voyage.files.length > 0 && (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                {voyage.files.map((_file) => {
                                    const file = makeSeaFiles([_file])[0];
                                    const ext = file.ext?.toLowerCase();
                                    const isImage = ext === 'jpg' || ext === 'jpeg' || ext === 'png';
                                    const imgSrc = (
                                        isImage ?
                                        getImgSrc(
                                            file.state!,
                                            file.id!,
                                            file.ext!,
                                            'full'
                                        ) :
                                        getImgSrcFromExt(file.ext, 'medium')
                                    );
                                    const fileName = file.name ? file.name : `${file.id}.${file.ext}`;
                                    if (isImage) {
                                        imagesRef.current.push({
                                            src: imgSrc,
                                            fileName: fileName,
                                        });
                                    }
                                    return (
                                        <View
                                            key={file.id}
                                            style={{
                                                flexDirection: 'row',
                                                paddingBottom: '0.1543cm',
                                            }}
                                        >
                                            <Image
                                                src={imgSrc}
                                                style={{
                                                    ...pdfStyles.checkbox,
                                                    objectFit: 'cover',
                                                    marginRight: '0.2571cm',
                                                }}
                                            />
                                            <Text style={{ alignSelf: 'center' }}>{fileName}</Text>
                                            {isImage && (
                                                <Text
                                                    style={{
                                                        alignSelf: 'center',
                                                        padding: '0 0 0.25cm 0.02cm',
                                                        fontSize: '7pt',
                                                    }}
                                                >
                                                    {imagesRef.current.length}
                                                </Text>
                                            )}
                                        </View>
                                    );
                                })}
                            </View>
                        </View>
                    )}

                    <View wrap={false} style={pdfStyles.rowNoSpace}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.label}>Signed Off By</Text>
                        </View>
                    </View>
                    {voyage.signature ? (
                        <View wrap={false} style={pdfStyles.rowNoSpace}>
                            <View
                                style={{
                                    //...pdfStyles.input,
                                    padding: '0 0 0 0',
                                    width: pixelsToCm(352) + 'cm',
                                    height: pixelsToCm(202) + 'cm',
                                }}
                                wrap={false}
                            >
                                <Image
                                    src={getImgSrcFromString(voyage.signature, 'sig')}
                                    style={{
                                        width: pixelsToCm(350) + 'cm',
                                        height: pixelsToCm(200) + 'cm',
                                    }}
                                />
                            </View>
                        </View>
                    ) : (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text>-</Text>
                            </View>
                        </View>
                    )}
                </Page>
            );
        },
        [selectedItem, showTides, safetyCheckCompleteds, maintenanceTaskCompleteds]
    );

    if (!selectedItem) return null;

    return (
        <SeaModal
            title={`Voyage${reportTitlePostfix}`}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            onPdf={onGeneratePdf}
        >
            <div className="voyage-info">
                <SeaGrid>
                    {selectedItem.tripType !== 'multiTrip' && (
                        <SeaGridCell label="Name" w="50">
                            {formatValue(selectedItem.name)}
                        </SeaGridCell>
                    )}
                    <SeaGridCell label="Trip Type" w="50">
                        {renderTripType(selectedItem.tripType)}
                    </SeaGridCell>
                    <SeaGridCell label="Vessel" w="50">
                        {vessel?.name}
                    </SeaGridCell>
                    <SeaGridCell label="Operation" w="50" capitalize>
                        {formatValue(selectedItem.operation)}
                    </SeaGridCell>
                    {selectedItem.masterIds?.length > 0 ? (
                        <SeaGridCell label={`Master${selectedItem.masterIds.length === 1 ? '' : 's'}`} w="50">
                            {renderCrewList(selectedItem.masterIds, '-')}
                        </SeaGridCell>
                    ) : (
                        <SeaGridCell label="Skipper" w="50">
                            {formatValue(selectedItem.skipper)}
                        </SeaGridCell>
                    )}
                    {selectedItem.tripType !== 'multiTrip' && (
                        <>
                            <SeaGridCell label="Crew Onboard" w="50">
                                {renderCrewList(selectedItem.personnelInvolved)}
                            </SeaGridCell>
                            <SeaGridCell label="Departure" w="50">
                                {`${formatValue(selectedItem.departureFrom)} - ${formatDatetime(selectedItem.whenDeparted)}`}
                            </SeaGridCell>
                            {selectedItem.stopsOrRouteDetails ? (
                                <SeaGridCell label="Routes, Anchorages, Stops" w="50">
                                    {formatValue(formatTextAreaText(selectedItem.stopsOrRouteDetails))}
                                </SeaGridCell>
                            ) : (
                                <SeaGridCell label="Stops / Anchorages" w="50">
                                    {formatValue(formatTextAreaText(selectedItem.stops ? selectedItem.stops.join(', ') : '-'))}
                                </SeaGridCell>
                            )}
                        </>
                    )}
                    {selectedItem.weatherForecast && (
                        <SeaGridCell label="Weather Forecast" w="50">
                            {formatValue(formatTextAreaText(selectedItem.weatherForecast))}
                        </SeaGridCell>
                    )}
                    {selectedItem.weatherObservation && (
                        <SeaGridCell label="Weather Observation" w="50">
                            {selectedItem.weatherObservation && (Object.keys(selectedItem.weatherObservation).length ? formatWeatherObservation(selectedItem.weatherObservation) : '-')}
                        </SeaGridCell>
                    )}
                    {selectedItem.tripReportedTo && (
                        <SeaGridCell label="Trip Report" w="50">
                            {formatValue(selectedItem.tripReported ? `${selectedItem.tripReportedTo} - ${formatDatetime(selectedItem.whenTripReported)}` : undefined)}
                        </SeaGridCell>
                    )}
                    {showTides && selectedItem.tidalData && (
                        <SeaGridCell label="Tides" w="50">
                            {formatTides(selectedItem.tidalData)}
                        </SeaGridCell>
                    )}
                    {selectedItem.showSafetyBriefing !== undefined && (
                        <SeaGridCell label="Safety Briefing" w="50">
                            {selectedItem.showSafetyBriefing ? 'Yes' : 'No'}
                        </SeaGridCell>
                    )}
                    {selectedItem.additionalPassengersOrDetails && (
                        <SeaGridCell label="Additional Passengers &amp; Details" w="50">
                            {formatValue(formatTextAreaText(selectedItem.additionalPassengersOrDetails))}
                        </SeaGridCell>
                    )}
                    {selectedItem.tripType !== "multiTrip" && (
                        <SeaGridCell label="Arrival" w="50">
                            {`${formatValue(selectedItem.destinationTo)} - ${formatDatetime(selectedItem.whenArrived)}`}
                        </SeaGridCell>
                    )}
                    <SeaGridCell label={`${selectedItem.tripType === 'multiTrip' ? 'Total ' : ''}PAX Carried`} w="50">
                        {selectedItem.pax !== undefined ? formatValue('' + selectedItem.pax) : '-'}
                    </SeaGridCell>
                    {selectedItem.tripType !== 'multiTrip' && selectedItem.safetyBriefCompleted !== undefined && (
                        <SeaGridCell label="Safety Briefing Completed?" w="50">
                            {selectedItem.safetyBriefCompleted ? 'Yes' : 'No'}
                        </SeaGridCell>
                    )}
                    {selectedItem.forecast && (
                        <SeaGridCell w="100" style={{ paddingRight: "0px" }}>
                            <SeaLabel>Weather Forecast {selectedItem.forecast.lat && selectedItem.forecast.lon && <>({formatCoords(selectedItem.forecast.lat, selectedItem.forecast.lon)})</>}</SeaLabel>
                            <SeaModalScrollableArea>
                                <SeaWindyForecastTable mode="view-only" forecastData={selectedItem.forecast} marginLeft="16px" />
                            </SeaModalScrollableArea>
                            <div style={{ height: '8px' }}></div>
                        </SeaGridCell>
                    )}
                </SeaGrid>
                <div className="end-info-line"></div>
                <SeaGrid>
                    <SeaGridCell label="Startup Checks" w="50">
                        {selectedItem.startupChecks?.map((checkItem) => formatStartupShutDownCheckString(checkItem))}
                        {(!selectedItem.startupChecks || selectedItem.startupChecks.length === 0) && '-'}
                    </SeaGridCell>
                    <SeaGridCell label="Shutdown Checks" w="50">
                        {selectedItem.shutdownChecks?.map((checkItem) => formatStartupShutDownCheckString(checkItem))}
                        {(!selectedItem.shutdownChecks || selectedItem.shutdownChecks.length === 0) && '-'}
                    </SeaGridCell>
                    <SeaGridCell label="Startup Notes" w="50">
                        {formatValue(formatTextAreaText(selectedItem.startupNotes))}
                    </SeaGridCell>
                    <SeaGridCell label="Shutdown Notes" w="50">
                        {formatValue(formatTextAreaText(selectedItem.shutdownNotes))}
                    </SeaGridCell>
                </SeaGrid>
                {selectedItem.tripType !== 'multiDay' && (
                    <>
                        <div className="end-info-line"></div>
                        <SeaGrid>
                            <SeaGridCell label="Voyage Notes" w="100">
                                {formatValue(formatTextAreaText(selectedItem.voyageNotes))}
                            </SeaGridCell>
                        </SeaGrid>
                    </>
                )}
                {selectedItem.tripType === 'singleDay' && (
                    (selectedItem.customTextFields && selectedItem.customTextFields?.length > 0) || 
                    (selectedItem.customHourFields && selectedItem.customHourFields?.length > 0)
                ) && (
                    <>
                        <div className="end-info-line"></div>
                        <SeaLabel>Voyage Data</SeaLabel>
                        <SeaGrid>
                            {selectedItem.customTextFields?.map((textField) => {
                                return (
                                    <SeaGridCell key={textField.name} w="33" short>
                                        <strong>{textField.name}:</strong> {textField.value ? textField.value : '-'}
                                    </SeaGridCell>
                                );
                            })}
                            {selectedItem.customHourFields?.map((hourField) => {
                                return (
                                    <SeaGridCell key={hourField.name} w="33" short>
                                        <strong>{hourField.name}:</strong> {hourField.value + " hrs"}
                                    </SeaGridCell>
                                );
                            })}
                            {selectedItem.customHourFields && selectedItem.customHourFields?.length > 0 && (
                                <SeaGridCell short w="100">
                                    <strong>Total Task Hours: </strong>
                                    {selectedItem.totalHours} hrs
                                </SeaGridCell>
                            )}
                        </SeaGrid>
                    </>
                )}
                {selectedItem.tripType === 'multiDay' &&
                    selectedItem.days?.map((day, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className="end-info-line"></div>
                                <h2 className="for-view-modal">{renderDayName(selectedItem.whenDeparted, index)}</h2>
                                <SeaGrid>
                                    <SeaGridCell label="Notes" w="100">
                                        {formatValue(formatTextAreaText(day.notes))}
                                    </SeaGridCell>
                                    {day.customText?.map((textField) => {
                                        return (
                                            <SeaGridCell key={textField.name} w="33" short>
                                                <strong>{textField.name}:</strong> {textField.value ? textField.value : '-'}
                                            </SeaGridCell>
                                        );
                                    })}
                                    {day.customHours?.map((hourField) => {
                                        return (
                                            <SeaGridCell key={hourField.name} w="33" short>
                                                <strong>{hourField.name}:</strong> {hourField.value + ' hrs'}
                                            </SeaGridCell>
                                        );
                                    })}
                                    <SeaGridCell short w="100">
                                        <strong>Total Task Hours: </strong>
                                        {calculateTotalHours(day.customHours)} hrs
                                    </SeaGridCell>
                                    {!(vessel?.logbookSettings?.crewHours === 'hidden') &&
                                        Array.isArray(day.crewHours) &&day.crewHours?.map((item) => {
                                            return (
                                                <SeaGridCell key={item.id} w="33" short>
                                                    <strong>{renderFullNameForUserId(item.id)}:</strong> {item.hours + ' hrs'}
                                                </SeaGridCell>
                                            );
                                        })}
                                    {selectedItem.days && index === selectedItem.days.length - 1 && index > 0 && (
                                        <SeaGridCell short w="100">
                                            <strong>TOTAL VOYAGE TASK HOURS: </strong>
                                            {calculateTotalVoyageHours(selectedItem.days)} hrs
                                        </SeaGridCell>
                                    )}
                                </SeaGrid>
                            </React.Fragment>
                        );
                    })}
                {selectedItem.tripType === 'multiTrip' &&
                    selectedItem.trips?.map((trip, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className="end-info-line"></div>
                                <h2 style={{ color: 'var(--text-on-white)', fontSize: '18px', marginBottom: '16px' }}>Trip {index + 1}</h2>
                                <SeaGrid>
                                    <SeaGridCell label="Master" w="33">
                                        {renderFullNameForUserId(trip.masterId)}
                                    </SeaGridCell>
                                    <SeaGridCell label="Crew Onboard" w="66">
                                        {renderCrewList(trip.crewInvolved)}
                                    </SeaGridCell>
                                    <SeaGridCell label="Departure Location" w="33">
                                        {formatValue(trip.departureFrom)}
                                    </SeaGridCell>
                                    <SeaGridCell label="Departure Time" w="33">
                                        {formatTime(trip.whenDeparted)}
                                    </SeaGridCell>
                                    <SeaGridCell label="PAX On" w="33">
                                        {trip.paxOn !== undefined ? formatValue('' + trip.paxOn) : '-'}
                                    </SeaGridCell>
                                </SeaGrid>
                                {trip.stops && trip.stops.length > 0 && (
                                    <SeaGrid>
                                        <SeaGridCell w="100">
                                            <div className="voyage-trip-stops sea-label">Stops / Anchorages</div>
                                            <div className="voyage-trip-stops">
                                                <div className="sea-row-history headings-history">
                                                    <div>Stops / Anchorages</div>
                                                    <div>Arrival Time</div>
                                                    <div>PAX Off</div>
                                                    <div>PAX On</div>
                                                    <div>Departure Time</div>
                                                </div>
                                                {trip.stops.map((stop, index) => {
                                                    return (
                                                        <div key={index} className="sea-card sea-row-history">
                                                            <div className="truncate">
                                                                <span>{index + 1}.&nbsp;</span>
                                                                {stop.location}
                                                            </div>
                                                            <div className="truncate">{formatTime(stop.whenArrived)}</div>
                                                            <div className="truncate">{stop.paxOff}</div>
                                                            <div className="truncate">{stop.paxOn}</div>
                                                            <div className="truncate">{formatTime(stop.whenDeparted)}</div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </SeaGridCell>
                                    </SeaGrid>
                                )}
                                <SeaGrid>
                                    <SeaGridCell label="Arrival Location" w="33">
                                        {formatValue(trip.destinationTo)}
                                    </SeaGridCell>
                                    <SeaGridCell label="Arrival Time" w="33">
                                        {formatTime(trip.whenArrived)}
                                    </SeaGridCell>
                                </SeaGrid>
                                {trip.customText?.length ? (
                                        <SeaGrid>
                                            <h6 style={{ color: 'var(--text-on-white)',  marginBottom: '16px', fontSize: '14px', width: '100%' }}>TRIP {index + 1} DATA</h6>
                                            {trip.customText.map((text, index) => {
                                                return (
                                                    <SeaGridCell label={text.name as string} w="33" key={index}>
                                                        <div className="truncate-3">{text.value}</div>
                                                    </SeaGridCell>
                                                );
                                            })}
                                        </SeaGrid>
                                    ) : null}
                            </React.Fragment>
                        );
                    })}

                <div className="end-info-line"></div>
                <SeaLabel>Forms / Documents</SeaLabel>
                <SeaGrid>
                    <SeaGridCell short w="100">
                        {(formsAndFiles === undefined || formsAndFiles.length === 0) && '-'}
                        {formsAndFiles &&
                            formsAndFiles.length > 0 &&
                            formsAndFiles.map((item) => {
                                const customFormCompleted = completedCustomForms?.byId[item.id]
                                const voyageDocument = voyageDocuments?.byId[item.id]

                                if (customFormCompleted?.customFormId && customFormCompleted?.data) {
                                    const customForm = customForms?.byId[customFormCompleted.customFormId] ? customForms.byId[customFormCompleted.customFormId] : undefined;
                                    return (
                                        <div key={customFormCompleted.id} className="voyage-forms-docs columns">
                                            <div>
                                                <img className="sea-file-image tiny file" src="/assets/file-pdf_tiny@2x.png" onClick={(e) => onOpenFormPdf(e, customForm, customFormCompleted)} alt="Open PDF" />
                                            </div>
                                            <div>{customForm?.title}</div>
                                        </div>
                                    );
                                } else if (voyageDocument) {
                                    return (
                                        <div key={item.id} className="voyage-forms-docs columns">
                                            <div>
                                                <SeaFileImage files={item.files} size="tiny" showOthers={true} />
                                            </div>
                                            <div>{voyageDocument.name}</div>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                    </SeaGridCell>
                </SeaGrid>

                {canView("safetyEquipmentChecks") && (
                    <>
                        <div className="end-info-line"></div>
                        <SeaLabel>Safety Checks Completed</SeaLabel>
                        <SeaGrid>
                            {(safetyCheckCompleteds === undefined || safetyCheckCompleteds.length === 0) && (
                                <SeaGridCell short w="100">
                                    -
                                </SeaGridCell>
                            )}
                            {safetyCheckCompleteds &&
                                safetyCheckCompleteds.length > 0 &&
                                safetyCheckCompleteds.map((item) => {
                                    if (item.item) {
                                        return (
                                            <SeaGridCell key={item.id} short w="100">
                                                <strong>{item.item.name}</strong> completed by {renderFullNameForUserId(item.completedBy)}, {formatDatetime(item.whenCompleted)}
                                                {item.shouldReportFault ? <strong> (FAULT REPORTED) </strong> : ' '}
                                                {item.notes && ` - ${item.notes}`}
                                            </SeaGridCell>
                                            // <div key={item.id} className="voyage-forms-docs columns">
                                            //     <div>
                                            //         <SeaFileImage files={item.item.files} size="tiny" showOthers={true} />
                                            //     </div>
                                            //     <div>
                                            //         {item.item.item}
                                            //     </div>
                                            // </div>
                                        );
                                    }
                                    return undefined;
                                })}
                        </SeaGrid>
                    </>
                )}

                {canView('maintenanceSchedule') && (
                    <>
                        <div className="end-info-line"></div>
                        <SeaLabel>Scheduled Maintenance Tasks Completed</SeaLabel>
                        <SeaGrid>
                            {(maintenanceTaskCompleteds === undefined || maintenanceTaskCompleteds.length === 0) && (
                                <SeaGridCell short w="100">
                                    -
                                </SeaGridCell>
                            )}
                            {maintenanceTaskCompleteds &&
                                maintenanceTaskCompleteds.length > 0 &&
                                maintenanceTaskCompleteds.map((item) => {
                                    if (item.task) {
                                        return (
                                            <SeaGridCell key={item.id} short w="100">
                                                <strong>{item.task}</strong> completed by {renderFullNameForUserId(item.completedBy)}, {formatDatetime(item.whenCompleted)}
                                                {item.notes && ` - ${item.notes}`}
                                            </SeaGridCell>
                                        );
                                    }
                                    return undefined;
                                })}
                        </SeaGrid>
                    </>
                )}

                <div className="end-info-line"></div>
                <SeaLabel>Fuel Usage</SeaLabel>
                <SeaGrid>
                    <SeaGridCell short w="33">
                        <strong>Fuel onboard (start): </strong>
                        {formatValue(selectedItem.fuelStart)}
                    </SeaGridCell>
                    <SeaGridCell short w="33">
                        <strong>Fuel bunkered: </strong>
                        {formatValue(selectedItem.fuelBunkered)}
                    </SeaGridCell>
                    <SeaGridCell short w="33">
                        <strong>Fuel bunkered cost: </strong>
                        {selectedItem.fuelBunkeredCost ? formatCurrency(selectedItem.fuelBunkeredCost, 2) : '-'}
                    </SeaGridCell>
                    <SeaGridCell short w="33">
                        <strong>Fuel onboard (end): </strong>
                        {formatValue(selectedItem.fuelEnd)}
                    </SeaGridCell>
                    <SeaGridCell short w="33">
                        <strong>Fuel used: </strong>
                        {selectedItem.fuelStart !== undefined && selectedItem.fuelBunkered !== undefined && selectedItem.fuelEnd !== undefined ? '' + (selectedItem.fuelStart - selectedItem.fuelEnd + selectedItem.fuelBunkered) : '-'}
                    </SeaGridCell>
                </SeaGrid>
                <div style={{ height: '32px' }}></div>
                <SeaLabel>Engine Hours on Arrival</SeaLabel>
                <SeaGrid>
                    {selectedItem.engineHours?.map((hoursAndEngineId) => {
                        return (
                            <SeaGridCell key={hoursAndEngineId.id} w="33" short>
                                <strong>{getEngineName(hoursAndEngineId.id)}:</strong> {hoursAndEngineId.hours}
                            </SeaGridCell>
                        );
                    })}
                </SeaGrid>
                {!(vessel?.logbookSettings?.crewHours === 'hidden') && (
                    <>
                        <div style={{ height: '32px' }}></div>
                        <SeaLabel>{selectedItem.tripType === 'multiDay' ? 'Total ' : ''}Crew Hours</SeaLabel>
                        <SeaGrid>
                            {selectedItem.tripType === 'multiDay' ? (
                                combineVoyageCrewHours(selectedItem.days)?.map((hoursAndCrewId) => {
                                    return (
                                        <SeaGridCell key={hoursAndCrewId.id} w="33" short>
                                            <strong>{renderFullNameForUserId(hoursAndCrewId.id)}:</strong> {hoursAndCrewId.hours} hrs
                                        </SeaGridCell>
                                    );
                                })
                            ) : !selectedItem.crewHours?.length ? (
                                <SeaGridCell w="100" short>
                                    <div>-</div>
                                </SeaGridCell>
                            ) : (
                                selectedItem.crewHours?.map((hoursAndCrewId) => {
                                    return (
                                        <SeaGridCell key={hoursAndCrewId.id} w="33" short>
                                            <strong>{renderFullNameForUserId(hoursAndCrewId.id)}:</strong> {hoursAndCrewId.hours} hrs
                                        </SeaGridCell>
                                    );
                                })
                            )}
                        </SeaGrid>
                    </>
                )}
                <SeaGrid>
                    <SeaGridCell label="Signed off by">
                        <SeaSignatureImage file={selectedItem.signature} />
                    </SeaGridCell>
                </SeaGrid>
            </div>

            <RequirePermissions role="logbook" level={permissionLevels.FULL}>
                <div className="view-modal-buttons">
                    <SeaLinkButton mode="standard-link" onClick={(e) => onDeleteVoyage(e, selectedItem)}>
                        Delete Voyage
                    </SeaLinkButton>
                </div>
            </RequirePermissions>

            {showModal && (
                <>
                    <SeaPdfGenerator
                        //show={showPdfGenerator}
                        //setShow={setShowPdfGenerator}
                        generatingPdf={generatingPdf}
                        setGeneratingPdf={setGeneratingPdf}
                        pdfTitle={`Voyage Report${reportTitlePostfix}`}
                        MakePdf={MakeVoyagePDF}
                        requestedFrom="ViewCompletedVoyage"
                    />
                    <CompletedCustomFormPdf generatingPdf={generatingFormPdf} setGeneratingPdf={setGeneratingFormPdf} customForm={selectedCustomForm} completedCustomForm={selectedCompletedCustomForm} />
                </>
            )}
        </SeaModal>
    );
};

export default ViewCompletedVoyage;
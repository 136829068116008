import React, { useMemo, useState } from 'react';
import { useDevices } from '../../../../shared-state/Admin/devices';
import { formatDatetimeISO, getDayOffsetMillis, makeDateTime, toInt, toMillis } from '../../../../lib/util';
import { DeviceInfo } from '../../../../shared-state/Core/deviceInfo';
import { mb } from '../../../../shared-state/FileSyncSystem/diskSpaceStatus';
import SeaDatetime from '../../../../components/SeaDatetime/SeaDatetime';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import SeaHorizontalBarGraph from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import SeaTabsGroup from '../../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../../../components/SeaTab/SeaTab';
import './Devices.css';
import SeaInput from '../../../../components/SeaInput/SeaInput';

const tabs = ['Platform / OS', 'OS Version', 'Version / Build', 'Model', 'Browser', 'Disk Space', 'Last Active', 'Timezones'] as const;
type Tab = typeof tabs[number];

const platforms = ['web', 'ios', 'android'];
const platformOptions = platforms.map((platform) => {
    return { id: platform, name: platform };
});

interface DevicesProps {
    visible: boolean
}

const Devices: React.FC<DevicesProps> = ({ visible}) => {
    const [tab, setTab] = useState<Tab>('Platform / OS');
    const [whenFrom, setWhenFrom] = useState(formatDatetimeISO(getDayOffsetMillis(-365 * 10) + 60000));
    const [whenTo, setWhenTo] = useState(formatDatetimeISO());
    const [selectedPlatforms, setSelectedPlatforms] = useState(['ios', 'android']);
    const [fromBuild, setFromBuild] = useState('');
    const [toBuild, setToBuild] = useState('');

    const devices = useDevices(
        toMillis(whenFrom),
        toMillis(whenTo)
    );

    const filteredDevices = useMemo(() => {
        const filtered = [] as DeviceInfo[];
        const buildFrom = toInt(fromBuild);
        const buildTo = toInt(toBuild);
        devices?.forEach((device) => {
            if (!selectedPlatforms.includes(device.platform)) {
                return;
            }
            if (buildFrom > 0 && !(device.build && device.build >= buildFrom)) {
                return;
            }
            if (buildTo > 0 && !(device.build && device.build <= buildTo)) {
                return;
            }
            filtered.push(device);
        });
        return filtered;
    }, [devices, selectedPlatforms, fromBuild, toBuild]);

    const stats = useMemo(() => {
        if (!filteredDevices) return undefined;

        const reverseAlphabeticalOrder = (a: any, b: any) => {
            return String(b.name).localeCompare(a.name);
        };

        const makeGraphData = (stats: any) => {
            return Object.keys(stats).sort().map((groupName: string) => {
                return {
                    name: groupName,
                    value: stats[groupName]
                };
            });
        };

        const processGroup = (
            getGroupName: (info: DeviceInfo) => string,
            compare?: (a: any, b: any) => number
        ) => {
            const byGroupName = {} as any;
            filteredDevices?.forEach((data: DeviceInfo) => {
                const groupName = getGroupName(data);
                if (byGroupName[groupName] === undefined) {
                    byGroupName[groupName] = 0;
                }
                byGroupName[groupName]++;
            });
            const graphData = makeGraphData(byGroupName);
            if (compare) {
                graphData.sort(compare);
            }
            return graphData;
        };

        const simplify = (s: string) => {
            if (!s) return '';
            let index = 0;
            while (true) {
                if (s.length > index && s[index].toLowerCase() !== s[index].toUpperCase()) {
                    index++;
                } else {
                    break;
                }
            }
            return s.substring(0, index);
        };

        const bytesToCat = (b: number | undefined) => {
            if (b) {
                if (b < 500 * mb) {
                    return '< 500MB';
                } else if (b < 1000 * mb) {
                    return '500MB - 1GB';
                } else if (b < 5 * 1000 * mb) {
                    return '1-5GB';
                } else if (b < 10 * 1000 * mb) {
                    return '5-10GB';
                } else if (b < 50 * 1000 * mb) {
                    return '10-50GB';
                } else if (b < 100 * 1000 * mb) {
                    return '50-100GB';
                } else if (b < 1000 * 1000 * mb) {
                    return '100GB-1TB';
                } else if (b < 10000 * 1000 * mb) {
                    return '1-10TB';
                } else {
                    return '>10TB';
                }
            }
            return '?';
        };

        return {
            platforms: processGroup(
                (info) => info.platform ?? '?'
            ),
            operatingSystems: processGroup(
                (info) => info.operatingSystem ?? '?'
            ),
            platformAndOs: processGroup(
                (info) => info.platform === 'web' ? `web / ${info.operatingSystem ?? '?'}` : `${info.platform} app`
            ),
            osVersionsMajor: processGroup(
                (info) => info.osVersion ? `${info.operatingSystem ?? ''} ${info.osVersion.indexOf('.') !== -1 ? info.osVersion.substring(0, info.osVersion.indexOf('.')) : info.osVersion}` : '?'
            ),
            versions: processGroup(
                (info) => info.version ?? '?',
                reverseAlphabeticalOrder
            ),
            builds: processGroup(
                (info) => `${info.version ?? '?'} / ${String(info.build)}`,
                reverseAlphabeticalOrder
            ),
            osVersionsFull: processGroup(
                (info) => info.osVersion ? `${info.operatingSystem ?? ''} ${info.osVersion}` : '?'
            ),
            monthly: processGroup (
                (data) => data.whenUpdated ? makeDateTime(data.whenUpdated).toFormat('yyyy-MM MMM') : '?',
                reverseAlphabeticalOrder
            ),
            yearly: processGroup (
                (data) => data.whenUpdated ? makeDateTime(data.whenUpdated).toFormat('yyyy') : '?',
                reverseAlphabeticalOrder
            ),
            manufacturers: processGroup(
                (info) => info.manufacturer ?? '?'
            ),
            modelsSimplified: processGroup(
                (info) => info.model ? simplify(info.model) : '?'
            ),
            models: processGroup(
                (info) => info.model ?? '?'
            ),
            timezoneRegions: processGroup(
                (info) => (info.timezone && info.timezone.indexOf('/') !== -1) ? `${info.timezone.substring(0, info.timezone.indexOf('/'))}` : '?'
            ),
            timezones: processGroup(
                (info) => info.timezone ?? '?'
            ),
            freeSpace: processGroup(
                (info) => bytesToCat(info.realDiskFree)
            ),
            diskSize: processGroup(
                (info) => bytesToCat(info.realDiskTotal)
            ),
            userAgents: processGroup(
                (info) => info.userAgent ?? '?'
            )
            // Probably want a simplified version of userAgent as well... e.g. Chrome vs Firefox etc...
        };
    }, [filteredDevices]);

    return (
        <>
            <div className="error-reports columns">
                <div style={{ flex: '0 1 160px' }}>
                    <SeaDatetime
                        label="From"
                        value={whenFrom}
                        onchange={(e) => {
                            setWhenFrom(e.detail.value);
                        }}
                    />
                </div>
                <div style={{ flex: '0 1 160px' }}>
                    <SeaDatetime
                        label="To"
                        value={whenTo}
                        onchange={(e) => {
                            setWhenTo(e.detail.value);
                        }}
                    />
                </div>
                <div style={{ flex: '0 1 160px' }}>
                    <SeaMultiSelect
                        label="Platforms"
                        values={selectedPlatforms}
                        setValues={setSelectedPlatforms}
                        //setValues={setForVesselIds}
                        options={platformOptions}
                        useAllOption={true}
                        mode="popover"
                    />
                </div>
                <div style={{ flex: '0 1 90px' }}>
                    <SeaInput
                        label="From Build"
                        value={fromBuild}
                        type="number"
                        onchange={(e) => setFromBuild(e.detail.value)}
                    />
                </div>
                <div style={{ flex: '0 1 90px' }}>
                    <SeaInput
                        label="To Build"
                        value={toBuild}
                        type="number"
                        onchange={(e) => setToBuild(e.detail.value)}
                    />
                </div>
            </div>
            <div>
                {devices ? `${devices.length?.toLocaleString()} filtered device's.` : <i>Loading...</i>}
            </div>
            <div>
                <SeaTabsGroup selectedTab={tab} setTab={setTab as any} mode="forms" mini={true}>
                    {tabs.map((tabName) => {
                        return (
                            <SeaTab key={tabName} tab={tabName} setTab={setTab as any}>{tabName}</SeaTab>
                        );
                    })}
                </SeaTabsGroup>
            </div>
            {stats &&
                <div className={`reporting-grid max-4-graphs`} style={{ paddingTop: '16px' }}>
                    {tab === 'Platform / OS' &&
                        <SeaHorizontalBarGraph
                            n={1}
                            title="Platform"
                            mode="modal"
                            forAdmin={true}
                            data={stats.platforms}
                            hashNamesForColours={true}
                            yLabelWidth={80}
                            sortData={true}
                        />
                    }
                    {tab === 'Platform / OS' &&
                        <SeaHorizontalBarGraph
                            n={2}
                            title="Operating System"
                            mode="modal"
                            forAdmin={true}
                            data={stats.operatingSystems}
                            hashNamesForColours={true}
                            yLabelWidth={120}
                            sortData={true}
                        />
                    }
                    {tab === 'Platform / OS' &&
                        <SeaHorizontalBarGraph
                            n={3}
                            title="Platform + OS"
                            mode="modal"
                            forAdmin={true}
                            data={stats.platformAndOs}
                            hashNamesForColours={true}
                            yLabelWidth={150}
                            sortData={true}
                        />
                    }
                    {tab === 'OS Version' &&
                        <SeaHorizontalBarGraph
                            n={1}
                            title="OS Version (Major)"
                            mode="modal"
                            forAdmin={true}
                            data={stats.osVersionsMajor}
                            hashNamesForColours={true}
                            yLabelWidth={140}
                            sortData={true}
                        />
                    }
                    {tab === 'OS Version' &&
                        <SeaHorizontalBarGraph
                            n={2}
                            title="OS Version (Full)"
                            mode="modal"
                            forAdmin={true}
                            data={stats.osVersionsFull}
                            hashNamesForColours={true}
                            yLabelWidth={160}
                            sortData={true}
                        />
                    }
                    {tab === 'Version / Build' &&
                        <SeaHorizontalBarGraph
                            n={1}
                            title="App Version"
                            mode="modal"
                            forAdmin={true}
                            data={stats.versions}
                            hashNamesForColours={true}
                            yLabelWidth={100}
                        />
                    }
                    {tab === 'Version / Build' &&
                        <SeaHorizontalBarGraph
                            n={2}
                            title="Version / Build"
                            mode="modal"
                            forAdmin={true}
                            data={stats.builds}
                            hashNamesForColours={true}
                            yLabelWidth={100}
                        />
                    }
                    {tab === 'Model' &&
                        <SeaHorizontalBarGraph
                            n={2}
                            title="Models (Simplified)"
                            mode="modal"
                            forAdmin={true}
                            data={stats.modelsSimplified}
                            hashNamesForColours={true}
                            yLabelWidth={120}
                            sortData={true}
                        />
                    }
                    {tab === 'Model' &&
                        <SeaHorizontalBarGraph
                            n={2}
                            title="Models (Full)"
                            mode="modal"
                            forAdmin={true}
                            data={stats.models}
                            hashNamesForColours={true}
                            yLabelWidth={160}
                            sortData={true}
                        />
                    }
                    {tab === 'Model' &&
                        <SeaHorizontalBarGraph
                            n={3}
                            title="Manufacturers"
                            mode="modal"
                            forAdmin={true}
                            data={stats.manufacturers}
                            hashNamesForColours={true}
                            yLabelWidth={160}
                            sortData={true}
                        />
                    }
                    {tab === 'Browser' &&
                        <SeaHorizontalBarGraph
                            n={1}
                            title="Browser User Agents"
                            mode="modal"
                            forAdmin={true}
                            data={stats.userAgents}
                            hashNamesForColours={true}
                            yLabelWidth={180}
                            sortData={true}
                        />
                    }
                    {tab === 'Disk Space' &&
                        <SeaHorizontalBarGraph
                            n={1}
                            title="Disk Space Free"
                            mode="modal"
                            forAdmin={true}
                            data={stats.freeSpace}
                            hashNamesForColours={true}
                            yLabelWidth={120}
                            sortData={true}
                        />
                    }
                    {tab === 'Disk Space' &&
                        <SeaHorizontalBarGraph
                            n={1}
                            title="Disk Capacity"
                            mode="modal"
                            forAdmin={true}
                            data={stats.diskSize}
                            hashNamesForColours={true}
                            yLabelWidth={120}
                            sortData={true}
                        />
                    }
                    {tab === 'Last Active' &&
                        <SeaHorizontalBarGraph
                            n={1}
                            title="Last Active Month"
                            mode="modal"
                            forAdmin={true}
                            data={stats.monthly}
                            hashNamesForColours={true}
                            yLabelWidth={120}
                        />
                    }
                    {tab === 'Last Active' &&
                        <SeaHorizontalBarGraph
                            n={2}
                            title="Last Active Year"
                            mode="modal"
                            forAdmin={true}
                            data={stats.yearly}
                            hashNamesForColours={true}
                            yLabelWidth={80}
                        />
                    }
                    {tab === 'Timezones' &&
                        <SeaHorizontalBarGraph
                            n={1}
                            title="Timezone Regions"
                            mode="modal"
                            forAdmin={true}
                            data={stats.timezoneRegions}
                            hashNamesForColours={true}
                            yLabelWidth={180}
                            sortData={true}
                        />
                    }
                    {tab === 'Timezones' &&
                        <SeaHorizontalBarGraph
                            n={2}
                            title="Timezones"
                            mode="modal"
                            forAdmin={true}
                            data={stats.timezones}
                            hashNamesForColours={true}
                            yLabelWidth={180}
                            sortData={true}
                        />
                    }
                </div>
            }
        </>
    );
};

export default Devices;

import { SharedStateConfig, sharedState } from "../shared-state";
import { CategoriesData, onCategoriesSnapshot } from "../../lib/categories";

//
// Loads injuryTypes
//

export const injuryTypesConfig: SharedStateConfig<CategoriesData> = {
    isAlwaysActive: false,
    dependencies: ['licenseeId', 'licenseeSettings'],
    countLiveDocs: () => sharedState.injuryTypes.current?.ids.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        if (licenseeId && sharedState.licenseeSettings.current?.hasIncidents) {
            return onCategoriesSnapshot(
                'injuryTypes',
                'licenseeId',
                licenseeId,
                (data) => { // onLoaded
                    done();
                    set(data);
                },
                (error) => { // onError
                    done();
                    clear();
                    console.log(`Error getting injuryTypes`, error);
                }
            );
        }
    }
};


import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { sharedState, SharedStateConfig } from "../shared-state";
import { appActivityToJson } from "./appActivity";
import packageJson from '../../../package.json';
import { physicalDeviceInfo } from "../Core/deviceInfo";
import { isPlatform } from "@ionic/react";
import { toInt } from "../../lib/util";

export let iosRefreshProblem = undefined as any;
//export let reportRefresh = '';

export const setAppIsOkToRestart = (appIsOkToRestart: boolean, lastState: string) => {
    // If true, it means if the app restarts we should consider it ok/normal
    // If false, it means the app restarted unexpectedly
    // App is ok to restart if the app is in background mode
    window.localStorage.setItem('__appIsOkToRestart', appIsOkToRestart ? 'true' : 'false');
    window.localStorage.setItem('__appLastState', lastState);
};

export const checkForIosRefreshProblem = () => {
    //reportRefresh = `__appIsOkToRestart=${window.localStorage.getItem('__appIsOkToRestart')} __appLastState=${window.localStorage.getItem('__appLastState')} `;
    if (window.localStorage.getItem('__appIsOkToRestart') && window.localStorage.getItem('__appIsOkToRestart') === 'false') {
        //reportRefresh += `WANT TO REPORT! `;
        iosRefreshProblem = {
            lastState: window.localStorage.getItem('__appLastState'),
            appActivityJson: window.localStorage.getItem('__appActivity')
        }
    }
};

export const handleRefreshProblemReportingConfig: SharedStateConfig<string> = {
    isAlwaysActive: true,
    dependencies: ['deviceId', 'licenseeId'],
    default: 'Not run',
    run: (done, set, clear) => {
        done();
        const deviceId = sharedState.deviceId.current;
        const licenseeId = sharedState.licenseeId.current;
        if (deviceId && licenseeId) {
            //reportRefresh += `maybe... `;
            if (iosRefreshProblem) {
                // Report!
                //reportRefresh += `Report! `;

                //reportRefresh += `userId=${sharedState.userId.current} licenseeId=${sharedState.licenseeId.current} `;
                setDoc(
                    doc(collection(firestore, '_unintentionalRefreshes')),
                    {
                        userId: sharedState.userId.current,
                        licenseeId: licenseeId,
                        deviceId: deviceId,
                        lastState: iosRefreshProblem.lastState,
                        appActivity: appActivityToJson(iosRefreshProblem.appActivityJson),
                        whenInit: sharedState.appActivity.current!.init,
                        whenReported: Date.now(),
                        touched: serverTimestamp(),
                        version: packageJson.version,
                        build: toInt(packageJson.build),
                        device: physicalDeviceInfo,
                        platform: isPlatform('hybrid') ? (isPlatform('ios') ? 'ios' : 'android') : 'desktop'
                    }
                ).then(() => {
                    console.log(`Successfully reported unintentional refresh!`);
                    //reportRefresh += `Success `;
                }).catch((error: any) => {
                    console.log(`Failed to report refresh problem!`, error);
                    //reportRefresh += `Fail `;
                    //reportRefresh += error?.message + ' ';
                });
            }
        } else {
            set('Not ready');
        }
    }
};

import React, { useState, useEffect, Profiler } from 'react';
import { logPageView } from '../../../lib/firebase';
import { warnDays } from '../../../lib/util';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { permissionLevels } from '../../../shared-state/Core/userPermissions';
import { onProfilerRender } from '../../../lib/performance';
import { renderFullName } from '../../../shared-state/Core/users';
import { UserType } from '../../../shared-state/Core/user';
import { sharedState } from '../../../shared-state/shared-state';
import { TrainingTask } from '../../../shared-state/Crew/trainingTasks';
import { TrainingTaskReport, useTrainingTaskReports } from '../../../shared-state/Crew/trainingTaskReports';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import EditTrainingTask from '../../../modals/Crew/CrewTraining/EditTrainingTask/EditTrainingTask';
import EditTrainingReport from '../../../modals/Crew/CrewTraining/EditTrainingReport/EditTrainingReport';
import ViewTrainingTaskUser from '../../../modals/Crew/CrewTraining/ViewTrainingTaskUser/ViewTrainingTaskUser';
import ViewTrainingTask from '../../../modals/Crew/CrewTraining/ViewTrainingTask/ViewTrainingTask';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import SeaSelectVesselFilter from '../../../components/SeaSelectVesselFilter/SeaSelectVesselFilter';
import './CrewTraining.css';

interface CrewTrainingProps {
    visible: boolean
}

const CrewTraining: React.FC<CrewTrainingProps> = ({visible}) => {
    const trainingTasks = sharedState.trainingTasks.use(visible ? 1 : 101);
    const [selectedVesselId, setSelectedVesselId] = useState<string>();
    const trainingTaskReports = useTrainingTaskReports(selectedVesselId, visible ? 1 : 101);
    const vesselIds = sharedState.vesselIds.use(visible ? 1 : 101);
    const vesselId = sharedState.vesselId.use(visible ? 1 : 101);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [selectedTask, setSelectedTask] = useState<TrainingTask>();
    const [selectedUser, setSelectedUser] = useState<UserType>();
    const [selectedReport, setSelectedReport] = useState<TrainingTaskReport>();
    const [showEditTrainingTaskModal, setShowEditTrainingTaskModal] = useState(false);
    const [showEditTrainingReportModal, setShowEditTrainingReportModal] = useState(false);
    const [showViewTrainingTaskUserModal, setShowViewTrainingTaskUserModal] = useState(false);
    const [showViewTrainingTaskModal, setShowViewTrainingTaskModal] = useState(false);
    const [expandedTasks, setExpandedTasks] = useState<{
        [key: string]: boolean
    }>({});


    useEffect(() => {
        resetPageLimit();
    }, [vesselId, resetPageLimit]);

    useEffect(() => {
        if (visible) {
            logPageView('Crew/CrewTraining');
        }
        resetPageLimit();
    }, [visible, resetPageLimit]);

    // Make sure we have a default vessel selected
    useEffect(() => {
        if (visible && !selectedVesselId) {
            if (vesselId) {
                setSelectedVesselId(vesselId);
            } else if (vesselIds && vesselIds.length > 0) {
                setSelectedVesselId(vesselIds[0]);
            }
        }
    }, [visible, vesselIds, selectedVesselId, vesselId]);

    // useEffect(() => {
    //     if (vessels && vessels?.all?.length > 0) {
    //         if (selectedVesselId === undefined) {
    //             if (vesselId) {
    //                 setSelectedVesselIdForCrewTraining(vesselId);
    //             } else {
    //                 setSelectedVesselIdForCrewTraining(vessels.all[0].id);
    //             }
    //         }
    //     }
    // }, [vessels]);

    // Keep selectedTask fresh
    useEffect(() => {
        if (selectedTask?.id && trainingTasks?.byId[selectedTask.id]) { 
            setSelectedTask(trainingTasks.byId[selectedTask.id])
        };
    }, [trainingTasks, selectedTask?.id]);

    const onCompleteTraining = () => {
        setShowEditTrainingReportModal(true);
    };

    const onAddNewTask = () => {
        setShowEditTrainingTaskModal(true);
    };

    const onViewTask = (task: TrainingTask) => {
        setSelectedTask(task);
        setShowViewTrainingTaskModal(true);
    };

    const onViewTaskUser = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, task: TrainingTask, user: UserType, report: TrainingTaskReport) => {
        e.stopPropagation();
        setSelectedTask(task);
        setSelectedUser(user);
        setShowViewTrainingTaskUserModal(true);
        setSelectedReport(report);
    };

    const toggleTask = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, taskId: string) => {
        e.stopPropagation();
        const o = {...expandedTasks};
        if (expandedTasks[taskId]) {
            delete o[taskId];
            setExpandedTasks(o);
        } else {
            o[taskId] = true;
            setExpandedTasks(o);
        }
    };

    return (
        <RequirePermissions
            role="crewTraining"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="crew-tasks page-head">
                <div><h2>Crew Training</h2></div>
                <div className="actions">
                    <SeaSelectVesselFilter
                        filterVesselId={selectedVesselId ?? ''}
                        setFilterVesselId={setSelectedVesselId}
                        includeAll={false}
                    />
                    {/* <SeaSelect
                        name="selectedVesselId"
                        value={selectedVesselId}
                        width="200px"
                        zone="grey"
                        onchange={(e) => setSelectedVesselIdForCrewTraining(e.detail.value)}
                    >
                        {vessels?.all?.map((vessel) => {
                            return (
                                <IonSelectOption key={vessel.id} value={vessel.id}>{vessel.name}</IonSelectOption>
                            );
                        })}
                    </SeaSelect> */}
                    <RequirePermissions
                        role="crewTraining"
                        level={permissionLevels.COMPLETE}
                    >
                        <SeaButton onClick={(e) => onCompleteTraining()} zone="grey">
                            <SeaIcon slot="start" icon="notes"/>
                            Complete Training
                        </SeaButton>
                        <RequirePermissions
                            role="crewTraining"
                            level={permissionLevels.CREATE}
                        >
                            <div className="spacer"></div>
                            <SeaButton onClick={(e) => onAddNewTask()} zone="grey">
                                <SeaIcon slot="start" icon="add"/>
                                Add New Task
                            </SeaButton>
                        </RequirePermissions>
                    </RequirePermissions>
                    {/* <div className="spacer"></div>
                    <SeaButton onClick={(e) => onGeneratePdf()} zone="grey" shape="circle"><SeaIcon slot="icon-only" icon="pdf"/></SeaButton> */}
                </div>
            </div>

            <div className="crew-training-content">
                <SeaNoData
                    dataName="training tasks for the selected vessel or facility"
                    isLoading={!trainingTasks || !trainingTaskReports}
                    hasNoData={!!(selectedVesselId && trainingTasks?.allByVesselId && trainingTasks.allByVesselId[selectedVesselId] === undefined)}
                    isUsingFilter={false}
                />

                <div className={`crew-tasks has-thumbs ${(selectedVesselId && trainingTasks?.allByVesselId && trainingTasks.allByVesselId[selectedVesselId] && trainingTasks?.allByVesselId[selectedVesselId].length > 0) ? 'reveal' : 'conceal'}`}>
                    <div className="sea-row headings">
                        <div></div>
                        <div>Task</div>
                        {trainingTaskReports?.users?.map((user: UserType) => {
                            if (selectedVesselId && user.vesselIds?.includes(selectedVesselId)) {
                                return <div key={user.id}>{renderFullName(user)}</div>
                            }
                            return undefined;
                        })}
                        <div></div>
                    </div>
                    <Profiler id="crew.training" onRender={onProfilerRender}>
                        {selectedVesselId && mapArrayWithLimit(trainingTasks?.allByVesselId[selectedVesselId], (task: TrainingTask) => {
                            let foundUser = false;
                            return (
                                <div key={task.id} className={`sea-card sea-row no-select ${expandedTasks[task.id] ? 'expand' : 'shrunk'}`} onClick={(e) => onViewTask(task)}>
                                    <div><SeaFileImage files={task.files} size="tiny"/></div>
                                    <div className="bold truncate-3">
                                        {task.task}
                                    </div>
                                    {trainingTaskReports?.users?.map((user: UserType) => {
                                        if (selectedVesselId && user.vesselIds?.includes(selectedVesselId)) {
                                            const key = `${task.id}${user.id}`;
                                            const report = trainingTaskReports.byTaskAndUser[key];
                                            if (report) {
                                                foundUser = true;
                                                return (
                                                    <div key={key} data-fullname={renderFullName(user)} className="slot" onClick={(e) => onViewTaskUser(e, task, user, report)}>
                                                        <div className={`pushy due ${
                                                            (report.whenDueDiff && report.whenDueDiff < 0) ? 'od' : 
                                                            ((report.whenDueDiff && report.whenDueDiff <= warnDays.crewTraining[0]) ? 'soon' : 'ok')
                                                        }`}>
                                                            {report.whenDueDiff && Math.abs(report.whenDueDiff)}
                                                            <div>
                                                                {report.whenDueDiff === 1 || report.whenDueDiff === -1 ? 'DAY' : 'DAYS'}{report.whenDueDiff && report.whenDueDiff < 0 && ' OD'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div key={key}>

                                                    </div>
                                                );
                                            }
                                        }
                                        return undefined;
                                    })}
                                    {foundUser &&
                                        <div
                                            className={`more`}
                                            onClick={(e) => toggleTask(e, task.id)}
                                        >
                                            <span>Crew</span> <SeaIcon icon={expandedTasks[task.id] ? 'moveUp' : 'moveDown'}/>
                                        </div>
                                    }
                                </div>
                            )
                        })}
                        {limitTriggerElement}
                    </Profiler>
                </div>
            </div>
            {visible && trainingTaskReports &&
                <>
                    <EditTrainingTask
                        vesselId={selectedVesselId}
                        showModal={showEditTrainingTaskModal}
                        setShowModal={setShowEditTrainingTaskModal}
                    />
                    <EditTrainingReport
                        vesselId={selectedVesselId}
                        trainingTasks={trainingTasks}
                        showModal={showEditTrainingReportModal}
                        setShowModal={setShowEditTrainingReportModal}
                        trainingTaskReports={trainingTaskReports}
                    />
                    {selectedTask && <ViewTrainingTask
                        vesselId={selectedVesselId}
                        trainingTaskReports={trainingTaskReports}
                        selectedTask={selectedTask}
                        showModal={showViewTrainingTaskModal}
                        setShowModal={setShowViewTrainingTaskModal}
                    />}
                    {selectedTask && <ViewTrainingTaskUser
                        trainingTaskReports={trainingTaskReports}
                        selectedTask={selectedTask}
                        selectedUser={selectedUser}
                        selectedReport={selectedReport}
                        vesselId={selectedVesselId}
                        showModal={showViewTrainingTaskUserModal}
                        setShowModal={setShowViewTrainingTaskUserModal}
                    />}
                </>
            }
        </RequirePermissions>
    );
};

export default CrewTraining;

import { disableNetwork, enableNetwork } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { sharedState, SharedStateConfig } from "../shared-state";

//
// Only relevant for iOS.
// We control firestore networking to safeguard against indexedDb issues specific to iOS.
//
// The problem:
// When an iOS app comes back into the foreground from having been in the background indexedDb can be broken.
// This is bad because anything to do with firestore will fail... anything like pending writes or any
// changes to the database at all.
//
// The workaround:
// We disable firestore networking when the app goes into the background.
// Then we re-enable it then the app comes back into the foreground.
// Well, there's more to it than that, see sharedState.appState for more...
//

export type FirestoreState = {
    isEnabled: boolean
};

export const firestoreStateConfig: SharedStateConfig<FirestoreState> = {
    isAlwaysActive: true,
    default: {
        isEnabled: true
    },
    notes: 'Only relevant for iOS! We control firestore networking to safeguard against indexedDb issues specific to iOS.'
};

export const enableFirestoreNetwork = () => {
    return enableNetwork(firestore).then(() => {
        console.log('Firestore network ENABLED');
        //debugApp('File Caching', 'Firestore network ENABLED.');
        sharedState.firestoreState.set((current) => {
            return {
                ...current!,
                isEnabled: true
            };
        });
        //debugApp('Data Sync', 'Firestore network ENABLED');
    }).catch((e) => {
        console.log('Failed to enable firestore network', e);
        //debugApp('Data Sync', 'Failed to enable firestore network');
    });
};

export const disableFirestoreNetwork = () => {
    return disableNetwork(firestore).then(() => {
        console.log('Firestore network DISABLED');
        //debugApp('File Caching', 'Firestore network DISABLED.');
        sharedState.firestoreState.set((current) => {
            return {
                ...current!,
                isEnabled: false
            };
        });
        //debugApp('Data Sync', 'Firestore network DISABLED');
    }).catch((e) => {
        console.log('Failed to disable firestore network', e);
        //debugApp('Data Sync', 'Failed to disable firestore network');
    });
};

import { DocumentData, QueryDocumentSnapshot, collection, orderBy, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../lib/firebase";
import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { registerFiles } from "../FileSyncSystem/filesToCache";
import { canView } from "../Core/userPermissions";

//
// Load dangerous goods
//

export interface DangerousGood extends CreateableDocument, UpdateableDocument {
    class?: string;
    deletedBy?: string;
    imageFiles: string[];
    isHazardous: boolean;
    location?: string;
    msdsFiles: string[];
    name: string;
    quantity?: string;
    state: string;
    vesselIds: string[];
    whenDeleted?: number;
    whenExpires?: number;
}

export type DangerousGoodsData = {
    all: DangerousGood[], // Active & alphabetical
    byId: {
        [goodId: string]: DangerousGood
    },
    byVesselId: {
        [vesselId: string]: DangerousGood[] // Active & alphabetical
    }
};

export const dangerousGoodsConfig: SharedStateConfig<DangerousGoodsData> = {
    isAlwaysActive: false,
    dependencies: ['vesselIds'],
    countLiveDocs: () => sharedState.dangerousGoods.current?.all.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselIds = sharedState.vesselIds.current;

        if (
            vesselIds &&
            vesselIds.length > 0 &&
            canView('dangerousGoodsRegister')
        ) {
            return setupArrayQueryListener(
                'dangerous goods', // what
                collection(firestore, 'dangerousGoods'),
                [where('state', '==', 'active')], // baseConstraints
                'vesselIds',
                'array-contains-any',
                vesselIds,
                [orderBy('name', 'asc')],
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean
                ) => { // processDocs
                    done();
                    const all = docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as DangerousGood;
                    });

                    if (isCombined) { // Need to sort by name
                        all.sort((a, b) => {
                            return a.name.localeCompare(b.name);
                        });
                    }

                    const byId = {} as {
                        [goodId: string]: DangerousGood
                    };
                    const byVesselId = {} as {
                        [vesselId: string]: DangerousGood[]
                    };
                    all.forEach((good) => {
                        registerFiles(good.imageFiles, 'dangerousGoods', good);
                        registerFiles(good.msdsFiles, 'dangerousGoods', good);
                        byId[good.id] = good;
                        for (const vesselId of good.vesselIds) {
                            if (byVesselId[vesselId] === undefined) {
                                byVesselId[vesselId] = [] as DangerousGood[];
                            }
                            byVesselId[vesselId].push(good);
                        }
                    });

                    set({
                        all,
                        byId,
                        byVesselId
                    });
                }, (error) => {
                    done();
                }
            );
        }
    }
};

import React, { useState, useMemo, useCallback } from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { sharedState } from '../../../../shared-state/shared-state';
import { formatDay, formatTime, pulseElementById, toFloat } from '../../../../lib/util';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { Voyage, VoyageDay } from '../../../../shared-state/VesselLogbook/voyages';
import { CustomHours } from '../../CreateLogbookReport/CreateLogbookReport';
import { PreparedVoyageDay } from '../voyage-utils';
import SeaTabsGroup from '../../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../../../components/SeaTab/SeaTab';
import SeaTabContent from '../../../../components/SeaTabContent/SeaTabContent';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaCustomFields from '../../../../components/SeaCustomFields/SeaCustomFields';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaCrewHours, { SeaCrewHour, SeaCrewHourData } from '../../../../components/SeaCrewHours/SeaCrewHours';
import './VoyageDays.css';

interface VoyageDaysProps {
    showModal: boolean,
    days: VoyageDay[],
    setDays: React.Dispatch<React.SetStateAction<PreparedVoyageDay[] | VoyageDay[]>>,
    dayTab: string,
    setDayTab: (dayTab: string) => void,
    activeVoyage?: Voyage,
    personnelInvolvedIncludingMasters: string[],
    makeCrewHours: (parent: any) => any,
    whenDeparted: number,
    makeInitialCustomFields: (possibleFields: string[], data: any[], defaultValue: string) => any[],
    isCompletingVoyage?: boolean
}

const VoyageDays: React.FC<VoyageDaysProps> = ({
    showModal,
    days,
    setDays,
    dayTab,
    setDayTab,
    activeVoyage,
    personnelInvolvedIncludingMasters,
    makeCrewHours,
    whenDeparted,
    makeInitialCustomFields,
    isCompletingVoyage
}) => {
    const vessel = sharedState.vessel.use(showModal);
    const [showCommonActions, setShowCommonActions] = useState(false);

    const dayNames = useMemo(() => {
        const names = [] as string[];
        if (days && whenDeparted) {
            let whenDay = whenDeparted;
            for (let i = 0; i < days.length; i++) {
                names.push(
                    formatDay(whenDay)
                );
                whenDay += 24*60*60*1000;
            }
        }
        return names;
    }, [days, whenDeparted]);

    const setDayValue = (dayIndex: number, field: keyof VoyageDay, value: any) => {
        setDays((prevDays) => {
            const updatedDays = [...prevDays] as VoyageDay[] | PreparedVoyageDay[];
            updatedDays[dayIndex] = {
                ...updatedDays[dayIndex],
                [field]: value
            };
            return updatedDays;
        });
    };

    const onAddDayTab = () => {
        setDayTab(''+days.length);
        const newDay = {
            notes: '',
            customText: makeInitialCustomFields(vessel?.logbookSettings?.customTextFields || [], activeVoyage?.customTextFields || [], ''),
            customHours: makeInitialCustomFields(vessel?.logbookSettings?.customHourFields || [], activeVoyage?.customHourFields || [], '0'),
            crewHours: makeCrewHours(undefined),
            totalHours: 0
        };
        setDays([...days, newDay]);
    };

    const onDeleteLastDay = (e: React.MouseEvent<Element, MouseEvent>) => {
        e.preventDefault();
        confirmAction(`Are you sure you want to delete ${dayNames[days.length - 1]}?`, `Yes, delete`).then(() => {
            let _days = [...days];
            _days.splice(days.length - 1, 1);
            setDays(_days);
            setDayTab(''+(_days.length - 1));
        }).catch(() => {});
    };

    const addCommonAction = (action: string, index: number, currentNotes: string) => {
        let s = '';
        if (currentNotes && currentNotes.length > 0) {
            s += currentNotes + '\n';
        }
        s += `${formatTime()} - ${action}`;
        //setFieldValue('voyageNotes', s);
        setDayValue(index, 'notes', s);
        pulseElementById(`dailyNotes${index}`, 0);
    };

    const dayHasErrors = useCallback((day: VoyageDay) => {
        if (
            isCompletingVoyage &&
            vessel?.logbookSettings?.crewHours === 'mandatory' &&
            personnelInvolvedIncludingMasters?.length > 0
        ) {
            for (let i = 0; i < personnelInvolvedIncludingMasters.length; i++) {
                if (toFloat((day?.crewHours as { [id: string]: SeaCrewHour} | undefined)?.[personnelInvolvedIncludingMasters[i]].hours, 0) <= 0) {
                    return true;
                }
            }
        }
        return false;
    }, [vessel?.logbookSettings, isCompletingVoyage, personnelInvolvedIncludingMasters]);

    return (<>
        <SeaTabsGroup
            selectedTab={dayTab}
            setTab={setDayTab}
            mode="forms"
            micro={true}
            showPlus={true}
            plusText="Add Day"
            disablePlus={false} // Todo: stop if in future?
            onAddTab={(e) => onAddDayTab()}
            onAddTabFailed={(e) => {
                // Do we need this?
            }}
            alwaysShowAllTabs={true}
        >
            {days.map((day, index: number) => {
                return (
                    <SeaTab
                        key={index}
                        tab={''+index}
                        //hasErrors={tripsTouched && tripsTouched[index]?.any && tripsErrors && tripsErrors[index]?.any}
                        //hasErrors={tabHasErrors && tabHasErrors[index]?.any}
                        hasErrors={dayHasErrors(day)}
                        mode="forms"
                        width="84px"
                    >
                        {dayNames?.length > index && dayNames[index]}
                    </SeaTab>
                );
            })}
        </SeaTabsGroup>
        {days.map((day: VoyageDay, index: number) => {
            return (
                <SeaTabContent
                    key={index}
                    tab={''+index}
                    selectedTab={dayTab}
                >
                    <IonGrid className="form-grid">
                        <IonRow>
                            <IonCol size="12">
                                <SeaTextarea
                                    label="Daily Notes"
                                    name="notes"
                                    id={`dailyNotes${index}`}
                                    value={day.notes}
                                    maxLength={5000}
                                    //onchange={handleChange}
                                    onchange={(e: CustomEvent<any>) => {
                                        setDayValue(index, 'notes', e.detail.value);
                                    }}
                                    //onblur={handleBlur}
                                    height={300}
                                    zone="white"
                                    inputmode="text"
                                    //error={touched.voyageNotes ? errors.voyageNotes : ''}
                                />
                                {(vessel?.logbookSettings?.actions || [])?.length > 0 &&
                                    <>
                                        <div className="input-link no-select">
                                            <div onClick={(e) => {
                                                setShowCommonActions((previous: boolean) => {
                                                    return !previous
                                                });
                                            }}>
                                                {showCommonActions ? 'Hide' : 'Show' } common actions&nbsp;
                                                <SeaIcon icon={showCommonActions ? 'moveUp' : 'moveDown'} />
                                            </div>
                                        </div>
                                        <div className={`common-actions ${showCommonActions ? 'show' : 'hide'}`}>
                                            <div>
                                                {vessel?.logbookSettings?.actions?.map((action: string) => {
                                                    return (
                                                        <div key={action} className="pushy no-select" onClick={(e) => addCommonAction(action, index, day.notes || '')}>
                                                            {action}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </>
                                }
                            </IonCol>
                            <SeaCustomFields
                                data={day.customText}
                                setData={(data) => {
                                    setDayValue(index, 'customText', data as VoyageDay[]);
                                }}
                                inputmode="text"
                                type="text"
                                maxLength={500}
                            />
                            {day.customHours && day.customHours?.length > 0 &&
                                <>
                                    <IonCol size="12">
                                        <div className="form-line"></div>
                                    </IonCol>
                                    <SeaCustomFields
                                        data={day.customHours}
                                        setData={(data) => {
                                            setDayValue(index, 'customHours', data as CustomHours[]);
                                        }}
                                        inputmode="numeric"
                                        type="number"
                                        maxLength={10}
                                        isHours={true}
                                        totalHours={day.totalHours}
                                        setTotalHours={(hours: number) => {
                                            setDayValue(index, 'totalHours', hours);
                                        }}
                                    />
                                </>
                            }
                            {!(vessel?.logbookSettings?.crewHours === 'hidden') &&
                                <>
                                    <IonCol size="12">
                                        <div className="form-line"></div>
                                    </IonCol>
                                    <IonCol size="12">
                                        <div className="sea-label blue">Crew Hours</div>
                                    </IonCol>
                                    <SeaCrewHours
                                        name={`crewHours${index}`}
                                        crewHours={day.crewHours}
                                        setCrewHours={(hours: SeaCrewHourData) => {
                                            setDayValue(index, 'crewHours', hours);
                                        }}
                                        personnelInvolved={personnelInvolvedIncludingMasters}
                                        totalHours={day.totalHours || 0}
                                        colSize="3"
                                        isRequired={
                                            isCompletingVoyage &&
                                            vessel?.logbookSettings?.crewHours === 'mandatory'
                                        }
                                    />
                                    {days?.length > 1 && (index === days.length - 1) &&
                                        <IonCol size="4" style={{ paddingTop: '8px' }}>
                                            <SeaLinkButton mode="standard-link" onClick={(e: React.MouseEvent<Element, MouseEvent>) => onDeleteLastDay(e)}>Delete day</SeaLinkButton>
                                        </IonCol>
                                    }
                                </>
                            }
                        </IonRow>
                    </IonGrid>
                </SeaTabContent>
            );
        })}
    </>);
};

export default VoyageDays;

import { DocumentData, QueryDocumentSnapshot, collection, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../lib/firebase";
import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { canView } from "../../shared-state/Core/userPermissions";
import { ControlStrategies, InjuryConclusions } from '../../pages/HealthSafety/Incidents/incidents-util';
import { registerFiles, registerSignature } from "../FileSyncSystem/filesToCache";

//
// Loads incident reviews.
// An incident may have 0 or 1 incident reviews.
//

export interface IncidentReview extends CreateableDocument, UpdateableDocument {
    addedToRiskRegister?: string;
    analysis?: string;
    causeIds: string[];
    completedBy?: string;
    deletedBy?: string;
    externalRequired?: string;
    files: string[];
    injuryConclusions?: InjuryConclusions[];
    licenseeId: string;
    lostTime?: string;
    notifiedAuthorities?: string;
    prevention?: string;
    severity: number;
    signature?: string;
    state: string;
    strategies: ControlStrategies[];
    vesselId: string;
    whenCompleted?: number;
    whenDeleted?: number;
}

export type IncidentReviewsData = {
    byId: {
        [incidentId: string]: IncidentReview
    },
};

export const incidentReviewsConfig: SharedStateConfig<IncidentReviewsData> = {
    isAlwaysActive: false,
    dependencies: ['vesselIds', 'licenseeSettings'],
    countLiveDocs: () => Object.keys(sharedState.incidentReviews.current?.byId ?? {}).length,
    run: (done, set, clear) => {
        clear();
        const vesselIds = sharedState.vesselIds.current;
        if (
            vesselIds &&
            vesselIds.length > 0 &&
            sharedState.licenseeSettings.current?.hasIncidents &&
            canView('incidentAccidentMedicalRegister')
        ) {
            return setupArrayQueryListener(
                'incidentReviews', // what
                collection(firestore, 'incidentReviews'),
                [where('state', 'in', ['draft', 'completed'])], // baseConstraints
                'vesselId',
                'in',
                vesselIds,
                [],
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean
                ) => { // processDocs
                    done();
                    const byId = {} as {
                        [id: string]: IncidentReview
                    };
                    docs.forEach((doc) => {
                        const review = {
                            id: doc.id,
                            ...doc.data()
                        } as IncidentReview;
                        byId[doc.id] = review;
                        registerFiles(review.files, 'incidentReviews', review);
                        registerSignature(review.signature, 'incidentReviews', review);
                    });
                    set({
                        byId
                    });
                }, (error) => {
                    done();
                }
            );
        }
    }
};

import React, { useState, useMemo, useEffect } from 'react';
import { sharedState } from '../../shared-state/shared-state';
import { reportError } from '../../managers/ErrorsManager/ErrorsManager';
import SeaIcon from '../SeaIcon/SeaIcon';
import SyncModal from '../../modals/Utilities/SyncModal/SyncModal';
import './SyncFilesAndData.css';

const SyncFiles: React.FC = () => {
    const onlineStatus = sharedState.onlineStatus.use();
    const dataSyncStatus = sharedState.dataSyncStatus.use();
    const fileSyncStatus = sharedState.fileSyncStatus.use();
    const fileUploadStatus = sharedState.fileUploadStatus.use();
    const diskSpaceStatus = sharedState.diskSpaceStatus.use();
    const [showSyncModal, setShowSyncModal] = useState(false);

    useEffect(() => {
        if (fileUploadStatus?.error) {
            reportError(`Failed to upload a file`, fileUploadStatus.error.message, fileUploadStatus.error, fileUploadStatus.error.data);
        }
    }, [fileUploadStatus?.error]);

    const mode = useMemo(() => {
        if (!diskSpaceStatus!.haveEnoughSpace) {
            return 'noSpace';
        } else if (!onlineStatus?.isOnline) {
            return 'offline';
        } else if (
            (dataSyncStatus && dataSyncStatus.totalTasks > 0) ||
            (fileSyncStatus && fileSyncStatus.filesLeft > 0) ||
            (fileUploadStatus && fileUploadStatus.filesLeft > 0)
        ) {
            return 'syncing';
        }
        return 'ok'
    }, [onlineStatus, dataSyncStatus, fileSyncStatus, fileUploadStatus, diskSpaceStatus]);

    return (
        <>
            <div
                className={`item name sync`}
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                    setShowSyncModal(true);
                }}
            >
                <div style={{
                    padding: '2px 0px 0px 2px',
                    fontWeight: 'normal',
                    fontSize: '11px'
                }}>
                    {mode === 'noSpace' &&
                        <div style={{ paddingTop: '6px', color: 'var(--ion-color-danger)', position: 'relative' }}>
                            <SeaIcon icon="sync" forceFontSize="28px"/>
                            <div style={{ position: 'absolute', top: '12px', left: '6px' }}>
                                <SeaIcon icon="alertSimple" forceFontSize="16px" />
                            </div>
                        </div>
                    }
                    {mode === 'offline' &&
                        <div style={{ paddingTop: '6px', color: 'var(--ion-color-danger)' }}>
                            <SeaIcon icon="offline" forceFontSize="25px" />
                        </div>
                    }
                    {mode === 'syncing' &&
                        <div className="sync-spinner">
                            <SeaIcon icon="sync" forceFontSize="28px"/>
                        </div>
                    }
                    {mode === 'ok' &&
                        <div style={{ paddingTop: '5px', opacity: 1 }}>
                            <SeaIcon icon="cloudOk" forceFontSize="25px" />
                        </div>
                    }
                </div>
            </div>
            <SyncModal
                showModal={showSyncModal}
                setShowModal={setShowSyncModal}
                onlineStatus={onlineStatus}
                dataSyncStatus={dataSyncStatus}
                fileSyncStatus={fileSyncStatus}
                fileUploadStatus={fileUploadStatus}
                diskSpaceStatus={diskSpaceStatus}
            />
        </>
    );

};


export default SyncFiles;
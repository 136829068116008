import React, { useEffect, useState, useRef } from 'react';
import { disableSwipe, enableSwipe } from '../../shared-state/General/swipeGestures';
import { getFileTrappedSrc, getImgSrc, SeaFile } from '../../lib/files';
import { FileCollection, getCachedFileSrc } from '../../shared-state/FileSyncSystem/cachedFiles';
import SignatureCanvas from 'react-signature-canvas';
import SeaInputError from '../SeaInputError/SeaInputError';
import './SeaSignature.css';

interface SeaSignatureProps {
    file?: SeaFile,
    setFile?: (file?: SeaFile) => void,
    collection: FileCollection, // collection this will be attached to
    field?: string,     // collection.field this will be attached to ("signature" by default)
    label?: any,
    isRequired?: boolean, // if true will show an error when not set.
        // Plug formik's isSubmitting into this so it only shows once the form has been submitted.
        // Remember to check in handleSubmit for the presence of file
    isRequiredError?: string,
    viewOnly?: boolean
}

const SeaSignature: React.FC<SeaSignatureProps> = ({ file, setFile, collection, field = 'signature', label = 'Sign or intitial below', isRequired, isRequiredError = 'Your signature is required', viewOnly }) => {
    const [existingImgSrc, setExistingImgSrc] = useState<string>();
    const sigRef = useRef<any>({});
    const width = 350;
    const height = 200;
    const hasExistingImgRef = useRef(false);
    const fileUseEffectNRef = useRef(0); // id to prevent file useEffect from overlapping itself

    let error = '';
    if (isRequired && !file) {
        error = isRequiredError;
    }

    const resetSignature = () => {
        sigRef.current.clear();
        if (setFile) {
            setFile(undefined);
        }
    };

    useEffect(() => {
        fileUseEffectNRef.current = fileUseEffectNRef.current + 1;
        const fileUseEffectN = fileUseEffectNRef.current;
        if (file?.id) {
            getCachedFileSrc(`${file.state}${file.id}.${file.ext}`, 'S').then((src) => {
                if (fileUseEffectN !== fileUseEffectNRef.current) {
                    return; // useEffect has run again since this promise resolved
                }
                setExistingImgSrc(src);
                hasExistingImgRef.current = true;
            }).catch((e) => {
                if (fileUseEffectN !== fileUseEffectNRef.current) {
                    return; // useEffect has run again since this promise resolved
                }
                if (file.state === 0) {
                    setExistingImgSrc(getFileTrappedSrc('sig'));
                } else {
                    getImgSrc(
                        file.state!,
                        file.id!,
                        file.ext!,
                        'sig'
                    ).then((_imgSrc: string) => {
                        setExistingImgSrc(_imgSrc);
                    });
                }
                hasExistingImgRef.current = true;
            });
        } else {
            hasExistingImgRef.current = false;
            setExistingImgSrc(undefined);
        }
    }, [file]);

    useEffect(() => {
        sigRef.current._sigPad.onBegin = (e: any) => {
            disableSwipe();
            if (hasExistingImgRef.current) {
                if (setFile) setFile(undefined);
            }
        };
        sigRef.current._sigPad.onEnd = (e: any) => {
            const base64 = sigRef.current.toDataURL('image/png', { backgroundColor: '#f00' });
            if (setFile) {
                setFile({
                    collection: collection,
                    field: field,
                    ext: 'png',
                    contentType: 'image/png',
                    lastModified: Date.now(),
                    base64: base64.substring(base64.indexOf(',') + 1),
                    isSignature: true
                });
            }
            enableSwipe(500);
        };
    }, [collection, field, setFile]);

    return (<>
        <div className="sea-sig-container columns no-select" style={{width: `${width}px`}}>
            <div className="sea-label sig">
                {label}
            </div>
            <div className="sea-label right" style={{height: '100%'}}>
                <div className={`reset-button pushy ${file ? 'reveal' : 'conceal'}`} onClick={(e) => resetSignature()}>
                    <span>RESET</span>
                </div>
            </div>
        </div>
        <div
            className={`sea-sig-canvas-box ${error ? 'has-error' : ''}`}
            style={{width: (width+2)+'px', height: (height+2)+'px'}}
        >
            {existingImgSrc &&
                <img
                    alt="existing signature"
                    src={existingImgSrc}
                    style={{ position: 'absolute' }}
                    onClick={(e) => resetSignature()}
                />
            }
            <div style={{ pointerEvents: viewOnly ? 'none' : 'all' }}>
                <SignatureCanvas
                    ref={sigRef}
                    penColor='#666'
                    // backgroundColor='rgba(255,255,255,0)'
                    canvasProps={{
                        width: width,
                        height: height,
                        className: 'sea-sig-canvas'
                    }}
                />
            </div>
        </div>
        <div style={{ width: (width+2)+'px' }}>
            <SeaInputError>{error}</SeaInputError>
        </div>
    </>);
};

export default SeaSignature;

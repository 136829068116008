import { collection, getDocs, query, Timestamp } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useEffect, useState } from "react";

//
// Loads collection _fileInfo
// This can only be used by superAdmin.
//

export type FileInfoData = {
    id: string,
    when?: number,
    licenseeIds: string[],
    licensees?: string[], // Not set within this file. Added later during filter process.
    isSignature?: boolean,
    type?: string,        // Not set within this file. Added later during filter process.
    collection?: string,
    ext?: string,
    extMooshed?: string, // Lowercase version. Equivalent exts combined like jpeg --> jpg
    name: string,
    fileState?: 0 | 1 | 2,
    storageState?: 0 | 1 | 2,
    refsState?: 0 | 1 | 2,
    sizes?: {
        [fileType: string]: number
    },
    numRefs: number,
    problems?: {
        badLicenseeIds?: true,
        trappedOnDevice?: true,
        lostForever?: true,
        storageStateAhead?: true,
        storageStateBehind?: true,
        storageWithNoFile?: true,
        undefinedRefState?: true,
        inconsistentRefStates?: true,
        badStatePropagation?: true,
        failedToOptimise?: true,
    },
    problemText?: string // Not set within this file. Added later during filter process.
};

export const useFileInfo = () => {
    const [fileInfo, setFileInfo] = useState<{all: FileInfoData[], when: number} | undefined>();

    useEffect(() => {
        setFileInfo(undefined);
        // Just get once, no need to actively listen
        let when = 0;
        getDocs(
            query(
                collection(firestore, '_fileInfoData')
            )
        ).then((snap) => {
            const all = [] as FileInfoData[];
            snap.docs.forEach((doc) => {
                when = (doc.data().touched as Timestamp).toMillis();
                doc.data().items?.forEach((item: FileInfoData) => {
                    all.push(item);
                });
            });
            setFileInfo({
                all,
                when
            });
        }).catch((error) => {
            console.error(`Error getting _fileInfoData`, error);
        });
    }, []);

    return fileInfo;
};

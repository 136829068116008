import { collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { canView } from "../../shared-state/Core/userPermissions";
import { getDayOffsetMillis, warnDays } from "../../lib/util";
import { registerFiles } from "../FileSyncSystem/filesToCache";

//
// Loads Safety Equipment Expiries
//

export interface SafetyEquipmentItem extends CreateableDocument, UpdateableDocument {
    deletedBy?: string;
    description?: string;
    emailReminder?: string;
    engineHoursLeft?: number;
    files: string[];
    hasFault?: boolean;
    interval?: string;
    isCritical?: boolean;
    itemId: string;
    locationId?: string;
    name: string;
    quantity?: string;
    state: string;
    type: string;
    useHours?: boolean;
    vesselId: string;
    whenDeleted?: number;
    whenDue?: number;
    whenFaulted?: number;
    whenLastChecked?: number;
    whenToRemind?: number;
}

export type SafetyEquipmentItemsData = {
    all:  {
        servicable: SafetyEquipmentItem[],
        expiring: SafetyEquipmentItem[],
        nonExpiring: SafetyEquipmentItem[],
    },
    prioritised: {
        servicable: {
            [id: string]: SafetyEquipmentItem
        }
        expiring: {
            [id: string]: SafetyEquipmentItem
        }
        nonExpiring: {
            [id: string]: SafetyEquipmentItem
        }
    },
    byId: {
        [id: string]: SafetyEquipmentItem
    },
    count: number
};

export const safetyEquipmentItemsConfig: SharedStateConfig<SafetyEquipmentItemsData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId', 'vesselSafetyItems', 'todayMillis'], // todayMillis needed because we're using getDayOffsetMillis
    countLiveDocs: () => sharedState.safetyEquipmentItems.current?.count ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        const vesselSafetyItems = sharedState.vesselSafetyItems.current;
        if (
            vesselId &&
            vesselSafetyItems?.ids &&
            canView('safetyEquipmentList')
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'safetyEquipmentItems'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'active'),
                    // orderBy('whenDue', 'asc') <-- Sorting by whenDue meant that no nonExpiring documents were returned
                ),
                (snap) => {
                    done();
                    const items = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                        } as SafetyEquipmentItem;
                    }).sort((a, b) => {
                        if (a.whenDue === undefined && b.whenDue === undefined) return 0;
                        if (a.whenDue === undefined) return 1;
                        if (b.whenDue === undefined) return -1;
                        return a.whenDue - b.whenDue;
                    });
                    // Sort list prioritised items
                    let prioritised = [...items] as SafetyEquipmentItem[];
                    prioritised.sort((a: SafetyEquipmentItem, b: SafetyEquipmentItem) => {
                        // Assign a high value for nonExpiring items to ensure they go to the bottom of the list
                        const aValue = a.whenDue && (a.type === 'expiring' || a.type === 'servicable') ? a.whenDue : Number.MAX_SAFE_INTEGER;
                        const bValue = b.whenDue && (b.type === 'expiring' || b.type === 'servicable') ? b.whenDue : Number.MAX_SAFE_INTEGER;
                    
                        return aValue - bValue;
                    });

                    const maxWhenDue = getDayOffsetMillis(
                        warnDays.safetyEquipmentExpiries?.[warnDays.safetyEquipmentExpiries.length - 1]
                    );
                    for (let i = 0; i < prioritised.length; i++) {
                        if (
                            prioritised[i].whenDue === undefined ||
                            (prioritised[i].whenDue || Number.MAX_SAFE_INTEGER) > maxWhenDue
                        ) {
                            prioritised = prioritised.slice(0, i);
                            break;
                        }
                    }

                    const byId = {} as {
                        [id: string]: SafetyEquipmentItem
                    };
                    const byItemId = {} as {
                        [id: string]: SafetyEquipmentItem[]
                    };
                    items.forEach((item) => {
                        registerFiles(item.files, 'safetyEquipmentItems', item);
                        byId[item.id] = item;
                        if (byItemId[item.itemId] === undefined) {
                            byItemId[item.itemId] = [];
                        }
                        byItemId[item.itemId].push(item);
                    });

                    const sortedItems = [] as SafetyEquipmentItem[];
                    vesselSafetyItems.ids.forEach((id: string) => {
                        if (byItemId[id]) {
                            sortedItems.push(...byItemId[id]);
                        }
                    });

                    // Categorise all items
                    const itemsCatorised = {
                        servicable: [] as SafetyEquipmentItem[],
                        expiring: [] as SafetyEquipmentItem[],
                        nonExpiring: [] as SafetyEquipmentItem[],
                    };

                    sortedItems.forEach((item) => {
                        switch (item.type) {
                            case 'servicable':
                                itemsCatorised.servicable.push(item);
                                break;
                            case 'expiring':
                                itemsCatorised.expiring.push(item);
                                break;
                            default:
                                itemsCatorised.nonExpiring.push(item);
                                break;
                        }
                    });

                    // Categorise prioritised items
                    const prioritisedCategorised = {
                        servicable: {} as {
                            [id: string]: SafetyEquipmentItem
                        },
                        expiring: {} as {
                            [id: string]: SafetyEquipmentItem
                        },
                        nonExpiring: {} as {
                            [id: string]: SafetyEquipmentItem
                        },
                    };
                    prioritised.forEach((item) => {
                        switch (item.type) {
                            case 'servicable':
                                prioritisedCategorised.servicable[item.id] = item;
                                break;
                            case 'expiring':
                                prioritisedCategorised.expiring[item.id] = item;
                                break;
                        }
                    });

                    set({
                        all: itemsCatorised,
                        prioritised: prioritisedCategorised,
                        byId,
                        count: items.length
                    });
                },
                (error) => {
                    done();
                    // This should be very rare
                    console.log(
                        `Failed to access safety check items for vessel ${vesselId}`,
                        error
                    );
                }
            )
        } else {
            done();
        }
    }
};
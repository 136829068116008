import React, { useCallback, useMemo } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { firestore, splittableBatch } from '../../lib/firebase';
import { collection, doc } from "firebase/firestore";
import { haveValuesChanged } from '../../lib/util';
import { useFormik } from 'formik';
import { renderFullName } from '../../shared-state/Core/users';
import { sharedState } from '../../shared-state/shared-state';
import { showToast } from '../../managers/ToastManager/ToastManager';
import { Action, traceAction } from '../../managers/ErrorsManager/ErrorsManager';
import SeaModal from '../../components/SeaModal/SeaModal';
import SeaInput from '../../components/SeaInput/SeaInput';
import SeaTextarea from '../../components/SeaTextarea/SeaTextarea';
import SeaButton from '../../components/SeaButton/SeaButton';
import Yup from '../../lib/yup';

interface ContactModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
}

const yupSchema = Yup.object({
    name: Yup.string().max(500).required(),
    company: Yup.string().max(500).required(),
    email: Yup.string().min(3).max(500).email().required(),
    phone: Yup.string().max(500).required(),
    message: Yup.string().max(5000).required(),
});

const ContactModal: React.FC<ContactModalProps> = ({ showModal, setShowModal }) => {
    const user = sharedState.user.use(showModal);
    const userId = sharedState.userId.use(showModal);
    const licenseeId = sharedState.licenseeId.use(showModal);
    const userDetails = sharedState.userDetails.use(showModal)

    const usersDetails = userId ? userDetails?.byId[userId] : undefined;

    const initialValues = useMemo(() => {
        return {
            name: ''+renderFullName(),
            company: (user?.companyName) ? ''+user?.companyName : '',
            email: (usersDetails?.email) ? ''+usersDetails?.email : '',
            phone: (user?.contactNumber) ? ''+user.contactNumber : '',
            message: '',
        };
    }, [user?.companyName, user?.contactNumber, usersDetails?.email]);

    const onOpened = () => {
        resetForm();
        setValues(initialValues);
    }
    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm } = useFormik({
        initialValues: initialValues,
        validationSchema: yupSchema,
        onSubmit: (data) => {

            const action = traceAction('notifications', 'create') as Action;
            const notifcationRef = doc(collection(firestore, 'notifications'));
            action.docId = notifcationRef.id;
            action.data = {data};
            const batch = splittableBatch(firestore, 20 - 0);

            batch.set(
                notifcationRef,
                {
                    licenseeId: licenseeId,
                    whenRequested: action.whenAction,
                    requestedBy: userId,
                    type: 'contactEnquiry',
                    // to: ['Tai Ellis <tai@seaflux.co.nz>'], // onNotificationCreate function will handle this
                    state: 'waiting',
                    form: {
                        name: data.name,
                        company: data.company,
                        email: data.email,
                        phone: data.phone,
                        message: data.message
                    }
                }
            );

            action.save('Contact enquiry', batch);
            batch.commit().then(() => {
                action.reportSuccess();
            }).catch((error) => {
                action.reportError(error.message, error);
            });
            // notifcationRef.set({
            //     licenseeId: licenseeId,
            //     whenRequested: action.whenAction,
            //     requestedBy: userId,
            //     type: 'contactEnquiry',
            //     // to: ['Tai Ellis <tai@seaflux.co.nz>'], // onNotificationCreate function will handle this
            //     state: 'waiting',
            //     form: {
            //         name: data.name,
            //         company: data.company,
            //         email: data.email,
            //         phone: data.phone,
            //         message: data.message
            //     }
            // }).then(() => {
            //     action.reportSuccess();
            // }).catch((error) => {
            //     action.reportError(error.message, error);
            // });

            setShowModal(false);
            showToast('Thank you for your message, we\'ll be in touch soon')
        }
    });
    
    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues)
    }, [initialValues, values]);

    return (
        <SeaModal
            title='Contact Sea Flux'
            showModal={showModal}
            setShowModal={setShowModal}
            size='standard'
            onOpened={onOpened}
            isDirty={isModalDirty}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="6">
                          	<SeaInput
								label="Name"
								name="name"
								value={values.name}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.name ? errors.name : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Company"
								name="company"
								value={values.company}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.company ? errors.company : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Email"
								name="email"
								value={values.email}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.email ? errors.email : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Phone"
								name="phone"
								value={values.phone}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.phone ? errors.phone : ''}
                          	/>
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                label="Message"
                                name="message"
                                value={values.message}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                height={200}
                                inputmode="text"
                                error={touched.message ? errors.message : ''}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div style={{ height: '60px' }}></div>
                <SeaButton zone="white" type="submit">Send Message</SeaButton>
                <div style={{ height: '30px' }}></div>
                <div>Alternatively you can email <a href="mailto:tai@seaflux.co.nz">tai@seaflux.co.nz</a> or call <a href="tel:+64 22 308 0209">+64 22 308 0209</a>.</div>
            </form>
        </SeaModal>
    );
};

export default ContactModal;

import React, { ReactNode, useMemo } from 'react';
import { sharedState } from '../../shared-state/shared-state';
import { permissionLevels, PermissionRole } from '../../shared-state/Core/userPermissions';
import SeaIcon from '../SeaIcon/SeaIcon';

interface RequirePermissionsProps {
    children: ReactNode,
    role?: PermissionRole,
    level?: number,
    showDenial?: boolean,
}

const RequirePermissions: React.FC<RequirePermissionsProps> = ({
    children,
    role,
    level = permissionLevels.VIEW,
    showDenial
}) => {
    const userPermissions = sharedState.userPermissions.use();

    const isDenied = useMemo(() => {
        if (role) {
            if (userPermissions && userPermissions[role] >= level) {
                return false;
            }
        } else {
            return false;
        }
        return true;
    }, [userPermissions, role, level]);

    if (isDenied) {
        if (showDenial) {
            return (
                <div className="sea-no-data columns">
                    <div><SeaIcon icon="warn"/></div>
                    <div>You do not have permission to view this content</div>
                </div>
            );
        }
        return <></>;
    }
    return <>{children}</>;
};

export default RequirePermissions;

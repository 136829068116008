/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, Profiler, useMemo } from "react";
import { extractSearchTerms, formatValue } from "../../../lib/util";
import { logPageView } from "../../../lib/firebase";
import { onProfilerRender } from "../../../lib/performance";
import { usePageLimiter } from "../../../hooks/usePageLimiter";
import { permissionLevels } from "../../../shared-state/Core/userPermissions";
import { renderCategoryName } from "../../../lib/categories";
import { sharedState } from '../../../shared-state/shared-state';
import { IonSelectOption } from "@ionic/react";
import { Equipment } from '../../../shared-state/VesselMaintenance/equipment';
import SeaIcon from "../../../components/SeaIcon/SeaIcon";
import SeaButton from "../../../components/SeaButton/SeaButton";
import SeaNoData from "../../../components/SeaNoData/SeaNoData";
import RequirePermissions from "../../../components/RequirePermissions/RequirePermissions";
import EditEquipment from "../../../modals/VesselMaintenance/EquipmentList/EditEquipment/EditEquipment";
import SeaFileImage from "../../../components/SeaFileImage/SeaFileImage";
import ViewEquipment from "../../../modals/VesselMaintenance/EquipmentList/ViewEquipment/ViewEquipment";
import SeaSearchbar from "../../../components/SeaSearchbar/SeaSearchbar";
import SeaSelect from "../../../components/SeaSelect/SeaSelect";
import EquipmentListPdf from "../../../pdfs/VesselMaintenance/EquipmentListPdf";
import SeaStatusIcon from "../../../components/SeaStatusIcon/SeaStatusIcon";
import EditEquipmentListSettings from '../../../modals/VesselMaintenance/EquipmentList/EditEquipmentListSettings/EditEquipmentListSettings';
import "./EquipmentList.css";

interface EquipmentListProps {
    visible: boolean;
}

export interface EquipmentCategoryOptions {
    isEmpty: boolean,
    systems: {
        [systemId: string]: Equipment[];
    }
}

const EquipmentList: React.FC<EquipmentListProps> = ({ visible }) => {
    const equipment = sharedState.equipment.use(visible ? 1 : 101);
    const vesselSystems = sharedState.vesselSystems.use(visible ? 1 : 101);
    const vesselLocations = sharedState.vesselLocations.use(visible ? 1 : 101);
    sharedState.contacts.use(visible ? 101 : 0); // Prepare for modals to access
    sharedState.scheduledMaintenanceTasks.use(visible ? 102 : 0); // Prepare for modals to access
    sharedState.equipmentManualDocuments.use(visible ? 102 : 0); // Prepare for modals to access
    sharedState.spareParts.use(visible ? 102 : 0); // Prepare for modals to access
    sharedState.maintenanceTasksCompleted.use(visible ? 103 : 0); // Prepare for modals to access
    const {
        limitTriggerElement,
        mapArrayWithLimit,
        resetPageLimit,
        isLimitReached,
    } = usePageLimiter();
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [showEquipmentListSettings, setShowEquipmentListSettings] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Equipment>();
    const [searchText, setSearchText] = useState("");
    const [systemFilter, setSystemFilter] = useState("");
    const [locationFilter, setLocationFilter] = useState("");
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const [categoriesFiltered, setCategoriesFiltered] = useState<EquipmentCategoryOptions>();
    const [listType, setListType] = useState("all");

    useEffect(() => {
        if (visible) {
            logPageView("VesselMaintenance/EquipmentList");
        }
        resetPageLimit();
    }, [visible, resetPageLimit]);

    useEffect(() => {
        resetPageLimit();
    }, [searchText, systemFilter, locationFilter, resetPageLimit]);

    const isUsingFilters = useMemo(() => {
        return searchText || systemFilter || locationFilter || listType === "critical" ? true : false;
    }, [searchText, systemFilter, locationFilter, listType]);

    // Filter categories
    useEffect(() => {
        setCategoriesFiltered(undefined);
        if (vesselSystems && equipment) {
            const _categories: EquipmentCategoryOptions = {
                isEmpty: true,
                systems: {}
            };
            const terms = extractSearchTerms(searchText, true);
            for (let i = 0; i < equipment.all.length; i++) {
                const _equipment = equipment.all[i];
                if (listType === "critical" && !_equipment.isCritical) {
                    continue;
                }
                if (
                    systemFilter && (
                        _equipment.equipment === undefined ||
                        _equipment.systemId !== systemFilter
                    )
                ) {
                    continue;
                }

                if (
                    locationFilter && (
                        _equipment.locationId === undefined ||
                        _equipment.locationId !== locationFilter
                    )
                ) {
                    continue;
                }

                if (terms.length > 0) {
                    let isMatch = true;
                    for (let j = 0; j < terms.length; j++) {
                        if (_equipment.searchText?.indexOf(terms[j]) === -1) {
                            isMatch = false;
                            break;
                        }
                    }
                    if (!isMatch) {
                        continue;
                    }
                }
                _categories.isEmpty = false;
                if (_categories.systems[_equipment.systemId] === undefined) {
                    _categories.systems[_equipment.systemId] = [];
                }
                _categories.systems[_equipment.systemId].push(_equipment);
            }
            setCategoriesFiltered(_categories);
        }
    }, [equipment, searchText, systemFilter, locationFilter, listType]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (selectedItem?.id && equipment?.byId[selectedItem.id]) {
            setSelectedItem(equipment?.byId[selectedItem.id]);
        }
    }, [equipment, selectedItem?.id]);

    const onAddNewItem = () => {
        setSelectedItem(undefined);
        setShowEditItemModal(true);
    };
    const onViewItem = (item: Equipment) => {
        setSelectedItem(item);
        setShowViewItemModal(true);
    };
    const onEditSettings = () => {
        setShowEquipmentListSettings(true);
    }
    const onGeneratePdf = () => {
        setGeneratingPdf(true);
    };
    const hasFilters = useMemo(() => {
        return (
            (equipment?.filterOptions?.systemIds && equipment.filterOptions.systemIds.length > 0) ||
            (equipment?.filterOptions?.locationIds && equipment.filterOptions.locationIds.length > 0)
        );
    }, [equipment]);

    return (
        <RequirePermissions
            role="maintenanceSchedule"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="maintenance-schedule page-head">
                <div>
                    <h2>Equipment List</h2>
                </div>
                <div className="actions">
                    <SeaSelect
                        name="listType"
                        value={listType}
                        width="240px"
                        zone="grey"
                        onchange={(e) => {
                            setListType(e.detail.value);
                        }}
                    >
                        <IonSelectOption value="all">All</IonSelectOption>
                        <IonSelectOption value="critical">Critical</IonSelectOption>
                    </SeaSelect>
                    <RequirePermissions
                        role="maintenanceSchedule"
                        level={permissionLevels.CREATE}
                    >
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add" />
                            Add New Equipment
                        </SeaButton>
                        <div className="spacer"></div>
                    </RequirePermissions>
                    <div className="spacer"></div>
                    <SeaButton onClick={(e) => onGeneratePdf()} zone="grey" shape="circle"><SeaIcon slot="icon-only" icon="pdf"/></SeaButton>
                    <div className="spacer"></div>
                    <RequirePermissions
                        role="vesselSettings"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton
                            zone="grey"
                            shape="circle"
                            onClick={(e) => onEditSettings()}
                        >
                            <SeaIcon slot="icon-only" icon="settings" />
                        </SeaButton>
                    </RequirePermissions>
                </div>
            </div>

            {/* Filters */}
            <div className={`columns wrap filters reveal`}>
                <div>
                    <SeaSearchbar value={searchText} setValue={setSearchText} />
                </div>
                {vesselSystems &&
                equipment?.filterOptions?.systemIds &&
                equipment.filterOptions.systemIds.length > 0 &&
                    (
                        <div>
                            <SeaSelect
                                name="systemFilter"
                                value={systemFilter}
                                width="195px"
                                zone="grey"
                                onchange={(e) =>
                                    setSystemFilter(e.detail.value)
                                }
                            >
                                <IonSelectOption value="">
                                    Filter by System
                                </IonSelectOption>
                                {equipment?.filterOptions?.systemIds?.map(
                                    (id: string) => {
                                        return (
                                            <IonSelectOption
                                                key={id}
                                                value={id}
                                            >
                                                {renderCategoryName(
                                                    id,
                                                    vesselSystems
                                                )}
                                            </IonSelectOption>
                                        );
                                    }
                                )}
                            </SeaSelect>
                        </div>
                    )}
                {vesselLocations &&
                equipment?.filterOptions?.locationIds &&
                equipment.filterOptions.locationIds.length > 0 &&
                    (
                        <div>
                            <SeaSelect
                                name="locationFilter"
                                value={locationFilter}
                                width="195px"
                                zone="grey"
                                onchange={(e) =>
                                    setLocationFilter(e.detail.value)
                                }
                            >
                                <IonSelectOption value="">
                                    Filter by Location
                                </IonSelectOption>
                                {equipment?.filterOptions?.locationIds?.map(
                                    (id: string) => {
                                        return (
                                            <IonSelectOption
                                                key={id}
                                                value={id}
                                            >
                                                {
                                                    vesselLocations?.byId[id]
                                                        ?.name
                                                }
                                            </IonSelectOption>
                                        );
                                    }
                                )}
                            </SeaSelect>
                        </div>
                    )}
                {hasFilters && (
                    <div>
                        <SeaButton
                            onClick={(e) => {
                                setSearchText("");
                                setSystemFilter("");
                                setLocationFilter("");
                            }}
                            zone="grey"
                        >
                            Reset
                        </SeaButton>
                    </div>
                )}
            </div>

            <SeaNoData
                dataName="equipment"
                isLoading={!categoriesFiltered}
                hasNoData={categoriesFiltered?.isEmpty}
                isUsingFilter={isUsingFilters}
            />

            <div
                className={`equipment-list has-thumbs ${
                    categoriesFiltered && !categoriesFiltered.isEmpty
                        ? "reveal"
                        : "conceal"
                }`}
            >
                <div className="sea-row headings">
                    <div></div>
                    <div>Equipment</div>
                    <div>Location</div>
                    <div>Make</div>
                    <div>Model</div>
                    <div>Serial #</div>
                    <div>Critical</div>
                </div>
                <Profiler
                    id="maintenance.equipment"
                    onRender={onProfilerRender}
                >
                    {categoriesFiltered &&
                        vesselSystems?.ids?.map((systemId: string) => {
                            if (
                                isLimitReached() ||
                                !categoriesFiltered.systems[systemId]?.length
                            ) {
                                return;
                            }

                            return (
                                <React.Fragment key={systemId}>
                                    <div className="category-heading">
                                        {renderCategoryName(
                                            systemId,
                                            vesselSystems
                                        )}
                                    </div>
                                    {mapArrayWithLimit(
                                        categoriesFiltered.systems[systemId],
                                        (item: Equipment) => {
                                            return (
                                                <div
                                                    key={item.id}
                                                    className="sea-card sea-row"
                                                    onClick={(e) =>
                                                        onViewItem(item)
                                                    }
                                                >
                                                    <div>
                                                        <SeaFileImage
                                                            files={item.files}
                                                            size="tiny"
                                                        />
                                                    </div>
                                                    <div className="bold truncate-2">
                                                        {item.equipment}
                                                    </div>
                                                    <div className="truncate-2">
                                                        {formatValue(
                                                            renderCategoryName(
                                                                item.locationId,
                                                                vesselLocations
                                                            )
                                                        )}
                                                    </div>
                                                    <div className="truncate-2">
                                                        {formatValue(item.make)}
                                                    </div>
                                                    <div className="truncate-2">
                                                        {formatValue(
                                                            item.model
                                                        )}
                                                    </div>
                                                    <div className="truncate-2">
                                                        {formatValue(
                                                            item.serial
                                                        )}
                                                    </div>
                                                    <div className="truncate-2">
                                                        {item.isCritical && (
                                                            <SeaStatusIcon
                                                                icon="flag"
                                                                className="critical"
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                                </React.Fragment>
                            );
                        })}
                    {limitTriggerElement}
                </Profiler>
            </div>

            {visible && (
                <>
                    <EditEquipment
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        setShowViewItemModal={setShowViewItemModal}
                        itemToUpdate={selectedItem}
                        level={2}
                    />
                    {selectedItem && <ViewEquipment
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                        setShowEditItemModal={setShowEditItemModal}
                        level={1}
                    />}
                    <EditEquipmentListSettings
                        showModal={showEquipmentListSettings}
                        setShowModal={setShowEquipmentListSettings}
                    />
                    <EquipmentListPdf
                        generatingPdf={generatingPdf}
                        setGeneratingPdf={setGeneratingPdf}
                        categories={categoriesFiltered?.systems || {}}
                    />
                </>
            )}
        </RequirePermissions>
    );
};

export default EquipmentList;

import React, { useMemo, useState } from 'react';
import { sharedState } from '../../../../shared-state/shared-state';
import { renderDebugging } from '../../../../shared-state/Core/debugging';
import SeaTabsGroup from '../../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../../../components/SeaTab/SeaTab';
import SeaButton from '../../../../components/SeaButton/SeaButton';

const DebuggingTab: React.FC = () => {
    const debugging = sharedState.debugging.use();
    const [category, setCategory] = useState('');

    const categories = useMemo(() => {
        const o = Object.keys(debugging!).sort();
        if (!category && o.length > 0) {
            setCategory(o[0]);
        }
        return o;
    }, [debugging, category]);

    return (
        <div style={{ padding: '8px' }}>
            <SeaTabsGroup selectedTab={category} setTab={setCategory} mode="forms" mini>
                {categories?.map((category) => {
                    return <SeaTab key={category} tab={category} mode="forms">{category}</SeaTab>
                })}
            </SeaTabsGroup>
            <div style={{ position: 'absolute', right: '8px' }}>
                <SeaButton
                    mini
                    onClick={(e) => {
                        sharedState.debugging.set((current) => {
                            const o = { ...current! };
                            o[category] = ['',''];
                            return o;
                        });
                    }}
                >
                    Clear
                </SeaButton>
            </div>
            <pre style={{ whiteSpace: 'pre-wrap' }}>
                {category && debugging && renderDebugging(category)}
            </pre>
        </div>
    );
};

export default DebuggingTab;

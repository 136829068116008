import React, { useState, useCallback } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { haveCategoriesChanged, makeCategoryIdsForEditing, makeCategoryNamesForEditing, saveCategoryChanges } from '../../../../lib/categories';
import { sharedState } from '../../../../shared-state/shared-state';
import { Action, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaInputList from '../../../../components/SeaInputList/SeaInputList';

interface EditVesselCertificateSettingsProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
    vesselCertificateCategories?: any
}

const EditVesselCertificateSettings: React.FC<EditVesselCertificateSettingsProps> = ({showModal, setShowModal, vesselCertificateCategories}) => {
    const licenseeId = sharedState.licenseeId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const [categoryIds, setCategoryIds] = useState<string[]>();
    const [categoryNames, setCategoryNames] = useState<string[]>();

    const onOpened = () => {
        setCategoryIds(makeCategoryIdsForEditing(vesselCertificateCategories));
        setCategoryNames(makeCategoryNamesForEditing(vesselCertificateCategories));
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        const action = traceAction('vesselCertificateCategories', 'update') as Action;
        action.docId = licenseeId;
        const batch = splittableBatch(firestore, 20 - 0);
        saveCategoryChanges(
            batch,
            categoryIds,
            categoryNames,
            undefined,
            vesselCertificateCategories,
            'vesselCertificateCategories',
            'vesselId',
            vesselId,
            action
        );

        action.save(`Update vessel certificate categories`, batch);
        batch.commit().then(() => {
            action.reportSuccess();
        }).catch((error) => {
            action.reportError(error.message, error);
        });

        setShowModal(false);
    };

    const isModalDirty = useCallback(() => {
        return haveCategoriesChanged(categoryIds, categoryNames, vesselCertificateCategories);
    }, [categoryIds, categoryNames, vesselCertificateCategories]);

    return (
        <SeaModal
            title={`Vessel Certificate Settings`}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size="semi-thin"
        >
            <form onSubmit={(e) => onSubmit(e)}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaInputList
                                name="vesselCertificateCategories"
                                label="Vessel Certificate Categories"
                                maxWidth="350px"
                                ids={categoryIds}
                                setIds={setCategoryIds}
                                values={categoryNames}
                                setValues={setCategoryNames}
                                addNewText="Add Category"
                                confirmDelete={true}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div style={{ height: '60px' }}></div>
                <SeaButton zone="white" type="submit">{`Save Settings`}</SeaButton>
            </form>
        </SeaModal>
    );
};

export default EditVesselCertificateSettings;

import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { deleteValue, firestore, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { deepClone, haveValuesChanged, formatDatetime, preventMultiTap } from '../../../../lib/util';
import { formElementsToArray, formArrayToElements } from '../../../../lib/customForms';
import { makeCategoryId } from '../../../../lib/categories';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { renderVesselName } from '../../../../shared-state/Core/vessels';
import { Action, reportError, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import { CustomForm } from '../../../../shared-state/CompanyDocuments/CustomForms/customForms';
import { CustomFormTemplate } from '../../../../shared-state/CompanyDocuments/CustomForms/customFormTemplates';
import { VesselOptions } from '../../../../shared-state/Core/vessels';
import { CustomFormElementType } from '../../../../components/CustomFormElement/CustomFormElement';
import Yup from '../../../../lib/yup'
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLabel from '../../../../components/SeaLabel/SeaLabel';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import BuildCustomForm from '../BuildCustomForm/BuildCustomForm';
import SeaInputError from '../../../../components/SeaInputError/SeaInputError';
import SeaCheckbox from '../../../../components/SeaCheckbox/SeaCheckbox';
import SeaSelectCategory from '../../../../components/SeaSelectCategory/SeaSelectCategory';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import './EditCustomForm.css';

interface EditCustomFormProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    itemToUpdate?: CustomForm,
    templateToUse?: Partial<CustomFormTemplate>,
    setTemplateToUse: (template?: CustomFormTemplate) => void
}

const EditCustomForm: React.FC<EditCustomFormProps> = ({showModal, setShowModal, itemToUpdate, templateToUse, setTemplateToUse, level}) => {
    const userId = sharedState.userId.use(showModal);
    const vesselIds = sharedState.vesselIds.use(showModal);
    const licenseeId = sharedState.licenseeId.use(showModal);
    const superAdmin = sharedState.superAdmin.use(showModal);
    const customFormCategories = sharedState.customFormCategories.use(showModal);
    const customFormVersions = sharedState.customFormVersions.use(showModal);
    const [showFormBuilder, setShowFormBuilder] = useState(false);
    const [formElements, setFormElements] = useState<any[]>([]);
    const [historyElementN, setHistoryElementN] = useState<number>(0);
    const [whenNewVersion, setWhenNewVersion] = useState<number>();
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [forVesselIds, setForVesselIds] = useState<string[]>();
    const [vesselOptions, setVesselOptions] = useState<VesselOptions[]>();

    const [vesselsElement, setVesselsElement] = useState<CustomFormElementType>();
    const [crewElement, setCrewElement] = useState<CustomFormElementType>();

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                title: itemToUpdate.title ? ''+itemToUpdate.title : '',
                categoryId: itemToUpdate.categoryId ? ''+itemToUpdate.categoryId : '',
                yesno: 0,
                isTemplate: itemToUpdate.isTemplate ? true : false,
                forVessels: (itemToUpdate.forVesselIds.length !== 1 || itemToUpdate.forVesselIds[0] !== 'none'),
                forCrew: itemToUpdate.forCrew ? true : false
            };
        } else {
            return {
                title: '',
                categoryId: '',
                yesno: 0,
                isTemplate: false,
                forVessels: true,
                forCrew: false
            };
        }
    }, [itemToUpdate]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setWhenNewVersion(undefined);
        setHistoryElementN(0);

        const _vesselOptions = [] as any[];
        vesselIds?.forEach((vesselId: string) => {
            _vesselOptions.push({
                id: vesselId,
                name: renderVesselName(vesselId)
            });
        });
        setVesselOptions(_vesselOptions);

        if (itemToUpdate && itemToUpdate.forVesselIds[0] !== 'none') {
            setForVesselIds(itemToUpdate.forVesselIds);
        } else {
            setForVesselIds(vesselIds);
        }

        if (itemToUpdate) {
            setVesselsElement(itemToUpdate?.vesselsElement);
            setCrewElement(itemToUpdate?.crewElement);
        } else {
            setVesselsElement(undefined);
            setCrewElement(undefined);
        }

        let _formElements: CustomFormElementType[] = [];
        if (templateToUse && templateToUse.version) {
            setFieldValue('title', templateToUse.title);
            setWhenNewVersion(templateToUse.version.version);
            _formElements = formElementsToArray(deepClone(templateToUse.version.form));
            setHistoryElementN(templateToUse.version.historyElementN ? templateToUse.version.historyElementN : 0)
        } else if (itemToUpdate && itemToUpdate.latestVersion) {
            const version = customFormVersions?.byFormIdAndVersion[itemToUpdate.id][itemToUpdate.latestVersion];
            _formElements = formElementsToArray(deepClone(version?.form));
            setHistoryElementN(version?.historyElementN ? version.historyElementN : 0);
        }

        // Update formElement's help files
        _formElements.forEach((element: any) => {
            if (element?.help?.files) {
                element.help.files = makeSeaFiles(element.help.files);
            }
        });

        setFormElements(_formElements);
    };

    const onClosed = () => {
        setTemplateToUse(undefined);
        setFieldValue('categoryId', '');
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue, setValues, resetForm, isSubmitting, isValid } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            title: Yup.string().max(500).required(),
            categoryId: Yup.string().max(500).required(),
            forVessels: Yup.boolean(),
            forCrew: Yup.boolean()
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('customForm') || formElements.length === 0) {
                return;
            }
            if (!licenseeId) {
                reportError('User has no licenseeId');
                return;
            }
            if (data.forVessels) {
                if (forVesselIds === undefined || forVesselIds.length === 0) {
                    // If for vessels, there must be at least one vessel selected
                    return;
                }
            };

            // Gather any files we need to upload
            const files = [] as any[];
            formElements.forEach((element) => {
                if (element?.help?.files && element.help.files.length > 0) {
                    files.push(...element.help.files);
                }
            });

            let templateCategory = undefined as string | undefined;
            if (superAdmin && data.isTemplate) {
                if (customFormCategories?.byId[data.categoryId]) {
                    templateCategory = customFormCategories.byId[data.categoryId].name; // Use name of existing category
                } else {
                    templateCategory = data.categoryId; // data.categoryId in this case contains the name of a new category
                }
            }

            uploadFiles(files).then(() => {
                // Process form
                const action = traceAction('customForms') as Action;
                const batch = splittableBatch(firestore, 20 - 0);
                let customFormRef;
                if (itemToUpdate) {
                    action.type = 'update';
                    action.docId = itemToUpdate.id;
                    customFormRef = doc(firestore, 'customForms', itemToUpdate.id);
                    batch.set(
                        customFormRef,
                        {
                            updatedBy: userId,
                            whenUpdated: action.whenAction,
                            title: data.title,
                            latestVersion: whenNewVersion ? whenNewVersion : itemToUpdate.latestVersion,
                            categoryId: makeCategoryId(
                                data.categoryId,
                                customFormCategories,
                                deleteValue,
                                batch,
                                'customFormCategories',
                                'licenseeId',
                                licenseeId as string
                            ),
                            forVesselIds: data.forVessels ? forVesselIds : ['none'], // (changes will be propagated server side)
                            forCrew: data.forCrew,
                            vesselsElement: vesselsElement,
                            crewElement: crewElement,
                            isTemplate: (superAdmin ? (data.isTemplate ? true : false) : undefined),
                            templateCategory: templateCategory,
                            touched: serverTimestamp()
                        },
                        { merge: true }
                    );

                    logAction(
                        batch,
                        'Update',
                        'customForms',
                        itemToUpdate.id,
                        data.title
                    );
                } else {
                    customFormRef = doc(collection(firestore, 'customForms'));
                    action.type = 'create';
                    action.docId = customFormRef.id;
                    batch.set(customFormRef, {
                        licenseeId: licenseeId,
                        addedBy: userId,
                        whenAdded: action.whenAction,
                        title: data.title,
                        categoryId: makeCategoryId(
                            data.categoryId,
                            customFormCategories,
                            undefined,
                            batch,
                            'customFormCategories',
                            'licenseeId',
                            licenseeId as string
                        ),
                        latestVersion: whenNewVersion,
                        state: 'active',
                        forVesselIds: data.forVessels ? forVesselIds : ['none'],
                        forCrew: data.forCrew,
                        vesselsElement: vesselsElement,
                        crewElement: crewElement,
                        isTemplate: (superAdmin ? (data.isTemplate ? true : false) : undefined),
                        templateCategory: templateCategory,
                        touched: serverTimestamp()
                    });

                    logAction(
                        batch,
                        'Add',
                        'customForms',
                        customFormRef.id,
                        data.title
                    );
                }

                // Save new form version if there is one
                if (whenNewVersion !== undefined) {

                    // Conform formElement's help files to be string values
                    formElements.forEach((element: any) => {
                        if (element?.help?.files) {
                            element.help.files = seaFilesToValue(element.help.files);
                        }
                    });
                    const customFormVersionRef = doc(collection(firestore, 'customFormVersions'));
                    batch.set(customFormVersionRef, {
                        customFormId: customFormRef.id,
                        licenseeId: licenseeId,
                        version: whenNewVersion,
                        numCompleted: 0,
                        state: 'active',
                        forVesselIds: data.forVessels ? forVesselIds : ['none'],
                        historyElementN: historyElementN,
                        form: formArrayToElements(formElements),
                        whenAdded: action.whenAction,
                        addedBy: userId,
                        touched: serverTimestamp()
                    });

                    onCollectionUpdated(batch, 'customFormVersions');
                    saveFileRefs(batch, files, 'customFormVersions', customFormVersionRef.id);
                }

                // For templates, the files may need to be shared across multiple licensees
                if (data.isTemplate) {
                    files.forEach((file: SeaFile) => {
                        if (file.id) {
                            batch.set(
                                doc(firestore, 'files', file.id),
                                { canShare: true },
                                { merge: true }
                            );
                        }
                    });
                }

                action.data = {
                    data,
                    files: seaFilesToValue(files),
                    vesselsElement,
                    crewElement,
                    whenNewVersion,
                    form: formArrayToElements(formElements),
                    historyElementN,
                    itemToUpdate
                };
                action.save(`${itemToUpdate ? 'Update' : 'Create'} form/checklist ${data?.title}`, batch);

                onCollectionUpdated(batch, 'customForms');

                batch.commit().then(() => {
                    action.reportSuccess();
                }).catch((error) => {
                    action.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload Crew Certificate files`, error.message, error,{
                        data,
                        itemToUpdate,
                        files: seaFilesToValue(files)
                    });
                }
            });
        }
    });

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues) || whenNewVersion !== undefined;
        // todo need to check if form elements have changed also
    }, [values, whenNewVersion, initialValues]);

    return (
        <SeaModal
            title={`${itemToUpdate ? 'Edit ' : 'Add New '} Form/Checklist`}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            onClosed={onClosed}
            level={level}
            size="semi-wide"
            confirmDismissDirty={{
                title: "Are you sure you want to leave this form without saving first?",
                no: "Cancel",
                yes: "Yes, leave form"
            }}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="6">
                          	<SeaInput
								label="Title"
								name="title"
								value={values.title}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.title ? errors.title : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelectCategory
                                categories={customFormCategories}
                                label="Category"
                                name="categoryId"
                                initialCategoryId={initialValues.categoryId}
                                categoryId={values.categoryId}
                                otherPlaceholder="New Category"
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.categoryId ? errors.categoryId : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaCheckbox
                                label="Is this for vessels / facilities?"
                                name="forVessels"
                                checked={values.forVessels ? true : false}
                                setFieldValue={setFieldValue}
                                help={{text: "When completing this form, you will be required to select at least one vessel / facility."}}
                            >
                                Yes, link this form with vessels / facilities
                            </SeaCheckbox>
                        </IonCol>
                        {/* <IonCol size="6" className={ values.forVessels ? 'reveal-vessels' : 'conceal-vessels' }>
                            <SeaLabel
                                help={{text: 'This form will only show under the vessels / facilities you select.'}}
                            >
                                Select relevant vessels / facilities
                            </SeaLabel>
                            <SeaMultiSelect
                                modalTitle="Select relevant vessels / facilities"
                                values={forVesselIds}
                                setValues={setForVesselIds}
                                options={vesselOptions}
                                useAllOption={true}
                                required={true}
                                isSubmitting={isSubmitting}
                                emptyText="Not Set"
                            />
                        </IonCol> */}
                        <IonCol size="12">
                            <SeaCheckbox
                                label="Is this for personnel?"
                                name="forCrew"
                                checked={values.forCrew ? true : false}
                                setFieldValue={setFieldValue}
                                help={{
                                    text: " When completing this form, you will be required to select at least one crew member. Completed forms will appear under the selected crew member's profile. For example: skipper or crew induction forms, crew training forms."
                                }}
                            >
                                Yes, link this form with personnel
                            </SeaCheckbox>
                        </IonCol>
                        {superAdmin &&
                            <IonCol size="6">
                                <SeaCheckbox
                                    name="isTemplate"
                                    label="Template"
                                    checked={values.isTemplate ? true : false}
                                    setFieldValue={setFieldValue}
                                >
                                    Mark this as a template
                                </SeaCheckbox>
                            </IonCol>
                        }
                        <IonCol size="12">
                            <SeaLabel>Form</SeaLabel>
                            {(whenNewVersion || itemToUpdate) && 
                                <div style={{paddingBottom: '8px', lineHeight: '1.4em', opacity: 0.8}}>
                                    {whenNewVersion ? (
                                            <>
                                                {itemToUpdate ? 'Created a new version ' : 'Created '}
                                                {formatDatetime(whenNewVersion)}
                                            </>
                                        ) : (
                                            <>
                                                Last updated&nbsp;
                                                {formatDatetime(itemToUpdate?.latestVersion)}
                                            </>
                                        )
                                    }
                                </div>
                            }
                            <div className="edit-cf-button">
                                <SeaButton onClick={(e) => setShowFormBuilder(true)}>
                                    <SeaIcon slot="start" icon="build" />
                                    {(itemToUpdate || whenNewVersion) ? 'Edit with Form Builder' : 'Create with Form Builder'}
                                </SeaButton>
                            </div>
                            <div className="edit-cf-disabled">
                                <SeaIcon
                                    icon="alert"
                                />
                                To edit forms/checklists you need be using the desktop app or a tablet with at least 1000 pixels of screen width.
                            </div>
                            <div style={{ height: '8px' }}></div>
                            <SeaInputError alignLeft>{hasSubmitted && formElements.length === 0 ? 'Please use the form builder to create a form' : ''}</SeaInputError>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                 <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid && formElements.length > 0 && (values.forVessels ? forVesselIds === undefined || forVesselIds.length === 0 : true)}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{`${itemToUpdate ? 'Save Changes' : 'Save Form/Checklist'} `}</SeaButton>
                </div>
            </form>
            {showModal &&
                <BuildCustomForm
                    showModal={showFormBuilder}
                    setShowModal={setShowFormBuilder}
                    title={values.title}
                    parentFormElements={formElements}
                    setParentFormElements={setFormElements}
                    setWhenNewVersion={setWhenNewVersion}
                    historyElementN={historyElementN}
                    setHistoryElementN={setHistoryElementN}
                    forVessels={values.forVessels}
                    forVesselIds={forVesselIds}
                    setForVesselIds={setForVesselIds}
                    vesselOptions={vesselOptions}
                    vesselsElement={vesselsElement}
                    setVesselsElement={setVesselsElement}
                    forCrew={values.forCrew}
                    crewElement={crewElement}
                    setCrewElement={setCrewElement}
                    confirmationText='Apply Changes'
                />
            }
        </SeaModal>
    );
};

export default EditCustomForm;

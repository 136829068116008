import React, { useEffect, useMemo, useState } from 'react';
import { sharedState, sharedStateProfiling, SharedStateType } from '../../../../shared-state/shared-state';
import SeaTabsGroup from '../../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../../../components/SeaTab/SeaTab';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';

const columns = [
    {name: 'Shared Data', style: {padding: '0px 4px 0px 0px', flex: '0 320px', overflow: 'hidden'}},
    {name: 'Runs', style: {padding: '0px 4px 0px 4px', flex: '0 80px', textAlign: 'right', fontFamily: 'monospace', overflow: 'hidden'} as React.CSSProperties},
    {name: 'Changed', style: {padding: '0px 4px 0px 4px', flex: '0 80px', textAlign: 'right', fontFamily: 'monospace', overflow: 'hidden'} as React.CSSProperties},
    {name: 'Live Docs', style: {padding: '0px 4px 0px 4px', flex: '0 80px', textAlign: 'right', fontFamily: 'monospace', overflow: 'hidden'} as React.CSSProperties},
    {name: 'Run Time (ms)', style: {padding: '0px 4px 0px 4px', flex: '0 180px', textAlign: 'right', fontFamily: 'monospace', overflow: 'hidden'} as React.CSSProperties},
    {name: 'Load Time (ms)', style: {padding: '0px 4px 0px 4px', flex: '0 180px', textAlign: 'right', fontFamily: 'monospace', overflow: 'hidden'} as React.CSSProperties},
];

const renderTimeTaken = (millisFloat: number) => {
    return millisFloat.toFixed(1);
};

const renderProfile = (item: any) => {
    const whens = Object.keys(item.runs);
    if (whens.length === 0) {
        return <div>No data</div>;
    }
    whens.sort((a, b) => {
        return Number(a) - Number(b);
    });
    let maxRunTime = 0;
    let maxLoadTime = 0;
    whens.forEach((when) => {
        if (item.runs[when] && item.runs[when] > maxRunTime) {
            maxRunTime = item.runs[when];
        }
        if (item.loads[when] && item.loads[when] > maxLoadTime) {
            maxLoadTime = item.loads[when];
        }
    });
    let previousWhen = Number(whens[0]);
    return (
        <>
            <div className="columns" style={{
                textAlign: 'right',
                fontFamily: 'monospace'
            }}>
                <div style={{ width: '180px' }}>When</div>
                <div style={{ width: '100px' }}>Run Time</div>
                <div style={{ width: '300px' }}></div>
                <div style={{ width: '100px' }}>Load Time</div>
                <div style={{ width: '300px' }}></div>
            </div>
            {whens.map((when, index) => {
                const diff = Number(when) - previousWhen;
                previousWhen = Number(when);
                return (
                    <div
                        key={when}
                        className="columns"
                        style={{
                            textAlign: 'right',
                            fontFamily: 'monospace'
                        }}
                    >
                        <div style={{ width: '80px' }}>
                            {index > 0 && `+${renderTimeTaken(Number(diff))}`}
                        </div>
                        <div style={{ width: '100px' }}>{renderTimeTaken(Number(when))}</div>
                        <div style={{ width: '100px' }}>{renderTimeTaken(Number(item.runs[when]))}</div>
                        <div style={{ width: '300px', padding: '1px 4px' }}>
                            {maxRunTime &&
                                <div style={{ width: `${(item.runs[when] ?? 0) * 100 / maxRunTime}%`, height: '100%', backgroundColor: 'pink' }}></div>
                            }
                        </div>
                        <div style={{ width: '100px' }}>{renderTimeTaken(Number(item.loads[when]))}</div>
                        <div style={{ width: '300px', padding: '1px 4px' }}>
                            {maxLoadTime &&
                                <div style={{ width: `${(item.loads[when] ?? 0) * 100 / maxLoadTime}%`, height: '100%', backgroundColor: 'skyblue' }}></div>
                            }
                        </div>
                    </div>
                );
            })}
        </>
    );
};

let currentTab = 'runTime';

const SharedStateProfilerTab: React.FC = () => {
    const [refresh, setRefresh] = useState(1);
    const [tab, setTab] = useState(currentTab);
    const [valuesToShow, setValuesToShow] = useState<any>({});

    useEffect(() => {
        currentTab = tab; // Remember tab for next time we view this
    }, [tab]);

    const items = useMemo(() => {
        if (!refresh) return undefined;
        const list = Object.keys(sharedStateProfiling).map((sharedStateType) => {
            return {
                type: sharedStateType,
                runCount: sharedState[sharedStateType as SharedStateType].countRuns,
                changeCount: sharedState[sharedStateType as SharedStateType].countChanged,
                docCount: sharedState[sharedStateType as SharedStateType].countLiveDocs(),
                ...sharedStateProfiling[sharedStateType as SharedStateType]
            };
        });
        list.sort((a, b) => {
            return ((b as any)[tab] ?? 0) - ((a as any)[tab] ?? 0);
        });
        return list;
    }, [refresh, tab]);

    const toggleItem = (sharedStateType: SharedStateType) => {
        setValuesToShow((current: any) => {
            const newValuesToShow = {...current};
            if (current[sharedStateType]) {
                delete newValuesToShow[sharedStateType];
            } else {
                newValuesToShow[sharedStateType] = true;
            }
            return newValuesToShow;
        });
    };

    return (
        <div style={{ padding: '8px', position: 'relative', color: 'var(--text-on-white)' }}>
            <div className="columns">
                <div>
                    <SeaTabsGroup selectedTab={tab} setTab={setTab} mode="forms" mini>
                        <SeaTab tab="runCount" mode="forms">Runs</SeaTab>
                        <SeaTab tab="changeCount" mode="forms">Changed</SeaTab>
                        <SeaTab tab="docCount" mode="forms">Live Docs</SeaTab>
                        <SeaTab tab="runTime" mode="forms">Run Time</SeaTab>
                        <SeaTab tab="loadTime" mode="forms">Load Time</SeaTab>
                    </SeaTabsGroup>
                </div>
                <div
                    className="pushy no-select"
                    style={{ width: '70px', padding: '16px 0px 0px 8px', color: 'white' }}
                    onClick={(e) => {
                        // Clear sharedStateProfiling
                        for (const property in sharedStateProfiling) {
                            delete (sharedStateProfiling as any)[property];
                        }
                        Object.values(sharedState).forEach((state) => {
                            state.countRuns = 0;
                            state.countChanged = 0;
                        });
                        setRefresh((current) => current + 1);
                    }}
                >
                    <SeaIcon icon="trash" forceFontSize="18px" marginTop={-4} marginBottom={-5}/> Clear
                </div>
                <div
                    className="pushy no-select"
                    style={{ width: '120px', padding: '16px 0px 0px 8px', color: 'white' }}
                    onClick={(e) => setRefresh((current) => current + 1)}
                >
                    <SeaIcon icon="refresh" forceFontSize="18px" marginTop={-4} marginBottom={-5}/> Reload ({refresh})
                </div>
            </div>
            <div style={{ maxWidth: '1000px' }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '8px',
                }}>
                    {columns.map((column) => {
                        return (
                            <div key={column.name} style={{...column.style, fontWeight: '500', color: 'white' }}>
                                {column.name}
                            </div>
                        );
                    })}
                </div>
                {items?.map((item) => {
                    return (
                        <div
                            key={item.type} 
                            style={{
                                ...columns[0].style,
                                backgroundColor: '#f5f5f5',
                                borderRadius: '8px',
                                marginBottom: '4px',
                                padding: '8px',
                                cursor: 'pointer'
                            }}
                            onClick={(e) => toggleItem(item.type as SharedStateType)}
                        >
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}>
                                <div style={{ ...columns[0].style, fontSize: '16px' }}>
                                    {item.type}
                                </div>
                                <div style={{ ...columns[1].style }}>
                                    {item.runCount}
                                </div>
                                <div style={{ ...columns[2].style }}>
                                    {item.changeCount}
                                </div>
                                <div style={{ ...columns[3].style }}>
                                    {item.docCount ?? '-'}
                                </div>
                                <div style={{ ...columns[4].style }}>
                                    {renderTimeTaken(item.runTime ?? 0)}
                                </div>
                                <div style={{ ...columns[5].style }}>
                                    {renderTimeTaken(item.loadTime ?? 0)}
                                </div>
                            </div>
                            {valuesToShow[item.type] && renderProfile(item)}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SharedStateProfilerTab;

import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { Device } from "@capacitor/device";
import { formatSeaDate, makeDateTime, toInt } from "../../lib/util";
import packageJson from '../../../package.json';

//
// physicalDeviceInfo includes information about the underlying device (like operating system).
// deviceInfo is then kept in sync with Firestore which is physicalDeviceInfo with additional data like userId, deviceId, timezone etc.
//

export interface DeviceInfo extends UpdateableDocument {
    androidSDKVersion?: number;
    deviceId: string;
    diskFree?: number;
    diskTotal?: number;
    iOSVersion?: number;
    isVirtual: boolean;
    lastDate: string;
    manufacturer: string;
    memUsed?: number;
    model: string;
    name?: string;
    operatingSystem: string;
    osVersion: string;
    platform: string;
    userAgent?: string;
    realDiskFree?: number;
    realDiskTotal?: number;
    timezone: string;
    userId: string;
    webViewVersion?: string;
    whenUpdated: number;
    version: string,
    build: number
}

export let physicalDeviceInfo = {} as DeviceInfo;

export const initPhysicalDeviceInfo = () => {
    Device.getInfo().then((info) => {
        physicalDeviceInfo = info as DeviceInfo;
    });
};

export const deviceInfoConfig: SharedStateConfig<DeviceInfo> = {
    isAlwaysActive: true,
    dependencies: ['deviceId', 'userId'],
    countLiveDocs: () => sharedState.deviceInfo.current ? 1 : 0,
    run: (done, set, clear) => {
        clear();
        const deviceId = sharedState.deviceId.current;
        const userId = sharedState.userId.current;
        
        if (userId && deviceId) {
            let isActive = true; 
            // Load deviceInfo from Firestore
            const cleanup = onSnapshot(
                doc(firestore, 'deviceInfo', `${userId}${deviceId}`),
                (doc) => {
                    done();
                    if (doc.exists()) {
                        set({
                            id: doc.id,
                            ...doc.data()
                        } as DeviceInfo);
                    }
                },
                (error) => {
                    done();
                    clear();
                    console.log(`Error getting deviceInfo for id=${userId}${deviceId}`, error);
                }
            );

            // Record latest deviceInfo
            let hasRunBefore = window.localStorage.getItem('hasRunBefore') ? true : false;
            Device.getInfo().then((info) => {
                if (!isActive) return;
                const deviceId = sharedState.deviceId.current;
                const userId = sharedState.userId.current;

                console.log(`Saving deviceInfo under docId=${userId}${deviceId}`);
                setDoc(
                    doc(firestore, 'deviceInfo', `${userId}${deviceId}`),
                    {
                        ...info,
                        timezone: makeDateTime().zoneName,
                        lastDate: formatSeaDate(),
                        userId: userId,
                        deviceId: deviceId,
                        version: packageJson.version,
                        build: toInt(packageJson.build),
                        userAgent: navigator?.userAgent,
                        whenCreated: hasRunBefore ? undefined : Date.now(),
                        whenUpdated: Date.now()
                    },
                    { merge: true }
                );
                if (!hasRunBefore) {
                    console.log(`------ Sea Flux has been freshly run on this device or browser for the first time!`);
                    window.localStorage.setItem('hasRunBefore', 'true');
                }
            });

            return () => {
                isActive = false;
                cleanup();
            };
        }
    },
};

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { IonProgressBar, isPlatform } from '@ionic/react';
import { OnlineStatus } from '../../../shared-state/Core/onlineStatus';
import { DataSyncStatus } from '../../../shared-state/DataSyncSystem/dataSyncTasks';
import { FileSyncStatus } from '../../../shared-state/FileSyncSystem/filesToCache';
import { FileUploadStatus } from '../../../shared-state/FileSyncSystem/filesToUpload';
import { DiskSpaceStatus, mb, minSpaceOk } from '../../../shared-state/FileSyncSystem/diskSpaceStatus';
import { fileSyncDeviceSettingsUi } from '../../../shared-state/FileSyncSystem/fileSyncDeviceSettings';
import { cachedFiles, CachedFileType, FileCacheEntry, FileCollection } from '../../../shared-state/FileSyncSystem/cachedFiles';
import { GraphData } from '../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import SeaModal from '../../../components/SeaModal/SeaModal';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaPieGraph from '../../../components/reporting/SeaPieGraph/SeaPieGraph';
import EditStorageSettingsModal from './EditStorageSettingsModal/EditStorageSettingsModal';
import './SyncModal.css';

interface SyncModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    onlineStatus?: OnlineStatus,
    dataSyncStatus?: DataSyncStatus,
    fileSyncStatus?: FileSyncStatus,
    fileUploadStatus?: FileUploadStatus,
    diskSpaceStatus?: DiskSpaceStatus
}

let filesLeftToCache = -1;  // -1 is to force this to not match fileSyncStats.filesLeft
let dataSyncTasksLeft = -1; // -1 is to force this to not match dataSyncStatus.tasksLeft
let alreadyRefreshingGraphData = false;
const renderStorageValue = (value: number) => {
    const x = Math.round(value / mb);
    if (x === 0) {
        return '< 1';
    }
    return Math.round(value / mb).toLocaleString();
};
const renderStorageValueMB = (value: number) => {
    return renderStorageValue(value)+'MB'
};


const SyncModal: React.FC<SyncModalProps> = ({showModal, setShowModal, onlineStatus, dataSyncStatus, fileSyncStatus, fileUploadStatus, diskSpaceStatus}) => {
    const [showAtleast, setShowAtleast] = useState({
        dataSync: false,
        uploads: false,
        downloads: false
    });
    const [graphData, setGraphData] = useState<GraphData[]>();
    const [totalSize, setTotalSize] = useState(0);
    const [showSettingsModal, setShowSettingsModal] = useState(false);

    // Will include MB units if the value is big enough
    const renderStorageValueSmart = useCallback((value: number) => {
        const x = Math.round(value / 1048576);
        let s = '';
        if (x === 0) {
            s = '< 1';
        } else {
            s = Math.round(value / 1048576).toLocaleString();    
        }
        if (value / totalSize > 0.06) {
            s += 'MB';
        }
        return s;
    }, [totalSize]);

    const refreshGraphData = useCallback(() => {
        // Prepare fileCollectionsMap used to map FileCollection to a Data Type presentable to the user
        alreadyRefreshingGraphData = false;

        const fileCollectionsMap = {} as {
            [collection in FileCollection]: string
        };
        const tinyFiles = 'Thumbnails & Signatures';
        const storageUsed = {
            [tinyFiles]: 0
        } as {
            [name: string]: number
        };
        Object.values(fileSyncDeviceSettingsUi).forEach((setting) => {
            setting.types.forEach((type) => {
                const name = typeof type.name === 'function' ? type.name() : type.name;
                type.collections?.forEach((collection) => {
                    fileCollectionsMap[collection as FileCollection] = name;
                });
                if (type.history?.collection) {
                    fileCollectionsMap[type.history.collection as FileCollection] = name;
                }
                storageUsed[name] = 0;
            });
        });

        // Aggregate all cachedFiles
        let total = 0;
        Object.values(cachedFiles).forEach((entry: FileCacheEntry) => {
            if (entry[5]) {
                Object.keys(entry[5]).forEach((fileType) => {
                    const value = entry[5][fileType as CachedFileType];
                    if (value) {
                        if (fileType === 'T' || fileType === 'S') { // Tiny file
                            storageUsed[tinyFiles] += value;
                            total += value;
                        } else if (entry[3]) { // has collection recorded
                            storageUsed[
                                fileCollectionsMap[entry[3]]
                            ] += value;
                            total += value;
                        }
                    }
                });
            }
        });

        const minProportion = 0.025;
        const data = [] as GraphData[];
        let otherSize = 0;

        Object.keys(storageUsed).forEach((dataType) => {
            if (storageUsed[dataType] / total >= minProportion) {
                // Include in graph
                data.push({
                    name: dataType,
                    value: storageUsed[dataType]
                });
            } else {
                otherSize += storageUsed[dataType];
            }
        });

        data.sort((a, b) => {
            return b.value! - a.value!;
        });

        if (otherSize > 0) {
            data.push({
                name: 'Other',
                value: otherSize
            });
        }

        setTotalSize(total);
        setGraphData(data);
    }, []);

    useEffect(() => {
        if (showModal) {
            if (
                fileSyncStatus!.filesLeft !== filesLeftToCache ||
                dataSyncStatus!.tasksLeft !== dataSyncTasksLeft
            ) {
                filesLeftToCache = fileSyncStatus!.filesLeft;
                dataSyncTasksLeft = dataSyncStatus!.tasksLeft;

                // Do not let graphData be refreshed more than once per second
                if (!alreadyRefreshingGraphData) {
                    alreadyRefreshingGraphData = true;
                    setTimeout(() => {
                        refreshGraphData();
                    }, 1000);
                }
            }
        } else {
            filesLeftToCache = -1;
            dataSyncTasksLeft = -1;
        }
    }, [showModal, fileSyncStatus, dataSyncStatus, refreshGraphData]);

    useEffect(() => {
        if (showModal) {
            setShowAtleast((current) => {
                return {
                    dataSync: Boolean(current.dataSync || (dataSyncStatus && dataSyncStatus.totalTasks > 0)),
                    downloads: Boolean(current.downloads || (fileSyncStatus && fileSyncStatus.totalFilesToCache > 0)),
                    uploads: Boolean(current.uploads || (fileUploadStatus && fileUploadStatus.totalFilesToUpload > 0)),
                };
            });
        }
    }, [showModal, dataSyncStatus, fileSyncStatus, fileUploadStatus]);

    const onOpened = useCallback(() => {
        setShowAtleast({
            dataSync: (dataSyncStatus && dataSyncStatus.totalTasks > 0) ? true : false,
            downloads: (fileSyncStatus && fileSyncStatus.totalFilesToCache > 0) ? true : false,
            uploads: (fileUploadStatus && fileUploadStatus.totalFilesToUpload > 0) ? true : false,
        });
    }, [dataSyncStatus, fileSyncStatus, fileUploadStatus]);

    const isSyncing = useMemo(() => (
        (dataSyncStatus && dataSyncStatus.totalTasks > 0) ||
        (fileSyncStatus && fileSyncStatus.totalFilesToCache > 0) ||
        (fileUploadStatus && fileUploadStatus.totalFilesToUpload > 0)
    ), [dataSyncStatus, fileSyncStatus, fileUploadStatus]);

    const isOnline = onlineStatus?.isOnline ? true : false;

    const pieGraph = useMemo(() => {
        if (showModal && graphData) {
            return (
                <SeaPieGraph
                    n={1}
                    title="Device Storage by Type"
                    mode="modal"
                    modalWidth={800}
                    data={graphData}
                    //forceGraphHeight={480}
                    //forceGraphSize={{ w: 400, h: 360 }}
                    renderValue={renderStorageValueSmart}
                    sortData={false}
                    //visible={true}
                    //showAllLabels={true}
                    //hashNamesForColours={true}
                />
            );
        }
        return <></>;
    }, [showModal, graphData, renderStorageValueSmart]);

    return (
        <SeaModal
            title="Cloud Sync"
            showModal={showModal}
            setShowModal={setShowModal}
            size="semi-thin"
            onOpened={onOpened}
        >
            {!diskSpaceStatus!.haveEnoughSpace &&
                <div style={{ display: 'flex' }}>
                    <div style={{ color: 'var(--ion-color-danger)', paddingTop: '2px', position: 'relative' }}>
                        <SeaIcon icon="alert2" forceFontSize="32px"/>
                    </div>
                    <div style={{ padding: '8px 0px 0px 12px', color: 'var(--ion-color-danger)' }}>
                        You are running out of space on your device!
                        <br/>
                        Sea Flux requires at least <b>{renderStorageValueMB(minSpaceOk)}</b> of free space to enable file caching, but your device only has <b>{renderStorageValueMB(diskSpaceStatus!.bytesFree)}</b> left to use!
                        <p>
                            Please free up space on your device, or reduce which types of files Sea Flux is set to cache.
                        </p>
                        <div style={{ position: 'relative', left: '-16px', top: '-4px', paddingBottom: '8px' }}>
                            <SeaButton
                                zone="white"
                                onClick={(e) => setShowSettingsModal(true)}
                                mini
                            >
                                Edit Device Storage Settings
                            </SeaButton>
                        </div>
                    </div>
                </div>
            }

            {!isOnline &&
                <div style={{ display: 'flex' }}>
                    <div style={{ color: 'var(--ion-color-danger)', paddingTop: '2px' }}>
                        <SeaIcon icon="offline" forceFontSize="32px"/>
                    </div>
                    <div style={{ padding: '8px 0px 0px 12px' }}>
                        You are currently not connected to the internet
                        <p>
                            While offline you will only be seeing cached data.
                            <br/>
                            Any changes you make will not be saved until you next connect online.
                        </p>
                    </div>
                </div>
            }

            {!isSyncing &&
                <div style={{ display: 'flex' }}>
                    <div>
                        <SeaIcon icon="tick" forceFontSize="32px"/>
                    </div>
                    <div style={{ padding: '9px 0px 0px 12px' }}>
                        Your device is synced with the cloud.
                    </div>
                </div>
            }

            {isSyncing &&
                <div style={{ display: 'flex' }}>
                    {isOnline ?
                        <div className="sync-spinner big">
                            <SeaIcon icon="sync" forceFontSize="32px"/>
                        </div>
                        :
                        <div className="big" style={{ color: 'var(--ion-color-danger)', position: 'relative' }}>
                            <SeaIcon icon="sync" forceFontSize="33px"/>
                            <div style={{ position: 'absolute', top: '8px', left: '8px' }}>
                                <SeaIcon icon="pauseOutline" forceFontSize="17px" />
                            </div>
                        </div>
                    }
                    <div style={{ padding: '8px 0px 0px 12px' }}>
                        {isOnline ?
                            <>
                                Sea Flux is syncing your device with the cloud...
                            </>
                            :
                            <>
                                Syncing with the cloud is paused and will resume once you're back online.
                            </>
                        }
                    </div>
                </div>
            }

            {(showAtleast.dataSync || (dataSyncStatus && dataSyncStatus.totalTasks > 0)) &&
                <div className="sync-progress-container">
                    <div className="info">
                        Syncing data... {(dataSyncStatus && dataSyncStatus.totalTasks > 0) ? (!isOnline ? '(Paused)' : '') : '(Done)'}
                    </div>
                    <IonProgressBar
                        color={isOnline ? 'primary' : 'danger'}
                        value={
                            dataSyncStatus?.totalTasks
                            ?
                            ((dataSyncStatus.totalTasks - dataSyncStatus.tasksLeft - 1) / dataSyncStatus.totalTasks)
                            :
                            1
                        }
                    />
                </div>
            }
            {(showAtleast.uploads || (fileUploadStatus && fileUploadStatus.totalFilesToUpload > 0)) &&
                <div className="sync-progress-container">
                    <div className="info">
                        Uploading files... {(fileUploadStatus && fileUploadStatus.totalFilesToUpload > 0) ? `(${fileUploadStatus.totalFilesToUpload - fileUploadStatus.filesLeft + 1} of ${fileUploadStatus.totalFilesToUpload})${ !isOnline ? ' (Paused)' : ''}` : '(Done)'}
                    </div>
                    <IonProgressBar
                        color={isOnline ? 'primary' : 'danger'}
                        value={
                            (fileUploadStatus?.totalFilesToUpload)
                            ?
                            fileUploadStatus.progress
                            :
                            1
                        }
                    />
                </div>
            }
            {(showAtleast.downloads || (fileSyncStatus && fileSyncStatus.totalFilesToCache > 0)) &&
                <div className="sync-progress-container">
                    <div className="info">
                        Downloading files... {(fileSyncStatus && fileSyncStatus.totalFilesToCache > 0) ? `(${fileSyncStatus.totalFilesToCache - fileSyncStatus.filesLeft + 1} of ${fileSyncStatus.totalFilesToCache})${!isOnline ? ' (Paused)' : ''}` : '(Done)'}
                    </div>
                    <IonProgressBar
                        color={isOnline ? 'primary' : 'danger'}
                        value={
                            (fileSyncStatus?.totalFilesToCache)
                            ?
                            ((fileSyncStatus.totalFilesToCache - fileSyncStatus.filesLeft - 1) / fileSyncStatus.totalFilesToCache)
                            :
                            1
                        }
                    />
                </div>
            }
            {isSyncing && isOnline &&
                <div style={{ paddingTop: '24px' }}>
                    {isSyncing && `Note: You can continue using the app while we keep syncing in the background.`}
                </div>
            }

            <div style={{ paddingTop: '32px' }}>
                <h2 className="for-view-modal">Device Storage</h2>
                <p>
                    Sea Flux is using up about <b>{renderStorageValueMB(totalSize)}</b> of storage on your device to cache files.
                </p>
                {(diskSpaceStatus!.haveEnoughSpace) && isPlatform('hybrid') &&
                    <p>
                        Your device has <b>{renderStorageValueMB(diskSpaceStatus!.bytesFree)}</b> of storage space left.
                    </p>
                }
            </div>
            <div className="sync-modal-graph">
                {pieGraph}
            </div>

            <div style={{ textAlign: 'right', paddingBottom: '40px' }}>
                <SeaButton
                    zone="white"
                    onClick={(e) => setShowSettingsModal(true)}
                    mini
                >
                    Edit Device Storage Settings
                </SeaButton>
            </div>

            {/* <SeaButton onClick={(e) => sharedState.diskSpaceStatus.set({
                bytesFree: 32189745,
                haveEnoughSpace: false
            })}>
                Test: Fake running out of space
            </SeaButton> */}

            {showModal &&
                <EditStorageSettingsModal
                    showModal={showSettingsModal}
                    setShowModal={setShowSettingsModal}
                    diskSpaceStatus={diskSpaceStatus!}
                />
            }

        </SeaModal>
    );
};

export default SyncModal;

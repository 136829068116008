import React, { useState, useEffect, Profiler } from 'react';
import { logPageView } from '../../../lib/firebase';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { formatDate, formatValue } from '../../../lib/util';
import { permissionLevels } from '../../../shared-state/Core/userPermissions';
import { onProfilerRender } from '../../../lib/performance';
import { sharedState } from '../../../shared-state/shared-state';
import { SurveyReport } from '../../../shared-state/VesselDocuments/vesselSurveyReports';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import EditSurveyReport from '../../../modals/VesselDocuments/SurveyReports/EditSurveyReport/EditSurveyReport';
import ViewSurveyReport from '../../../modals/VesselDocuments/SurveyReports/ViewSurveyReport/ViewSurveyReport';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import './SurveyDocuments.css';

interface SurveyReportsProps {
    visible: boolean
}

const SurveyReports: React.FC<SurveyReportsProps> = ({visible}) => {
    const reportItems = sharedState.vesselSurveyReports.use(visible ? 1 : 101);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter();
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<SurveyReport>();

    useEffect(() => {
        if (visible) {
            logPageView('VesselDocuments/SurveyDocuments');
        }
        resetPageLimit();
    }, [visible, resetPageLimit]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (selectedItem?.id && reportItems?.byId[selectedItem.id]) { 
            setSelectedItem(reportItems.byId[selectedItem.id]);
        };
    }, [reportItems, selectedItem?.id]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };
    const onViewItem = (item: SurveyReport) => {
        setShowViewItemModal(true);
        setSelectedItem(item)
    };

    return (
        <RequirePermissions
            role="survey"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="survey-reports page-head">
                <div><h2>Survey Documents</h2></div>
                <div className="actions">
                    <RequirePermissions
                        role="survey"
                        level={permissionLevels.CREATE}
                    >
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add"/>
                            Add New Report
                        </SeaButton>
                    </RequirePermissions>
                    {/* <div className="spacer"></div>
                    <SeaButton onClick={(e) => onGeneratePdf()} zone="grey" shape="circle"><SeaIcon slot="icon-only" icon="pdf"/></SeaButton> */}
                </div>
            </div>

            <SeaNoData
                dataName="survey documents"
                isLoading={!reportItems}
                hasNoData={reportItems && Object.keys(reportItems.data).length === 0}
                isUsingFilter={false}
            />

            <div className={`survey-reports has-thumbs ${reportItems && Object.keys(reportItems.data).length > 0 ? 'reveal' : 'conceal' }`
            }>
                <div className="sea-row headings">
                    <div></div>
                    <div>Title</div>
                    <div>Date</div>
                    <div>Surveyor</div>
                    <div>Present</div>
                    <div>Location</div>
                    <div>In / Out Water</div>
                </div>
                <Profiler id="documents.surveyReports" onRender={onProfilerRender}>
                    {reportItems && reportItems.categories?.map((category: string) => {
                        if (isLimitReached()) {
                            return undefined;
                        }
                        return (
                            <React.Fragment key={category}>
                                <div className="category-heading">{category}</div>
                                {mapArrayWithLimit(reportItems?.data[category], (item: SurveyReport) => {
                                    return (
                                        <div key={item.id} className="sea-card sea-row" onClick={(e) => onViewItem(item)}>
                                            <div>
                                                <SeaFileImage files={item.files} size="tiny"/>
                                            </div>
                                            <div className="bold truncate-2">{item.title}</div>
                                            <div className="truncate-2">{formatDate(item.whenSurveyed)}</div>
                                            <div className="truncate-2">{formatValue(item.surveyor)}</div>
                                            <div className="truncate-2">{formatValue(item.personnelPresent)}</div>
                                            <div className="truncate-2">{formatValue(item.location)}</div>
                                            <div className="truncate-2">{formatValue(item.inOrOutWater)}</div>
                                        </div>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                    {limitTriggerElement}
                </Profiler>
            </div>
            {visible &&
                <>
                    <EditSurveyReport
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                    />
                    {selectedItem && <ViewSurveyReport
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                    />}
                </>
            }
        </RequirePermissions>
    );
};

export default SurveyReports;

import React, { useState, useEffect } from 'react';
import { WriteBatch } from "firebase/firestore";
import { formatDate, formatValue } from '../../../../lib/util';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { permissionLevels } from '../../../../shared-state/Core/userPermissions';
import { CategoriesData, renderCategoryName } from '../../../../lib/categories';
import { RichTextState, initialRichTextState, loadSfdoc } from '../../../../lib/richText';
import { SplittableBatch } from '../../../../lib/firebase';
import { SeaScrollable } from '../../../../components/SeaScrollableArea/SeaScrollableArea';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { sharedState } from '../../../../shared-state/shared-state';
import { SOP } from '../../../../shared-state/VesselDocuments/vesselSOPS';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import EditSOP from '../EditSOP/EditSOP';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaRichText from '../../../../components/lexical/SeaRichText/SeaRichText';
import './ViewSOP.css';

interface ViewSOPProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem: SOP,
    vesselSopCategories: CategoriesData
}

const ViewSOP: React.FC<ViewSOPProps> = ({showModal, setShowModal, selectedItem, vesselSopCategories}) => {
    const vesselId = sharedState.vesselId.use(showModal);
    const { resetPageLimit } = usePageLimiter();
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [richTextState, setRichTextState] = useState<RichTextState>(initialRichTextState);
    const [onScroll, setOnScroll] = useState<(event: Event) => void>();
    const [visible, setVisible] = useState(false);
    const [modalContentRef, setModalContentRef] = useState<React.RefObject<SeaScrollable>>();

    useEffect(() => {
        resetPageLimit();
    }, [showModal, resetPageLimit]);

    // Handle loading
    useEffect(() => {
        if (selectedItem?.sfdoc) {
            return loadSfdoc(
                selectedItem.sfdoc,
                setRichTextState
                // getDefaultContent,
                // licenseeSettings !== undefined,
            );
        }
    }, [selectedItem?.sfdoc]);

    // useEffect(() => {
    //     setCompletedSopItems(undefined);
    //     if (selectedItem) {
    //         return onSnapshot(
    //             query(
    //                 collection(firestore, 'SOPsCompleted'),
    //                 where('sopId', '==', selectedItem.id),
    //                 where('state', '==', 'active'),
    //                 orderBy('whenCompleted', 'desc')
    //             ),
    //             (snap) => {
    //                 const _completedSopItems = snap.docs.map((doc) => {
    //                     registerFiles(doc.data().files);
    //                     return {
    //                         id: doc.id,
    //                         ...doc.data()
    //                     };
    //                 });
    //                 setCompletedSopItems(_completedSopItems);
    //             }, (error) => {
    //                 reportError('Failed to access SOPs completed data', error.message, error, {
    //                     selectedItem
    //                 });
    //             }
    //         );
    //     }
    // }, [selectedItem]);

    const onEditItem = () => {
        setShowEditItemModal(true);
    };
    const onDeleteItem = (item: SOP, closeModal = false) => {
        deleteIfConfirmed(
            'SOPs',
            item.id,
            (batch: WriteBatch | SplittableBatch) => {
                onCollectionUpdated(batch, 'SOPs');
                if (closeModal) {
                    setShowModal(false);
                }
            },
            'item',
            selectedItem?.title,
            vesselId ? [vesselId] : []
        );
        //setRequestToDeleteItem({item: item, collection: collectionName, closeModal: closeModal})
    };

    const editButtons = (
        <RequirePermissions
            role="standardOperatingProcedures"
            level={permissionLevels.EDIT}
        >
            <SeaButton zone="white" type="submit" onClick={(e) => onEditItem()}><SeaIcon icon="edit" slot="start" />Edit SOP</SeaButton>
            <RequirePermissions
                role="standardOperatingProcedures"
                level={permissionLevels.FULL}
            >
                <div className="spacer-wide"></div>
                <SeaLinkButton mode="standard-link" onClick={(e) => onDeleteItem(selectedItem, true)}>Delete SOP</SeaLinkButton>
            </RequirePermissions>
        </RequirePermissions>
    );

    return (
        <SeaModal
            title={selectedItem?.title}
            showModal={showModal}
            setShowModal={setShowModal}
            size="rich-text"
            noTitleOverflow={true}
            scrollEvents={true}
            onScroll={onScroll}
            onOpened={() => {
                setVisible(true);
            }}
            onClosed={() => {
                setVisible(false);
            }}
            setModalContentRef={setModalContentRef}
        >
            <div className="info-image">
                <SeaFileImage
                    files={selectedItem?.files}
                    size="medium"
                    showOthers
                />
            </div>
            <SeaGrid>
                <SeaGridCell label="Category" w="50">
                    {formatValue(renderCategoryName(selectedItem?.categoryId, vesselSopCategories))}
                </SeaGridCell>
                <SeaGridCell label="Issue Date" w="50">
                    {formatDate(selectedItem?.whenIssued)}
                </SeaGridCell>
            </SeaGrid>

            <div className="view-modal-buttons">
                {editButtons}
            </div>

            {selectedItem?.sfdoc &&
                <>
                    <div className="end-info-line"></div>
                    <SeaRichText
                        forModal={true}
                        visible={visible}
                        setOnScroll={setOnScroll}
                        richTextState={richTextState}
                        modalContentRef={modalContentRef}
                        editButtons={editButtons}
                    />
                </>
            }

            {showModal &&
                <EditSOP
                    showModal={showEditItemModal}
                    setShowModal={setShowEditItemModal}
                    itemToUpdate={selectedItem}
                    level={2}
                    vesselSopCategories={vesselSopCategories}
                />
            }
        </SeaModal>
    );
};

export default ViewSOP;

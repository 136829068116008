import React from 'react';
import { sharedState } from '../../../shared-state/shared-state';
import { collection, doc, setDoc } from 'firebase/firestore';
import { firestore } from '../../../lib/firebase';
import { makeDefaultErrorReport } from '../../../managers/ErrorsManager/ErrorsManager';
import { refreshApp } from '../../../App';
import { jsonToStrings } from '../../../lib/util';
import { physicalDeviceInfo } from '../../../shared-state/Core/deviceInfo';
import packageJson from '../../../../package.json';
import SeaButton from '../../../components/SeaButton/SeaButton';

interface ErrorFallbackProps {
    error: Error,
    resetErrorBoundary: () => void
}

let errorFallbackUsed = false;
let newErrorReportRef: any = undefined;
const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    console.log('ErrorFallback hit!');
    if (!errorFallbackUsed) {
        newErrorReportRef = doc(collection(firestore, 'errorReports'));
        setDoc(
            newErrorReportRef,
            {
                ...makeDefaultErrorReport(),
                errorId: newErrorReportRef.id,
                whatFailed: 'App unexpected crash occurred.',
                reason: error.message,
                errorStack: error?.stack ? error.stack : undefined,
                shownToUser: true,
                state: 'unexpectedCrash'
            }
        ).then(() => {
            console.log(`Yellow Screen Error Reported id=${newErrorReportRef.id}`);
        }).catch((error) => {
            console.error('Failed to report error to firestore', error);
        });
    }
    errorFallbackUsed = true;
    return (
        <div style={{
            backgroundColor: '#ffff7f',
            padding: 'calc(20px + var(--safe-inset-top)) calc(20px + var(--safe-inset-right)) calc(20px + var(--safe-inset-bottom)) calc(20px + var(--safe-inset-left))',
            height: '100vh',
            width: '100vw',
            overflow: 'auto'
        }}>
            <div style={{ position: 'absolute', right: '16px', top: '24px' }}>
                <SeaButton onClick={(e) => { refreshApp('Yellow screen restart button pressed,') }}>Restart App</SeaButton>
            </div>
            <h2>Sea Flux has unexpectedly crashed.</h2>
            <pre style={{ color: "red", fontWeight: 'bold' }}>{error.message}</pre>
            <p>
                Build {packageJson?.version} {packageJson?.build}. Report Id: {newErrorReportRef.id}
            </p>
            <p>now: {Date.now()}, {jsonToStrings(physicalDeviceInfo).join(', ')}, deviceId: {sharedState.deviceId.current}</p>
            <p>{`${sharedState.user.current?.firstName} ${sharedState.user.current?.lastName}, id: ${sharedState.user.current?.id}, licenseeId: ${sharedState.user.current?.licenseeId}`}</p>
            <p>{jsonToStrings(sharedState.onlineStatus.current).join(', ')}</p>
            <p>{jsonToStrings(sharedState.appState.current).join(', ')}</p>
            <pre style={{ whiteSpace: 'pre-wrap' }}>{error.stack}</pre>
        </div>
    );
}

export default ErrorFallback;

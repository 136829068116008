import { WriteBatch, collection, doc, onSnapshot } from "firebase/firestore";
import { SplittableBatch, firestore } from "../../lib/firebase";
import { SeaFile, seaFilesToValue } from "../../lib/files";
import { ArchivableDocument, CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { renderFullName } from './users';
import { LogbookSettings } from '../VesselLogbook/voyages';
import { onUserAction } from "../General/appActivity";

//
// Loads vessel data based on vesselId.
// vesselId represents the currently selected vessel within the app.
//



export interface Vessel extends CreateableDocument, UpdateableDocument, ArchivableDocument {
    areaOfOperation?: string;
    breadth?: string;
    callsign?: string;
    construction?: string;
    driveType?: string;
    enginesDescription?: string;
    fuelRange?: string;
    grossTonnage?: string;
    images: string[];
    isShoreFacility?: boolean;
    length?: string;
    licenseeId: string;
    logbookSettings?: LogbookSettings;
    marineTrafficLink?: string;
    master?: string;
    mmsi?: string;
    mnz?: string;
    name: string;
    newMemberIds?: string[];
    portOfRegistry?: string;
    possibleTags?: string[];
    possibleMaintenanceTags?: string[];
    safetyMeetingSettings?: {
        interval?: string;
        whenDue?: string;
        emailReminder?: string;
        whenToRemind?: string;
    };
    state: string;
    whenLaunched?: number;
}

export const vesselConfig: SharedStateConfig<Vessel> = {
    isAlwaysActive: true,
    dependencies: ['vesselId'],
    countLiveDocs: () => sharedState.vessel.current ? 1 : 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        if (vesselId) {
            let isFirstLoad = true;
            return onSnapshot(
                doc(firestore, 'vessels', vesselId),
                (snap) => {
                    done();
                    if (snap.exists()) {
                        set({
                            id: snap.id,
                            ...snap.data(),
                        } as Vessel);
                        if (isFirstLoad) {
                            console.log(`Selected vessel ${vesselId} ${snap.data()?.name}`);
                            onUserAction(`Selected vessel ${snap.data()?.name} id=${vesselId}`);
                            isFirstLoad = false;
                        }
                    } else {
                        clear();
                    }
                },
                (error) => {
                    done();
                    console.log(`error getting vessel ${vesselId}`, error);
                    clear();
                }
            );
        }
    }
};

export const getEngineName = (engineId: string) => {
    const engines = sharedState.engines.current;
    if (engines && engines.byId[engineId]) {
        return engines.byId[engineId].name;
    }
    return '';
};

export const sendVesselNotification = (
    batch: WriteBatch | SplittableBatch,
    type: string, // e.g. hazardReported
    emailMeType: string, // See emailMeOptions for possible ids (types)
    itemData: any,
    files: SeaFile[],
    vesselIds?: string[] // leave out just to use current selected vesselId
) => {
    const userId = sharedState.userId.current;
    const user = sharedState.user.current;
    const licenseeId = sharedState.licenseeId.current;
    const vesselId = sharedState.vesselId.current;
    const _vesselIds = vesselIds ? vesselIds : [vesselId];
    if (!_vesselIds) return;

    batch.set(doc(collection(firestore, 'notifications')), {
        //origin: getServerUrl(),
        licenseeId: licenseeId,
        vesselIds: _vesselIds,
        whenRequested: Date.now(),
        requestedBy: userId,
        type: type,
        // to: to, // Rather than work out "to" here, we'll leave it up to functions/onNotificationCreate
        emailMeType: emailMeType,
        state: 'waiting',
        reportedBy: renderFullName(user),
        // images: (images && images.length > 0) ? images : undefined,
        // files: (_files && _files.length > 0) ? _files : undefined,
        files: files ? seaFilesToValue(files) : undefined,
        item: {
            ...itemData,
        },
    });
};

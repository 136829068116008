import React, { useCallback } from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { pdfStyles, styleItemColumn } from "../../lib/pdf";
import { renderCategoryName } from "../../lib/categories";
import { renderFullName } from "../../shared-state/Core/users";
import { sharedState } from '../../shared-state/shared-state';
import { getImgSrcFromString } from "../../lib/files";
import { SparePart } from '../../shared-state/VesselMaintenance/spareParts';
import SeaPdfGenerator from "../../components/SeaPdfGenerator/SeaPdfGenerator";
import PdfItemsHeader from "../../components/PdfItemsHeader/PdfItemsHeader";
import CriticalFlagPDF from "../../assets/svg/CriticalFlagPDF";

interface SparePartsListPdfProps {
    generatingPdf: boolean;
    setGeneratingPdf: (generatingPdf: boolean) => void;
    spareParts: SparePart[];
}

const SparePartsListPdf: React.FC<SparePartsListPdfProps> = ({
    generatingPdf,
    setGeneratingPdf,
    spareParts,
}) => {
    const vessel = sharedState.vessel.use(generatingPdf);
    const vesselLocations = sharedState.vesselLocations.use(generatingPdf);
    const equipment = sharedState.equipment.use(generatingPdf);
    const title = `Spare Parts List - ${vessel?.name}`;

    const checkContainsCritical = useCallback((equipmentIds: string[] | undefined) => {
        if (!equipmentIds) return false;
        for (const id of equipmentIds) {
            if (equipment?.byId[id]?.isCritical) {
                return true;
            }
        }
        return false;
    }, [equipment?.byId]);

    const MakePDF = useCallback(() => {
        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={pdfStyles.page}
                    orientation="portrait"
                    size="A4" // 21cm x 29.7cm
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        userFullName={renderFullName()}
                    />
                    <View wrap={false} style={pdfStyles.itemHeadingsRow}>
                        <View style={pdfStyles.thumbColumn}></View>
                        <View style={styleItemColumn(1.6)}>
                            <Text>Item</Text>
                        </View>
                        <View style={styleItemColumn(0.7)}>
                            <Text>Quantity</Text>
                        </View>
                        <View style={styleItemColumn(1.6)}>
                            <Text>Equipment</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Location</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Part #</Text>
                        </View>
                        <View style={pdfStyles.criticalColumn}>
                            <Text>Critical</Text>
                        </View>
                    </View>
                    <View
                        wrap={false}
                        style={pdfStyles.itemHeadingsLine}
                    ></View>
                    {
                        spareParts?.map(
                            (item: SparePart, index: number) => {
                                const file = (item.files && item.files.length > 0) ? item.files[0] : undefined;
                                const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                                const isCritical = checkContainsCritical(item.equipmentIds)
                                return (
                                    <React.Fragment key={item.id}>
                                        {index > 0 && (
                                            <View
                                                style={pdfStyles.itemsRowSpacer}
                                            ></View>
                                        )}
                                        <View
                                            wrap={false}
                                            style={pdfStyles.itemsRow}
                                        >
                                            <View style={pdfStyles.thumbColumn}> 
                                            {imgSrc && 
                                                <Image
                                                    src={imgSrc}
                                                    style={pdfStyles.thumbImg}
                                                />
                                            }
                                            </View>
                                            <View style={styleItemColumn(1.6)}>
                                                <Text>
                                                    {item.item}
                                                </Text>
                                            </View>
                                           
                                            <View
                                                style={styleItemColumn(0.7)}
                                            >
                                                <Text>
                                                    {item.quantity}
                                                </Text>
                                            </View>
                                            <View style={styleItemColumn(1.6)}>
                                                <Text>
                                                    {item.equipmentList ? item.equipmentList : '-'}
                                                </Text>
                                            </View>
                                             <View
                                                style={styleItemColumn(1)}
                                            >
                                                <Text>
                                                    {renderCategoryName(item.locationId, vesselLocations)}
                                                </Text>
                                            </View>
                                            <View
                                                style={styleItemColumn(1)}
                                            >
                                                <Text>
                                                    {item.partNum}
                                                </Text>
                                            </View>
                                            <View style={pdfStyles.criticalColumn}>
                                                {isCritical && (
                                                    <CriticalFlagPDF />
                                                )}
                                            </View>
                                        </View>
                                    </React.Fragment>
                                );
                            }
                        )}
                </Page>
            </Document>
        );
    }, [title, spareParts, checkContainsCritical, vesselLocations]);

    return (
        <SeaPdfGenerator
            generatingPdf={generatingPdf}
            setGeneratingPdf={setGeneratingPdf}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="SparePartsList"
        />
    );
};

export default SparePartsListPdf;

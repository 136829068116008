import React, { useState } from 'react';
import { runSharedState, sharedState } from '../../../../shared-state/shared-state';
import { cachedDataInfo, resetCachedDataInfo, updateLicenseeCachedDataInfo, updateVesselCachedDataInfo } from '../../../../shared-state/DataSyncSystem/cachedDataInfo';
import { formatDatetime } from '../../../../lib/util';
import { LicenseeDataSyncCollection, VesselDataSyncCollection } from '../../../../shared-state/DataSyncSystem/dataSyncTasks';
import { renderVesselName } from '../../../../shared-state/Core/vessels';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';


const DebugDataSyncTab: React.FC = () => {
    const dataSyncStatus = sharedState.dataSyncStatus.use();
    const [drawCount, setDrawCount] = useState(0); // eslint-disable-line @typescript-eslint/no-unused-vars

    const refreshLicenseeCache = (collection: string) => {
        updateLicenseeCachedDataInfo(collection as LicenseeDataSyncCollection, undefined);
        runSharedState('whenLicenseeTouched');
        setDrawCount((n) => n+1);
    };
    const refreshVesselCache = (vesselId: string, collection: string) => {
        updateVesselCachedDataInfo(vesselId, collection as VesselDataSyncCollection, undefined);
        runSharedState('whenVesselTouched');
        setDrawCount((n) => n+1);
    };

    return (
        <div style={{ padding: '4px 8px 0px 8px', maxWidth: '600px' }}>
            <div className="columns">
                <div>
                    Total Tasks: <b>{dataSyncStatus?.totalTasks ?? 0}</b>, Tasks Left: <b>{dataSyncStatus?.tasksLeft ?? 0}</b>
                </div>
                <div style={{ flex: '1 1 0', textAlign: 'right', padding: '0px 8px' }}>
                    <div
                        className="pushy"
                        style={{ width: '20px', display: 'inline-block' }}
                        onClick={(e) => resetCachedDataInfo()}
                    >
                        <SeaIcon icon="refresh" forceFontSize="18px" marginTop={-4} marginBottom={-5}/>
                    </div>
                </div>
            </div>
            <div>
                <h2 style={{ fontSize: '16px' }}>Cached Licensee Data</h2>
            </div>
            <div className="card" style={{ color: 'white', backgroundColor: 'unset', paddingBottom: '0px', paddingTop: '4px' }}>
                <div style={{ flex: '0 250px' }}>Collection</div>
                <div>Most recently touched</div>
                <div></div>
            </div>
            {Object.keys(cachedDataInfo.licensee).sort().map((key) => {
                const when = cachedDataInfo.licensee?.[key as keyof typeof cachedDataInfo.licensee];
                return (
                    <div key={key} className="card">
                        <div style={{ flex: '0 250px' }}>{key}</div>
                        <div>{when ? formatDatetime(when, ' - ') : '-'}</div>
                        <div style={{ flex: '1 1 0', textAlign: 'right', color: 'var(--ion-color-primary)' }}>
                            <div
                                className="pushy"
                                style={{ width: '20px', display: 'inline-block' }}
                                onClick={(e) => refreshLicenseeCache(key)}
                            >
                                <SeaIcon icon="refresh" forceFontSize="18px" marginTop={-4} marginBottom={-5}/>
                            </div>
                        </div>
                    </div>
                );
            })}
            {Object.keys(cachedDataInfo.vessels).map((vesselId) => {
                return (
                    <React.Fragment key={vesselId}>
                        <div>
                            <h2 style={{ fontSize: '16px', paddingTop: '8px' }}>{renderVesselName(vesselId)} - {vesselId}</h2>
                        </div>
                        {Object.keys(cachedDataInfo.vessels[vesselId]).sort().map((collection) => {
                            const when = cachedDataInfo.vessels[vesselId][collection as keyof typeof cachedDataInfo.vessels[string]];
                            return (
                                <div key={collection} className="card">
                                    <div style={{ flex: '0 250px' }}>{collection}</div>
                                    <div>{when ? formatDatetime(when, ' - ') : '-'}</div>
                                    <div style={{ flex: '1 1 0', textAlign: 'right', color: 'var(--ion-color-primary)' }}>
                                        <div
                                            className="pushy"
                                            style={{ width: '20px', display: 'inline-block' }}
                                            onClick={(e) => refreshVesselCache(vesselId, collection)}
                                        >
                                            <SeaIcon icon="refresh" forceFontSize="18px" marginTop={-4} marginBottom={-5}/>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default DebugDataSyncTab;

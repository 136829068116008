import { SharedStateConfig, sharedState } from "../shared-state";
import { canView } from "../../shared-state/Core/userPermissions";
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { formatYear } from '../../lib/util';
import { VesselCertificate } from './vesselCertificates';
import { registerFiles } from "../FileSyncSystem/filesToCache";


export type ArchivedVesselCertificatesData = {
    byCategory: {
        [key: string]: VesselCertificate[];
    },
    categories: string[];
};

export const archivedVesselCertificatesConfig: SharedStateConfig<ArchivedVesselCertificatesData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId'],
    countLiveDocs: () => {
        let count = 0;
        sharedState.archivedVesselCertificates.current &&
            Object.keys(sharedState.archivedVesselCertificates.current.byCategory).forEach((key) => {
                count += (sharedState.archivedVesselCertificates.current as ArchivedVesselCertificatesData).byCategory[key].length;
            });
        return count;
    },
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        if (
            vesselId &&
            canView('vesselCertificates')
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'vesselCertificates'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'archived'),
                    orderBy('whenArchived', 'desc')
                ),
                (snap) => {
                    done();
                    const _archivedVesselCertificates = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as VesselCertificate;
                    });

                    const byCategory = {} as {
                        [key: string]: VesselCertificate[]
                    };
                    const categories: string[] = [];
                    _archivedVesselCertificates.forEach((item) => {
                        registerFiles(item.files, 'vesselCertificates', item, 'thumbnailsOnly');
                        let whenIssued = formatYear(item.whenIssued);
                        if (byCategory[whenIssued] === undefined) {
                            categories.push(whenIssued);
                            byCategory[whenIssued] = [];
                        }
                        byCategory[whenIssued].push(item);
                    })
                    categories.sort().reverse();
                    set({
                        byCategory,
                        categories
                    });
                },
                (error) => {
                    done();
                    // This should be very rare
                    console.log(
                        `Failed to access archivedVesselCertificates for vessel ${vesselId}`,
                        error
                    );
                }
            );
        }
    }
};
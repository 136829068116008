import { SharedStateConfig, sharedState } from "../shared-state";
import { canView } from '../../shared-state/Core/userPermissions';
import { Drill } from './drills';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';

export type VesselDrillsData = {
    all: Drill[],
    byId: {
        [drillId: string]: Drill
    },
};

export const vesselDrillsConfig: SharedStateConfig<VesselDrillsData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId', 'todayMillis'],
    countLiveDocs: () => sharedState.vesselDrills.current?.all.length ?? 0,
    run: (done, set, clear) => {
        done();
        clear();
        const vesselId = sharedState.vesselId.current;
        if (
            vesselId &&
            canView('drills')
        ) {
            onSnapshot(
                query(
                    collection(firestore, 'drills'),
                    where('vesselId', '==', vesselId),
                    //where('state', '==', 'active')
                    orderBy('name', 'asc')
                ),
                (snap) => {
                    done();
                    if (snap.docs.length === 0) {
                        set({ all: [], byId: {} });
                        return;
                    }
                    const byId = {} as {
                        [drillId: string]: Drill
                    };
                    const all = [] as Drill[];
                    snap.docs.forEach((doc) => {
                        const drill = {
                            id: doc.id,
                            ...doc.data()
                        } as Drill;
                        byId[drill.id] = drill;
                        if (drill.state === 'active') {
                            all.push(drill);
                        }
                        return drill;
                    });

                    set({
                        all,
                        byId,
                    });
                },
                (error) => {
                    done();
                    // This should be very rare
                    console.log(
                        `Failed to access drills for ${vesselId}`,
                        error
                    );
                }
            );
        } else {
            done();
        }
    }
};

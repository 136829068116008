import { App } from '@capacitor/app';
import { sharedState } from "../shared-state";
import { isPlatform } from '@ionic/react';
import { doc, setDoc } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { setAppIsOkToRestart } from '../General/diagnoseRefreshProblems';
import { disableFirestoreNetwork, enableFirestoreNetwork } from './firestoreState';

//
// Records app state in the context of the operating system
// Whether it is in the foreground (active), or not
// As well as when it last changed state
//
// Attempts to handle the indexedDb problem that can happen in iOS...
// When the app comes back into the foreground, indexedDb may have broken so we'll need to restart the app.
//

export type AppState = {
    isActive: boolean,
    lastActive?: number,
    lastInactive?: number
};

export const appStateConfig = {
    isAlwaysActive: true,
    default: {
        isActive: true // Assume when the app is initialising, it will be active (i.e. in the foreground)
    },
    notes: 'Source: initAppState'
};

export const initAppState = () => {
    App.addListener('appStateChange', (state) => {
        onAppStateChanged(state.isActive, false);
    });
    onAppStateChanged(true, true);
    return () => {
        App.removeAllListeners();
    };
};

export const onAppStateChanged = (isAppActive: boolean, justStarted = false) => {
    // Let diagnoseIosRefresh that the next startup is intentional or not.
    // If the app is going into the background (i.e. start.isActive is false), the user could be about to shut down the app.
    // However, if the app is in the foreground, then if the the app restarts it is probably unintentional
    setAppIsOkToRestart(
        !isAppActive,
        isAppActive ? 'App to foreground' : 'App to background'
    );

    const appState = {
        ...sharedState.appState.current
    } as AppState;
    appState.isActive = isAppActive;
    if (appState.isActive) {
        appState.lastActive = Date.now();
    } else {
        appState.lastInactive = Date.now();
    }
    sharedState.appState.set(appState);

    // Diagnose issues with ios apps coming back into the foreground
    if (isPlatform('ios') && !justStarted) {
        if (isAppActive) {
            // While firestore network is still disabled, let's check if indexedDb is still functional
            let isBroken = false;
            setDoc(
                doc(firestore, 'global', 'iosTest'),
                {
                    whenLastActive: Date.now()
                },
                { merge: true }
            ).catch((error) => {
                console.error('Failed to update iosTest', error);
                if (error?.message && error.message.toLowerCase().indexOf('indexeddb') !== -1) {
                    // This is where we know there an indexedDb problem has occurred
                    isBroken = true;
                    sharedState.navigateTo.set('/restart?reason=indexedDbError');
                    disableFirestoreNetwork();
                }
            }).then(() => {
                console.log('iosTest succeeded');
            });

            //debugApp('File Caching', `iOS app coming into foreground, testing to see if we're ok...`);
            setTimeout(() => {
                //debugApp('File Caching', `iOS Enable firestore network? appState.isActive=${sharedState.appState.isActive} isBroken=${isBroken}`);
                if (sharedState.appState.isActive && !isBroken) {
                    enableFirestoreNetwork();
                }
            }, 5 * 1000);

        } else {
            disableFirestoreNetwork();
        };
    }
};

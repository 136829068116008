import { DocumentData, QueryDocumentSnapshot, collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../lib/firebase";
import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { renderFullNameForUserId } from "../Core/users";
import { registerFiles, registerSignature } from "../FileSyncSystem/filesToCache";
import { canDelete, canView } from "../../shared-state/Core/userPermissions";

//
// Load contacts
// Note: all contacts are always loaded regardless of permission
//

export type Injury = {
    whoInjured?: string;
    typeIds?: string[];
    locationIds?: string[];
    outcome?: string;
};

export interface Incident extends CreateableDocument, UpdateableDocument {
    authority?: string;
    categoryId?: string;
    causeIds: string[];
    completedBy?: string;
    conditions?: string;
    deletedBy?: string;
    description?: string;
    files: string[];
    initialActions?: string;
    injuries?: Injury[];
    isSensitive?: 'y' | 'n';
    licenseeId: string;
    location?: string;
    name: string;
    notifiedAuthorities?: string;
    prevention?: string;
    propertyDamage?: string;
    reportNum: string;
    reportedBy?: string;
    role?: string;
    searchText?: string;
    signature?: string;
    state: 'draft' | 'forReview' | 'inReview' | 'completed';
    type: string;
    vesselId: string;
    whenAccident?: number;
    whenCompleted?: number;
    whenDeleted?: number;
    whenEvent: number;
    whoInvolved?: string;
    whoInvolvedTypes: string[];
    witnesses?: string;
}

export type IncidentsData = {
    all: Incident[], // Ordered by whenEvent descending
    byId: {
        [incidentId: string]: Incident
    },
    byVesselId: {
        [vesselId: string]: Incident[] // Ordered by whenEvent descending
    }
};

export const incidentsConfig: SharedStateConfig<IncidentsData> = {
    isAlwaysActive: false,
    dependencies: ['userId', 'vesselIds', 'licenseeSettings'],
    countLiveDocs: () => sharedState.incidents.current?.all?.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const userId = sharedState.userId.current;
        const vesselIds = sharedState.vesselIds.current;
        if (
            userId &&
            vesselIds &&
            vesselIds.length > 0 &&
            sharedState.licenseeSettings.current?.hasIncidents
        ) {
            const searchText = (incident: Incident) => {
                let searchText = incident.name;
                if (incident.location) {
                    searchText += incident.location;
                }
                if (incident.reportedBy) {
                    searchText += renderFullNameForUserId(incident.reportedBy);
                }
                if (incident.role) {
                    searchText += incident.role;
                }
                if (incident.whoInvolved) {
                    searchText += renderFullNameForUserId(incident.whoInvolved);
                }
                if (incident.witnesses) {
                    searchText += incident.witnesses;
                }
                if (incident.conditions && incident.conditions !== 'N/A') {
                    searchText += incident.conditions;
                }
                if (incident.propertyDamage) {
                    searchText += incident.propertyDamage;
                }
                if (incident.description) {
                    searchText += incident.description;
                }
                if (incident.initialActions) {
                    searchText += incident.initialActions;
                }
                if (incident.prevention) {
                    searchText += incident.prevention;
                }
                return searchText.toLowerCase();
            };

            const makeAll = (docs: QueryDocumentSnapshot<DocumentData>[]) => {
                return docs.map((doc) => {
                    return {
                        ...doc.data() as Incident,
                        id: doc.id,
                        searchText: searchText(doc.data() as Incident),
                    } as Incident;
                });
            };

            const sortAll = (all: Incident[]) => {
                all.sort((a, b) => {
                    return b.whenEvent - a.whenEvent;
                });
            };

            const processAll = (all: Incident[]) => {
                const byId = {} as {
                    [incidentId: string]: Incident
                };
                const byVesselId = {} as {
                    [vesselId: string]: Incident[]
                };
                all.forEach((incident) => {
                    byId[incident.id] = incident;
                    if (byVesselId[incident.vesselId] === undefined) {
                        byVesselId[incident.vesselId] = [];
                    }
                    byVesselId[incident.vesselId].push(incident);
                    registerFiles(incident.files, 'incidents', incident);
                    registerSignature(incident.signature, 'incidents', incident);
                });
                set({
                    all,
                    byId,
                    byVesselId
                });
            };

            if (canDelete('incidentAccidentMedicalRegister')) {
                //
                // Users with delete permission can view ALL reports (with relevant vessel access)
                //

                return setupArrayQueryListener(
                    'incidents', // what
                    collection(firestore, 'incidents'),
                    [where('state', 'in', ['draft', 'forReview', 'inReview', 'completed'])], // baseConstraints
                    'vesselId',
                    'in',
                    vesselIds,
                    [orderBy('whenEvent', 'desc')],
                    (
                        docs: QueryDocumentSnapshot<DocumentData>[],
                        isCombined: boolean
                    ) => { // processDocs
                        done();
                        const all = makeAll(docs);
                        if (isCombined) { // Need to sort by whenAccident
                            sortAll(all);
                        }
                        processAll(all);
                    }, (error) => { // onError
                        done();
                    }
                );
            } else if (canView('incidentAccidentMedicalRegister')) {
                //
                // Users with view/edit permissions can view ALL non-draft reports (with relevant vessel access)
                // as well as all their own reports (we'll need to combine two query listener results)
                //

                let myDrafts: Incident[];
                let nonDrafts: Incident[];

                const combine = () => {
                    if (myDrafts !== undefined && nonDrafts !== undefined) {
                        const all = [...myDrafts, ...nonDrafts];
                        sortAll(all);
                        processAll(all);
                    }
                };

                // Gather drafts created by me
                const myDraftsCleanup = onSnapshot(
                    query(
                        collection(firestore, 'incidents'),
                        where('addedBy', '==', userId),
                        where('state', '==', 'draft'),
                        orderBy('whenEvent', 'desc')
                    ),
                    (snap) => {
                        done();
                        myDrafts = makeAll(snap.docs);
                        combine();
                    }, (error) => {
                        done();
                        console.log(`Error getting incidents for user ${userId}`, error);
                    }
                );

                // Gather all non draft reports (that I have access via vessel to)
                const nonDraftsCleanup = setupArrayQueryListener(
                    'incidents', // what
                    collection(firestore, 'incidents'),
                    [where('state', 'in', ['forReview', 'inReview', 'completed'])], // baseConstraints
                    'vesselId',
                    'in',
                    vesselIds,
                    [orderBy('whenEvent', 'desc')],
                    (
                        docs: QueryDocumentSnapshot<DocumentData>[],
                        isCombined: boolean
                    ) => { // processDocs
                        nonDrafts = makeAll(docs);
                        combine();
                    }
                );

                // Return cleanup function
                return (() => {
                    myDraftsCleanup();
                    nonDraftsCleanup();
                });
            } else {
                //
                // Users with no view permissions still see their own reports
                //

                return onSnapshot(
                    query(
                        collection(firestore, 'incidents'),
                        where('addedBy', '==', userId),
                        where('state', 'in', ['draft', 'forReview', 'inReview', 'completed']),
                        orderBy('whenEvent', 'desc')
                    ),
                    (snap) => {
                        done();
                        const all = makeAll(snap.docs);
                        processAll(all);
                    }, (error) => {
                        done();
                        console.log(`Error getting incidents for user ${userId}`, error.message, error);
                    }
                );
            }
        }
    }
};

import React, { ReactNode, useMemo } from 'react';
import { isPlatform } from '@ionic/react';
import { useNavigate } from 'react-router';
import { sharedState } from '../../shared-state/shared-state';
import { scrollPageToTop } from '../../layouts/AppLayout/AppLayout';
import { canView, PermissionRole } from '../../shared-state/Core/userPermissions';
import ReactTooltip from 'react-tooltip';
import './SeaSideBarItem.css';

interface SeaSideBarItemProps {
    children?: ReactNode,
    name: string,
    url?: string,
    tab?: string,
    section: string,
    subMenu?: boolean,
    hasTabs?: boolean,
    requireAnyRole?: PermissionRole[],
    closeMenu?: (showMenu: boolean) => void,
    onAction?: (e: any) => void,
    isNew?: boolean
}

const renderToolTips = !isPlatform('hybrid');

const SeaSideBarItem: React.FC<SeaSideBarItemProps> = ({
    children,
    name,
    url,
    tab,
    section,
    subMenu,
    hasTabs,
    requireAnyRole,
    closeMenu,
    onAction,
    isNew
}) => {
    const selectedSectionTab = sharedState.selectedSectionTab.use();

    const selectedSection = sharedState.selectedSection.use();
    const navigate = useNavigate();

    const canViewItem = useMemo(() => {
        if (requireAnyRole) {
            for (let i = 0; i < requireAnyRole.length; i++) {
                if (canView(requireAnyRole[i])) {
                    return true;
                }
            }
            return false;
        }
        return true;
    }, [requireAnyRole]);

    const isSelected = useMemo(() => {
        if (tab) {
            return (selectedSectionTab === tab && selectedSection === section);
        }
        return (selectedSection === section);
    }, [section, selectedSection, selectedSectionTab, tab]);

    const onClick = (e: any) => {
        e.preventDefault();
        if (onAction) {
            onAction(e);
        } else {
            if (closeMenu) {
                closeMenu(false);
            }
            if (url) {
                navigate(url);
            }
            if (tab) {
                scrollPageToTop();
                sharedState.selectedSectionTab.set(tab);
            }
        }
    };

    if (!canViewItem) {
        return <></>;
    }
    return (
        <div className={`sea-side-bar-box ${isSelected ? 'selected' : ''} ${subMenu ? 'sub-menu' : ''} ${hasTabs ? 'has-tabs' : ''} ${tab ? 'tab' : ''}`} onClick={(e) => onClick(e)} data-tip data-for={name}>
            <div className="sea-side-bar-box-content">
                <div className="sea-side-bar-icon">
                    <div>{children}</div>
                </div>
                <div className="sea-side-bar-name no-select">
                    {isNew && <div className="tag-new">NEW</div>}
                    {name ? name : 'Loading...'}
                </div>
            </div>
            {renderToolTips &&
                <ReactTooltip
                    id={name}
                    place="top"
                    type="light"
                    delayShow={0}
                    effect="solid"
                    className="sea-tool-tip"
                >
                    {name}
                </ReactTooltip>
            }
        </div>
    );
};

export default SeaSideBarItem;

import React, { useCallback } from 'react';
import { Document, View, Page,  Text, Image } from '@react-pdf/renderer';
import { pdfStyles, PdfPageLimiter, UnloadedPdfRows } from '../../../lib/pdf';
import { formatDate, formatValue, warnDays } from '../../../lib/util';
import { useReportSettings } from '../../../pages/Reporting/Reporting';
import { renderFullName } from '../../../shared-state/Core/users';
import { sharedState } from '../../../shared-state/shared-state';
import { getImgSrcFromString } from '../../../lib/files';
import { GraphData } from '../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import { VesselCertificate } from '../../../shared-state/VesselDocuments/vesselCertificates';
import SeaNoDataPDF from '../../Components/SeaNoDataPDF';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';
import SeaStatusDueDate from '../../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaHorizontalStackedBarGraph from '../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import reporting from '../../../lib/reporting';

interface VesselCertificateReportPDFProps {
    generatingPdf: boolean,
    setGeneratingPdf: (generatingPdf: boolean) => void,
    title: string,
    subTitle?: string,
    graphData?: GraphData[],
    filteredItems?: VesselCertificate[],
    categories?: (string | undefined)[],
    labelWidthsForPdf?: number[],
}

const VesselCertificateReportPDF: React.FC<VesselCertificateReportPDFProps> = ({
    generatingPdf,
    setGeneratingPdf,
    title,
    subTitle,
    graphData,
    filteredItems,
    categories,
    labelWidthsForPdf
}) => {
    const vessels = sharedState.vessels.use();
    const { selectedVesselId, selectedVesselIds } = useReportSettings();
    const counterRef = React.useRef(0);
    const {  mapPdfArrayWithLimit } = PdfPageLimiter(counterRef);
    
    const MakePDF = useCallback(() => {
        counterRef.current = 0;
        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={{
                        ...pdfStyles.page
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        subHeading={subTitle}
                        userFullName={renderFullName()}
                    />
                    {vessels && selectedVesselIds?.length > 1 ? (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Vessels</Text>
                                <Text>{selectedVesselIds.map((id: string) => vessels.byId[id].name).join(', ')}</Text>
                            </View>
                        </View>
                    ) : null}

                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <SeaHorizontalStackedBarGraph
                                mode="pdf"
                                data={graphData}
                                n={0}
                                visible={true}
                                categories={categories}
                                colourPalette={reporting.colours.taskStatuses}
                                labelWidthsForPdf={labelWidthsForPdf}
                            />
                        </View>
                    </View>

                    <SeaNoDataPDF 
                        dataName="vessel certificates"
                        hasNoData={!filteredItems?.length}
                        isUsingFilter={true}
                    />
                    {filteredItems?.length ? (
                        <View style={pdfStyles.dataContainer}>
                                <View style={pdfStyles.dataHeaderRow}>
                                    <View style={pdfStyles.thumbColumn}></View>
                                    <Text style={[pdfStyles.dataHeaderText, pdfStyles.column2x3]}>Title</Text>
                                    <Text style={[pdfStyles.dataHeaderText, pdfStyles.dateColumn]}>Expiry</Text>
                                    {!selectedVesselId ? <Text style={[pdfStyles.dataHeaderText, pdfStyles.column1]}>Vessel</Text> : null}
                                    <Text style={[pdfStyles.dataHeaderText, pdfStyles.statusColumn]}>Status</Text>
                                </View>
                                {mapPdfArrayWithLimit(filteredItems, (item) => {
                                    const file = (item.files && item.files.length > 0) ? item.files[0] : undefined;
                                    const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                                    return (
                                    <View wrap={false} key={item.id} style={pdfStyles.greyDataRow}>
                                        <View style={pdfStyles.thumbColumn}>
                                            {imgSrc && <Image src={imgSrc} style={pdfStyles.thumbImgRound} />}
                                        </View>
                                        <Text style={[pdfStyles.truncate, pdfStyles.column2x3, pdfStyles.bold]}>{formatValue(item.title)}</Text>
                                        <Text style={pdfStyles.dateColumn}>{formatDate(item.whenExpires)}</Text>
                                        {!selectedVesselId ? <Text style={[pdfStyles.truncate, pdfStyles.column1]}>{vessels?.byId[item.vesselId]?.name}</Text> : null}
                                        <View style={pdfStyles.statusColumn}>
                                            <SeaStatusDueDate
                                                whenDue={item.whenExpires}
                                                warnDays={warnDays.vesselCertificates[0]}
                                                forPdf={true}
                                            />
                                        </View>
                                    </View>
                                )})}
                                <UnloadedPdfRows length={filteredItems?.length} />
                        </View>
                    ) : null}
                </Page>
            </Document>
        )
    }, [title, subTitle, vessels, selectedVesselIds, graphData, categories, labelWidthsForPdf, filteredItems, selectedVesselId, mapPdfArrayWithLimit]);

    return (
        <SeaPdfGenerator
            generatingPdf={generatingPdf}
            setGeneratingPdf={setGeneratingPdf}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="vesselCertificateReport"
        />
    );

};

export default VesselCertificateReportPDF;

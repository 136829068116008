import React, { useState, useEffect, Profiler, useCallback } from 'react';
import { logPageView } from '../../lib/firebase';
import { onProfilerRender } from '../../lib/performance';
import { formatDatetime, formatMonthLonger } from '../../lib/util';
import { canCreate, permissionLevels } from '../../shared-state/Core/userPermissions';
import { renderFullNameForUserId } from '../../shared-state/Core/users';
import { sharedState } from '../../shared-state/shared-state';
import { alertMessage } from '../../managers/AlertManager/AlertManager';
import { Voyage } from '../../shared-state/VesselLogbook/voyages';
import SeaBreadcrumb from '../../components/SeaBreadcrumb/SeaBreadcrumb';
import SeaLink from '../../components/SeaLink/SeaLink';
import StandardPageLayout from '../../layouts/StandardPageLayout/StandardPageLayout';
import SeaButton from '../../components/SeaButton/SeaButton';
import SeaIcon from '../../components/SeaIcon/SeaIcon';
import EditVoyage from '../../modals/VesselLogbook/EditVoyage/EditVoyage';
import ViewCompletedVoyage from '../../modals/VesselLogbook/ViewCompletedVoyage/ViewCompletedVoyage';
import LogbookSettings from '../../modals/VesselLogbook/LogbookSettings/LogbookSettings';
import CreateLogbookReport from '../../modals/VesselLogbook/CreateLogbookReport/CreateLogbookReport';
import SeaNoData from '../../components/SeaNoData/SeaNoData';
import RequirePermissions from '../../components/RequirePermissions/RequirePermissions';
import './VesselLogbook.css';

const VesselLogbook: React.FC = () => {
    const vessel = sharedState.vessel.use();
    const voyages = sharedState.voyages.use();
    sharedState.engines.use(101); // Prepare for modals to access
    sharedState.customForms.use(102); // Prepare for modals to access
    sharedState.scheduledMaintenanceTasks.use(103); // Prepare for modals to access
    sharedState.safetyCheckItems.use(103); // Prepare for modals to access
    sharedState.vesselSafetyItems.use(103); // Prepare for modals to access
    sharedState.vesselLocations.use(103); // Prepare for modals to access
    const [showLogbookSettingsModal, setShowLogbookSettingsModal] = useState(false);
    const [showEditVoyageModal, setShowEditVoyageModal] = useState(false);
    const [showViewCompletedVoyageModal, setShowViewCompletedVoyageModal] = useState(false);
    const [showCreateLogbookReportModal, setShowCreateLogbookReportModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Voyage>();

    useEffect(() => {
        logPageView('VesselLogbook');
    }, []);

    useEffect(() => {
        setSelectedItem((current) => {
            if (current === undefined && voyages?.active) {
                return voyages.active;
            }
            return current;
        });
    }, [voyages?.active]);

    // useEffect(() => {
    //     if (selectedItem && voyages?.active && selectedItem.id === voyages?.active.id) {
    //         setSelectedItem(voyages?.active);
    //     }
    // }, [voyages?.active, selectedItem]);

    const onStartVoyage = () => {
        if (voyages?.active) {
            alertMessage('You can\'t start a new voyage until you\'ve completed the current one.');
        } else {
            setSelectedItem(undefined);
            setShowEditVoyageModal(true);
        }
    }

    const onViewVoyage = useCallback((voyage: Voyage) => {
        console.log(`View voyage ${voyage.id}`);
        if (voyage.state === 'started' && canCreate('logbook')) {
            setSelectedItem(voyage);
            setShowEditVoyageModal(true);
        } else {
            setSelectedItem(voyage);
            setShowViewCompletedVoyageModal(true);
        }
    }, []);

    const onCreateReport = () => {
        setShowCreateLogbookReportModal(true);
    };

    const renderMasterIds = useCallback((masterIds: string[]) => {
        if (masterIds && masterIds.length > 0) {
            const masters = [] as string[];
            masterIds.forEach((masterId: string) => {
                masters.push(renderFullNameForUserId(masterId));
            });
            return masters.join(', ');
        }
        return '';
    }, []);

    return (
        <StandardPageLayout section="vesselLogbook">
            <SeaBreadcrumb>
                <SeaLink to="/fleet">Fleet DashBoard</SeaLink>
                {vessel && <SeaLink to={`/vessel/${ vessel.id }`}>{ vessel.name }</SeaLink>}
                {vessel && <SeaLink to={`/vessel/${ vessel.id }/logbook`}>Logbook</SeaLink>}
            </SeaBreadcrumb>

            <RequirePermissions
                role="logbook"
                level={permissionLevels.VIEW}
                showDenial={true}
            >
                <div className="logbook page-head">
                    <div><h1>Ship's Logbook</h1></div>
                    <div className="actions">
                        <RequirePermissions
                            role="logbook"
                            level={permissionLevels.CREATE}
                        >
                            <SeaButton onClick={(e) => onStartVoyage()} zone="grey" faded={voyages?.active ? true : false}>
                                Start Voyage
                            </SeaButton>
                            <div className="spacer"></div>
                            <SeaButton onClick={(e) => onCreateReport()} zone="grey">
                                Create Report
                            </SeaButton>
                            <RequirePermissions
                                role="logbook"
                                level={permissionLevels.EDIT}
                            >
                                <div className="spacer"></div>
                                <SeaButton zone="grey" shape="circle" onClick={(e) => setShowLogbookSettingsModal(true)}>
                                    <SeaIcon slot="icon-only" icon="settings"/>
                                </SeaButton>
                            </RequirePermissions>
                        </RequirePermissions>
                       
                        {/* <div className="spacer"></div>
                        <SeaButton onClick={(e) => todo('Generate PDF')} zone="grey" shape="circle"><SeaIcon slot="icon-only" icon="pdf"/></SeaButton> */}
                    </div>
                </div>

                <SeaNoData
                    dataName="voyages"
                    isLoading={!voyages}
                    hasNoData={voyages && voyages.categories.length === 0}
                />

                <div className={`sea-voyages ${(voyages && voyages.categories.length > 0) ? 'reveal' : 'conceal'}`}>
                    <div className="sea-row headings">
                        <div>Voyage Name / Master</div>
                        <div>Departure</div>
                        <div></div>
                        <div>Arrival</div>
                        <div></div>
                    </div>
                    <Profiler id="logbook.voyages" onRender={onProfilerRender}>
                        {voyages?.categories?.map((category: string) => {
                            return (
                                <React.Fragment key={category}>
                                    <div className="category-heading">{formatMonthLonger(category)}</div>
                                    {voyages.byCategory[category].map((voyage) => {
                                        return (
                                            <div key={voyage.id} className={`sea-card sea-row ${voyage.state === 'started' ? 'warn' : ''}`} onClick={(e) => onViewVoyage(voyage)}>
                                                <div className="bold truncate-2">{voyage.name && `${voyage.name} - `}{renderMasterIds(voyage.masterIds)}</div>
                                                <div className="truncate-2">{voyage.whenDeparted ? formatDatetime(voyage.whenDeparted) : '-'}</div>
                                                <div className="truncate-2">{voyage.departureFrom ? voyage.departureFrom : '-'}</div>
                                                <div className="truncate-2">{voyage.whenArrived ? formatDatetime(voyage.whenArrived) : '-'}</div>
                                                <div className="truncate-2">{voyage.destinationTo ? voyage.destinationTo : '-'}</div>
                                            </div>
                                        );
                                    })}
                                </React.Fragment>
                            )
                        })}
                    </Profiler>
                </div>

                <LogbookSettings
                    showModal={showLogbookSettingsModal}
                    setShowModal={setShowLogbookSettingsModal}
                />
                <EditVoyage
                    activeVoyage={selectedItem}
                    showModal={showEditVoyageModal}
                    setShowModal={setShowEditVoyageModal}
                />
                {selectedItem && 
                    <ViewCompletedVoyage
                        showModal={showViewCompletedVoyageModal}
                        setShowModal={setShowViewCompletedVoyageModal}
                        selectedItem={selectedItem}
                    />
                }
                <CreateLogbookReport
                    showModal={showCreateLogbookReportModal}
                    setShowModal={setShowCreateLogbookReportModal}
                />
            </RequirePermissions>

        </StandardPageLayout>
    );
};

export default VesselLogbook;

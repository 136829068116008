import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { CreateableDocument, DeleteableDocument, SharedStateConfig, SyncableDocument, UpdateableDocument, sharedState } from "../shared-state";
import { permissionLevels, PermissionRole, PermissionLevel, canEdit } from "../Core/userPermissions";

//
// Loads userPermissionDefaults.
// They represent default userPermissions to aid in quickly setting up a new crew member.
//

export type UserPermissionDefaults = {
    [userPermissionId in PermissionRole]: PermissionLevel
} & {
    name: string,
    order: number,
    state: 'active' | 'deleted',
} & SyncableDocument & UpdateableDocument & CreateableDocument & DeleteableDocument;

export type UserPermissionDefaultsData = {
    all: UserPermissionDefaults[],
    byId: {
        [permissionName: string]: UserPermissionDefaults
    }
};


export const userPermissionDefaultsConfig: SharedStateConfig<UserPermissionDefaultsData> = {
    isAlwaysActive: false,
    dependencies: ['licenseeId'],
    countLiveDocs: () => Object.keys(sharedState.userPermissionDefaults.current?.byId ?? {}).length,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        if (licenseeId && canEdit('crewParticulars')) {
            return onSnapshot(
                query(
                    collection(firestore, 'userPermissionDefaults'),
                    where('licenseeId', '==', licenseeId),
                    where('state', '==', 'active'),
                    orderBy('order')
                ),
                (snap) => {
                    done();
                    const byId = {} as {
                        [id: string]: UserPermissionDefaults
                    };
                    const all = [] as UserPermissionDefaults[];
                    snap.docs.forEach((doc) => {
                        const permissionDefault = {
                            id: doc.id,
                            ...doc.data()
                        } as UserPermissionDefaults;
                        all.push(permissionDefault);
                        byId[doc.id] = permissionDefault;
                    });
                    set({
                        all,
                        byId
                    });
                }, (error) => {
                    done();
                    clear();
                    console.log(`Error getting userPermissionDefaults ${licenseeId}`, error);
                }
            );
        }
    }
};

export const defaultUserPermissionDefaults = {
    crew: {
        name: 'Crew',
        vesselSettings:                     permissionLevels.VIEW,
        engineHours:                        permissionLevels.EDIT,
        logbook:                            permissionLevels.EDIT,
        safetyEquipmentChecks:              permissionLevels.CREATE,
        safetyEquipmentList:                permissionLevels.CREATE,
        drills:                             permissionLevels.CREATE,
        maintenanceSchedule:                permissionLevels.CREATE,
        jobList:                            permissionLevels.EDIT,
        sparePartsList:                     permissionLevels.CREATE,
        equipmentManualDocuments:           permissionLevels.CREATE,
        maintenanceHistory:                 permissionLevels.CREATE,
        vesselCertificates:                 permissionLevels.VIEW,
        vesselDocuments:                    permissionLevels.VIEW,
        survey:                             permissionLevels.VIEW,
        standardOperatingProcedures:        permissionLevels.VIEW,
        incidentAccidentMedicalRegister:    permissionLevels.VIEW,
        hazardRegister:                     permissionLevels.VIEW,
        healthSafetyMeetings:               permissionLevels.VIEW,
        dangerousGoodsRegister:             permissionLevels.CREATE,
        companyPlan:                        permissionLevels.VIEW,
        companyDocuments:                   permissionLevels.VIEW,
        customForms:                        permissionLevels.COMPLETE,
        crewParticulars:                    permissionLevels.NONE,
        crewCertificates:                   permissionLevels.NONE,
        crewTraining:                       permissionLevels.COMPLETE,
        contacts:                           permissionLevels.CREATE
    } as UserPermissionDefaults,
    skipper: {
        name: 'Skipper',
        vesselSettings:                     permissionLevels.VIEW,
        engineHours:                        permissionLevels.EDIT,
        logbook:                            permissionLevels.EDIT,
        safetyEquipmentChecks:              permissionLevels.EDIT,
        safetyEquipmentList:                permissionLevels.EDIT,
        drills:                             permissionLevels.EDIT,
        maintenanceSchedule:                permissionLevels.EDIT,
        jobList:                            permissionLevels.EDIT,
        sparePartsList:                     permissionLevels.EDIT,
        equipmentManualDocuments:           permissionLevels.EDIT,
        maintenanceHistory:                 permissionLevels.EDIT,
        vesselCertificates:                 permissionLevels.EDIT,
        vesselDocuments:                    permissionLevels.CREATE,
        survey:                             permissionLevels.CREATE,
        standardOperatingProcedures:        permissionLevels.CREATE,
        incidentAccidentMedicalRegister:    permissionLevels.VIEW,
        hazardRegister:                     permissionLevels.CREATE,
        healthSafetyMeetings:               permissionLevels.EDIT,
        dangerousGoodsRegister:             permissionLevels.EDIT,
        companyPlan:                        permissionLevels.VIEW,
        companyDocuments:                   permissionLevels.VIEW,
        customForms:                        permissionLevels.EDIT,
        crewParticulars:                    permissionLevels.NONE,
        crewCertificates:                   permissionLevels.NONE,
        crewTraining:                       permissionLevels.EDIT,
        contacts:                           permissionLevels.EDIT
    } as UserPermissionDefaults,
    licensee: {
        name: 'Manager/Admin',
        vesselSettings:                     permissionLevels.EDIT,
        engineHours:                        permissionLevels.EDIT,
        logbook:                            permissionLevels.FULL,
        safetyEquipmentChecks:              permissionLevels.FULL,
        safetyEquipmentList:                permissionLevels.FULL,
        drills:                             permissionLevels.FULL,
        maintenanceSchedule:                permissionLevels.FULL,
        jobList:                            permissionLevels.FULL,
        sparePartsList:                     permissionLevels.FULL,
        equipmentManualDocuments:           permissionLevels.FULL,
        maintenanceHistory:                 permissionLevels.FULL,
        vesselCertificates:                 permissionLevels.FULL,
        vesselDocuments:                    permissionLevels.FULL,
        survey:                             permissionLevels.FULL,
        standardOperatingProcedures:        permissionLevels.FULL,
        incidentAccidentMedicalRegister:    permissionLevels.FULL,
        hazardRegister:                     permissionLevels.FULL,
        healthSafetyMeetings:               permissionLevels.FULL,
        dangerousGoodsRegister:             permissionLevels.FULL,
        companyPlan:                        permissionLevels.EDIT,
        companyDocuments:                   permissionLevels.FULL,
        customForms:                        permissionLevels.FULL,
        crewParticulars:                    permissionLevels.FULL,
        crewCertificates:                   permissionLevels.FULL,
        crewTraining:                       permissionLevels.FULL,
        contacts:                           permissionLevels.FULL
    } as UserPermissionDefaults
};

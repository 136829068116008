import React, { useState, useEffect, Profiler, useMemo } from 'react';
import { IonSelectOption } from '@ionic/react';
import { logPageView } from '../../../lib/firebase';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { extractSearchTerms, formatDate, formatValue, getDayOffsetMillis, warnDays, whenDueToClassName } from '../../../lib/util';
import { canEdit, canView, permissionLevels } from '../../../shared-state/Core/userPermissions';
import { onProfilerRender } from '../../../lib/performance';
import { renderFullName } from '../../../shared-state/Core/users';
import { CertificateHolderInfo, CrewCertificate } from '../../../shared-state/Crew/crewCertificates';
import { sharedState } from '../../../shared-state/shared-state';
import { renderCategoryName } from '../../../lib/categories';
import EditCrewCertificateSettings from '../../../modals/Crew/CrewCertificates/EditCrewCertificateSettings/EditCrewCertificateSettings';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaSelect from '../../../components/SeaSelect/SeaSelect';
import SeaStatusDueDate from '../../../components/SeaStatusDueDate/SeaStatusDueDate';
import EditCrewCertificate from '../../../modals/Crew/CrewCertificates/EditCrewCertificate/EditCrewCertificate';
import ViewCrewCertificate from '../../../modals/Crew/CrewCertificates/ViewCrewCertificate/ViewCrewCertificate';
import ArchiveCrewCertificate from '../../../modals/Crew/CrewCertificates/ArchiveCrewCertificate/ArchiveCrewCertificate';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import SeaSearchbar from '../../../components/SeaSearchbar/SeaSearchbar';
import './CrewCertificates.css';

interface CrewCertificatesProps {
    visible: boolean
}

const CrewCertificates: React.FC<CrewCertificatesProps> = ({visible}) => {
    const userId = sharedState.userId.use(visible ? 1 : 101);
    const crewCertificates = sharedState.crewCertificates.use(visible ? 1 : 101);
    const crewCertificateTitles = sharedState.crewCertificateTitles.use(visible ? 1 : 101);
    sharedState.crewCertificates.use(visible ? 102 : 0); // Prepare for modal to use
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter();
    const [searchText, setSearchText] = useState("");
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [showArchivedModal, setShowArchivedModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<CrewCertificate>();
    const [showEditCrewCertificateSettings, setShowEditCrewCertificateSettings] = useState(false);
    const [listType, setListType] = useState(warnDays.crewCertificates[0].toString());

    useEffect(() => {
        if (visible) {
            logPageView('Crew/CrewCertificates');
        }
        resetPageLimit();
    }, [visible, resetPageLimit]);

    // Keep selectedItem fresh
    useEffect(() => {
        // console.log(`<> crewCertificates`, crewCertificates);
        if (selectedItem?.id && crewCertificates?.byId[selectedItem.id]) { 
            setSelectedItem(crewCertificates.byId[selectedItem.id])
        };
    }, [crewCertificates, selectedItem?.id]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };
    const onViewItem = (item: CrewCertificate) => {
        if (item.state === 'missing') {
            setShowEditItemModal(true);
            setSelectedItem(item)
        } else {
            setShowViewItemModal(true);
            setSelectedItem(item)
        }
    };
    const onViewArchived = () => {
        setShowArchivedModal(true);
    };

    const prioritisedCrewCertificates = useMemo(() => {
        if (['all', 'gap'].includes(listType)) return [];
        const terms = extractSearchTerms(searchText, true);
        const whenExpires = getDayOffsetMillis(Number(listType));
        return crewCertificates?.prioritised?.filter((item) => {
            if ((canView('crewCertificates') || item.heldBy === userId) && item.whenExpires && item.whenExpires < whenExpires) {
                if (terms) {
                    let isMatch = true;
                    for (let term of terms) {
                        if (!item.searchText?.toLowerCase().includes(term.toLowerCase())) {
                            isMatch = false;
                            break;
                        }
                    }
                    return isMatch;
                }
                return true;
            } else {
                return false;
            }
        });
    }, [listType, searchText, crewCertificates?.prioritised, userId]);

    const filteredCertificateHolders: CertificateHolderInfo[] = useMemo(() => {
        // return [];
        if (!crewCertificates || !['all', 'gap'].includes(listType)) {
            return [];
        }
        const terms = extractSearchTerms(searchText, true);
        let _filteredHolders: CertificateHolderInfo[] = [];
    
        if (crewCertificates?.holdersSorted) {
            crewCertificates.holdersSorted.forEach((holder) => {
                const _certificates: CrewCertificate[] = [];
                if (canView('crewCertificates') || holder.id === userId) {
                    holder.certificates?.forEach(certificate => {
                        let isMatch = true;
                        for (let term of terms) {
                            if (!certificate.searchText?.toLowerCase().includes(term.toLowerCase())) {
                                isMatch = false;
                                break;
                            }
                        }
                        if (listType === 'gap' && certificate.state !== 'missing') {
                                isMatch = false;
                        }
                        if (isMatch) {
                            _certificates.push(certificate);
                        }
                    });
                    if (_certificates.length > 0) {
                        _filteredHolders.push({
                            ...holder,
                            certificates: _certificates
                        });
                    }
                }
            });
        }

        return _filteredHolders;
    }, [crewCertificates, listType, searchText, userId]);


    return (
        // <RequirePermissions
        //     role="crewCertificates"
        //     level={permissionLevels.VIEW}
        //     showDenial={true}
        // >
        <div>
            <div className="crew-certificates page-head">
                <div><h2>{!canView('crewCertificates') ? 'My' : 'Crew'} Certificates</h2></div>
                <div className="actions">
                    <SeaSelect
                        name="listType"
                        value={listType}
                        width="250px"
                        zone="grey"
                        onchange={(e) => {
                            resetPageLimit();
                            setListType(e.detail.value);
                        }}
                    >
                        {warnDays.crewCertificates.map((days) => {
                            return <IonSelectOption key={days} value={days.toString()}>Overdue &amp; Due Within {days} Days</IonSelectOption>
                        })}
                        <IonSelectOption value="gap">Gap Analysis</IonSelectOption>
                        <IonSelectOption value="all">All</IonSelectOption>
                    </SeaSelect>
                    <RequirePermissions
                        role="crewCertificates"
                        level={permissionLevels.CREATE}
                    >
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add"/>
                            Add New
                        </SeaButton>
                        <RequirePermissions
                            role="crewCertificates"
                            level={permissionLevels.FULL}
                        >
                            <div className="spacer"></div>
                            <SeaButton onClick={(e) => onViewArchived()} zone="grey">
                                <SeaIcon slot="start" icon="archive"/>
                                Archived
                            </SeaButton>
                        </RequirePermissions>
                        <RequirePermissions
                            role="crewCertificates"
                            level={permissionLevels.EDIT}
                        >
                            <div className="spacer"></div>
                            <SeaButton
                                zone="grey"
                                shape="circle"
                                onClick={(e) => {
                                    setShowEditCrewCertificateSettings(true);
                                }}
                            >
                                <SeaIcon slot="icon-only" icon="settings"/>
                            </SeaButton>
                        </RequirePermissions>
                    </RequirePermissions>
                    {/* <div className="spacer"></div>
                    <SeaButton onClick={(e) => onGeneratePdf()} zone="grey" shape="circle"><SeaIcon slot="icon-only" icon="pdf"/></SeaButton> */}
                </div>
            </div>

            <div className={`columns wrap filters`}>
                <div>
                    <SeaSearchbar value={searchText} setValue={setSearchText} />
                </div>
            </div>

            <div className={`crew-certificates has-thumbs has-status ${['all', 'gap'].includes(listType) ? 'all' : ''} ${(filteredCertificateHolders || prioritisedCrewCertificates) ? 'reveal' : 'conceal'}`}>
                {!canView('crewCertificates') && userId && !crewCertificates?.holders[userId] ?
                    <div className="sea-no-data columns">
                        <div><SeaIcon icon="info"/></div>
                        No certificates were found that are held by you.
                    </div>

                : !canView('crewCertificates') && !['all', 'gap'].includes(listType) && prioritisedCrewCertificates?.filter((item) => item.heldBy === userId).length === 0 ? 
                    <div className="sea-no-data columns">
                        <div><SeaIcon icon="info"/></div>
                        <div>
                            No certificates were found matching the filter
                        </div>
                    </div>
                : ['all', 'gap'].includes(listType) && !filteredCertificateHolders?.length ?
                    <div className="sea-no-data columns">
                        <div><SeaIcon icon="info"/></div>
                        No certificates were found matching the filter
                    </div>
                : !['all', 'gap'].includes(listType) && prioritisedCrewCertificates && prioritisedCrewCertificates.length === 0 ?
                    <div className="sea-no-data columns">
                        <div><SeaIcon icon="info"/></div>
                        No certificates are overdue or due within the selected period.
                    </div>
                : <div>    
                    <Profiler id="crew.certificates" onRender={onProfilerRender}>
                        
                        {/* Prioritised */}
                        {!['all', 'gap'].includes(listType) && prioritisedCrewCertificates && prioritisedCrewCertificates.length > 0 && <>
                            <div className="sea-row headings">
                                <div></div>
                                <div>Certificate</div>
                                <div>Held By</div>
                                <div>Issued By</div>
                                <div>Expiry</div>
                                <div>Status</div>
                                <div>Reminder</div>
                            </div>
                            {mapArrayWithLimit(prioritisedCrewCertificates, (item: CrewCertificate) => {
                                if (!canView('crewCertificates')) {
                                    if (item.heldBy === userId) {
                                        return (
                                            <div key={item.id} className={`sea-card sea-row ${whenDueToClassName(item.whenExpires, warnDays.crewCertificates[0])}`} onClick={(e) => onViewItem(item)}>
                                                <div>
                                                    <SeaFileImage files={item.files} size="tiny"/>
                                                </div>
                                                <div className="bold truncate-2">{renderCategoryName(item?.titleId, crewCertificateTitles)}</div>
                                                <div className="truncate-2">{formatValue(crewCertificates?.holders[item.heldBy].name)}</div>
                                                <div className="truncate-2">{formatValue(item.issuedBy)}</div>
                                                <div>{item.whenExpires ? formatDate(item.whenExpires) : null}</div>
                                                <div>{item.whenExpires ? <SeaStatusDueDate whenDue={item.whenExpires} warnDays={warnDays.crewCertificates[0]}/> : ''} </div>
                                                <div>{item.emailReminder && <SeaIcon icon='mail' />}</div>
                                            </div>
                                        );
                                    }
                                } else {
                                    return (
                                        <div key={item.id} className={`sea-card sea-row ${whenDueToClassName(item.whenExpires, warnDays.crewCertificates[0])}`} onClick={(e) => onViewItem(item)}>
                                            <div>
                                                <SeaFileImage files={item.files} size="tiny"/>
                                            </div>
                                            <div className="bold truncate-2">{item.title}</div>
                                            <div className="truncate-2">{formatValue(crewCertificates?.holders[item.heldBy].name)}</div>
                                            <div className="truncate-2">{formatValue(item.issuedBy)}</div>
                                            <div>{item.whenExpires ? formatDate(item.whenExpires) : null}</div>
                                            <div>{item.whenExpires ? <SeaStatusDueDate whenDue={item.whenExpires} warnDays={warnDays.crewCertificates[0]}/> : ''} </div>
                                            <div>{item.emailReminder && <SeaIcon icon='mail' />}</div>
                                        </div>
                                    );
                                }
                            })}
                        </>}
                        {/* Category By User Name */}
                        {['all', 'gap'].includes(listType) && filteredCertificateHolders && filteredCertificateHolders.length > 0 && <>
                            <div className="sea-row headings">
                                <div></div>
                                <div>Certificate</div>
                                <div>Issued By</div>
                                <div>Issue Date</div>
                                <div>Expiry</div>
                                <div>Status</div>
                                <div>Reminder</div>
                            </div>
                            {filteredCertificateHolders.map((holder) => {
                                if (isLimitReached()) {
                                    return undefined;
                                }
                                if (!canView('crewCertificates')) {
                                    if (holder.name === renderFullName()) {
                                        return <React.Fragment key={holder.name}>
                                            <div className="category-heading">{holder.name}</div>
                                            {mapArrayWithLimit(holder.certificates, (item: CrewCertificate) => {
                                                return (
                                                    <div key={item.id} className={`sea-card sea-row ${item.whenExpires && whenDueToClassName(item.whenExpires, warnDays.crewCertificates[0])}`} onClick={(e) => onViewItem(item)}>
                                                        <div>
                                                            <SeaFileImage files={item.files} size="tiny"/>
                                                        </div>
                                                        <div className="bold truncate-2">{item.title}</div>
                                                        <div className="truncate-2">{formatValue(item.issuedBy)}</div>
                                                        <div className={item.state === 'missing' ? 'not-card' : ''}>{formatDate(item.whenIssued)}</div>
                                                        <div className={item.whenExpires ? '' : 'not-card'}>{item.whenExpires ? formatValue(formatDate(item.whenExpires)) : null}</div>
                                                        <div className={(item.whenExpires || item.state === 'missing') ? '' : 'not-card'}>
                                                            {item.state === 'missing' ? (
                                                                <SeaStatusDueDate isMissing />
                                                            ) : item.whenExpires ?  (
                                                                <SeaStatusDueDate whenDue={item.whenExpires} warnDays={warnDays.crewCertificates[0]}/>
                                                            ) : ''}
                                                        </div>
                                                        <div>{item.emailReminder && <SeaIcon icon='mail' />}</div>
                                                    </div>
                                                );
                                            })} 
                                        </React.Fragment>
                                    }
                                } else {
                                    return <React.Fragment key={holder.name}>
                                        <div className="category-heading">{holder.name}</div>
                                        {mapArrayWithLimit(holder.certificates, (item: CrewCertificate) => {
                                            return (
                                                <div
                                                    key={item.id}
                                                    className={`sea-card sea-row ${item.whenExpires && whenDueToClassName(item.whenExpires, warnDays.crewCertificates[0])}`}
                                                    onClick={(e) => onViewItem(item)}
                                                >
                                                    <div>
                                                        <SeaFileImage files={item.files} size="tiny"/>
                                                    </div>
                                                    <div className="bold truncate-2">{item.title}</div>
                                                    <div className="truncate-2">{formatValue(item.issuedBy)}</div>
                                                        <div className={item.state === 'missing' ? 'not-card' : ''}>{formatDate(item.whenIssued)}</div>
                                                    <div className={item.whenExpires && item.state !== 'missing' ? '' : 'not-card'}>{item.whenExpires ? formatValue(formatDate(item.whenExpires)) : null}</div>
                                                    <div className={(item.whenExpires || item.state === 'missing') ? '' : 'not-card'}>
                                                            {item.state === 'missing' ? (
                                                                <SeaStatusDueDate isMissing />
                                                            ) : item.whenExpires ?  (
                                                                <SeaStatusDueDate whenDue={item.whenExpires} warnDays={warnDays.crewCertificates[0]}/>
                                                            ) : ''}
                                                        </div>
                                                    <div>{item.emailReminder && <SeaIcon icon='mail' />}</div>
                                                </div>
                                            );
                                        })} 
                                    </React.Fragment>   
                                }
                                return undefined;
                            })}
                        </>}
                        {limitTriggerElement}
                    </Profiler>
                </div>}
            </div>
            {visible &&
                <>
                    <EditCrewCertificate
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        itemToUpdate={selectedItem?.state === 'missing' ? selectedItem : undefined}
                        setItemToUpdate={setSelectedItem}
                    />
                    {selectedItem && <ViewCrewCertificate
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                    />}
                    {canView('crewCertificates') &&
                        <ArchiveCrewCertificate
                            showModal={showArchivedModal}
                            setShowModal={setShowArchivedModal}
                        />
                    }
                    {canEdit('crewCertificates') && <EditCrewCertificateSettings
                        showModal={showEditCrewCertificateSettings} 
                        setShowModal={setShowEditCrewCertificateSettings}
                    />}
                </>
            }
        </div>
    );
};

export default CrewCertificates;

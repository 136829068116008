import React, { useState, useEffect, useCallback } from 'react';
import { firestore, logPageView, splittableBatch } from '../../../lib/firebase';
import { doc, serverTimestamp } from 'firebase/firestore';
import { preventMultiTap, regions, warnDays } from '../../../lib/util';
import { canEdit, permissionLevels } from '../../../shared-state/Core/userPermissions';
import { fromByteArray } from 'base64-js';
import { getDefaultCompanyPlan } from './defaultPlan';
import { initialRichTextState, loadSfdoc, makeSfdocSeaFile, RichTextState } from '../../../lib/richText';
import { logAction } from '../../../shared-state/General/actionLog';
import { sharedState } from '../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../shared-state/DataSyncSystem/dataSync';
import { Action, reportError, traceAction } from '../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../managers/FileUploadManager/FileUploadManager';
import { saveFileRefs, sfdocToValue } from '../../../lib/files';
import SeaRichText from '../../../components/lexical/SeaRichText/SeaRichText';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import EditRichTextModal from '../../../modals/Utilities/EditRichTextModal/EditRichTextModal';
import CompanyPlanSettings from '../../../modals/CompanyDocuments/CompanyPlan/CompanyPlanSettings/CompanyPlanSettings';
import SeaStatusDueDate from '../../../components/SeaStatusDueDate/SeaStatusDueDate';
import './CompanyPlan.css';

interface CompanyPlanProps {
    visible: boolean,
    setOnScroll: React.Dispatch<React.SetStateAction<((event: Event) => void) | undefined>>
}

const CompanyPlan: React.FC<CompanyPlanProps> = ({
    visible,
    setOnScroll
}) => {
    const licenseeId = sharedState.licenseeId.use(visible ? 1 : 101);
    const licenseeSettings = sharedState.licenseeSettings.use(visible ? 1 : 101);
    const userId = sharedState.userId.use(visible ? 1 : 101);
    const companyPlan = sharedState.companyPlan.use(visible ? 1 : 101);
    const [richTextState, setRichTextState] = useState<RichTextState>(initialRichTextState);
    const [showEditorModal, setShowEditorModal] = useState(false);
    const [showCompanyPlanSettings, setShowCompanyPlanSettings] = useState(false);

    const companyPlanTitle = licenseeSettings ? regions[licenseeSettings?.region]?.companyPlan : '';
    const getDefaultContent = useCallback(() => {
        return getDefaultCompanyPlan(companyPlanTitle);
    }, [companyPlanTitle]);

    useEffect(() => {
        if (visible) {
            logPageView('Documents/Plan');
        }
    }, [visible]);

    const onEditPlan = () => {
        setShowEditorModal(true);
    };

    // Handle loading
    useEffect(() => {
        if (companyPlan?.sfdoc) {
            return loadSfdoc(
                companyPlan?.sfdoc,
                setRichTextState,
                getDefaultContent,
                licenseeSettings !== undefined,
            );
        }
    }, [licenseeSettings, companyPlan?.sfdoc, getDefaultContent]);

    // Handle saving
    const onSaveChanges = useCallback((json: any): Promise<boolean> => {
        if (preventMultiTap('companyPlan')) { return Promise.resolve(false); }
        if (!userId) {
            reportError('User ID not available', 'User ID not available', undefined, { userId });
            return Promise.resolve(false);
        }
        if (!licenseeId) {
            reportError('Licensee ID not available', 'Licensee ID not available', undefined, { licenseeId });
            return Promise.resolve(false);
        }
        const encoder = new TextEncoder();
        const utf8Bytes = encoder.encode(JSON.stringify(json));
        const base64 = fromByteArray(utf8Bytes);
        const sfdocFile = makeSfdocSeaFile(
            base64,
            'companyPlans',
            companyPlanTitle,
            userId
        );

        return uploadFiles([sfdocFile]).then(() => {
            const action = traceAction('companyPlans') as Action;
            action.docId = licenseeId;
            const batch = splittableBatch(firestore, 20 - 0);
            const docRef = doc(firestore, 'companyPlans', licenseeId);

            const sfdoc = {
                [Date.now()]: sfdocToValue(sfdocFile)
            };

            if (companyPlan) {
                action.type = 'update';
                batch.set(
                    docRef,
                    {
                        updatedBy: userId,
                        whenUpdated: action.whenAction,
                        sfdoc: sfdoc,
                        touched: serverTimestamp()
                    },
                    { merge: true }
                );
            } else {
                action.type = 'create';
                batch.set(
                    docRef,
                    {
                        addedBy: userId,
                        whenAdded: action.whenAction,
                        sfdoc: sfdoc,
                        touched: serverTimestamp()
                    }
                );
            }
            logAction(
                batch,
                companyPlan ? 'Update' : 'Add',
                'companyPlans',
                licenseeId,
                companyPlanTitle
            );
            saveFileRefs(batch, [sfdocFile], 'companyPlans', licenseeId);

            onCollectionUpdated(batch, 'companyPlans');

            action.data = {
                newContent: sfdocToValue(sfdocFile),
                existingPlan: companyPlan
            };
            action.save(`${companyPlan ? 'Update' : 'Create'} ${companyPlanTitle}`, batch);
            batch.commit().then(() => {
                action.reportSuccess();
            }).catch((error) => {
                action.reportError(error.message, error);
            });

            return Promise.resolve(true); // Signal modal should now be closed
        }).catch((error: any) => {
            if (!handleUploadError(error)) {
                reportError(`Failed to upload new ${companyPlanTitle}`, error.message, error, {
                    sfdocFile: sfdocFile,
                    existingItem: companyPlan
                });
            }
            return Promise.resolve(false);
        });
    }, [companyPlan, companyPlanTitle, licenseeId, userId]);

    const editDocumentButton = <SeaButton
        onClick={(e) => onEditPlan()}
        disabled={(richTextState.loading || richTextState.message) ? true : false}
        zone="grey"
    >
        <SeaIcon slot="start" icon="edit"/>
        Edit Document
    </SeaButton>;

    return (
        <RequirePermissions
            role="companyPlan"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="company-plan page-head">
                <div><h2>{companyPlanTitle}</h2></div>
                {companyPlan?.whenDue ? (
                    <div className='sea-status-container'>
                        <SeaStatusDueDate whenDue={companyPlan.whenDue} warnDays={warnDays.companyPlan[0]} />
                    </div>
                ) : null}
                {/* {richTextState &&
                    <div style={{
                        position: 'fixed',
                        top: '0px',
                        left: '0px',
                        backgroundColor: 'black',
                        color: 'white',
                        padding: '4px',
                        fontSize: '16px'
                    }}>
                        loading={richTextState.loading} downloading={richTextState.downloading} message={richTextState.message} loadedVersion={richTextState.loadedVersion}
                    </div>
                } */}
                <div className="actions">
                    <RequirePermissions
                        role="companyPlan"
                        level={permissionLevels.EDIT}
                    >
                        <div className="spacer"></div>
                        {editDocumentButton}
                        <SeaButton
                            zone="grey"
                            shape="circle"
                            onClick={(e) => {
                                setShowCompanyPlanSettings(true);
                            }}
                        >
                            <SeaIcon slot="icon-only" icon="settings"/>
                        </SeaButton>
                    </RequirePermissions>
                </div>
            </div>

            <div style={{ height: '40px' }}></div>
            {licenseeSettings &&
                <div className="company-plan">
                    <SeaRichText
                        forModal={false}
                        visible={visible}
                        setOnScroll={setOnScroll}
                        richTextState={richTextState}
                        editButtons={canEdit('companyPlan') ? editDocumentButton : undefined}
                    />
                </div>
            }
            {visible &&
                <RequirePermissions
                    role="companyPlan"
                    level={permissionLevels.EDIT}
                >
                    <EditRichTextModal
                        showModal={showEditorModal}
                        setShowModal={setShowEditorModal}
                        title={companyPlanTitle}
                        initialDocumentJson={richTextState.loadedJson}
                        onSaveChanges={onSaveChanges}
                        confirmText="Save Changes"
                    />
                    <CompanyPlanSettings
                        showModal={showCompanyPlanSettings}
                        setShowModal={setShowCompanyPlanSettings}
                    />
                </RequirePermissions>
            }
        </RequirePermissions>
    );
};

export default CompanyPlan;

import { QueryConstraint, collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { Voyage } from './voyages';
import { useState, useEffect } from 'react';
import { sharedState } from '../shared-state';
import { SafetyCheckItem } from '../VesselSafety/safetyCheckItems';
import { SafetyCheckCompleted } from '../VesselSafety/completedSafetyCheckItems';
import { registerFiles } from "../FileSyncSystem/filesToCache";

const voyageTimeLeeway = 5 * 60 * 1000; // Allow 5 minutes either side of voyage to match completed checks and tasks

//  This returns the safetyCheckItems that were completed during a voyage
export const useSafetyChecksCompletedDuringVoyage = (selectedVoyage: Voyage | undefined, includeLeeway?: boolean) => {
    const safetyCheckItems = sharedState.safetyCheckItems.use();
    const [safetyChecksCompletedDuringVoyage, setSafetyChecksCompletedDuringVoyage] = useState<SafetyCheckItem[]>();

    useEffect(() => {
        setSafetyChecksCompletedDuringVoyage(undefined);
        if (selectedVoyage && safetyCheckItems?.byId) {
            const queries: QueryConstraint[] = [
                where('vesselId', '==', selectedVoyage.vesselId),
                where('state', '==', 'active')
            ];

            if (includeLeeway) {
                queries.push(where('whenCompleted', '>=', selectedVoyage.whenDeparted - voyageTimeLeeway));
                queries.push(where('whenCompleted', '<=', (selectedVoyage.whenArrived || 0) + voyageTimeLeeway));
            } else {
                queries.push(where('whenCompleted', '>=', selectedVoyage.whenDeparted));
            }

            return onSnapshot(
                query(
                    collection(firestore, 'safetyCheckCompleted'),
                    ...queries
                ),
                (snap) => {
                    const items: SafetyCheckItem[] = []
                    snap.docs.forEach((doc) => {
                        const item = {
                            id: doc.id,
                            ...doc.data()
                        } as SafetyCheckCompleted;
                        registerFiles(item.files, 'safetyCheckCompleted', item);
                        if (safetyCheckItems.byId[item.safetyCheckId]) {
                            items.push(safetyCheckItems.byId[item.safetyCheckId]);
                        }
                    });
                    setSafetyChecksCompletedDuringVoyage(items);
                }, (error) => {
                    console.log(`Failed to access safetyCheckCompleted for voyageId=${selectedVoyage?.id} (tab)`, error.message, error, {
                        selectedVoyage
                    });
                }
            );
        }
    }, [includeLeeway, safetyCheckItems?.byId, selectedVoyage]);

    return safetyChecksCompletedDuringVoyage;
};

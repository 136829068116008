import { DocumentData, QueryDocumentSnapshot, collection, orderBy, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../lib/firebase";
import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { registerFiles } from "../FileSyncSystem/filesToCache";
import { canView } from "../Core/userPermissions";

//
// Loads the old hazard registry.
// A licensee can be still be on the old system if they choose, incidcated by licenseeSettings.riskRegister.version === 1
//

export interface Hazard extends CreateableDocument, UpdateableDocument {
    id: string,
    name: string,
    description: string,
    vesselIds: string[],
    files: string[],
    action: string,
    whoResponsible: string,
    riskRating: string,
    riskNotes: string,
    actionsRequired: string,
    shouldReportToManagement: boolean,
    vesselId: string;
    state: 'active' | 'archived' | 'deleted',
};

export type HazardRegistryData = {
    all: Hazard[], // List of active & alphabetical hazards
    byId: {
        [hazardId: string]: Hazard
    },
    byVesselId: {
        [vesselId: string]: Hazard[] // List of active & alphabetical hazards
    }
};

export const hazardRegistryConfig: SharedStateConfig<HazardRegistryData> = {
    isAlwaysActive: false,
    dependencies: ['vesselIds', 'licenseeSettings'],
    countLiveDocs: () => sharedState.hazardRegistry.current?.all.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselIds = sharedState.vesselIds.current;
        const licenseeSettings = sharedState.licenseeSettings.current;
        if (
            licenseeSettings?.riskRegister?.version === 1 &&
            vesselIds &&
            vesselIds.length > 0 &&
            canView('hazardRegister')
        ) {
            return setupArrayQueryListener(
                'hazards', // what
                collection(firestore, 'hazards'),
                [where('state', '==', 'active')], // baseConstraints
                'vesselIds',
                'array-contains-any',
                vesselIds,
                [orderBy('name', 'asc')],
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean
                ) => { // processDocs
                    done();
                    const all = docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as Hazard;
                    });

                    if (isCombined) { // Need to sort by name
                        all.sort((a, b) => {
                            return a.name.localeCompare(b.name);
                        });
                    }

                    const byId = {} as {
                        [hazardId: string]: Hazard
                    };
                    const byVesselId = {} as {
                        [vesselId: string]: Hazard[]
                    };
                    all.forEach((hazard: Hazard) => {
                        registerFiles(hazard.files, 'hazards', hazard);
                        byId[hazard.id] = hazard;

                        hazard.vesselIds.forEach((vesselId: string) => {
                            if (byVesselId[vesselId] === undefined) {
                                byVesselId[vesselId] = [];
                            }
                            byVesselId[vesselId].push(hazard);
                        });
                    });

                    set({
                        all,
                        byId,
                        byVesselId
                    });
                }, (error) => {
                    done();
                }
            );
        }
    }
};

import { SeaCrewHour } from '../../../components/SeaCrewHours/SeaCrewHours';
import { EditEngine } from '../../../components/SeaEngines/SeaEngines';
import { ForecastData } from '../../../components/SeaWindyMap/SeaWindyMap';
import { toInt, toFloat, combineDateAndHours24 } from '../../../lib/util';
import { Voyage, CustomFields, EngineHour, VoyageStop, VoyageTrip, VoyageDay } from '../../../shared-state/VesselLogbook/voyages';

export interface VoyageForm {
    tripType: string;
    name: string;
    masterId: string;
    whenDeparted: string;
    departureFrom: string;
    operation: string;
    fuelStart: string;
    safetyBriefCompleted: boolean;
    pax: string;
    startupNotes: string;
    voyageNotes: string;
    destinationTo: string;
    whenArrived: string;
    fuelBunkered: string;
    fuelBunkeredCost: string;
    fuelEnd: string;
    shutdownNotes: string;
    personnelInvolved?: string[];
    forecastData?: any;
    location?: {
        latitude: number;
        longitude: number;
        zoom: number;
        accuracy: number;
    };
    startupChecks?: CheckType[];
    crewHours?: SeaCrewHour[];
    trips?: VoyageTrip[];
    days?: PreparedVoyageDay[] | VoyageDay[];
    customHourFields?: CustomFields[];
    customTextFields?: CustomFields[];
    shutdownChecks?: CheckType[];
    stops?: VoyageStop[];
    isActive?: boolean;
}

export interface PreparedVoyage extends Omit<Voyage, 'stops' | 'crewHours' | 'days' | 'startupChecks' | 'customHourFields' | 'customTextFields' | 'shutdownChecks'> {
    days: PreparedVoyageDay[];
    startupChecks: CheckType[];
    crewHours: SeaCrewHour[];
    customHourFields: CustomFields[];
    customTextFields: CustomFields[];
    shutdownChecks: CheckType[];
    stops?: PreparedVoyageStop[];
}
export interface CheckType {
    check: string;
    value: string;
}

export interface PreparedForecastData {
    lon: number;
    lat: number;
    t: number[];
    temp: number[];
    windX: number[];
    windY: number[];
    gust: number[];
    waveD: number[];
    waveH: number[];
    waveP: number[];
}

export interface PreparedVoyageStop {
    location?: string;
    whenArrived?: string;
    paxOff: number;
    paxOn: number;
    whenDeparted?: string;
}
export interface PreparedVoyageTrip {
    masterId?: string;
    crewInvolved: string[];
    departureFrom?: string;
    whenDeparted?: number;
    paxOn: number;
    destinationTo?: string;
    whenArrived?: number;
    stops?: PreparedVoyageStop[];
    customText?: CustomFields[];
}

export interface PreparedVoyageDay {
    day?: number;
    paxOn?: number;
    crewInvolved?: string[];
    departureFrom?: string;
    whenDeparted?: number;
    paxOff?: number;
    whenArrived?: number;
    notes?: string;
    customText?: CustomFields[];
    customHours?: CustomFields[];
    crewHours?: PreparedSeaCrewHour[] | PreparedSeaCrewHourData;
    totalHours?: number;
}

export interface PreparedSeaCrewHour {
    id?: string;
    name: string,
    isEdited: boolean,
    initialHours: string,
    hours: string,
    error: string
}

  export interface PreparedSeaCrewHourData {
    [key: string]: PreparedSeaCrewHour
  }


const numTimeSlotsToSave = 12; // How many forecast time slots to save for weather

export const prepareForecastData = (data: ForecastData, location: { longitude: number, latitude: number }) => {
    const result: Partial<PreparedForecastData> = {};
    if (data.t && location.longitude && location.longitude) {
        const n = Math.min(data.t.length, numTimeSlotsToSave);
        (['t', 'temp', 'windX', 'windY', 'gust', 'waveD', 'waveH', 'waveP'] as (keyof Omit<ForecastData, 'lat' | 'lon'>)[]).forEach((key) => {
            if (data[key] && data[key]?.length && data[key]!.length > 0) {
                result[key] = data[key]?.slice(0, Math.min(n, data[key]!.length));
            }
        });
        result.lon = location.longitude;
        result.lat = location.latitude;
        return result;
    }
    return undefined;
};

export const makeInitialChecks = (possibleChecks: string[], data: CheckType[]): CheckType[] => {
    let _data: CheckType[] = [];
    if (possibleChecks?.length > 0) {
        _data = possibleChecks.map((check: string) => {
            if (data && data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    if (check === data[i].check) {
                        return {
                            check: check,
                            value: data[i].value
                        };
                    }
                }
            }
            return {
                check: check,
                value: ''
            }
        });
    }
    return _data;
};

export const makeInitialCustomFields = (possibleFields: string[], data: CustomFields[], defaultValue: string) => {
    let _data = [] as CustomFields[];
    if (possibleFields?.length > 0) {
        _data = possibleFields.map((name: string) => {
            if (data && data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    if (name === data[i].name) {
                        return {
                            name: data[i].name,
                            value: data[i].value
                        } as CustomFields;
                    }
                }
            }
            return {
                name: name,
                value: defaultValue
            }
        });
    }
    return _data;
};

export const prepareSeaTimeHours = (voyage: Voyage, tripType: string) => {
    let hasHours = false;
    const hours: {
        [key: string]: number[]
    } = {};
    const processUserId = (userId: string) => {
        const processCrewHours = (crewHours: SeaCrewHour[]) => {
            if (crewHours) {
                crewHours.forEach((item) => {
                    if (item.id === userId && item.hours > 0) {
                        if (hours[userId] === undefined) {
                            hours[userId] = [];
                        }
                        hours[userId].push(item.hours);
                        hasHours = true;
                    }
                });
            }
        };
        if (tripType === 'multiDay') {
            if (voyage.days) {
                voyage.days.forEach((day) => {
                    processCrewHours(day.crewHours as unknown as SeaCrewHour[] || []);
                });
            }
        } else { // singleDay or multiTrip
            processCrewHours(voyage.crewHours as unknown as SeaCrewHour[] || []);
        }
    };
    if (voyage.masterIds) {
        voyage.masterIds.forEach((masterId: string) => {
            processUserId(masterId);
        });
    }
    if (voyage.personnelInvolved) {
        voyage.personnelInvolved.forEach((crewId: string) => {
            processUserId(crewId);
        });
    }
    return hasHours ? hours : undefined;
};

export const compileEngineHours = (editEngines: EditEngine[], completeVoyageRef: React.MutableRefObject<boolean>) => {
    const _engineHours: EngineHour[] = [];
    editEngines?.forEach((item) => {
        _engineHours.push({
            id: item.id,
            hours: (completeVoyageRef.current || item.hours !== item.initialHours) ? toInt(item.hours, 0) : undefined,
            startHours: item.startHours
        } as EngineHour);
    });
    return _engineHours;
};

export const compileCrewHours = (
    crewHours: PreparedSeaCrewHourData | PreparedSeaCrewHour[] | undefined, 
    totalHours: number | undefined, 
    personnelInvolvedIncludingMasters: string[], 
    completeVoyageRef: React.MutableRefObject<boolean>
) => {
    const array = (crewHours && Array.isArray(crewHours)) ? crewHours : undefined;
    const object = (crewHours && !Array.isArray(crewHours)) ? crewHours : undefined;

    const _crewHours = [] as PreparedSeaCrewHour[];
    personnelInvolvedIncludingMasters?.forEach((userId: string) => {
        if (
            completeVoyageRef.current ||
            (
                object && 
                object?.[userId] &&
                object[userId].hours &&
                object[userId].hours as unknown !== ''+totalHours
            ) ||
            (
                array &&
                array?.length > 0 &&
                array?.find((item) => item.id === userId)?.hours &&
                array?.find((item) => item.id === userId)?.hours as unknown !== ''+totalHours
            )
        ) {
            _crewHours.push({
                id: userId,
                hours: object ? toFloat(object?.[userId]?.hours, 0) : array?.find((item) => item.id === userId)?.hours ? toFloat(array?.find((item) => item.id === userId)?.hours, 0) : 0
            } as PreparedSeaCrewHour);
        }
    });
    return _crewHours;
};

export const prepareDaysData = (days: PreparedVoyageDay[], compileCrewHours: Function) => {
    const _days = [] as PreparedVoyageDay[];
    if (days && days.length > 0) {
        days.forEach((day) => {
            _days.push({
                notes: day.notes ? ''+day.notes : '',
                customText: day.customText,
                customHours: day.customHours,
                crewHours: compileCrewHours(day.crewHours || [], day.totalHours),
                totalHours: day.totalHours ? day.totalHours : 0
            });
        });
    }
    return _days;
};

export const prepareTripsData = (baseDate: number, trips: VoyageTrip[]) => {
    const _trips = [] as PreparedVoyageTrip[];
    trips.forEach((trip) => {
        let tripPaxOn = 0;
        try {
            tripPaxOn = toInt(trip.paxOn, 0);
        } catch (e) {}
        let _stops: PreparedVoyageStop[] | undefined = undefined;
        if (trip.stops && trip.stops.length > 0) {
            _stops = [] as PreparedVoyageStop[];
            trip.stops.forEach((stop) => {
                let stopPaxOn = 0;
                let stopPaxOff = 0;
                try {
                    stopPaxOn = toInt(stop.paxOn, 0);
                } catch (e) {}
                try {
                    stopPaxOff = toInt(stop.paxOff, 0);
                } catch (e) {}
                _stops?.push({
                    location: stop.location ? stop.location : undefined,
                    whenArrived: stop.whenArrived ? combineDateAndHours24(baseDate, stop.whenArrived) : undefined,
                    paxOn: stopPaxOn,
                    paxOff: stopPaxOff,
                    whenDeparted: stop.whenDeparted ? combineDateAndHours24(baseDate, stop.whenDeparted) : undefined,
                } as PreparedVoyageStop);
            });
        }
        _trips.push({
            masterId: trip.masterId ? trip.masterId : undefined,
            crewInvolved: trip.crewInvolved,
            customText: trip.customText,
            departureFrom: trip.departureFrom ? trip.departureFrom : undefined,
            whenDeparted: trip.whenDeparted ? combineDateAndHours24(baseDate, trip.whenDeparted) : undefined,
            paxOn: tripPaxOn,
            destinationTo: trip.destinationTo ? trip.destinationTo : undefined,
            whenArrived: trip.whenArrived ? combineDateAndHours24(baseDate, trip.whenArrived) : undefined,
            stops: _stops
        });
    });
    return _trips;
};
import React, { useEffect, useRef, useState } from 'react';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';

interface EditUserPermissionDefaultModalProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    permissionDefaultName: string;
    setPermissionDefaultName: (name: string) => void;
    error: string;
    handleSubmit: () => void;
}

const EditUserPermissionDefaultModal = ({ showModal, setShowModal, permissionDefaultName, setPermissionDefaultName, error, handleSubmit }: EditUserPermissionDefaultModalProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [initialName, setInitialName] = useState(permissionDefaultName);

    useEffect(() => {
        if (showModal && inputRef.current) {
            inputRef.current.focus();
            setInitialName(permissionDefaultName);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    return (
        <SeaModal
            title={permissionDefaultName ? "Edit Permission Defaults Name" : "Add New Permission Default"}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={() => permissionDefaultName !== initialName}
            size="thin"
            level={2}
            onClosed={() => setShowModal(false)}
        >
            <SeaInput
                label="Permission Default Name"
                name="permissionDefaultName"
                value={permissionDefaultName}
                ref={inputRef}
                onchange={(e) => setPermissionDefaultName(e.detail.value)}
                error={error}
            />
            <div className="view-modal-buttons">
                <SeaButton onClick={handleSubmit}>
                    {permissionDefaultName ? 'Save' : 'Add Permission Default'}
                </SeaButton>
            </div>
        </SeaModal>
    );
};

export default EditUserPermissionDefaultModal;
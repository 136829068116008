import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FileSyncDeviceSettingsJson, fileSyncDeviceSettingsUi, loadFileSyncDeviceSettingsJson, saveFileSyncDeviceSettingsJson } from '../../../../shared-state/FileSyncSystem/fileSyncDeviceSettings';
import { deepClone, haveObjectsChanged, toInt } from '../../../../lib/util';
import { resetCachedDataInfo } from '../../../../shared-state/DataSyncSystem/cachedDataInfo';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { resetFilesToCache } from '../../../../shared-state/FileSyncSystem/filesToCache';
import { FileCollection, fileCollections } from '../../../../shared-state/FileSyncSystem/cachedFiles';
import { DiskSpaceStatus } from '../../../../shared-state/FileSyncSystem/diskSpaceStatus';
import { addToDataSyncTasksQueue, LicenseeDataSyncCollection, licenseeDataSyncTaskConfigs, VesselDataSyncCollection, vesselDataSyncTaskConfigs } from '../../../../shared-state/DataSyncSystem/dataSyncTasks';
import { runSharedState, sharedState, SharedStateType } from '../../../../shared-state/shared-state';
import { IonSelectOption } from '@ionic/react';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaCheckbox from '../../../../components/SeaCheckbox/SeaCheckbox';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import './EditStorageSettingsModal.css';


interface EditStorageSettingsModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    diskSpaceStatus: DiskSpaceStatus
}

const EditStorageSettingsModal: React.FC<EditStorageSettingsModalProps> = ({showModal, setShowModal, diskSpaceStatus}) => {
    const formRef = useRef<HTMLFormElement>(null);
    const [initialValues, setInitialValues] = useState<any>();
    const [fileSyncDeviceSettingsJson, setFileSyncDeviceSettingsJson] = useState<FileSyncDeviceSettingsJson>();

    const setIsEnabled = useCallback((checked: boolean, collections: FileCollection[]) => {
        setFileSyncDeviceSettingsJson((current) => {
            const newSettings = deepClone(current);
            collections.forEach((collection) => {
                newSettings[collection].isEnabled = checked;
            });
            return newSettings;
        });
    }, [setFileSyncDeviceSettingsJson]);

    const setHistoryMonths = useCallback((collection: FileCollection, historyMonths: number) => {
        setFileSyncDeviceSettingsJson((current) => {
            const newSettings = deepClone(current);
            newSettings[collection].historyMonths = historyMonths;
            return newSettings;
        });
    }, [setFileSyncDeviceSettingsJson]);



    useEffect(() => {
        if (showModal) {
            setFileSyncDeviceSettingsJson(
                loadFileSyncDeviceSettingsJson()
            );
            setInitialValues({
                fileSyncDeviceSettingsJson: loadFileSyncDeviceSettingsJson()
            });
        }
    }, [showModal]);

    const isModalDirty = useCallback(() => {
        return haveObjectsChanged(
            initialValues.fileSyncDeviceSettingsJson,
            fileSyncDeviceSettingsJson
        );
    }, [initialValues, fileSyncDeviceSettingsJson]);


    const onSubmit = useCallback((e: any) => {
        e.preventDefault();
        e.stopPropagation();

        if (haveObjectsChanged(
            initialValues.fileSyncDeviceSettingsJson,
            fileSyncDeviceSettingsJson
        )) {
            saveFileSyncDeviceSettingsJson(fileSyncDeviceSettingsJson!);
            // Restart filesToCache Queue
            resetFilesToCache();
            
            // Trigger a full Data Sync
            resetCachedDataInfo();

            // Reload all fileCollections data so that register* functions are called for all files
            Object.keys(fileCollections).forEach((collection) => {
                if ((licenseeDataSyncTaskConfigs as any)[collection]) {
                    // Licensee DataSyncTask
                    if ((licenseeDataSyncTaskConfigs as any)[collection].hasPermission()) {
                        addToDataSyncTasksQueue({
                            type: 'licensee',
                            collection: collection as LicenseeDataSyncCollection,
                            touched: 0
                        });
                    }
                } else if ((vesselDataSyncTaskConfigs as any)[collection]) {
                    // Vessel DataSyncTask
                    sharedState.vesselIds.current?.forEach((vesselId) => {
                        if ((vesselDataSyncTaskConfigs as any)[collection].hasPermission(vesselId)) {
                            addToDataSyncTasksQueue({
                                type: 'vessel',
                                collection: collection as VesselDataSyncCollection,
                                vesselId: vesselId,
                                touched: 0
                            });
                        }
                    });
                } else {
                    // No DataSyncTask for this fileCollection.
                    // Therefore, we'll manually rerun sharedState directly
                    runSharedState(collection as SharedStateType);
                }
            });
            showToast(`Applying new file caching settings...`);
        }

        setShowModal(false);
    }, [fileSyncDeviceSettingsJson, setShowModal, initialValues?.fileSyncDeviceSettingsJson]);





    return (
        <SeaModal
            title="Edit Device Storage Settings"
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            actionPanel={
                <>
                    <SeaButton zone="white" onClick={(e) => {
                        formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                    }}>Save Storage Settings</SeaButton>
                    {/* <SeaButton onClick={(e) => {
                        // Restart filesToCache Queue
                        resetFilesToCache();
                        // Trigger a full Data Sync
                        resetCachedDataInfo();
                        showToast(`Restart FileSync...`);
                    }}>
                        Test: Reset File Sync
                    </SeaButton> */}
                </>
            }
        >
            <form ref={formRef} onSubmit={onSubmit}>
                {!diskSpaceStatus!.haveEnoughSpace ?
                    <>
                        Please turn off the file types below that you're ok with not having access to while offline.
                    </>
                    :
                    <>
                        Select which kinds of files you would like to download to your device for use while offline.
                        <p>
                            If you are worried about Sea Flux using up too much space on your device we recommend turning off the file types below that you wont mind not having access to when offline.
                        </p>
                    </>
                }
                <p>
                    Note: This only affects full size images and files. All thumbnails, signatures, and non-file data are cached regardless of these settings.
                </p>
                <div className="file-caching-settings" style={{ paddingBottom: '40px' }}>
                    {fileSyncDeviceSettingsJson && fileSyncDeviceSettingsUi.map((section) => {
                        return (
                            <div key={section.name}>
                                <h2>{section.name}</h2>
                                {section.types.map((type) => {
                                    if ((type as any).isValid && !(type as any).isValid()) {
                                        return undefined;
                                    }
                                    const name = typeof type.name === 'function' ? type.name() : type.name;
                                    const prefix = '';

                                    const relevantCollections = type.collections ?? [];
                                    if (type.history?.collection) {
                                        relevantCollections.push(type.history.collection);
                                    }

                                    let isEnabled = false;
                                    relevantCollections.forEach((collection) => {
                                        if ((fileSyncDeviceSettingsJson as any)[collection]?.isEnabled) {
                                            isEnabled = true;
                                        }
                                    });

                                    return (
                                        <div key={name} className="columns">
                                            <div>
                                                {type.name &&
                                                    <SeaCheckbox
                                                        checked={isEnabled}
                                                        setChecked={(checked) => {
                                                            setIsEnabled(checked, relevantCollections as FileCollection[]);
                                                        }}
                                                    >
                                                        {name}
                                                    </SeaCheckbox>
                                                }
                                            </div>
                                            <div>
                                                {type.history &&
                                                    <SeaSelect
                                                        value={''+(fileSyncDeviceSettingsJson as any)[type?.history?.collection].historyMonths}
                                                        disabled={!isEnabled}
                                                        onchange={(e) => {
                                                            setHistoryMonths(type.history.collection as FileCollection, toInt(e.detail.value));
                                                        }}
                                                    >
                                                        <IonSelectOption value="0">No {type.history.name}</IonSelectOption>
                                                        <IonSelectOption value="1">{prefix} 1 month of {type.history.name}</IonSelectOption>
                                                        <IonSelectOption value="3">{prefix} 3 months of {type.history.name}</IonSelectOption>
                                                        <IonSelectOption value="6">{prefix} 6 months of {type.history.name}</IonSelectOption>
                                                        <IonSelectOption value="12">{prefix} 1 year of {type.history.name}</IonSelectOption>
                                                        <IonSelectOption value="24">{prefix} 2 years of {type.history.name}</IonSelectOption>
                                                        <IonSelectOption value="9999">All {type.history.name}</IonSelectOption>
                                                    </SeaSelect>
                                                }
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </form>
        </SeaModal>
    );
};

export default EditStorageSettingsModal;

import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useEffect, useState } from "react";

//
// Loads errorReports for analysis.
// This can only be used by superAdmin.
//

export type ErrorReport = any;

export const useErrorReports = (whenFrom: number, whenTo: number) => {
    const [errorReports, setErrorReports] = useState<ErrorReport[]>();

    useEffect(() => {
        setErrorReports(undefined);

        let standardErrorReports: ErrorReport[];
        let unintentionalRefreshes: ErrorReport[];

        const combine = () => {
            if (standardErrorReports !== undefined && unintentionalRefreshes !== undefined) {
                const all = [...standardErrorReports, ...unintentionalRefreshes];
                all.sort((a, b) => {
                    return b.whenReported - a.whenReported;
                });
                setErrorReports(all);
            }
        };

        // Gather standard errorReports
        const standardErrorReportsCleanup = onSnapshot(
            query(
                collection(firestore, 'errorReports'),
                where('whenReported', '>=', whenFrom),
                where('whenReported', '<=', whenTo),
                orderBy('whenReported', 'desc')
            ),
            (snap) => {
                standardErrorReports = snap.docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    };
                });
                combine();
            }, (error) => {
                console.log(`Error getting errorReports`, error);
            }
        );

        // Gather unintentionalRefreshes
        const unintentionalRefreshesCleanup = onSnapshot(
            query(
                collection(firestore, '_unintentionalRefreshes'),
                where('whenReported', '>=', whenFrom),
                where('whenReported', '<=', whenTo),
                orderBy('whenReported', 'desc')
            ),
            (snap) => {
                unintentionalRefreshes = snap.docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data(),
                        state: 'unintentionalRefresh',
                        type: 'Unintentional Refresh'
                    };
                });
                combine();
            }, (error) => {
                console.log(`Error getting _unintentionalRefreshes`, error);
            }
        );

        // Return cleanup function
        return (() => {
            standardErrorReportsCleanup();
            unintentionalRefreshesCleanup();
        });
    }, [whenFrom, whenTo]);

    return errorReports;
};


import React, { useState, useEffect, useMemo } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption, IonSpinner } from '@ionic/react';
import { functions } from '../../../lib/firebase';
import { httpsCallable } from "firebase/functions";
import { formatSeaDatetime, toMillis } from '../../../lib/util';
import { sharedState } from '../../../shared-state/shared-state';
import { renderFullName } from '../../../shared-state/Core/users';
import { useLicenseeVessels } from '../../../shared-state/Core/licenseeVessels';
import { useLicensees } from '../../../shared-state/Admin/licensees';
import { alertMessage } from '../../../managers/AlertManager/AlertManager';
import SeaModal from '../../../components/SeaModal/SeaModal';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaSelect from '../../../components/SeaSelect/SeaSelect';
import SeaDatetime from '../../../components/SeaDatetime/SeaDatetime';
import SeaCheckbox from '../../../components/SeaCheckbox/SeaCheckbox';

const config = [
    {
        category: 'Vessel Settings',
        options: [{
            id: 'engines',
            name: 'Engines'
        },{
            id: 'vesselSafetyItems',
            name: 'Safety Items'
        },{
            id: 'vesselSystems',
            name: 'Systems'
        },{
            id: 'vesselLocations',
            name: 'Locations'
        }],
    },{
        category: 'Logbook Settings',
        options: [{
            id: 'logbookSettings',
            name: 'Startup/shutdown checks, common actions, custom text/hour fields'
        }]
    },
    // {
    //     category: 'Logbook',
    //     options: [{
    //         id: 'voyages',
    //         name: 'Voyages'
    //     },{
    //         id: 'logbookSettings',
    //         name: 'Startup/shutdown checks, common actions, custom text/hour fields'
    //     }]
    // }
    {
        category: 'Vessel Safety',
        options: [{
            id: 'safetyCheckItems',
            name: 'Safety Checks'
        },{
            id: 'safetyEquipmentItems',
            name: 'Safety Equipment Expiries'
        },{
            id: 'drills',
            name: 'Drills'
        }]
    },{
        category: 'Vessel Maintenance',
        options: [{
            id: 'equipmentManualDocuments',
            name: 'Equipment Manuals'
        },{
            id: 'equipment',
            name: 'Equipment List',
            requires: 'Equipment Manuals'
        },{
            id: 'scheduledMaintenanceTasks',
            name: 'Maintenance Schedule',
            requires: 'Equipment Manuals and Engines'
        },{
            id: 'spareParts',
            name: 'Spare Parts',
            requires: 'Equipment'
        }]
    },{
        category: 'Vessel Documents',
        options: [{
            id: 'vesselCertificates',
            name: 'Vessel Certificates'
        },{
            id: 'vesselDocuments',
            name: 'Vessel Documents'
        },{
            id: 'SOPs',
            name: 'Standard Operating Procedures'
        }]
    },{
        category: 'Health & Safety',
        options: [{
            id: 'riskMatrix',
            name: 'Risk Matrix'
        },{
            id: 'risks',
            name: 'Risk Assessments',
            requires: 'Risk Matrix'
        },{
            id: 'dangerousGoods',
            name: 'Dangerous Goods'
        }]
    },{
        category: 'Crew',
        options: [{
            id: 'trainingTasks',
            name: 'Training Tasks'
        }]
    }
];

interface ReplicateVesselModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}

const ReplicateVesselModal: React.FC<ReplicateVesselModalProps> = ({showModal, setShowModal}) => {
    const licenseeId = sharedState.licenseeId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const users = sharedState.users.use(showModal);
    const todayMillis = sharedState.todayMillis.use(showModal);
    const licensees = useLicensees();
    const [selectedLicenseeId, setSelectedLicenseeId] = useState<string>('');
    const licenseeVessels = useLicenseeVessels(selectedLicenseeId);
    const [addedBy, setAddedBy] = useState<string>();
    const [selectedVesselId, setSelectedVesselId] = useState<string>('');
    const [defaultExpiry, setDefaultExpiry] = useState('');
    const [selectedOptions, setSelectedOptions] = useState<{
        [key: string]: boolean
    }>({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (showModal) {
            setIsSubmitting(false);

            // Reset selected options to all
            const options: {
                [key: string]: boolean
            } = {};
            config.forEach((category) => {
                category.options.forEach((option) => {
                    options[option.id] = true;
                });
            });
            console.log('options', options);
            setSelectedOptions(options);
            
            setDefaultExpiry(formatSeaDatetime(946638000000));
        }
    }, [showModal]);

    useEffect(() => {
        if (showModal && selectedLicenseeId) {
            setAddedBy(licenseeId);
        }
    }, [showModal, selectedLicenseeId, licenseeId]);

    const vesselUserOptions = useMemo(() => {
        if (!users || !vesselId) return;
        return users?.byVesselId[vesselId] && users.byVesselId[vesselId].map((user) => {
            return {
                id: user.id as string,
                name: renderFullName()
            };
        });
    }, [users, vesselId]);

    const onOpened = () => {
        setSelectedLicenseeId('');
        setSelectedVesselId('');
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);

        const sections: string[] = [];
        config.forEach((category) => {
            category.options.forEach((option) => {
                if (selectedOptions[option.id]) {
                    sections.push(option.id);
                }
            });
        });

        httpsCallable(functions, 'adminDoReplication', {timeout: 10 * 60 * 1000})({
            fromVesselId: selectedVesselId,
            toVesselId: vesselId,
            defaultAddedBy: addedBy,
            defaultExpiry: toMillis(defaultExpiry),
            todayMillis: todayMillis,
            sections: sections
        }).then((result: any) => {
            console.log('adminDoReplication result', result);
            if (result?.data?.error) {
                alertMessage('ERROR: '+result.data.error);
            } else {
                alertMessage(`Section replication completed! Took ${Math.round(result.data.timeTaken / 1000)} seconds. Added ${result.data.count.all} documents. Updated ${result.data.filesUpdated} files.`);
            }
        }).catch((error) => {
            console.log('error', error);
        }).finally(() => {
            setIsSubmitting(false);
        });
    }

    return (
        <SeaModal
            title="Replicate Another Vessel"
            showModal={showModal}
            setShowModal={setShowModal}
            onOpened={onOpened}
        >
            <form onSubmit={handleSubmit}>
                {licensees &&
                    <IonGrid className="form-grid">
                        <IonRow>
                            <IonCol size="6">
                                <SeaSelect
                                    label="Licensee"
                                    value={selectedLicenseeId}
                                    onchange={(e) => {
                                        setSelectedLicenseeId(e.detail.value);
                                    }}
                                    disabled={isSubmitting}
                                >
                                    <IonSelectOption value="">Select Licensee...</IonSelectOption>
                                    {licensees?.all.map((licensee) => {
                                        return (
                                            <IonSelectOption
                                                key={licensee.id}
                                                value={licensee.id}
                                            >
                                                {licensee.firstName} {licensee.lastName}
                                            </IonSelectOption>
                                        );
                                    })}
                                </SeaSelect>
                            </IonCol>
                            {licenseeVessels &&
                                <IonCol size="6">
                                    <SeaSelect
                                        label="Vessel"
                                        value={selectedVesselId}
                                        onchange={(e) => {
                                            setSelectedVesselId(e.detail.value);
                                        }}
                                        disabled={isSubmitting}
                                    >
                                        <IonSelectOption value="">Select Vessel...</IonSelectOption>
                                        {licenseeVessels.map((_vessel) => {
                                            return (
                                                <IonSelectOption
                                                    key={_vessel.id}
                                                    value={_vessel.id}
                                                >
                                                    {_vessel.name}
                                                </IonSelectOption>
                                            );
                                        })}
                                    </SeaSelect>
                                </IonCol>
                            }
                            {selectedVesselId &&
                                <>
                                    <IonCol size="6">
                                        <SeaSelect
                                            label="Add data as"
                                            name="addedBy"
                                            value={addedBy}
                                            onchange={(e) => {
                                                setAddedBy(e.detail.value);
                                            }}
                                            //onblur={handleBlur}
                                            disabled={isSubmitting}
                                        >
                                            {/* <IonSelectOption value="">Not Set</IonSelectOption> */}
                                            {vesselUserOptions?.map((option: {id: string, name: string}) => {
                                                return <IonSelectOption
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.name}
                                                </IonSelectOption>
                                            })}
                                        </SeaSelect>
                                    </IonCol>
                                    <IonCol size="6">
                                        <SeaDatetime
                                            label="Default Expiry Date"
                                            value={defaultExpiry}
                                            disabled={isSubmitting}
                                        />
                                    </IonCol>
                                </>
                            }
                            {selectedVesselId && addedBy &&
                                <>
                                    {config.map((category) => {
                                        return (
                                            <React.Fragment key={category.category}>
                                                <IonCol size="12">
                                                    <h4>
                                                        {category.category}
                                                    </h4>
                                                </IonCol>
                                                {category.options.map((option) => {
                                                    return (
                                                        <IonCol key={option.id} size="12">
                                                            <SeaCheckbox
                                                                checked={selectedOptions[option.id]}
                                                                name={option.id}
                                                                setFieldValue={(name, value) => {
                                                                    setSelectedOptions((current) => {
                                                                        const o = {
                                                                            ...current
                                                                        };
                                                                        o[option.id] = value;
                                                                        return o;
                                                                    });
                                                                }}
                                                                disabled={isSubmitting}
                                                            >
                                                                {option.name}
                                                                {option.requires &&
                                                                    <span style={{
                                                                        fontStyle: 'italic',
                                                                        fontSize: '11px',
                                                                        paddingLeft: '8px'
                                                                    }}>
                                                                        (Requires {option.requires})
                                                                    </span>
                                                                }
                                                            </SeaCheckbox>
                                                        </IonCol>
                                                    );
                                                })}
                                            </React.Fragment>
                                        );
                                    })}
                                    <IonCol size="12">
                                        <div className="view-modal-buttons">
                                            <SeaButton
                                                zone="white"
                                                type="submit"
                                                size="wide"
                                                disabled={isSubmitting}
                                            >
                                                Replicate Selected Sections
                                            </SeaButton>
                                            {isSubmitting &&
                                                <IonSpinner name="crescent" className="sea-spinner"/>
                                            }
                                        </div>
                                    </IonCol>
                                </>
                            }
                        </IonRow>
                        
                    </IonGrid>
                }
            </form>
        </SeaModal>
    );
};

export default ReplicateVesselModal;

import { DocumentData, QueryDocumentSnapshot, collection, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../lib/firebase";
import { SharedStateConfig, sharedState } from "../shared-state";
import { canView } from "../../shared-state/Core/userPermissions";
import { Job } from "../VesselMaintenance/jobs";
import { registerFiles } from "../FileSyncSystem/filesToCache";

export type SafetyMeetingJobsData = {
    byId: {
        [id: string]: Job
    }
};

export const safetyMeetingJobsConfig: SharedStateConfig<SafetyMeetingJobsData> = {
    isAlwaysActive: false,
    dependencies: ['vesselIds'],
    countLiveDocs: () => sharedState.jobs.current?.count ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselIds = sharedState.vesselIds.current;
        if (
            vesselIds &&
            canView('healthSafetyMeetings')
        ) {
            return setupArrayQueryListener(
                'health & safety meeting jobs', // what
                collection(firestore, 'jobs'),
                [
                    where('state', 'in', ['active', 'completed']),
                    where('addedFromMeetingId', '!=', null)
                ],
                'vesselId',
                'in',
                vesselIds,
                [], // orderBy
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean // (not needed as there is no sorting)
                ) => { // processDocs
                    done();
                    const byId = {} as {
                        [id: string]: Job
                    };
                    docs.forEach((doc) => {
                        byId[doc.id] = {
                            id: doc.id,
                            ...doc.data()
                        } as Job;
                        registerFiles(byId[doc.id].files, 'jobs', byId[doc.id]);
                    });
                    set({
                        byId
                    });
                }, (error) => {
                    done();
                    console.log(`Failed to get safetyMeetingJobs for vesselIds=${vesselIds}`, error);
                }
            );
        }
    },

};

import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { CreateableDocument, UpdateableDocument, sharedState } from "../shared-state";
import { makeDateTime } from "../../lib/util";
import { canView } from "../../shared-state/Core/userPermissions";
import { UserType } from "../Core/user";
import { renderFullName } from "../Core/users";
import { useEffect, useState } from 'react';

//
// Load training task reports
//

export interface TrainingTaskReport extends CreateableDocument, UpdateableDocument {
    completedBy: string[];
    deletedBy?: string;
    licenseeId: string;
    notes?: string;
    state: string;
    taskId: string;
    trainer: string;
    vesselId: string;
    whenCompleted: number;
    whenDeleted?: number;
    whenDue: number;
    whenDueDiff?: number;
}

export type TrainingTaskReportsData = {
    all: TrainingTaskReport[], // ordered by whenDue (desc)
    users: UserType[],  // users that have completed at least one task, sorted alphabetically
    byId: {
        [reportId: string]: TrainingTaskReport
    },
    byTaskId: {
        [taskId: string]: TrainingTaskReport[] // ordered by whenDue (desc)
    },
    byUserId: {
        [userId: string]: TrainingTaskReport[] // ordered by whenDue (desc)
    },
    byTaskAndUser: {
        [taskIdAndUserId: string]: TrainingTaskReport // will be the single latest report
    },
}


export const useTrainingTaskReports = (selectedVesselId: string | undefined, priority: number) => {
    const trainingTasks = sharedState.trainingTasks.use(priority);
    const todayMillis = sharedState.todayMillis.use(priority);
    const users = sharedState.users.use(priority);
    const [trainingTaskReports, setTrainingTaskReports] = useState<TrainingTaskReportsData>();

    useEffect(() => {
        setTrainingTaskReports(undefined);
        if (
            selectedVesselId &&
            trainingTasks &&
            users &&
            canView('crewTraining')
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'trainingTaskReports'),
                    where('vesselId', '==', selectedVesselId),
                    where('state', '==', 'active'),
                    orderBy('whenDue', 'desc')
                ),
                (snap) => {
                    const reports = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as TrainingTaskReport;
                    });
                    const byId = {} as {
                        [reportId: string]: TrainingTaskReport
                    };
                    const byTaskId = {} as {
                        [taskId: string]: TrainingTaskReport[]
                    };
                    const byTaskAndUser = {} as {
                        [taskIdAndUserId: string]: TrainingTaskReport
                    };
                    const byUserId = {} as {
                        [userId: string]: TrainingTaskReport[]
                    };
                    const trainingUsers = [] as UserType[];

                    const today = makeDateTime(todayMillis);
                    reports.forEach((report: TrainingTaskReport) => {
                        if (trainingTasks.byId[report.taskId]?.state === 'active') {
                            report.whenDueDiff = Math.round(
                                makeDateTime(report.whenDue).diff(today, ['days']).days
                            );
                            byId[report.id] = report;
                            if (byTaskId[report.taskId] === undefined) {
                                byTaskId[report.taskId] = [];
                            }
                            byTaskId[report.taskId].push(report);
                            report.completedBy?.forEach((userId: string) => {
                                if (
                                    users?.byId[userId]?.state === 'active' &&
                                    users.byId[userId].vesselIds?.indexOf(report.vesselId) !== -1
                                ) {
                                    if (byUserId[userId] === undefined) {
                                        byUserId[userId] = [];
                                        trainingUsers.push(users.byId[userId]);
                                    }
                                    byUserId[userId].push(report);
                                    if (byTaskAndUser[`${report.taskId}${userId}`] === undefined) {
                                        byTaskAndUser[`${report.taskId}${userId}`] = report;
                                    }
                                }
                            });
                        }
                    });
    
                    trainingUsers.sort((a, b) => {
                        return renderFullName(a).localeCompare(renderFullName(b));
                    });

                    setTrainingTaskReports({
                        all: reports,           // all reports ordered by whenDue (desc)
                        users: trainingUsers,   // users that have completed at least one task, sorted alphabetically
                        byId,                   // 
                        byTaskId,               // all reports for a given task, ordered by whenDue (desc)
                        byUserId,               // lists of reports ordered by whenDue (desc)
                        byTaskAndUser,          // will be set to the latest report
                    });

                }, (error) => {
                    // This should be very rare
                    console.log('Failed to access training task reports ', error)
                }
            );
        }
    }, [trainingTasks, users, selectedVesselId, todayMillis]);

    return trainingTaskReports;
};

import { isImage } from "../../lib/files";
import { CachedFileType, getCachedFileSrc } from "./cachedFiles";

const maxSimultaneousCachedFilesToLoad = 2;
const filesToLoad = [] as any[];
let cachedFilesBeingLoaded = 0;

export type LoadFromCacheSrc = 'nonImage' | 'noCache' | string;

export type LoadFromCacheTask = {
    fileType: CachedFileType,
    files: string[], // These get shifted off as they are processed
    srcs: LoadFromCacheSrc[], // This gets pushed onto as files are processed
    callback: (srcs: LoadFromCacheSrc[]) => void, // Gets called with resultant LoadFromCacheSrc[] when all files have been processed
    cancel: boolean // Set this to true to cancel the loading
};

// Get back LoadFromCacheSrc[] via the callback function given an array of files[].
// Returns a function that cancels the request.
export const getCachedImgSrcs = (files: string[], fileType: CachedFileType, callback: (srcs: LoadFromCacheSrc[]) => void) => {
    //console.log('getCachedSrcs files', files);
    const task = {
        fileType: fileType,
        files: [...files], // These get shifted off as they are loaded (or fail to load)
        srcs: [],
        callback: callback,
        cancel: false
    } as LoadFromCacheTask;
    filesToLoad.push(task);
    processFilesToLoad();
    return () => {
        task.cancel = true;
    };
};

const processFilesToLoad = () => {
    //const queue = sharedState.loadFromCacheTasksQueue.current!;
    if (
        cachedFilesBeingLoaded >= maxSimultaneousCachedFilesToLoad ||
        filesToLoad.length === 0
    ) {
        return;
    }

    // Grab a task from the queue to process
    let task = filesToLoad.shift();
    if (task === undefined) {
        //console.log('Loading cached files all done.');
        return;
    }

    cachedFilesBeingLoaded++;

    loadCachedImgSrcs(task).then(() => {
        // loaded files successfully
    }).catch((error) => {
        console.error('Error, loading cached files!', error);
    }).finally(() => {
        cachedFilesBeingLoaded--;
        processFilesToLoad();
    });
};

const loadCachedImgSrcs = (task: LoadFromCacheTask): Promise<any> => {
    if (task.cancel) {
        return Promise.resolve();
    }
    if (task.files.length === 0) {
        task.callback(task.srcs);
        return Promise.resolve();
    }
    const file = task.files.shift() as string;
    if (file === '') {
        task.srcs.push('noCache');
        return loadCachedImgSrcs(task);
    }
    if (!isImage(file.substring(file.lastIndexOf('.') + 1))) {
        // non image file
        task.srcs.push('nonImage');
        return loadCachedImgSrcs(task);
    }
    return getCachedFileSrc(file, task.fileType).then((src) => {
        task.srcs.push(src);
    }).catch((error) => {
        task.srcs.push('noCache');
    }).finally(() => {
        return loadCachedImgSrcs(task);
    });
};


import React, { ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { sharedState } from '../../shared-state/shared-state';
import PleaseWait from '../../pages/AccountManagement/PleaseWait/PleaseWait';

interface RequireSuperAdminProps {
    children: ReactNode
}

const RequireSuperAdmin: React.FC<RequireSuperAdminProps> = ({ children }) => {
    const superAdmin = sharedState.superAdmin.use();
    const user = sharedState.user.use();
    const appReadyState = sharedState.appReadyState.use()!;
    const navigate = useNavigate();
    const location = useLocation();

    if (!appReadyState.isReady) {
        return <PleaseWait message={appReadyState.notReadyMessage}/>
    }

    if (!superAdmin) {
        setTimeout(() => {
            if (user) {
                navigate('/fleet');
            } else {
                navigate(`/login?d=${encodeURIComponent(location.pathname + (location.search ? location.search : ''))}`);
            }
        });
        return <></>;
    }

    return <>{children}</>;
};

export default RequireSuperAdmin;

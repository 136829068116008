import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../lib/firebase";
import { sharedState } from "../shared-state";

export const initAuthentication = () => {
    // Listen to authentication state changes (Firebase)
    return onAuthStateChanged(auth, (authUser: any) => {
        sharedState.authUser.set(authUser);
        sharedState.user.clear();
        sharedState.superAdmin.clear();
        if (!authUser) {
            sharedState.userPending.set(false); // We can't get a user, so there's nothing left to wait for
        }
    });
}

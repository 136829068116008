import { SeaFluxError } from "../../managers/ErrorsManager/ErrorsManager";
import { sharedState, SharedStateConfig } from "../shared-state";

//
// errorsOnHold is used to hold errors precenting them from being displayed to the user (red screen)
// because we're not sure if they're real or fake.
// The problem is firestore security violations can occur when, in fact, the operation does go through - i.e. the error was "fake".
// This seems to only happen on iOS and usually occurs when the app has recently come online.
// Our plan to deal with this is to wait for fakeErrorWaitMillis at which point we'll test to see if the error was really real...
// This is done by seeing if the associated operation did in fact get saved to firestore (by querying the actionsConfirmed collection).
//

// sharedState.triggerProcessErrorsOnHold is used to trigger ErrorsManager checking if errorsOnHold are real or fake and act accordingly.
// According to my analysis, over 95% of fakeErrors will be ignored if we wait 15 minutes of being online before checking for fakeness.
// Note: This could be increased up to 20 minutes if too many fake errors are being seen and the tradeoff seems worth it.
export const fakeErrorWaitMillis = 15 * 60 * 1000; // 15 minutes

export const errorsOnHoldConfig: SharedStateConfig<SeaFluxError[]> = {
    isAlwaysActive: true,
    default: [],
    notes: `Errors that we're suspicious might be fake. When we're online long enough, we'll check to see if they're real.`
};

export const triggerProcessErrorsOnHoldConfig: SharedStateConfig<boolean> = {
    isAlwaysActive: true,
    default: false,
    notes: 'Source: sharedState.onlineStatus.run. This is used to trigger ErrorsManager checking if errorsOnHold are real or fake and acting accordingly.'
};

//
// errorsOnHold is persisted to localStorage so we don't lose them if/when the app is restarted
//

export const saveErrorsOnHold = () => {
    window.localStorage.setItem(`_${sharedState.licenseeId.current}_errorsOnHold`, JSON.stringify(sharedState.errorsOnHold.current));
};

export const loadErrorsOnHold = () => {
    const s = window.localStorage.getItem(`_${sharedState.licenseeId.current}_errorsOnHold`);
    if (s) {
        sharedState.errorsOnHold.set((current) => [...current ?? [], JSON.parse(s)]);
        saveErrorsOnHold();
    }
};

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IonCol, IonGrid, IonItem, IonList, IonPopover, IonRow } from '@ionic/react';
import { CSVLink } from 'react-csv';
import { firestore, logPageView, splittableBatch } from '../../../../lib/firebase';
import { collection, query, where, orderBy, doc, getDoc, getDocs, addDoc, serverTimestamp } from "firebase/firestore";
import { formatDate } from '../../../../lib/util';
import { sharedState } from '../../../../shared-state/shared-state';
import { useNavigate } from 'react-router';
import { LicenseesData } from '../../../../shared-state/Admin/licensees';
import { useLicenseeSettingsById } from '../../../../shared-state/Core/licenseeSettings';
import { useLicenseeDetailsById } from '../../../../shared-state/Admin/licenseeDetails';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { alertMessage } from '../../../../managers/AlertManager/AlertManager';
import { reportError } from '../../../../managers/ErrorsManager/ErrorsManager';
import { LicenseeUserType } from '../../../../shared-state/Core/user';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import EditLicenseeAccountModal from '../../../../modals/Admin/EditLicenseeAccountModal/EditLicenseeAccountModal';
import EditVesselModal from '../../../../modals/VesselDashboard/EditVesselModal/EditVesselModal';
import PeopleIcon from '../../../../assets/svg/People';
import DuckIcon from '../../../../assets/svg/Duck';
import SeaSearchbar from '../../../../components/SeaSearchbar/SeaSearchbar';
import './LicenseeAccounts.css';


// Notes to self:
//
// How to know data is cached (because we must be offline) ?
//   snapshot.getMetadata().isFromCache()
// also, can try
//   documentRef.get({ source: 'server' })    // or 'cache'

interface LicenseeAccountsProps {
    visible: boolean,
    licensees: LicenseesData | undefined
}

const LicenseeAccounts: React.FC<LicenseeAccountsProps> = ({ visible, licensees}) => {
    const isMounted = useRef(false);
    const superAdmin = sharedState.superAdmin.use();
    const navigate = useNavigate();
    const licenseeSettingsById = useLicenseeSettingsById();
    const licenseeDetailsById = useLicenseeDetailsById();
    const [licenseeToEdit, setLicenseeToEdit] = useState<LicenseeUserType>();
    const [showEditLicenseeModal, setShowEditLicenseeModal] = useState(false);
    const [showEditVesselModal, setShowEditVesselModal] = useState(false);
    const [licenseePopoverState, setLicenseePopoverState] = useState<{
        showPopover: boolean;
        event: any;
    }>({ showPopover: false, event: undefined });
    const [actionUser, setActionUser] = useState<LicenseeUserType>();
    const [isShoreFacility, setIsShoreFacility] = useState(false);
    const [csvData, setCsvData] = useState<any>('');
    const [isLoadingCsv, setIsLoadingCsv] = useState(false);
    const [searchText, setSearchText] = useState('');
    const csvRef = useRef<any>(null);

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false; };
      }, []);

    useEffect(() => {
        sharedState.userId.clear(); // When we view this page, we don't want to have a licensee selected
        sharedState.user.clear();
        sharedState.vesselId.clear();
        logPageView('Admin');
    }, []);

    const filteredLicensees = useMemo(() => {
        if (licenseeDetailsById && licenseeSettingsById && licensees?.all && searchText && searchText.trim().length > 0) {
            const text = searchText.trim().toLowerCase();
            const results = [] as LicenseeUserType[];
            licensees.all.forEach((licensee) => {
                if (
                    `${licensee.firstName?.toLowerCase()} ${licensee.lastName?.toLowerCase()}`.indexOf(text) !== -1 ||
                    (licenseeDetailsById[licensee.id].companyName && licenseeDetailsById[licensee.id].companyName?.toLowerCase().indexOf(text) !== -1) ||
                    (licenseeDetailsById[licensee.id].companyAddress && licenseeDetailsById[licensee.id].companyAddress?.toLowerCase().indexOf(text) !== -1) ||
                    (licenseeDetailsById[licensee.id]?.email && licenseeDetailsById[licensee.id]?.email.toLowerCase().indexOf(text) !== -1) ||
                    (licenseeSettingsById[licensee.id]?.region && licenseeSettingsById[licensee.id]?.region.toLowerCase().indexOf(text) !== -1) ||
                    String(licensee.licenseeNumber) === text
                ) {
                    results.push(licensee);
                }
            });
            return results;
        } else {
            return licensees?.all;
        }
    }, [licenseeDetailsById, licenseeSettingsById, licensees?.all, searchText])

    const closePopover = () => {
        if (!isMounted.current) return;
        setLicenseePopoverState({ showPopover: false, event: undefined });
    };
    const openPopover = (e:  React.MouseEvent<Element, MouseEvent>, user: LicenseeUserType) => {
        if (!isMounted.current) return;
        // e.persist(); // No longer needed in React 17+
        setActionUser(user);
        setLicenseePopoverState({ showPopover: true, event: e });
    };
    const onAddVessel = (addShoreFacility?: boolean) => {
        closePopover();
        addShoreFacility && setIsShoreFacility(true);
        setShowEditVesselModal(true);
    };
    const onEditAccount = () => {
        closePopover();
        setLicenseeToEdit(actionUser);
        setShowEditLicenseeModal(true);
    };
    const onGoToAccount = () => {
        closePopover();
        if (!actionUser) return;
        // Load user
        sharedState.userPending.set(true);
        sharedState.userId.set(actionUser.id);
        sharedState.vesselId.clear();
        setTimeout(() => {
            navigate('/fleet');
        });
    };
    const onSendWelcomeEmail = () => {
        if (!actionUser) return;
        closePopover();
        addDoc(
            collection(firestore, 'welcomeEmails'),
            {
                emailedTo: actionUser.id,
                sentBy: superAdmin?.id,
                whenAdded: Date.now(),
                isExpired: false
                //origin: getServerUrl()
            }
        ).then((id) => {
            showToast(`Sending a welcome email...`);
        }).catch((error) => {
            reportError(`Failed to send a welcome email to ${actionUser.id}`, error.message, error, {
                actionUser
            });
        });
    };
    const onDeactivateAccount = () => {
        closePopover();
        if (!actionUser) return;
        confirmAction(`Are you sure you want to deactivate ${actionUser.firstName} ${actionUser.lastName}'s account?`, 'Yes, deactivate account')
            .then(() => {
                const batch = splittableBatch(firestore, 20 - 0);
                batch.set(
                    doc(firestore, 'users', actionUser.id),
                    {
                        state: 'deactivated',
                        whenDeactivated: Date.now(),
                        deactivatedBy: superAdmin?.id
                    },
                    { merge: true }
                );
                batch.set(
                    doc(firestore, 'userDetails', actionUser.id),
                    {
                        state: 'deactivated',
                        touched: serverTimestamp()
                    },
                    { merge: true }
                );

                return getDocs(
                    query(
                        collection(firestore, 'users'),
                        where('licenseeId', '==', actionUser.id)
                    )
                ).then((snap) => {
                    snap.docs.forEach((_doc) => {
                        batch.set(
                            doc(firestore, 'users', _doc.id),
                            { isDeactivated: true },
                            { merge: true }
                        );
                    })
                }).then(() => {
                    return batch.commit();
                });
            }).then(() => {
                showToast('Licensee account has been deactivated');
            }).catch(() => {});
    };
    const onGenerateFakeErrors = () => {
        reportError('Failed to not click generate fake error', 'User clicked generate fake error', new Error('Yes, it is fake'), {
            actionUser
        });
        setTimeout(() => {
            reportError('A fake error one second later has occurred', 'This was automatically generated.', new Error('Fake error'));
        }, 1000);
        closePopover();
    };

    const onDownloadEmailsCSV = async () => {
        console.log('Generating Emails CSV...');
        setIsLoadingCsv(true);
        const data = [
            ["Licensee Name", "Region", "Email Address", "First Name", "Last Name", "State", "Tags"]
            // ["ah@smthing.co.com", "Ahmed", "Tomi", "nz", "existing customer"]
        ];

        const actions: any[] = [];
        const licensees: LicenseeUserType[] = [];
        const regionMap: {
            [key: string]: string
        } = {};
        const startTime = Date.now();

        const doActions = (): Promise<any> => {
            if (actions.length === 0) {
                return Promise.resolve();
            }
            const promises = [];
            for (let i = 0; i < 50; i++) {
                if (actions.length === 0) {
                    break;
                }
                const action = actions.shift();
                promises.push(action());
            }
            return Promise.all(promises).then(() => {
                return doActions();
            }).catch((error: any) => {
                console.log('ERROR!', error);
            });
        }

        let count = 0;
        const doLicensees: any = () => {
            if (licensees.length === 0) {
                return Promise.resolve();
            }
            const licensee = licensees.shift()!;
            console.log(`Licensee: ${licensee.firstName?.trim()} ${licensee.lastName?.trim()}`);
    
            return getDocs(
                query(
                    collection(firestore, 'users'),
                    //where('state', '==', 'active'),
                    where('licenseeId', '==', licensee.id)
                )
            ).then((snap) => {
                snap.docs.forEach((_doc) => {
                    actions.push(() => {
                        return getDoc(
                            doc(firestore, 'userDetails', _doc.id)
                        ).then((detailsDoc) => {
                            count++;
                            console.log(`[] ${licensee.firstName?.trim()} ${licensee.lastName?.trim()}: ${_doc.data()?.firstName?.trim()} ${_doc.data()?.lastName?.trim()} ${detailsDoc.data()?.email}`);
                            data.push([
                                `${licensee.firstName?.trim()} ${licensee.lastName?.trim()}`,
                                regionMap[licensee.id],
                                (detailsDoc.data()?.email) ? (detailsDoc.data()?.email) : '',
                                (_doc.data()?.firstName ?? '').trim(),
                                (_doc.data()?.lastName ?? '').trim(),
                                _doc.data()?.state,
                                'existing customer'
                            ]);
                        });
                    });
                });
                return doLicensees();
            });
        };

        getDocs(
            query(
                collection(firestore, 'licenseeSettings')
            )
        ).then((snap) => {
            snap.docs.forEach((doc) => {
                regionMap[doc.id] = doc.data().region;
            });
            return getDocs(
                query(
                    collection(firestore, 'users'),
                    where('isLicensee', '==', true),
                    where('state', '==', 'active'),
                    orderBy('firstName'),
                    orderBy('lastName')
                )
            );
        }).then((snap) => {
            snap.docs.forEach((doc) => {
                licensees.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            return doLicensees();
        }).then(() => {
            return doActions();
        }).then(() => {
            alertMessage(`Finished generating CSV. ${count} emails found. Took ${Math.round((Date.now() - startTime) / (1000))} seconds.`);
            setCsvData(data);
            setIsLoadingCsv(false);
        });
    };

    useEffect(() => {
        if (csvData && csvData !== '') {
            csvRef.current.link.click();
        }
    }, [csvData]);

    const formatLicenseeNumber = (id: number): string => {
        let s = '' + id;
        while (s.length < 3) {
            s = '0'+s;
        }
        return s;
    };

    return (
        <>
            <div className="columns">
                <div style={{ flex: '1 1 0' }}>
                    <SeaSearchbar
                        value={searchText}
                        setValue={setSearchText}
                        maxWidth="600px"
                    />
                </div>
                <div className="right right-buttons" style={{ flexGrow: 'unset' }}>
                    <SeaButton
                        onClick={(e) => {
                            onDownloadEmailsCSV();
                        }}
                        zone="grey"
                        disabled={isLoadingCsv}
                    >
                        <SeaIcon slot="start" icon="download"/>{isLoadingCsv ? 'Generating...' : 'Emails CSV' }
                    </SeaButton>
                    <CSVLink
                        data={csvData}
                        filename="SeaFlux_Emails.csv"
                        style={{display: 'none'}}
                        ref={csvRef}
                        target="_blank"
                    />
                    <SeaButton
                        onClick={(e) => {
                            setLicenseeToEdit(undefined);
                            setShowEditLicenseeModal(true);
                        }}
                        zone="grey"
                    >
                        <SeaIcon slot="start" icon="add"/>Create New Account
                    </SeaButton>
                </div>
            </div>
            {/* <IonProgressBar style={{display: isLoadingCsv ? 'flex' : 'none'}} value={licenseesCompiled / totalActiveLicensees * 2} /> */}
            <div className="licensee-accounts">
                <div className="sea-row headings">
                    <div className="bold">Name</div>
                    <div>Company</div>
                    <div>ID#</div>
                    <div>Email</div>
                    <div>Status</div>
                    <div>Region</div>
                    <div># Vessels</div>
                    <div>Offline</div>
                    <div></div>
                </div>
                {licenseeSettingsById && licenseeDetailsById && filteredLicensees?.map((user) => {
                    if (!user.id) {
                        return '';
                    }
                    return (
                        <div key={user.id} className="sea-card sea-row" style={{cursor:'default'}}>
                            <div className="bold">{user.firstName} {user.lastName}</div>
                            <div>{licenseeDetailsById[user.id]?.companyName}</div>
                            <div>{user.licenseeNumber ? formatLicenseeNumber(user.licenseeNumber) : '-'}</div>
                            <div>{licenseeDetailsById[user.id]?.email}</div>
                            <div>
                                {
                                    user.whenActivated ? `Joined ${formatDate(user.whenActivated)}` : (
                                        user.whenWelcomed ? `Emailed ${formatDate(user.whenWelcomed)}` : (
                                            `Added ${formatDate(user.whenAdded)}`
                                        )
                                    )
                                }
                            </div>
                            <div>{(licenseeSettingsById[user.id]?.region)}</div>
                            <div>{(licenseeDetailsById[user.id]?.numVessels) ? licenseeDetailsById[user.id].numVessels : 0}</div>
                            <div>{(licenseeSettingsById[user.id]?.hasOffline) ? <SeaIcon icon="tick"/> : '-'}</div>
                            <div>
                                <SeaButton shape="square-outline" onClick={(e) => openPopover(e, user)}>
                                    <SeaIcon slot="icon-only" icon="moveDown"/>
                                </SeaButton>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div style={{ height: '5px' }}></div>
            <IonGrid className="card-grid accounts-summary">
                <IonRow>
                    <IonCol size="6">
                        <div className="sea-card summary">
                            <div><h2>{licensees?.totalActive}</h2></div>
                            <div className="line"></div>
                            <div>Total Active Licensee Holders</div>
                            <div><PeopleIcon /></div>
                        </div>
                    </IonCol>
                    <IonCol size="6">
                        <div className="sea-card summary">
                            <div><h2>{licensees?.totalVessels}</h2></div>
                            <div className="line"></div>
                            <div>Total vessels stored in system</div>
                            <div><DuckIcon /></div>
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonPopover
                event={licenseePopoverState.event}
                isOpen={licenseePopoverState.showPopover}
                onDidDismiss={() => closePopover()}
            >
                <IonList className="account-actions" lines="none">
                    <IonItem button color="primary" onClick={(e) => onAddVessel()}>Add Vessel</IonItem>
                    <IonItem button color="primary" onClick={(e) => onAddVessel(true)}>Add Shore Based Facility</IonItem>
                    <IonItem button color="primary" onClick={(e) => onEditAccount()}>Edit Account</IonItem>
                    <IonItem button color="primary" onClick={(e) => onGoToAccount()}>Go To Account</IonItem>
                    <div className="line"></div>
                    {
                        (actionUser?.uid) ? '' :
                        <IonItem button color="primary" onClick={(e) => onSendWelcomeEmail()}>Send Welcome Email</IonItem>
                    }
                    <IonItem button color="primary" onClick={(e) => onDeactivateAccount()}>Deactivate</IonItem>
                    <IonItem button color="primary" onClick={(e) => onGenerateFakeErrors()}>TEST: Generate fake errors</IonItem>
                </IonList>
            </IonPopover>
            <EditVesselModal
                showModal={showEditVesselModal} 
                setShowModal={setShowEditVesselModal} 
                licenseeUserId={actionUser?.id}
                fromAddShoreFacility={isShoreFacility}
            />
            {licenseeSettingsById && <EditLicenseeAccountModal
                showModal={showEditLicenseeModal}
                setShowModal={setShowEditLicenseeModal}
                licenseeSettingsById={licenseeSettingsById}
                licenseeToEdit={licenseeToEdit}
                licenseeDetailsById={licenseeDetailsById}
            />}
        </>
    );
};

export default LicenseeAccounts;

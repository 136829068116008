import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { Voyage } from './voyages';
import { useState, useEffect } from 'react';
import { CreateableDocument, UpdateableDocument } from '../shared-state';
import { registerFiles } from "../FileSyncSystem/filesToCache";

export interface VoyageDocument extends CreateableDocument, UpdateableDocument {
    deletedBy?: string;
    files: string[];
    name: string;
    state: string;
    vesselId: string;
    voyageId: string;
    whenDeleted?: number;
}

export type VoyageDocumentsData = {
    byId: {
        [id: string]: VoyageDocument
    },
    all: VoyageDocument[]
};

export const useVoyageDocuments = (selectedVoyage: Voyage | undefined) => {
    const [voyageDocuments, setVoyageDocuments] = useState<VoyageDocumentsData>();

    useEffect(() => {
        setVoyageDocuments(undefined);
        if (selectedVoyage) {
            return onSnapshot(
                query(
                    collection(firestore, 'voyageDocuments'),
                    where('vesselId', '==', selectedVoyage.vesselId),
                    where('voyageId', '==', selectedVoyage.id),
                    where('state', '==', 'active'),
                    orderBy('whenAdded', 'desc')
                ),
                (snap) => {
                    const byId = {} as {
                        [id: string]: VoyageDocument
                    };
                    const all = snap.docs.map((doc) => {
                        const o = {
                            id: doc.id,
                            ...doc.data()
                        } as VoyageDocument;
                        byId[o.id] = o;
                        registerFiles(o.files, 'voyageDocuments', o);
                        return o;
                    });
                    setVoyageDocuments({
                        byId,
                        all
                    });
                }, (error) => {
                    console.log(`Failed to access voyage documents for voyageId=${selectedVoyage?.id} (tab)`, error.message, error, {
                        selectedVoyage
                    });
                }
            );
        }
    }, [selectedVoyage]);

    return voyageDocuments;
};

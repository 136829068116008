import React, { useState, useRef, useEffect } from 'react';
import { IonGrid, IonRow, IonCol, isPlatform, IonModal, IonIcon } from '@ionic/react';
import { logPageView, functions } from '../../../lib/firebase';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { firestore } from '../../../lib/firebase';
import { collection, query, where, getDocs } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useFormik } from 'formik';
import { formatTextAreaText } from '../../../lib/util';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import { sharedState } from '../../../shared-state/shared-state';
import { loginUser } from '../../../shared-state/Core/user';
import QueryString from 'query-string';
import Yup from '../../../lib/yup';
import CenteredBoxPageLayout from '../../../layouts/CenteredBoxPageLayout/CenteredBoxPageLayout';
import SeaInput from '../../../components/SeaInput/SeaInput';
import SeaFormError from '../../../components/SeaFormError/SeaFormError';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaInputError from '../../../components/SeaInputError/SeaInputError';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import './Login.css';

const Login: React.FC = () => {
    const user = sharedState.user.use();
    const navigate = useNavigate();
    const location = useLocation();
    const formRef = useRef<HTMLFormElement>(null);
    const [formError, setFormError] = useState('');
    const [passcode, setPasscode] = useState('');
    const [passcodeError, setPasscodeError] = useState('');
    const [showPasscodeModal, setShowPasscodeModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false; };
      }, []);

    useEffect(() => {
        console.log(formError);
    }, [formError]);

    const {handleSubmit, handleChange, handleBlur, values, errors, setFieldValue, setFieldError, touched, resetForm} = useFormik({
        initialValues: {
            email: '',
            password: ''
        }, validationSchema: Yup.object({
            email: Yup.string().min(3).max(200).email().required(),
            password: Yup.string().min(5).max(32).required()
        }), onSubmit: (data) => {
            setLoading(true);
            setFormError('');
            sharedState.userPending.set(true);

            httpsCallable(functions, 'signIn')({
                email: data.email,
                password: data.password,
                passcode: passcode
            }).then((result: any) => {
                if (!isMounted.current) return;
                if (result.data.error) {
                    //reportError(`Failed to reset login for ${selectedUser.email}`, result.data.error);
                    return Promise.reject(result.data.error);
                } else {
                    //onResendWelcomeEmail();
                    return loginUser(result.data.token)
                    .then((userSnapshot) => {
                        return getDocs(
                            query(
                                collection(firestore, 'users'),
                                where('uid', '==', userSnapshot.user.uid)
                            )
                        ).then((userSnapshot) => {
                            // Successfully logged in
                            setTimeout(() => {
                                if (!isMounted.current) return;
                                resetForm(); // (If we come back here later, we want inputs to be blank)
                            }, 2000);
                            setShowPasscodeModal(false);
                            setPasscode('');
                            setPasscodeError('');
                            const qs = QueryString.parse(location.search);
                            if (userSnapshot.docs && userSnapshot.docs.length > 0 && userSnapshot.docs[0].data().isSuperAdmin) {
                                console.log(`Logged in as superAdmin! navigate to ${(qs?.d) ? String(qs.d) : '/admin'}`);
                                navigate((qs?.d) ? String(qs.d) : '/admin');
                            } else {
                                console.log(`Logged in as user! navigate to ${(qs?.d) ? String(qs.d) : '/fleet'}`);
                                navigate((qs?.d) ? String(qs.d) : '/fleet');
                            }
                        });
                    });
                }
            }).catch((error) => {
                if (!isMounted.current) return;
                console.log('signIn error', error);
                // console.log('signIn typeof error', (typeof error));
                if (typeof error === 'string' && error.startsWith('Passcode')) {
                    if (error === 'PasscodeInvalid') {
                        setPasscodeError('Passcode is invalid.\nPlease check your email for the most recent passcode we sent you.');
                    } else if (error === 'PasscodeExpired') {
                        //setPasscodeError('This passcode has expired.\nPlease log in again to receive a new passcode.');
                        setFormError('The passcode you entered had expired.');
                        setShowPasscodeModal(false);
                        setPasscode('');
                        setPasscodeError('');
                    } else {
                        setPasscodeError('There was an error processing your passcode.');
                    }
                } else {
                    setShowPasscodeModal(false);
                    setPasscode('');
                    if (error === 'RequireEmailPasscode') {
                        setPasscode('');
                        setPasscodeError('');
                        setShowPasscodeModal(true);
                        // Make sure password hasn't been wiped by Password Manager
                        setFieldValue('password', data.password);
                        setTimeout(() => {
                            setFieldValue('password', data.password);
                        }, 500);
                        setTimeout(() => {
                            setFieldValue('password', data.password);
                        }, 1000);
                        setTimeout(() => {
                            setFieldValue('password', data.password);
                        }, 2000);
                    } else if (error === 'NoAccount') {
                        setFieldError('email', 'There is no account corresponding to this email');
                    } else if (error === 'BadAccount') {
                        setFieldError('email', 'The account corresponding to this email has been deactivated or deleted');
                    } else if (error === 'AccountArchived') {
                        setFieldError('email', 'This account has been archived');
                    } else if (error === 'AccountDeactivated') {
                        setFieldError('email', 'This account has been deactivated');
                    } else if (error === 'AccountDeleted') {
                        setFieldError('email', 'This account has been deleted');
                    } else if (error === 'LoginDisabled') {
                        setFieldError('email', 'This account is currently disabled');
                    } else if (error === 'BadPassword') {
                        setFieldError('password', 'This password is invalid');
                    } else if (error === 'SendEmailFailed') {
                        setFormError('There was a problem sending a passcode to your email address');
                    } else if (typeof error === 'object' && error.code === 'functions/internal') {
                        if (!sharedState.onlineStatus.current?.isOnline) {
                            // alertMessage('You must be online to login. Please check your internet connection.');
                            setFormError('You must be online to login. Please check your internet connection.');
                        } else {
                            // alertMessage('Unable to login. Please check your internet connection.');
                            setFormError('Unable to login. Please check your internet connection.');
                        }
                    } else if (error.code || error.message) {
                        setFormError(`${error.message ? error.message : ''} ${error.code ? `(Error Code: ${error.code})` : ''}`);
                    } else {
                        setFormError('There was an error logging you in');
                    }
                }
            }).finally(() => {
                if (!isMounted.current) return;
                setLoading(false);
            });

        }
    });

    useEffect(() => {
        if (location?.search) { // Process query string
            const qs = QueryString.parse(location.search);
            if (qs.email) {
                setFieldValue('email', qs.email);
            }
        }
        logPageView('Login');
    }, [location?.search, setFieldValue]);

    return (
        <CenteredBoxPageLayout>
            <form ref={formRef} onSubmit={handleSubmit}>
                <IonGrid className="auth-form-grid" style={{paddingTop: '8px'}}>
                    <IonRow>
                        {user && (user.isLoginDisabled || user.isDeactivated || user.state !== 'active') &&
                            <IonCol size="12" style={{ color: 'var(--ion-color-danger)' }}>
                                This account is currently {
                                    user.isLoginDisabled ? 'disabled' : (
                                        user.isDeactivated ? 'deactivated' : 'archived'
                                    )
                                }.
                            </IonCol>
                        }
                        <IonCol size="12">
                            <SeaInput
                                label="Email"
                                name="email"
                                value={values.email}
                                onchange={handleChange}
                                //onblur={onBlurWithAutofillFix('email', setFieldValue, handleBlur)}
                                onblur={handleBlur}
                                zone="white"
                                type="email"
                                inputmode="email"
                                autocomplete="email"
                                disabled={loading}
                                error={touched.email ? errors.email : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaInput
                                label="Password"
                                name="password"
                                value={values.password}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type={showPassword ? "text" : "password"}
                                enterkeyhint="enter"
                                autocomplete="current-password"
                                disabled={loading}
                                error={touched.password ? errors.password : ''}
                                suffix={
                                    <button
                                        className="show-password-button"
                                        type="button"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        <IonIcon
                                            icon={showPassword ? eyeOffOutline : eyeOutline}
                                        />
                                    </button>
                                }
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className="form-spacer"></div>
                <SeaFormError message={formError}/>
                <SeaButton
                    zone="white"
                    disabled={loading}
                    type="submit"
                    size="wide"
                >
                    Log In
                </SeaButton>
                <p>
                    <Link to="/forgot-password">Forgot Password?</Link>
                </p>
                {!isPlatform('hybrid') && 
                    <>
                        {/* <p>
                            To access the legacy version of Sea Flux <a href="https://legacy.seaflux.co.nz">click here</a>.
                        </p> */}
                        <div className="sea-app-links columns">
                            <div>
                                <a
                                    href="https://apps.apple.com/us/app/sea-flux/id1560908960"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src="/assets/AppStore.png" alt="Apple App Store"/>
                                </a>
                            </div>
                            <div className="right">
                                <a
                                    href="https://play.google.com/store/apps/details?id=nz.co.orchid.seaflux"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src="/assets/GooglePlay.png" alt="Google Play Store"/>
                                </a>
                            </div>
                        </div>
                    </>
                }
                <input type="submit" className="submit-enter"/>
            </form>
            <IonModal
                //ref={modalRef}
                isOpen={showPasscodeModal}
                mode="ios"
                showBackdrop={true}
                backdropDismiss={false}
                cssClass="sea-modal-center"
                // enterAnimation={enterAnimation}
                // leaveAnimation={leaveAnimation}
                onDidDismiss={() => {
                    setShowPasscodeModal(false);
                    setPasscode('');
                    setPasscodeError('');
                }}
                // onDidPresent={onDidPresent}
            >
                <div className="close-button" onClick={(e) => setShowPasscodeModal(false)}>
                    <SeaIcon
                        icon="close"
                        forceFontSize="24px"
                    />
                </div>
                <div style={{ textAlign: 'center' }}>
                    <h2>Authentication Code Required</h2>
                    <p>Please enter the authentication code we sent to you via email.</p>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (passcode.length === 6) {
                            setPasscodeError('');
                            formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                        }
                    }}>
                        <SeaInput
                            value={passcode}
                            placeholder="------"
                            inputmode="tel"
                            type="tel"
                            maxLength={6}
                            disabled={loading}
                            onchange={(event) => {
                                setPasscode(event.detail.value);
                            }}
                        />
                        <div className="passcode-error">
                            <SeaInputError>{passcodeError &&
                                <>
                                    {formatTextAreaText(passcodeError)}
                                </>
                            }</SeaInputError>
                        </div>
                        <div className="submit-container">
                            <SeaButton
                                zone="white"
                                disabled={(loading || passcode.length !== 6)}
                                //type="submit"
                                size="wide"
                                onClick={(e) => {
                                    setPasscodeError('');
                                    formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                                }}
                            >
                                Submit
                            </SeaButton>
                        </div>
                    </form>
                </div>
            </IonModal>
        </CenteredBoxPageLayout>
    );
};

export default Login;
